import {query} from "../../helpers/query";

export const usePartner = () => {
  const createPartner = async partner => {
    const {error, data} = await query('/api/partner', partner);
    return {error, partner_id: data?.partner_id};
  }

  const getPartner = async partner_id => {
    const {error, data} = await query(`/api/partner/${partner_id}`, false, 'GET');
    return {error, partner: data?.partner};
  }

  const editPartner = async partner => {
    const {error, data} = await query(`/api/partner/${partner.partner_id}`, partner, 'PUT');
    return {error, success: data?.success};
  }

  const deletePartner = async partner_id => {
    const {error, data} = await query(`/api/partner/${partner_id}`, false, 'DELETE');
    return {error, success: data?.success};
  }
  const getPartners = async () => {
    const {error, data} = await query('/api/partner', false, 'GET');
    return {error, partners: data?.partners};
  }
  const getPartnersBasicInfo = async () => {
    const {error, data} = await query('/api/partner/basic_info', false, 'GET');
    return {error, partners: data?.partners};
  }
  const deletePartners = async ids => {
    for(let i = 0;i<ids.length;i++){
      let id = ids[i];
      const {error} = await deletePartner(id);
      if(error)return {error};
    }
    return {success: true};
  }
  const getPartnerDemands = async partner_id => {
    const {error, data} = await query(`/api/partner/${partner_id}/demands`, false, 'GET');
    return {error, demands: data?.demands};
  }

  const checkExists = async (phone) => {
    const {error, data} = await query(`/api/partner/exists`, {phone}, 'POST');
    return {error, exists: data?.exists};
  }

  return {createPartner, getPartner, editPartner,getPartnersBasicInfo, deletePartner, getPartners, deletePartners, getPartnerDemands, checkExists};
};

export default usePartner;
