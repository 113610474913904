import React, {useEffect, useState} from 'react';
import s from './AdministratorData.module.css';
import {useTranslation} from "react-i18next";
import useAdmin from "../../../controllers/useAdmin";
import Loader from "../../Loader";
import {NavLink, useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";
import TableV2 from "../../TableV2";
import AnimatedModal from "../../AnimatedModal";
import FilterPage from "../../FilterPage";
import EmailInput from "../../Inputs/EmailInput";
import FilterButton from "../../FilterButton";
import Button from "../../Inputs/Button";
import Prompt from "../../Prompt";
import {useError} from "../../../hooks/useError/useError";

const AdministratorData = props => {
  const {t} = useTranslation();
  const {getAdmins, deleteAdmins} = useAdmin();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {error, ShowError, manageError} = useError();
  const [filter, setFilter] = useState({
    filter: false,
    visible: false,
    data: []
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);


  const toggleFilter = v => setFilter(prev => ({...prev, visible: v ?? !prev.visible}));
  const setFilteredData = data => {
    setFilter(prev => ({...prev, data}));
  }



  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    setLoading(false);
    if (error) return manageError(error);

    setData(admins);
    setFilteredData(admins);
  }

  const removeElements = async () => {
    setDeleteVisible(true);
  }

  const removeConfirmed = async () => {
    setDeleteVisible(false);
    setLoading(true);
    await deleteAdmins(selectedRows);
    await loadAdmins();
    setSelectedRows([]);
    setLoading(false);
  }

  const columns = React.useMemo(() => [
    {
      Header: t('id'),
      accessor: 'admin_id',
    },
    {
      Header: t('email'),
      accessor: 'email',
    },
    {
      Header: t('name'),
      accessor: 'name',
    },
    {
      Header: t('full_access'),
      accessor: 'full_access',
    }
  ], []);


  useEffect(() => {
    loadAdmins();
  }, []);





  if(loading)return (<Loader/>);
  if (error) return (<ShowError/>);

  return (
    <div className="pt-20">
      <div className="menuHeader">
        <NavLink to={`/${bases.admin}/new`}>{t("create new admin")}</NavLink>
        <FilterButton onClick={toggleFilter}/>
        {selectedRows?.length > 0 && <FilterButton icon="remove" text={t('delete')} onClick={removeElements}/>}

      </div>
      <TableV2
        columns={columns}
        data={filter.data}
        renders={{
          admin_id: admin => (<NavLink className="hover:underline" to={`/${bases.admin}/${admin.admin_id}`}>{admin.admin_id}</NavLink>),
          name: admin => (<NavLink className="hover:underline" to={`/${bases.admin}/${admin.admin_id}`}>{admin.name}</NavLink>),
          full_access: ({full_access}) => full_access === 1 ? 'SI' : 'NO'
        }}
        id="admin_id"
        options={{checkbox: true, editable: true}}
        onSelect={rows => {
          setSelectedRows(rows.length > 0 ? rows : []);
        }}
        onEdit={admin_id => navigate(`/${bases.admin}/${admin_id}`)}
      />

      {!data.length && <div className="w-96 pl-8">
        <Button onClick={() => navigate(`/${bases.partner}/new`)} buttonStyle={3}>{t('create new')}</Button>
      </div>}

      <Prompt
        title={t("delete confirmation")}
        message={t("not reversible")}
        visible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onAccept={removeConfirmed}
      />

      <AnimatedModal
        visible={filter.visible}
        onClose={toggleFilter}
        className={s.filter}
      >
        <div className={s.container}>
          <FilterPage
            data={data}
            filters={[
              {name: 'name', label: t('name')},
              {name: 'email', label: t('email'),
                render:({name, label}, register, i) =>
                  <EmailInput key={i} name={name} label={label} spellCheck={false} {...register(name)} containerClassName="mb-4"/>
              }
            ]}
            onFilter={data => {
              setFilteredData(data);
              toggleFilter(false);
            }}
          />
        </div>
      </AnimatedModal>
    </div>
  );
};


export default AdministratorData;
