import React, {useEffect, useState} from 'react';
import s from './AdministratorEdit.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import MiddleText from "../../TextComponents/MiddleText";
import bases from "../../../routes/bases";
import {useForm, FormProvider} from "react-hook-form";
import useAdmin from "../../../controllers/useAdmin";
import Input from "../../Inputs/Input";
import EmailInput from "../../Inputs/EmailInput";
import SubmitButton from "../../Inputs/SubmitButton";
import PasswordInput from "../../Inputs/PasswordInput";
import useAuth from "../../../providers/AuthProvider/useAuth";
import {useError} from "../../../hooks/useError";
import Select from "../../Inputs/Select";

const AdministratorEdit = props => {
  const {t} = useTranslation();
  const {ShowErrors} = useError();
  let {id: admin_id} = useParams();
  const {getAdmin: getLoggedAdmin, downloadAdmin} = useAuth();


  let form = useForm();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError: _setError,
    clearErrors,
  } = form;

  const {
    errors,
  } = formState;

  const {createAdmin, getAdmin, editAdmin} = useAdmin();

  const navigate = useNavigate();


  const isNewAdmin = () => !admin_id;
  const setError = message => _setError('other', {message});


  const texts = {
    title: isNewAdmin() ? t('create new admin') : `${t('edit admin')} (ID:${admin_id})`,
    submit: isNewAdmin() ? t('create') : t('change')
  }

  const onSubmit = async admin => {
    console.log(admin);
    let id = admin_id;
    if (!admin_id) {
      const {error, admin_id} = await createAdmin(admin);
      if (error) return setError(error);
      id = admin_id;
    } else {
      const {error, success} = await editAdmin({admin_id, ...admin});
      if (error) return setError(error);
      if(admin_id*1 === getLoggedAdmin().admin_id)await downloadAdmin();
    }
    navigate(`/${bases.admin}`);
  }

  const loadAdmin = async () => {
    const {error, admin} = await getAdmin(admin_id);
    if (error) return setError(error);
    console.log(admin);
    for (let key in admin) {
      setValue(key, admin[key]);
    }
  }

  useEffect(() => {
    if (!admin_id) return;
    loadAdmin();
  }, [admin_id]);

  return (
    <div className="p-5">
      <MiddleText>{texts.title}</MiddleText>
      <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-11/12 m-auto">
        <div className="w-full lg:w-10/12 m-auto">
          <div className="flex w-full mb-10 flex-wrap lg:flex-nowrap">
            <Input
              containerClassName="lg:mr-10"
              {...register('name', {
                required: 'name is required'
              })}
              label={t("name")} />
            <EmailInput
              {...register('email', {
                required: 'email is required',
              })}
              label={t('email')} />
          </div>
          <div className="flex w-full mb-10 flex-wrap lg:flex-nowrap">
            <PasswordInput
              containerClassName="mr-10"
              {...register('password')}
              label={t('password')} />
            <Select label="Acceso" defaultValue="1" name="full_access" options={[
              {value: 1, label: 'Acceso total'},
              {value: 0, label: 'Acceso limitado'}
            ]}/>
          </div>

          {Object.keys(errors).length > 0 && <div className="my-5"> <ShowErrors errors={errors}/></div>}

          <div style={{width: 200, marginBottom: '30px'}}>
            <SubmitButton onClick={() => clearErrors('other')}
                          loading={formState.isSubmitting}>{texts.submit}</SubmitButton>
          </div>
        </div>
      </form>
      </FormProvider>
    </div>
  );
};


export default AdministratorEdit;
