import React from 'react';
import s from './PossibleAssetSelectState.module.css';
import Select from "../../Inputs/Select";

const PossibleAssetSelectState = ({name = 'not_accepted_condition', filter = false, defaultValue, small= false, ...props}) => {
  const options = React.useMemo(() => {
      let str = ['Interesado','En combate', 'Atacar', 'No vende', 'No localizado', 'Sin atacar', 'Ir a Mapa', "Mandar carta", "Carta enviada", 'NS - rechazada', "No rentable", 'Ya visitado'];
      if(filter)str.push('ignorar');
      return str.map(t => ({value: t, label: t[0].toUpperCase() + t.substring(1, t.length)}))
    }, []);

  const styles = React.useMemo(() => ({
    control: (provided, state) => {
      let bg = '#fff';
      if(state.hasValue){
        let v = state.getValue()[0].value;
        switch(v){
          case 'Interesado':
            bg = '#30b611';
            break;
          case 'En combate':
            bg = 'yellow';
            break;
          case 'Atacar':
            bg = '#2cd2d9';
            break;
          case 'No vende':
            bg = '#de2c2c';
            break;
          case 'No localizado':
            bg = '#6c6c6c';
            break;
        }
      }
      return {
        ...provided,
        backgroundColor: bg
      };
    },
    singleValue: (provided, state) => {
      let color = '#000';

      if(state.hasValue){
        let v = state.getValue()[0].value;
        switch(v){
          case 'No vende':
          case 'Interesado':
          case 'No localizado':
            color = '#fff';
            break;
        }
      }


      return {
        ...provided,
        color
      };
    }
  }), []);

  var params = {
    options,
    defaultValue: defaultValue ? options.find(({value}) => value === defaultValue)?.value : null,
    name,
    ...props
  };
  if(small)params.styles = styles;
  if(filter){
    params.usePortal = false;
    params.isMulti = true;
    params.menuPlacement="top"
  }

  return (
    <Select {...params}/>
  );
};


export default PossibleAssetSelectState;
