import React from 'react';
import s from './NotFoundPage.module.css';

const NotFoundPage = props => {
    return (
        <div>Not found</div>
    );
};


export default NotFoundPage;
