import React from 'react';
import s from './Asset.module.css';
import {Outlet} from "react-router-dom";

const Asset = props => {
    return (
        <Outlet/>
    );
};


export default Asset;
