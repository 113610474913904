import Select from "../Select";
import React from "react";


export const sellTypeOptions = [
  {value: 'asset', label: 'Activo'},
  {value: 'credit', label: 'Credito'}
]

export function SellType(props){

  return <Select
    name={`asset_type`}
    label={"Tipo de venta"}
    options={sellTypeOptions}
    {...props}
  />
}