import React, {useState} from 'react';
import s from './FavoritesStar.module.css';
import {ReactComponent as StarSVG} from "../../../resources/svg/star.svg";
import {ReactComponent as SpinnerSVG} from "../../../resources/svg/spinner.svg";


export const FavoritesStar = ({onChange, width = 20, height = 20, shiny = false}) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await onChange();
    setLoading(false);
  }

  if(loading)return <SpinnerSVG width={width} height={height}/>

  return <StarSVG
    width={width}
    height={height}
    onClick={() => onClick()}
    className={shiny ? s.starOn : s.starOff}
  />

}
