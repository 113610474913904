import React, {useState} from 'react';
import s from './ClientShow.module.css';
import {useNavigate, useParams} from "react-router-dom";
import useClient from "../../../controllers/useClient";
import {useTranslation} from "react-i18next";
import bases from "../../../routes/bases";
import NavBarShow from "../../NavBarShow";
import {useError} from "../../../hooks/useError/useError";
import Loader from "../../Loader";
import ShowTable from "../../ShowTable";
import TableV2 from "../../TableV2";
import {useTableColumns} from "../../../hooks/useTableColumns";
import ShowAddress from "../../ShowAddress";
import Accordion from "../../Accordion";
import FileUploader from "../../FileUploader";
import {ReactComponent as WhiteBell} from "../../../resources/svg/bell.svg";
import OperationData from "../../Operation/OperationData";
import ShowTaskConnectionFilter from "../../ShowTaskConnectionFilter";
import useOperation from "../../../controllers/useOperation";
import AssetData from "../../Asset/AssetData";

const ClientShow = props => {
	const {t} = useTranslation();
	const {error, ShowError, manageError} = useError();
	const {demandColumnData} = useTableColumns();

	const {id: client_id} = useParams();
	const {getClient, deleteClient, getClientDemands} = useClient();
	const {checkOperationsExists} = useOperation();

	const navigate = useNavigate();

	const [client, setClient] = useState(false);
	const [loading, setLoading] = useState(true);
	const [demands, setDemands] = useState([]);

	const loadClient = async () => {
		const {error, client} = await getClient(client_id);
		setLoading(false);
		if (error) return manageError(error);
		if (!client) return manageError('not found');
		setClient(client);
	}
	const loadDemands = async () => {
		let {error, demands} = await getClientDemands(client_id);
		if (error) return manageError(error);

		let data = demands.map(a => ({
			enterprise_id: a.enterprise_id,
			client_id: client_id,
		}));
		const {error: error2, operations} = await checkOperationsExists({data});
		if (error2) return manageError(error2);

		if (Array.isArray(operations)) {
			demands = demands.map(d => {
				if (operations.find(a =>
					a.enterprise_id === d.enterprise_id
				)) {
					return ({
						...d,
						exists: true
					})
				}
				return d;
			});
		}
		setDemands(demands);
	}

	const onDeleteClick = async () => {
		const {error, success} = await deleteClient(client_id);
		if (error || !success) return manageError(error || 'unknown');
		alert(t('deleted'));
		navigate(`/${bases.client}`);
	}


	const createOperation = demand => {
		let p = {
			enterprise_id: demand.enterprise_id,
			enterprise_name: demand.enterprise?.name,
			client_id: client.client_id,
			client_name: client?.name,
			partner_name: demand.partner?.name,
			partner_id: demand.partner_id
		};
		let params = '';
		for (let key in p) {
			if (p[key]) params += key + '=' + p[key] + '&'
		}

		navigate('/' + bases.operation + '/new?' + params.substr(0, params.length - 1));
	}

	useState(() => {
		loadClient();
		loadDemands();
	}, [client_id]);

	let center = [client.lng * 1, client.lat * 1];


	if (loading) return (<Loader/>);
	if (error) return <ShowError/>

	return (
		<div className="showContainer">
			<div className="menuHeader">
				<NavBarShow name={'client'} id={client_id} onDeleteClick={onDeleteClick}/>
				<div className="inline-flex ml-5 items-center"
						 onClick={() => navigate(`/${bases.task}/new?type=client&id=${client_id}`)}>
					<WhiteBell width={15} height={15}/>
					<a href="">Tarea</a>
				</div>
			</div>
			<div className="showDataContainer">

				<div className="flex flex-wrap">
					<ShowTable data={client}/>
					<ShowAddress title="Posición del activo" center={center}/>
				</div>


				{demands &&
				<Accordion sections={[
					{
						title: 'Demandas del cliente', visible: false, content: <TableV2
							columns={demandColumnData.filter(({accessor}) => accessor !== 'person')}
							data={demands}
							id={"demand_id"}
							options={{
								editable: false
							}}
							onDeal={demand => createOperation(demand)}
							onView={demand_id => navigate(`/${bases.demand}/show/${demand_id}`)}
						/>
					},
					{
						title: 'Hoja de Seguimiento',
						loadOnShow: true,
						visible: false,
						content: <OperationData fullPage={false} filter={{client_id}}/>
					},
					{
						title: 'Activos del cliente',
						loadOnShow: true,
						visible: false,
						content: <AssetData fullPage={false} filter={{client_id}}/>
					},
					{
						title: 'Tareas',
						loadOnShow: true,
						visible: false,
						content: <ShowTaskConnectionFilter type="client" id={client_id}/>
					},
					{
						title: 'Archivos',
						visible: false,
						content: <FileUploader name="client_file_upload" resource="client" folder={client_id}/>
					}
				]}/>
				}
			</div>
		</div>)
};


export default ClientShow;
