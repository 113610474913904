import React, {useCallback, useEffect, useState} from 'react';
import s from './SelectOwners.module.css';
import Input from "../../../Inputs/Input";
import {useTranslation} from "react-i18next";
import Button from "../../../Inputs/Button";
import SelectClientAndEnterprise from "../../../SelectClientAndEnterprise";
import EmailInput from "../../../Inputs/EmailInput";
import PhoneInput from "../../../Inputs/PhoneInput";
import Accordion from "../../../Accordion";
import {useFormContext} from "react-hook-form";
import FullAddress from "../../../Inputs/FullAddress";


const SelectOwners = ({defaultOwners}) => {
  const {t} = useTranslation();
  const [ownersNames, setOwnersNames] = useState({});
  const {register, unregister, setValue, watch, getValues} = useFormContext();

  const [list, setList] = useState([0]);

  let owners = watch('owners', []);

  const addOwner = (e) => {
    e?.preventDefault();
    setList(list => [...list, (list[list.length-1] + 1) || 0]);
  }
  const removeOwner = useCallback((e, index) => {
    e?.preventDefault();
    setList(prev => prev.filter(i => i !== index));
    unregister(`owners.${index}`);
  }, [getValues, setValue, list]);

  const setToOwner = (i,owner) => {
    if(!owner)return;
    setValue(`owners.${i}.type`, owner.type);

    setValue(`owners.${i}.enterprise_id`, owner.enterprise_id);
    setValue(`owners.${i}.partner_id`, owner.partner_id);
    setValue(`owners.${i}.client_id`, owner.client_id);

    setValue(`owners.${i}.name`, owner.name);
    setValue(`owners.${i}.surname`, owner.surname);
    setValue(`owners.${i}.email`, owner.email);
    setValue(`owners.${i}.phone`, owner.phone);
    setValue(`owners.${i}.participation`, owner.participation);

    // address

    if(owner.address){
      setValue(`owners.${i}.address_id`, owner.address_id);
      setValue(`owners.${i}.address`, owner.address);
      setValue(`owners.${i}.lng`, owner.lng);
      setValue(`owners.${i}.lat`, owner.lat);
      setValue(`owners.${i}.postcode`, owner.postcode);
      setValue(`owners.${i}.town`, owner.town);
      setValue(`owners.${i}.province`, owner.province);
      setValue(`owners.${i}.country`, owner.country);
    }
  }



  useEffect(() => {

    if (Array.isArray(defaultOwners )) {
      setList([]);
      defaultOwners.forEach((owner, i) => {
        addOwner();
      });

      // need setTimeout to re-render
      setTimeout(() => {
        defaultOwners.forEach((owner, i) => {
          setToOwner(i, owner);
        });
      }, 1000);
    }
  }, [defaultOwners]);


  const limitFeeInput = name => (e) => {
    const value = e.target.value * 1;
    if (value > 100) setValue(name, 100);
    else if (value < 0) setValue(name, 1);
  }

  const onClientSelect = (i, client) => {
    setOwnersNames(prev => ({...prev, [i]: `${client.name} ${client.surname || ''}`}));
  }


  return (
    <div className="mb-10 w-full">
      {<Accordion sections={list.map((i) => ({
          title: (owners && owners[i] && (owners[i].type === 'partner' ? ownersNames[i] : owners[i]?.name)) || 'Propietario',
          header: false,
          visible: true,
          key: i,
          content: (
            <div className={s.container} >
              <input type="hidden" className="hidden" {...register(`owners.${i}.id`)}/>
              <div className="flex flex-wrap md:flex-nowrap w-full my-10">
                <input type="radio" id={`owners_${i}_type`} {...register(`owners.${i}.type`)} defaultChecked={true} value="partner" />
                <label style={{marginBottom: 0}} htmlFor={`owners_${i}_type`}>CRM</label>

                <input type="radio" id={`owners_${i}_particular`} {...register(`owners.${i}.type`)}  value="particular"/>
                <label style={{marginBottom: 0}} htmlFor={`owners_${i}_particular`}>Nuevo</label>
              </div>

              <div className={(owners && owners[i]?.type === 'partner') ? '' : 'hidden'}>
                <SelectClientAndEnterprise
                  defaultData={defaultOwners ? defaultOwners[i] : {}}
                  enterprise_name={`owners.${i}.enterprise_id`}
                  partner_name={`owners.${i}.partner_id`}
                  client_name={`owners.${i}.client_id`}
                  onClientSelect={client => onClientSelect(i,client)}
                />
              </div>

              <div className={owners && owners[i]?.type === 'partner' ? 'hidden' : ''}>
                <div className="flex flex-wrap md:flex-nowrap w-full">
                  <Input
                    containerClassName="md:mr-10 mb-10"
                    label={t('name')}
                    {...register(`owners.${i}.name`)}
                  />
                  <Input
                    containerClassName="md:mr-10 mb-10"
                    label={t('surname')}
                    {...register(`owners.${i}.surname`)}
                  />
                  <Input
                    type="number"
                    min="0.01"
                    step="0.01"
                    max="100"
                    containerClassName="mr-10"
                    defaultValue={100}
                    label="Participación"
                    {...register(`owners.${i}.participation`)}
                    onBlur={limitFeeInput(`owners.${i}.participation`)}
                    icons={{
                      left: {
                        icon: <span className="inputIconText">%</span>
                      }
                    }}
                  />
                </div>
                <div className="flex flex-wrap md:flex-nowrap w-full">
                  <EmailInput
                    label={t("email")}
                    {...register(`owners.${i}.email`)}
                    containerClassName="md:mr-10 mb-10"
                  />
                  <PhoneInput
                    label={t("phone")}
                    {...register(`owners.${i}.phone`)}
                    containerClassName="md:mr-10 mb-10"
                  />
                </div>
                <FullAddress name={`owners.${i}.`} defaultValue={{lng: owners[i]?.lng, lat: owners[i]?.lat}}/>
              </div>
              <div className={s.button}>
                <Button buttonStyle={3} className={s.removeButton} onClick={(e) => removeOwner(e, i)}>{t('remove')}</Button>
              </div>
              {i === list.length - 1 && <hr/>}

            </div>
          )
        })
      )}/>
      }

      <div className={s.buttons}>
        <Button className={s.addOwnerButton} buttonStyle={3} onClick={addOwner}>{t("add owner")}</Button>
        {/*{indexes.length > 1 && <Button onClick={clearOwners} buttonStyle={3}>{t("clear owners")}</Button>}*/}
      </div>
    </div>
  );
}


export default SelectOwners;
