import {query} from "../../helpers/query";

export const useNotification = () => {
  const getNotifications = async () => {
    const {error, data} = await query('/api/notification', false, 'GET');
    return {error, notifications: data?.notifications}
  }

  const completeNotification = async (notification_id) => {
    const {error, data} = await query(`/api/notification/${notification_id}`, {showed: true}, 'PUT');
    return {error, success: data?.success};
  }

  return {
    getNotifications,
    completeNotification
  };
};

export default useNotification;
