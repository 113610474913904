import React from 'react';
import s from './Textarea.module.css';

const Textarea = React.forwardRef(
  ({
     containerClassName = '',
     name,
     label,
     rows = 5,
     cols = 50,
     ...props
   }, ref) => {

    return (
      <div className={`${s.container} ${containerClassName}`}>
        {label && <label htmlFor={name}>{label}</label>}
        <textarea ref={ref} rows={rows} cols={cols} name={name} {...props}/>
      </div>
    );
  });


export default Textarea;
