import {query} from "../../helpers/query";

const downloadjs = require('downloadjs');

export const useDropbox = () => {
  const listFiles = async (resource, folder, allowFolders = false) => {
    const {
      error,
      data
    } = await query(`/api/dropbox/list_files/${encodeURIComponent(resource)}/${encodeURIComponent(folder)}?folders=${allowFolders}`, false, 'GET');
    console.log(data);
    return {error, files: data?.files};
  }

  const uploadFile = async (resource, folder, files) => {
    let formData = new FormData();
    for (const file of files) {
      formData.append('file[]', file, file.name);
    }

    let r = await fetch(`/api/dropbox/upload/${encodeURIComponent(resource)}/${encodeURIComponent(folder)}`, {
      method: 'POST',
      body: formData,
      withCredentials: true
    });

    if (r.ok) {
      return r.json();
    } else {
      return {error: r.error};
    }
    //return {error, success: data?.success};
  }

  const uploadFiles = async ({resource, folder, files}) => {
    let formData = new FormData();
    for (const file of files) {
      formData.append('file[]', file, file.name);
    }

    let r = await fetch(`/api/dropbox/upload_v2/${encodeURIComponent(resource)}/${encodeURIComponent(folder)}`, {
      method: 'POST',
      body: formData,
      withCredentials: true
    });

    if (r.ok) {
      return r.json();
    } else {
      return {error: r.error};
    }
  }
  const createFolder = async ({resource, folder, name}) => {
    const {error, data} = await query(`/api/dropbox/folder/${encodeURIComponent(resource)}/${encodeURIComponent(folder)}`, {name}, 'POST');
    return {error, folder: data?.folder};
  }

  const deleteFile = async (resource, folder , file) => {
    const {error, data} = await query(`/api/dropbox/file/${encodeURIComponent(resource)}/${encodeURIComponent(folder)}/${file.name}`, false, 'DELETE');
    return {error, success: data?.success};
  }

  const moveFile = async ({resource, base, from, to}) => {
    const {error, data} = await query('/api/dropbox/file/move', {resource, base, from, to}, 'POST');
    return {error, success: !!data?.result, newFile: data?.result};
  }
  const renameFile = moveFile;

  const downloadFile = async (resource, folder, file, pdf = false) => {
    let r = await fetch(`/api/dropbox/file/${encodeURIComponent(resource)}/${encodeURIComponent(folder)}/${file.name}`, {
      method: 'GET',
      withCredentials: true
    });
    let blob = await r.blob();
    if (!pdf) return downloadjs(blob, file.name);

    blob = blob.slice(0, blob.size, 'application/pdf');
    let fileURL = URL.createObjectURL(blob);

/*    let a = document.getElementById('dropbox_download_pdf_link');
    if (!a) {
      a = document.createElement('a');
      a.setAttribute('id', 'dropbox_download_pdf_link')
      document.body.appendChild(a);
    }

    a.setAttribute('href', fileURL);
    a.download = file.name;
    a.click();*/

    window.open(fileURL);
  }

  /*  const getFileURL = async (resource, folder, file) => {
      let r = await fetch(`/api/dropbox/file/${resource}/${folder}/${file.name}`,{
        method: 'GET',
        withCredentials: true
      });
      let blob = await r.blob();
      return window.URL.createObjectURL(blob);
    }*/

  const getImageURL = async (resource, folder, file) => {
    let r = await fetch(`/api/dropbox/thumbnail/${resource}/${encodeURIComponent(folder)}/${file.name}`, {
      method: 'GET',
      withCredentials: true
    });


    let blob = await r.blob();
    blob = blob.slice(0, blob.size, 'image/jpeg');
    return window.URL.createObjectURL(blob);
  }

  const getImagesURL = async (resource, folder, fileNames) => {
    const {error, data} = await query(`/api/dropbox/thumbnails/${resource}/${encodeURIComponent(folder)}`, {fileNames}, 'POST');
    console.log(error);
    console.log(data);
    return {error, images: data?.images};
  }


  const getAccount = async () => {
    const {error, data} = await query(`/api/dropbox/account`, false, 'GET');
    return {error, account: data?.account};
  }

  const changeAccount = async () => {
    return new Promise((resolve) => {
      let win = window.open('/api/dropbox/auth', '', 'target="_blank"');
      let timer = window.setInterval(() => {
        if (win.closed !== false) {
          window.clearInterval(timer);
          //window.location.reload();
          window.location = window.location.origin;
        }
      });
    });
  }


  return {
    listFiles,
    uploadFile,
    deleteFile,
    downloadFile,
    getAccount,
    changeAccount,
    getImageURL,
    getImagesURL,
    renameFile,
    moveFile,
    uploadFiles,
    createFolder
  };
}

export default useDropbox;
