import React from 'react';
import s from './Select.module.css';
import ReactSelect, {components as componentsSelect} from 'react-select';
import {Controller, useFormContext} from "react-hook-form";


const styles = {
  control: styles => ({
    ...styles,
    border: 'none',
    fontSize: '18px',
    lineHeight: '24px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    boxShadow: '0 0 2px #ccc',
    padding: '5px 10px'
  }),
  menuPortal: styles => ({...styles, zIndex: 99})
};

const Select = ({name, minSelect, components, small = false, placeholder, usePortal = true,isMulti, label, options, onChange: _onChange, containerClassName, defaultValue, ...props}) => {
  const {control} = useFormContext();

  const NoOptionsMessage = props => {
    return <componentsSelect.NoOptionsMessage {...props}>No encontrado</componentsSelect.NoOptionsMessage>
  }

  let _props = {};
  if(usePortal)_props.menuPortalTarget = document.body;
  if(!small)_props.styles = styles;


  return (
    <div className={`${s.container} ${containerClassName}`}>
      {label && <label className="label" htmlFor={name}>{label}</label> }
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? (isMulti ? [options[0]] : options[0]?.value)}
      render={({field: {onChange, value, ref}}) =>
        <ReactSelect
          isMulti={isMulti}
          inputRef={ref}
          components={{...components, NoOptionsMessage}}
          value={!isMulti ? options.find(c => c.value === value) : options.filter(c => {
            if(value instanceof Array) {
              for (let i = 0; i < value.length; i++) {
                if (value[i] && (value[i].value === c.value)) return true;
              }
            }
            return false;
          }) || options[0]}
          onChange={val => {
            if(isMulti && val.length < minSelect)return;
            onChange(isMulti ? val : val.value);
            _onChange && _onChange(isMulti ? val : val.value);
          }}
          options={options}
          placeholder={placeholder || 'Seleccionar'}
          {..._props}
          {...props}
        />
      }
    />
    </div>
  );
};


export default Select;
