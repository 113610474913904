import React from 'react';
import s from './NumberInput.module.css';
import Input from "../Inputs/Input";
import {Controller} from "react-hook-form";
import MaskedInput from "react-text-mask";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'


const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 50,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const NumberInput = ({name, containerClassName, onBlur, formatFunction, onChange: _onChange, withDecimals= true, maskOptions = {}, ...props}) => {
  return (
    <Controller
      name={name}
      render={({field: {onChange, value, ref}}) =>
        <MaskedInput
          value={value}
          mask={createNumberMask({
            ...defaultMaskOptions,
            allowDecimal: withDecimals,
            ...maskOptions
          })}
          containerClassName={`${s.container} ${containerClassName ? containerClassName : ''}`}
          type="string"
          {...props}
          onChange={e => {
            let v = e.target.value.replace(/[^\d.]/g, '');
            onChange(v);
            _onChange && _onChange(v);
          }}
          onBlur={e => {
            onBlur && onBlur(e.target.value.replace(/[^\d.]/g, ''));
          }
          }
          render={(ref, props) => {
            return <Input ref={ref} {...props} />
          }}
          ref={ref}
        />
      }
    />
  );
};


export default NumberInput;
