import React, {useState} from 'react';
import s from './Button.module.css';

const Button = ({children, buttonStyle = 0, loadButton = false, onClick, className = '', ...props}) => {
  const [loading, setLoading] = useState(false);

  const _onClick = async e => {
    if (typeof onClick !== 'function') return;
    if(loadButton){
      setLoading(true);
      await onClick(e);
      setLoading(false);
    }else{
      onClick(e);
    }
  }


  const buttonsCSS = [s.button, s.button2, s.button3, s.button4, s.button5];
  const _className = `${buttonsCSS[buttonStyle]} ${className} ${loading ? s.loading : ''}`;

  const fill = buttonStyle === 3 ? 'var(--color-primary)' : '#fff';

  return (
    <button disabled={loading} onClick={_onClick} className={_className} {...props}>
      {loading ? (
        <div className="m-auto" style={{width: 20}}>
        <svg version="1.1" id="L4" x="0px"
             y="0px" viewBox="0 0 100 100" width={40} height={40}>
  <circle fill={fill} stroke="none" cx="6" cy="50" r="6">
    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/>
  </circle>
          <circle fill={fill} stroke="none" cx="26" cy="50" r="6">
    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/>
  </circle>
          <circle fill={fill} stroke="none" cx="46" cy="50" r="6">
    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/>
  </circle>
</svg></div>) : children}
    </button>
  );
};


export default Button;
