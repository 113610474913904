import React, {useMemo} from 'react';
import s from './OrderList.module.css';
import {arrayMove, useSortable, verticalListSortingStrategy, SortableContext} from "@dnd-kit/sortable";
import {closestCenter, DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {CSS} from '@dnd-kit/utilities';


const Item = ({content, id, items}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const index = useMemo(() => items.findIndex(item => item.id === id), [items]);


  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className={s.list}>
        {index + 1}. {content}
      </div>
    </div>
  );
}

const OrderList = ({items, setItems}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  function handleDragEnd(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(a => a.id === active.id);
        const newIndex = items.findIndex(a => a.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }


  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {items.map(({id, content}) => <Item key={id} id={id} content={content} items={items}/>)}
      </SortableContext>
    </DndContext>
  );
};


export default OrderList;
