import {useParams} from "react-router-dom";
import PDFGenerator from "../../PDFGenerator";


const AssetPDF = (props) => {
  const {id: asset_id} = useParams();

  if(!asset_id)return 'No encontrado';

  return <div className="p-10">
    <PDFGenerator asset_id={asset_id}/>
  </div>
}

export default AssetPDF;
