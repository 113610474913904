import React from 'react';
import s from './Administrator.module.css';
import {Outlet} from "react-router-dom";

const Administrator = props => {
    return (
      <Outlet/>
    );
};


export default Administrator;
