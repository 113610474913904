import {query} from "../../helpers/query";
import usePartner from "../usePartner";

export const useClient = () => {
  const {getPartners} = usePartner();

  const createClient = async client => {
    const {error, data} = await query('/api/client', client);
    return {error, client_id: data?.client_id};
  }

  const getClient = async client_id => {
    const {error, data} = await query(`/api/client/${client_id}`, false, 'GET');
    return {error, client: data?.client};
  }

  const editClient = async client => {
    const {error, data} = await query(`/api/client/${client.client_id}`, client, 'PUT');
    return {error, success: data?.success};
  }

  const deleteClient = async client_id => {
    const {error, data} = await query(`/api/client/${client_id}`, false, 'DELETE');
    return {error, success: data?.success};
  }
  const getClients = async () => {
    const {error, data} = await query('/api/client', false, 'GET');
    return {error, clients: data?.clients};
  }
  const getClientsBasicInfo = async () => {
    const {error, data} = await query('/api/client/basic_info', false, 'GET');
    return {error, clients: data?.clients};
  }
  const deleteClients = async ids => {
    for(let i = 0;i<ids.length;i++){
      let id = ids[i];
      const {error} = await deleteClient(id);
      if(error)return {error};
    }
    return {success: true};
  }
  const getClientDemands = async client_id => {
    const {error, data} = await query(`/api/client/${client_id}/demands`, false, 'GET');
    return {error, demands: data?.demands};
  }

  const getClientsAndPartners = async () => {
    let a = await getClients();
    if(a.error)return {error: a.error};
    let b = await getPartners();
    return {error: b.error, clients: [...(a.clients || []), ...(b.partners || [])]}
  }

  const checkExists = async (phone) => {
    const {error, data} = await query(`/api/client/exists`, {phone}, 'POST');
    return {error, exists: data?.exists};
  }


  return {createClient, getClient, getClientsBasicInfo, editClient, deleteClient, getClients, deleteClients, getClientDemands, getClientsAndPartners,checkExists};
};

export default useClient;
