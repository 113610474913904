import {query} from "../../helpers/query";

export const useOperation = () => {

  const createOperation = async operation => {
    const {error, data} = await query('/api/operation', operation);
    return {error: error || data?.error, operation_id: data?.operation_id};
  }

  const getOperation = async operation_id => {
    const {error, data} = await query(`/api/operation/${operation_id}`, false, 'GET');
    return {error, operation: data?.operation};
  }

  const editOperation = async operation => {
    const {error, data} = await query(`/api/operation/${operation.operation_id}`, operation, 'PUT');
    return {error, success: data?.success};
  }

  const deleteOperation = async operation_id => {
    const {error, data} = await query(`/api/operation/${operation_id}`, false, 'DELETE');
    return {error, success: data?.success};
  }

  const getOperations = async (filter = false) => {
    const {error, data} = await query('/api/operation', filter, 'GET');
    return {error, operations: data?.operations};
  }

  const getOperationsBasicInfo = async () => {
    const {error, data} = await query('/api/operation/basic_info', false, 'GET');
    return {error, operations: data?.operations};
  }

  const deleteOperations = async ids => {
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      const {error} = await deleteOperation(id);
      if (error) return {error};
    }
    return {success: true};
  }

  const setOperationState = async (operation_id, state) => {
    const {error, data} = await query(`/api/operation/state/${operation_id}`, {state}, 'PUT');
    return {error, success: data?.success};
  }

  const updateLastDateOperation = async ({operations}) => {
    const {error, data} = await query(`/api/operation/last_date`, {operations}, 'PUT');
    return {error, success: data?.success}
  }

  const checkOperationsExists = async ({data: d}) => {
    const {error, data} = await query(`/api/operations/exists`, {data: d}, 'POST');
    return {error, operations: data?.operations};
  }


  return {
    createOperation,
    getOperation,
    editOperation,
    deleteOperation,
    getOperations,
    deleteOperations,
    setOperationState,
    updateLastDateOperation,
    getOperationsBasicInfo,
    checkOperationsExists
  };
};

export default useOperation;
