module.exports = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3.672008514404297,
              40.47150204787303
            ],
            [
              -3.673381805419922,
              40.47306908282607
            ],
            [
              -3.673381805419922,
              40.48233995802499
            ],
            [
              -3.676643371582031,
              40.483906740037675
            ],
            [
              -3.684883117675781,
              40.482470524589516
            ],
            [
              -3.6944961547851562,
              40.485342924756075
            ],
            [
              -3.7130355834960938,
              40.48025085845379
            ],
            [
              -3.726425170898438,
              40.473330251762974
            ],
            [
              -3.7319183349609375,
              40.47254674190491
            ],
            [
              -3.743934631347656,
              40.474113752478836
            ],
            [
              -3.7494277954101562,
              40.474113752478836
            ],
            [
              -3.7513160705566406,
              40.4718938100396
            ],
            [
              -3.746509552001953,
              40.46105421367483
            ],
            [
              -3.7432479206376147,
              40.4549153532153
            ],
            [
              -3.74359130859375,
              40.45295602435479
            ],
            [
              -3.738956451416015,
              40.43923912266282
            ],
            [
              -3.7377548217773438,
              40.43792063761475
            ],
            [
              -3.738269805908203,
              40.434666200029056
            ],
            [
              -3.736038208007812,
              40.42813291388417
            ],
            [
              -3.726253509521484,
              40.42251378015387
            ],
            [
              -3.72161865234375,
              40.41924662614147
            ],
            [
              -3.723163604736328,
              40.414541645611635
            ],
            [
              -3.7223052978515625,
              40.40800640462028
            ],
            [
              -3.722820281982422,
              40.403692797907254
            ],
            [
              -3.720932006835937,
              40.400032551179976
            ],
            [
              -3.7147521972656254,
              40.39964037008388
            ],
            [
              -3.712005615234375,
              40.39702577105907
            ],
            [
              -3.7054824829101567,
              40.395587698315374
            ],
            [
              -3.701534271240234,
              40.39258071969131
            ],
            [
              -3.690032958984375,
              40.38185909446202
            ],
            [
              -3.6869430541992183,
              40.381336044695495
            ],
            [
              -3.682479206376147,
              40.38434352539334
            ],
            [
              -3.679206376147234,
              40.389704353619976
            ],
            [
              -3.6668586730957027,
              40.40029400397483
            ],
            [
              -3.665313720703125,
              40.406176423413704
            ],
            [
              -3.658447265625,
              40.41872386677478
            ],
            [
              -3.6601638793945317,
              40.434012899977404
            ],
            [
              -3.6587920637614762,
              40.439500423133325
            ],
            [
              -3.660678863525391,
              40.444726219263586
            ],
            [
              -3.66342544555664,
              40.45295602435479
            ],
            [
              -3.6661720275878906,
              40.46209907022882
            ],
            [
              -3.672008514404297,
              40.47150204787303
            ]
          ]
        ]
      }
    }
  ]
}
