import React from 'react';
import s from './Text.module.css';

const Text = ({className, children, ...props}) => {
    return (
      <span className={`${s.text} ${className}`} {...props}>{children}</span>
    );
};


export default Text;
