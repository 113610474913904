import React, {useCallback, useEffect, useState} from 'react';
import s from './OperationEdit.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import MiddleText from "../../TextComponents/MiddleText";
import {useForm, FormProvider} from "react-hook-form";
import SubmitButton from "../../Inputs/SubmitButton";
import Textarea from "../../Inputs/Textarea";
import useOperation from "../../../controllers/useOperation";
import bases from "../../../routes/bases";
import SelectAsset from "../../SelectAsset";
import SelectClientAndEnterprise from "../../SelectClientAndEnterprise";
import OperationSelectState from "../OperationSelectState";
import Button from "../../Inputs/Button";

const OperationEdit = props => {
	const {t} = useTranslation();
	let {id: operation_id} = useParams();

	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	const [list, setList] = useState([0]);


	const form = useForm();
	const {
		unregister,
		getValues,
		register,
		handleSubmit,
		formState,
		setError: _setError,
		clearErrors,
		setValue
	} = form;


	const {createOperation, getOperation, editOperation} = useOperation();

	const [state, setState] = useState({
		operation: false
	});

	const navigate = useNavigate();

	const setError = message => _setError('other', {message});
	const isNewOperation = () => !operation_id;

	const texts = {
		title: isNewOperation() ? t('create new operation') : `${t('edit operation')} (ID:${operation_id})`,
		submit: isNewOperation() ? t('create') : t('change')
	}

	const add = e => {
		e?.preventDefault();
		setList(list => [...list, (list[list.length - 1] + 1) || 0]);
	}
	const remove = useCallback((e, index) => {
		e?.preventDefault();
		setList(prev => prev.filter(i => i !== index));
		unregister(`row.${index}`);
	}, [getValues, setValue, list]);


	const onSubmit = async operation => {
		let id = operation_id;
		console.log(operation);

		if (!operation.asset_id) return alert('Falta seleccionar el activo');

		let rows = operation.row.filter(a => a);

		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			if (!row.enterprise_id) return alert('Falta seleccionar la empresa del comprador');
			if (!row.client_id && !row.partner_id) return alert('Falta seleccionar el cliente');
		}


		if (!operation_id) {
			for (let i = 0; i < rows.length; i++) {
				let row = rows[i];
				const {error} = await createOperation({
					asset_id: operation.asset_id,
					notes: operation.notes,
					enterprise_id: row.enterprise_id,
					client_id: row.client_id,
					partner_id: row.partner_id,
					state: row.state
				});
				if (error) return alert(error);
			}
		} else {
			let row = rows[0];
			const {error, success} = await editOperation({
				operation_id,
				asset_id: operation.asset_id,
				notes: operation.notes,
				enterprise_id: row.enterprise_id,
				client_id: row.client_id,
				partner_id: row.partner_id,
				state: row.state
			});
			if (error) return alert(error);
		}

		navigate(`/${bases.operation}`);
	}

	const loadOperation = async () => {
		const {error, operation} = await getOperation(operation_id);
		if (error) return setError(error);

		console.log(operation);
		setState(prev => ({
			...prev, operation: {
				enterprise: operation.enterprise,
				enterprise_id: operation.enterprise_id,
				client: operation.client,
				client_id: operation.client_id
			}
		}));

		/*		setState(prev => ({
					...prev, operation: {
						asset_id: operation.asset_id,
						notes: operation.notes,
						enterprise_id: operation.enterprise_id,
						client_id: operation.client_id,
						partner_id: operation.partner_id,
						state: operation.state
					}
				}));*/


		setValue('asset_id', operation.asset_id);
		setValue('notes', operation.notes);
/*		setValue('row.0.enterprise_id', operation.enterprise_id);
		setValue('row.0.client_id', operation.client_id);
		setValue('row.0.partner_id', operation.partner_id);*/
		setValue('row.0.state', operation.state);


	}

	useEffect(() => {
		if (!operation_id) return;
		loadOperation();
	}, [operation_id]);

	useEffect(() => {

		if (isNewOperation) {
			setState({
				operation: {
					asset_id: params.asset_id,
					enterprise: {enterprise_id: params.enterprise_id, name: params.enterprise_name},
					client: {client_id: params.client_id || params.partner_id, name: params.client_name || params.partner_name}
				}
			});
			setValue('asset_id', params.asset_id * 1);
			setValue('row.0.enterprise_id', params.enterprise_id * 1);
		}

	}, []);

	return (
		<div className="editContainer">
			<div className="menuHeader">
				<NavLink to={`/${bases.operation}`}>{t("see all operations")}</NavLink>
			</div>

			<MiddleText>{texts.title}</MiddleText>
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
					<SelectAsset defaultValue={state?.operation?.asset_id} className="md:mr-10 mb-10"/>
					{list.map(i => <div key={i} className="flex flex-wrap md:flex-nowrap w-full mb-10 items-center">
						<SelectClientAndEnterprise
							enterprise_name={`row.${i}.enterprise_id`}
							client_name={`row.${i}.client_id`}
							partner_name={`row.${i}.partner_id`}
							defaultData={state?.operation}
							enterprise_params={{containerClassName: "mr-12"}}
							containerClassName="mr-12"
						/>
						<OperationSelectState name={`row.${i}.state`} containerClassName="mr-10" label="Estado"/>
						{list.length > 1 &&
						<div className={s.remove_button}>
							<Button buttonStyle={3} onClick={(e) => remove(e, i)}>{t('remove')}</Button>
						</div>
						}
					</div>)}

					{isNewOperation() && <div className={s.add_button}>
						<Button buttonStyle={3} onClick={(e) => add(e)}>Agregar</Button>
					</div>}

					<Textarea
						containerClassName="mb-10"
						name="notes"
						label={t('notes')}
						{...register('notes')}
					/>

					<div style={{width: 200, marginBottom: '30px'}}>
						<SubmitButton onClick={() => clearErrors('other')}
													loading={formState.isSubmitting}>{texts.submit}</SubmitButton>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};


export default OperationEdit;
