import {query} from "../../helpers/query";

export const useEnterprise = () => {
  const createEnterprise = async enterprise => {
    const {error, data} = await query('/api/enterprise', enterprise);
    return {error, enterprise_id: data?.enterprise_id};
  }

  const getEnterprise = async enterprise_id => {
    const {error, data} = await query(`/api/enterprise/${enterprise_id}`, false, 'GET');
    return {error, enterprise: data?.enterprise};
  }

  const editEnterprise = async enterprise => {
    const {error, data} = await query(`/api/enterprise/${enterprise.enterprise_id}`, enterprise, 'PUT');
    return {error, success: data?.success};
  }

  const deleteEnterprise = async enterprise_id => {
    const {error, data} = await query(`/api/enterprise/${enterprise_id}`, false, 'DELETE');
    return {error, success: data?.success};
  }

  const getEnterprises = async () => {
    const {error, data} = await query('/api/enterprise', false, 'GET');
    return {error, enterprises: data?.enterprises}
  }
  const getEnterprisesBasicInfo = async () => {
    const {error, data} = await query('/api/enterprise/basic_info', false, 'GET');
    return {error, enterprises: data?.enterprises}
  }
  const deleteEnterprises = async ids => {
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      const {error} = await deleteEnterprise(id);
      if (error) return {error};
    }
    return {success: true};
  }

  const getEnterpriseClients = async enterprise_id => {
    const {error, data} = await query(`/api/enterprise/${enterprise_id}/clients`, false, 'GET');
    return {error, clients: data?.clients};
  }

  const getEnterprisePartners = async enterprise_id => {
    const {error, data} = await query(`/api/enterprise/${enterprise_id}/partners`, false, 'GET');
    return {error, partners: data?.partners};
  }

  const getEnterpriseClientsAndPartners = async enterprise_id => {
    let a = await getEnterpriseClients(enterprise_id);
    if (a.error) return {error: a.error};
    let b = await getEnterprisePartners(enterprise_id);

    return {error: b.error, clients: [...(a.clients || []), ...(b.partners || [])]};
  }

  const getEnterpriseDemands = async enterprise_id => {
    const {error, data} = await query(`/api/enterprise/${enterprise_id}/demands`, false, 'GET');
    return {error, demands: data?.demands};
  }

  return {
    getEnterpriseDemands,
    createEnterprise,
    getEnterprise,
    editEnterprise,
    deleteEnterprise,
    getEnterprises,
    deleteEnterprises,
    getEnterpriseClients,
    getEnterprisePartners,
    getEnterprisesBasicInfo,
    getEnterpriseClientsAndPartners
  };
};

export default useEnterprise;
