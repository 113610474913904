import React, {useEffect} from 'react';
import s from './TaskPeriodicChoose.module.css';
import Input from "../../Inputs/Input";
import Select from "../../Inputs/Select";
import {useFormContext} from "react-hook-form";

const TaskPeriodicChoose = props => {
  const {register, watch, setValue} = useFormContext();
  const allMonthsOptions = React.useMemo(() => [
    {value: 1, label: 'Enero'},
    {value: 2, label: 'Febrero'},
    {value: 3, label: 'Marzo'},
    {value: 4, label: 'Abril'},
    {value: 5, label: 'Mayo'},
    {value: 6, label: 'Junio'},
    {value: 7, label: 'Julio'},
    {value: 8, label: 'Agosto'},
    {value: 9, label: 'Septiembre'},
    {value: 10, label: 'Octubre'},
    {value: 11, label: 'Noviembre'},
    {value: 12, label: 'Diciembre'},
  ], []);
  const allWeeksOptions = React.useMemo(() => [
    {value: 1, label: 'Lunes'},
    {value: 2, label: 'Martes'},
    {value: 3, label: 'Miércoles'},
    {value: 4, label: 'Jueves'},
    {value: 5, label: 'Viernes'},
    {value: 6, label: 'Sábado'},
    {value: 7, label: 'Domingo'},
  ], []);


  const periodicType = watch('task.periodic');
  const periodicMonthType = watch('task.periodic_month_type');

  return (
    <div className="ml-10">
      <div className="label">Tipo de repetición</div>
      <div className="flex flex-wrap md:flex-nowrap w-full my-10">
        <input type="radio" id={`task_periodic_day`} {...register(`task.periodic`)}
               value="day" className="mr-5"/>
        <label htmlFor={`task_periodic_day`}>Diaria</label>

        <input type="radio" id={`task_periodic_month`} {...register(`task.periodic`)}
               value="month" className="mx-5"/>
        <label htmlFor={`task_periodic_month`}>Mensual</label>


        <input type="radio" id={`task_periodic_week`} {...register(`task.periodic`)} value="week"
               className="mx-5"/>
        <label htmlFor={`task_periodic_week`}>Semanal</label>

        <input type="radio" id={`task_periodic_xdays`} {...register(`task.periodic`)} value="xdays"
               className="mx-5"/>
        <label htmlFor={`task_periodic_xdays`}>Cada X días</label>
      </div>

      {(periodicType === 'month' && <>
        <hr/>
        <div>
          <div className="flex mt-10">
            <div>
              <input type="radio"
                     id={`task_periodic_data_all_months`} {...register(`task.periodic_month_type`)}
                     value="all" className="mr-5"/>
              <label htmlFor={`task_periodic_data_all_months`}>Todos los meses</label>
            </div>
            <div>
              <input type="radio"
                     id={`task_periodic_data_select_months`} {...register(`task.periodic_month_type`)}
                     value="select" className="mx-5"/>
              <label htmlFor={`task_periodic_data_select_months`}>Elegir meses</label>
            </div>
          </div>

          <div className={s.monthSelectContainer}>
            {periodicMonthType === 'select' &&
            <Select name="task.periodic_data.months" isMulti={true} minSelect={1} placeholder="Selecciona los meses"
                    options={allMonthsOptions} containerClassName="mt-10" closeMenuOnSelect={false}/>}

            <div className="flex items-end mt-10" style={{width: '100px'}}>
              <Input type="number" {...register('task.day_of_month')} onChange={e => {
                let n = e.target.value;
                if (n < 1) n = 1;
                if (n > 32) n = 32;
                setValue('task.day_of_month', n);
                e.target.value = n;
              }} label="Día del mes"/>
            </div>
          </div>
        </div>
      </>)}

      {periodicType === 'week' && <div>
        <Select name="task.weeks" isMulti={true} minSelect={1} placeholder="Selecciona los días de la semana"
                options={allWeeksOptions} containerClassName="mt-10" closeMenuOnSelect={false}/>
      </div>}

      {periodicType === 'xdays' && <div>
        <div className="flex items-center">
          <label className="w-full mr-5" htmlFor="task_xdays_start_date">Primera / última repetición</label>
          <Input type="date" {...register('task.xdays.start_date')} id="task_xdays_start_date"/>
        </div>
        <div className="flex items-center mt-5 justify-start">
          <label htmlFor="task_xdays_days" className="w-full" style={{marginRight: '-15px'}}>Repetir cada</label>
          <div className="w-full flex items-center">
            <Input containerClassName={s.xdays_days_container} type="number" {...register('task.xdays.days')}
                   id="task_xdays_days"/>
            <label htmlFor="task_xdays_days" className="ml-5"> días</label>

          </div>
        </div>
      </div>}

    </div>
  );
};


export default TaskPeriodicChoose;
