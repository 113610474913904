import React from 'react';
import s from './PublicDocs.module.css';
import FileExplorer from "../FileExplorer";

const PublicDocs = props => {
    return <div className={s.container}>
      <FileExplorer resource="public" minHeight={"700px"} folder="Documentos"/>
    </div>

};


export default PublicDocs;
