import React, {useEffect} from 'react';
import s from './EditableTable.module.css';

import {ReactComponent as MinusSVG} from "../../resources/svg/minus.svg";

const addSize = 15;

const EditableTable = ({name = 'Tabla', table, setTable}) => {

  useEffect(() => {
    if (table instanceof Array) return setTable(table);
    if (typeof table !== 'object') return setTable([]);
  }, [table]);


  const addColumn = () => {
    setTable(prev => prev.map((p, i) => [...p, i ? 'contenido' : 'Nueva columna']));
  }
  const addRow = () => {
    setTable(prev => [...prev, new Array(prev[0].length).fill('contenido')]);
  }
  const removeColumn = k => {
    setTable(prev => prev.map((p,i) => p.filter((a,_i) => _i !== k)));
  }
  const removeRow = i => {
    setTable(prev => prev.filter((a,_i) => _i !== i));
  }

  const setTd = (i, j, value) => {
    setTable(prev => {
      let n = [...prev];
      n[i][j] = value;
      return n;
    });
  }
  let size = table.length > 0 ? Math.floor(100 / table[0].length) + '%' : 0;

  if(table.length === 0)return <div/>

  return (
        <div>
          {name && <label className="label mt-5">{name}</label>}
          <div className={s.table}>
            {table.map((tr, i) => (
              <div className={`${s.tr} ${!i ? s.thead : ''}`} key={i}>
                {tr.map((td, j) => (
                  <div key={j} className={!i ? s.th : s.td} style={{flexBasis: size}}>
                    {!i && tr.length > 1 && <MinusSVG onClick={() => removeColumn(j)} width={addSize} height={addSize}/>}

                    <input type="text" value={td} onChange={e => setTd(i, j, e.target.value)}/>
                  </div>
                ))}
                <div className={s.th} style={{flexBasis: '1%'}}>
                  {!i && <svg onClick={addColumn} height={addSize} width={addSize} viewBox="0 0 512 512">
                    <path
                      d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/>
                    <path
                      d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
                    <path
                      d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"/>
                  </svg>}
                </div>
                {i !== 0 && table.length > 2 && <div className={s.th} style={{flexBasis: '1%'}}>
                  <MinusSVG onClick={() => removeRow(i)} width={addSize} height={addSize}/>
                </div>
                }
              </div>
            ))}

            <svg onClick={addRow} height={addSize} width={addSize} viewBox="0 0 512 512">
              <path
                d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/>
              <path
                d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
              <path
                d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"/>
            </svg>

          </div>
        </div>
  );
};


export default EditableTable;
