export function getAddressParts(address){
  let r = {
    calle: '',
    nCalle: '',
    escalera: '',
    piso: ''
  };

  let parts = address.split(',');
  if(!isNaN(Number(parts[0]?.charAt(parts[0]?.length - 1)))){
    const calleParts = parts[0].split(' ');
    r.nCalle = calleParts.pop();
    r.calle = calleParts.join(' ');
  }

  return r;
}

export function validarDatos(datos){
  if(!datos.calle?.length){
    return 'Introduce la calle'
  }
  if(!datos.ciudad?.length){
    return 'Introduce la ciudad'
  }
  return false;
}