import React from 'react';
import s from './AdministratorShow.module.css';

const AdministratorShow = props => {
    return (
        <div/>
    );
};


export default AdministratorShow;