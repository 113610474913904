import React, {useEffect, useRef, useState} from 'react';
import s from './Map.module.css';
import useMap from "../../providers/MapProvider/useMap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {isMobile} from "react-device-detect";


const Map = ({
               interactive = true,
               onDraw = false,
               defaultDrawn,
               sidebar = false,
               center = [-2.1805, 40.1035],
               zoom: _zoom = 5,
               markers: _markers = [],
               hideDrawControls = false,
               onLoad = () => 1
             }) => {
  const mapContainer = useRef();
  const {mapboxgl} = useMap();
  const [lng, setLng] = useState(center[0]);
  const [lat, setLat] = useState(center[1]);
  const [zoom, setZoom] = useState(_zoom);
  const [map, setMap] = useState(null);

  const [markers, setMarkers] = useState([]);
  const [draw, setDraw] = useState(null);


  useEffect(() => setZoom(_zoom), [_zoom]);
  useEffect(() => {
    setLng(center[0].toFixed(4));
    setLat(center[1].toFixed(4));
    if (map) map.setCenter(center);
  }, [center, map]);

  useEffect(() => {
    if (!map) return;
    removeAllMarkers();
    if (!_markers || !_markers.length) return;
    let newMarkers = [];

    _markers.forEach(_marker => {
      let marker = new mapboxgl.Marker(_marker.options).setLngLat(_marker.center);
      if (_marker.popup) {
        marker.setPopup(new mapboxgl.Popup(_marker.popup.options || {offset: 25}).setDOMContent(_marker.popup.component))
      }
      marker.addTo(map);
      newMarkers.push(marker);
    });
    setMarkers(newMarkers);
  }, [_markers, map]);

  useEffect(() => {
    if (map && defaultDrawn instanceof Array && draw) {
      defaultDrawn.forEach(d => draw.add(d));
    }
  }, [defaultDrawn, map, draw]);


  const removeAllMarkers = () => {
    markers.forEach(marker => marker.remove());
    setMarkers([]);
  }


  useEffect(() => {
    let map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [lng, lat],
      zoom: zoom,
      locale: 'es',
      interactive,
      testMode: true
    });

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    map.on('load', () => {
      if (onLoad) onLoad(map);
      map.resize();
    });

    if (onDraw) {
      let draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: hideDrawControls ? false : !isMobile,
          trash: hideDrawControls ? false : !isMobile
        }
      });
      map.addControl(draw);
      map.on('draw.create', onDraw);
      map.on('draw.delete', onDraw);
      map.on('draw.update', onDraw);
      setDraw(draw);
    }


    setMap(map);
    return () => map.remove();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        if (map) map.resize();
      }, 500);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className={s.container}>
      {sidebar && <div className={s.sidebar}>
        Zoom: {zoom}
      </div>}
      <div className="map-container" ref={mapContainer}/>
    </div>
  );
};


export default Map;
