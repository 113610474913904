import React from 'react';
import s from './Enterprise.module.css';
import {Outlet} from "react-router-dom";

const Enterprise = props => {
  return (
    <Outlet/>
  );
};


export default Enterprise;
