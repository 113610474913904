import React from 'react';
import s from './Client.module.css';
import {Outlet} from "react-router-dom";

const Client = props => {
    return (
      <Outlet/>
    );
};


export default Client;
