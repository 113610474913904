import React, {useCallback, useEffect, useState} from 'react';
import s from './PossibleAssetsContacts.module.css';
import {useFormContext} from "react-hook-form";
import SelectEnterprise from "../../Inputs/SelectEnterprise";
import Button from "../../Inputs/Button";
import {useTranslation} from "react-i18next";
import Input from "../../Inputs/Input";
import EmailInput from "../../Inputs/EmailInput";
import PhoneInput from "../../Inputs/PhoneInput";

const PossibleAssetsContacts = ({asset}) => {
  const {t} = useTranslation();
  const [list, setList] = useState([0]);

  const {register, unregister, setValue, getValues} = useFormContext();

  const addContact = e => {
    e?.preventDefault();
    setList(list => [...list, (list[list.length-1] + 1) || 0]);
  }
  const removeContact = useCallback((e, index) => {
    e?.preventDefault();
    setList(prev => prev.filter(i => i !== index));
    unregister(`contact_list.${index}`);
  }, [getValues, setValue, list]);

  const setToContact = (i, contact) => {
    if(!contact)return;

    setValue(`contact_list.${i}.enterprise_id`, contact.enterprise_id);
    setValue(`contact_list.${i}.name`, contact.name);
    setValue(`contact_list.${i}.phone`, contact.phone);
    setValue(`contact_list.${i}.email`, contact.email);
    setValue(`contact_list.${i}.state`, contact.state);
  }

  useEffect(() => {
    console.log(asset);
    if(Array.isArray(asset.asset_contacts)){
      setList([]);
      asset.asset_contacts.forEach((contact, i) => {
        addContact();
      });
      setTimeout(() => {
        asset.asset_contacts.forEach((contact, i) => {
          setToContact(i, contact);
        }, 1000)
      });
    }
  }, [asset]);

  return (
    <div>
      {list.map((i) =>
        <div>
          <Input
            type="hidden"
            {...register(`contact_list.${i}.state`)}
          />
          <div className="flex flex-wrap md:flex-nowrap w-full">
            <SelectEnterprise
              label={t("enterprise")}
              name={`contact_list.${i}.enterprise_id`}
              defaultEnterprise={asset?.asset_contacts ? asset.asset_contacts[i]?.enterprise : false}
              containerClassName="mr-10 mb-10"
            />
            <Input
              label={"Nombre y Apellidos"}
              {...register(`contact_list.${i}.name`)}
            />
          </div>
          <div className="flex flex-wrap md:flex-nowrap w-full mb-5">
            <EmailInput
              label={t("email")}
              {...register(`contact_list.${i}.email`)}
              containerClassName="md:mr-10 mb-10"
            />
            <PhoneInput
              label={t("phone")}
              {...register(`contact_list.${i}.phone`)}
            />
          </div>
          <div className={s.button}>
            <Button buttonStyle={3} onClick={(e) => removeContact(e, i)}>{t('remove')}</Button>
          </div>
        </div>
      )}
      <div className={s.button}>
        <Button className="mt-10" buttonStyle={3} onClick={addContact}>Agregar Contacto</Button>
      </div>
    </div>
  );
};


export default PossibleAssetsContacts;
