import React, {useEffect, useState} from 'react';
import s from './ShowAddress.module.css';
import Map from "../Map";

//  let center = [asset.lng * 1, asset.lat * 1];

const ShowAddress = ({center: _center, title,markers: _markers, zoom = 15}) => {
  const [center, setCenter] = useState(_center ? [..._center] : [0, 0]);
  const [markers, setMarkers] = useState(_markers || null);


  useEffect(() => {
    if(_markers)return setMarkers(_markers);
    if(_center[0]){
      setMarkers([{
        options: {},
        center: _center
      }]);
    }
  }, [_markers, _center]);

  useEffect(() => {
    setCenter(_center);
  }, [_center]);

  if(!center[0] || center[0] === 0)return <></>;

  return (
    <div className="mapContainer">
      {title && <div className={s.title}>{title}</div>}
      <Map center={center} zoom={zoom} markers={markers}/>
    </div>
  );
};


export default ShowAddress;
