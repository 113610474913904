import React, {useEffect, useState} from 'react';
import s from './DemandData.module.css';
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useDemand from "../../../controllers/useDemand";
import Loader from "../../Loader";
import bases from "../../../routes/bases";
import TableV2 from "../../TableV2";
import AnimatedModal from "../../AnimatedModal";
import FilterPage from "../../FilterPage";
import FilterButton from "../../FilterButton";
import Button from "../../Inputs/Button";
import Prompt from "../../Prompt";
import {useError} from "../../../hooks/useError";
import {
  assetConditionFilter,
  AssetConditionRenderer,
  checkboxFilter,
  checkboxRenderer,
  enterpriseFilterRenderer,
  fullNameFilter,
  includesRequiredFilter,
  numberLessFilter,
  numberMoreFilter,
  objectNameFilter,
  SelectFilter,
  SelectNumberFilter,
  textareaRenderer
} from "../../FilterPage/FilterPage";
import {useTableColumns} from "../../../hooks/useTableColumns";
import {transform} from "../../../helpers/util";
import ExportTableButton from "../../ExportTableButton";
import {useLocalStorage} from "../../../hooks/useLocalStorage/useLocalStorage";
import {SimplifiedData} from "../../TableV2/renders";
import {DateSort} from "../../TableV2/sortFunctions";
import useServerData from "../../../controllers/useServerData";
import Select from "../../Inputs/Select";
import useFavorites from "../../../controllers/useFavorites";
import useAdmin from "../../../controllers/useAdmin";

const DemandData = ({fullPage = true, tableProps = {}, onSelect}) => {
  const {t} = useTranslation();
  const {error, ShowError, manageError} = useError();
  const [onlyFavorites, setOnlyFavorites] = useLocalStorage('demands_fav', false);
  const [admins, setAdmins] = useState([]);

  const {getDemands, deleteDemands} = useDemand();
  const {getSpainProvinces} = useServerData();
  const {demandColumnData} = useTableColumns();

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    filter: false,
    visible: false,
    data: []
  });
  const {getFavorites, setFavorite} = useFavorites();
  const {getAdmins} = useAdmin();

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const [provincesOptions, setProvincesOptions] = useState([]);

  const toggleFilter = v => setFilter(prev => ({...prev, visible: v ?? !prev.visible}));
  const setFilteredData = data => {
    setFilter(prev => ({...prev, data}));
  }

  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    setLoading(false);
    if (error) return manageError(error);

    setAdmins(admins);
  }

  const loadProvinces = async () => {
    let {error, provinces} = await getSpainProvinces();
    if (error) return console.error(error);

    if (provinces && Array.isArray(provinces.features)) {
      setProvincesOptions(provinces.features.map(feature => ({
        label: `${feature.properties.NAME_2}(${feature.properties.NAME_1})`,
        value: feature.properties.ID_2
      })))
    }

  }

  const loadDemands = async () => {
    const {error, demands} = await getDemands();
    setLoading(false);
    if (error) return manageError(error);

    setData(demands);
    setFilteredData(demands);
  }

  const removeElements = async () => {
    setDeleteVisible(true);
  }

  const removeConfirmed = async () => {
    setDeleteVisible(false);
    setLoading(true);
    await deleteDemands(selectedRows);
    await loadDemands();
    setSelectedRows([]);
    setLoading(false);
  }


  useEffect(() => {
    loadDemands();
    loadProvinces();
    loadAdmins();
  }, []);


  if (loading) return (<Loader/>);
  if (error) return <ShowError/>

  return (
    <div className={`${fullPage ? 'pt-20' : ''}`}>
      {fullPage &&
        <div className="menuHeader">
          <NavLink to={`/${bases.demand}/new`}>{t("create new demand")}</NavLink>
          <FilterButton onClick={toggleFilter}/>
          {selectedRows?.length > 0 && <FilterButton icon="remove" text={t('delete')} onClick={removeElements}/>}

          <ExportTableButton title={"demandas"} getData={async () => {

            let data = filter.data;

            if (onlyFavorites) {
              let {error, favorites} = await getFavorites("demand");
              favorites = favorites.map(f => f.id);
              if (!error) {
                data = data.filter(d => favorites.includes(d.demand_id));
              }
            }

            return ({
              headers: demandColumnData,
              columns: data.map(d => ({
                enterprise: d?.enterprise?.name,
                person: d.client_id ? d?.client?.name : d?.partner?.name,
                max_size: transform(d.max_size, 'm2'),
                min_size: transform(d.min_size, 'm2'),
                type: d.type,
                condition: d.condition,
                usage: d.usage,
                updatedAt: transform(d.updatedAt, 'datetime')
              }))
            })
          }}/>
          <FilterButton icon="star" text="Favoritos" className={`${onlyFavorites ? 'starOn' : 'starOff'}`} size={22}
                        onClick={() => setOnlyFavorites(prev => !prev)}/>

        </div>
      }
      {!fullPage && <div className="menuHeaderFullPage">
        <FilterButton onClick={toggleFilter}/>
      </div>}
      <TableV2
        favoritesType="demand"
        onlyFavorites={onlyFavorites}
        columns={demandColumnData}
        data={filter.data}
        id="demand_id"
        renders={{
          type: a => SimplifiedData(a, 'type'),
          usage: a => SimplifiedData(a, 'usage'),
          condition: a => SimplifiedData(a, 'condition'),
          max_size: a => transform(a.max_size, 'size'),
          min_size: a => transform(a.min_size, 'size')
        }}
        customSortFunctions={{
          updatedAt: DateSort
        }}
        options={{checkbox: true, editable: true}}
        onSelect={rows => {
          setSelectedRows(rows.length > 0 ? rows : []);
          onSelect && onSelect(rows);
        }}
        onEdit={demand_id => window.open(`${window.location.origin}/${bases.demand}/${demand_id}`)}
        onView={demand_id => window.open(`${window.location.origin}/${bases.demand}/show/${demand_id}`)}
        {...tableProps}
      />

      {!data.length && <div className="w-96 pl-8">
        <Button onClick={() => navigate(`/${bases.demand}/new`)} buttonStyle={3}>{t('create new')}</Button>
      </div>}

      <Prompt
        title={t("delete confirmation")}
        message={t("not reversible")}
        visible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onAccept={removeConfirmed}
      />

      <AnimatedModal
        visible={filter.visible}
        onClose={toggleFilter}
        className='filter'
      >
        <div className={s.container}>
          <FilterPage
            data={data}
            filters={[
              {
                name: 'creator_id',
                label: 'Creador',
                render: SelectFilter({
                  options: admins.map(a => ({value: a.admin_id, label: a.name}))
                }),
                filter: SelectNumberFilter
              },
              {
                name: 'provinces', label: "Provincias", render: ({name, label}, register, i) =>
                  <Select
                    name={name}
                    label={label}
                    options={provincesOptions}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    containerClassName="mb-5"
                    defaultValue={[]}
                  />,
                filter: (data, filter) => {
                  // no convertir a objeto, se puede buscar directamente en la cadena para mejorar el rendimiento
                  if (Array.isArray(filter)) filter = filter.filter(a => a);
                  if (!filter.length) return true;
                  for (let i = 0; i < filter.length; i++) {
                    if (data.includes(filter[i].value + '')) return true;
                  }
                  return false;
                }
              },
              {
                name: 'enterprise', label: t('enterprise'),
                filter: objectNameFilter,
                render: enterpriseFilterRenderer
              },
              {name: 'person', label: "Persona", filter: fullNameFilter},
              {name: 'm30', label: 'M-30', render: checkboxRenderer, filter: checkboxFilter},
              {name: 'm40', label: 'M-40', render: checkboxRenderer, filter: checkboxFilter},
              {name: 'm50', label: 'M-50', render: checkboxRenderer, filter: checkboxFilter},
              {name: 'type', filter: assetConditionFilter, render: AssetConditionRenderer},

              {
                name: 'm_min',
                label: 'm^2 Mínimos',
                filter: (data, filter, filterData, record) => numberMoreFilter(record.min_size, filter)
              },
              {
                name: 'm_max',
                label: "m^2 Máximos",
                filter: (data, filter, filterData, record) => numberLessFilter(record.max_size, filter)
              },

              {
                name: 'min_houses',
                label: "Nº Viviendas Mínimos",
                filter: (data, filter, filterData, record) => numberLessFilter(record.min_houses, filter)
              },
              {
                name: 'max_houses',
                label: "Nº Viviendas Máximos",
                filter: (data, filter, filterData, record) => numberMoreFilter(record.max_houses, filter)
              },
              {name: 'notes', label: "Notas", render: textareaRenderer, filter: includesRequiredFilter}
            ]}
            onReset={(setValue) => {
              setValue('type', 0);
              setValue('usage', 0);
              setValue('condition', 0);
              setValue('m30', 'indeterminate');
              setValue('m40', 'indeterminate');
              setValue('m50', 'indeterminate');
              setValue('provinces', 0);
            }}
            onFilter={data => {
              setFilteredData(data);
              toggleFilter(false);
            }}
          />
        </div>
      </AnimatedModal>
    </div>
  );
}


export default DemandData;
