import React from 'react';
import s from './ErrorCallback.module.css';

const ErrorCallback = ({error, componentStack, resetErrorBoundary}) => {
    return (
      <div>{error}</div>
    );
};


export default ErrorCallback;
