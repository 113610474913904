import React, {useEffect, useState} from 'react';
import s from './SelectElement.module.css';
import Select from "../Inputs/Select";
import usePartner from "../../controllers/usePartner";
import useAsset from "../../controllers/useAsset";
import useClient from "../../controllers/useClient";
import useEnterprise from "../../controllers/useEnterprise";
import Loader from "../Loader";

const SelectElement = ({type, ...props}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const {getPartnersBasicInfo} = usePartner();
  const {getAssetsBasicInfo} = useAsset();
  const {getClientsBasicInfo} = useClient();
  const {getEnterprisesBasicInfo} = useEnterprise();


  const loadData = async () => {
    setLoading(true);
    let f, label;
    switch (type) {
      case 'partner':
        f = getPartnersBasicInfo;
        label = a => (a.alias ? a.alias : `${a.name} ${a.surname}`);
        break;
      case 'asset':
        f = getAssetsBasicInfo;
        label = a => a.title;
        break;
      case 'enterprise':
        f = getEnterprisesBasicInfo;
        label = a => a.name;
        break;
      case 'client':
        f = getClientsBasicInfo;
        label = a => (a.alias ? a.alias : `${a.name} ${a.surname}`);
        break;
      default:
        f = getClientsBasicInfo;
        label = a => (a.alias ? a.alias : `${a.name} ${a.surname}`);
    }
    let data = await f();
    if (data.error) return alert('Error al cargar los datos');

    setOptions(data[type + 's'].map(d => ({
      value: d[type + '_id'],
      label: label(d)
    })));
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [type]);

  if(loading)return <Loader/>

  return (
    <Select
      options={options}
      {...props}
    />
  );
};


export default SelectElement;
