import React, {useEffect, useState} from 'react';
import s from './ConfigPage.module.css';
import {useTranslation} from "react-i18next";
import {useError} from "../../hooks/useError";
import {useForm, FormProvider} from "react-hook-form";
import useServerData from "../../controllers/useServerData";
import MiddleText from "../TextComponents/MiddleText";
import CreateListInput from "../CreateListInput";
import Accordion from "../Accordion";
import SubmitButton from "../Inputs/SubmitButton";
import DropboxSetAccount from "./DropboxSetAccount";
import Backup from "../Backup";
import useAuth from "../../providers/AuthProvider/useAuth";

const ConfigPage = props => {
  const {t} = useTranslation();
  const {manageError} = useError();
  const {getAdmin} = useAuth();
  const form = useForm();
  const {handleSubmit, formState, clearErrors} = form;
  const {getConfigData, saveConfigData} = useServerData();

  const [configDefault, setConfigDefault] = useState({});


  const loadData = async () => {
    const {error, config} = await getConfigData();
    if (error) return manageError(error);
    setConfigDefault(config);
  }

  useEffect(() => {
    loadData();
  }, []);


  const onSubmit = async config => {
    for (let key of Object.keys(config)) {
      if (Array.isArray(config[key])) config[key] = config[key].filter(a => a);
    }
    console.log(config);

    const {error, success} = await saveConfigData(config);
    if (error) return manageError(error);
    if (success) alert('Guardado!');
  }

  let sections = React.useMemo(() => {
    let r = [{
      title: 'Tipos de Empresa', visible: false, content:
        <CreateListInput defaultValue={configDefault.enterprise_types} name="enterprise_types"/>
    },
      /*          {title: 'Tipos de Partner', visible: false, content:
                    <CreateListInput defaultValue={configDefault.partner_types} name="partner_types" />
                },
                {title: 'Tipos de Cliente', visible: false, content:
                    <CreateListInput defaultValue={configDefault.client_types} name="client_types" />
                },*/
      {
        title: 'Lista quien paga honorarios', visible: false, content:
          <CreateListInput defaultValue={configDefault.fee_payer} name="fee_payer"/>
      },
      {
        title: 'Estado de posibles activos', visible: false, content:
          <CreateListInput defaultValue={configDefault.asset_contact_state} name="asset_contact_state"/>
      }];
    if (getAdmin().full_access) {
      r.push(...[{title: 'Configuración de Dropbox', visible: false, content: <DropboxSetAccount/>},
        {title: 'Base de datos', visible: false, content: <Backup/>}]);
    }
    return r;

  }, []);


  return (
    <div className="pr-5">
      <MiddleText className="p-5">Configuración</MiddleText>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
          <Accordion sections={sections}
          />
          <div style={{width: 200, marginBottom: '30px'}}>
            <SubmitButton onClick={() => clearErrors('other')}
                          loading={formState.isSubmitting}>{t('save')}</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};


export default ConfigPage;
