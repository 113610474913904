import React from 'react';
import s from './AnimatedModal.module.css';
import ReactDOM from 'react-dom';


const AnimatedModal = ({visible = false, normalClose = true, className = '', children, onClose}) => {
  const modalRef = React.createRef();

  const parentClassName = `${s.parent} ${!normalClose ? s.moreIndex : ''} ${visible ? s.parentVisible : ''}`;
  const modalClassName = `${s.modal} ${visible ? s.visible : ''} ${className}`;

  const onParentClick = ({target}) => {
    if(normalClose)return;
    if(modalRef.current && !modalRef.current.contains(target)) onClose();
  };


  return ReactDOM.createPortal(
    <div onClick={onParentClick} className={parentClassName}>
      <div className={modalClassName} ref={modalRef}>
        {normalClose && <div className={s.closeButton} onClick={() => onClose()}>X</div>}
        {children}
      </div>
    </div>,
    document.body
  );
};


export default AnimatedModal;
