import React, {useState} from 'react';
import s from './SubmitButton.module.css';

const SubmitButton = ({children, buttonStyle = 2, loading = false, className = '', ...props}) => {
  const buttonsCSS = [s.button, s.button2, s.button3, s.button4];
  const _className = `${buttonsCSS[buttonStyle]} ${className} ${loading ? s.loading : ''}`

  return (
    <button type="submit" disabled={loading}  className={_className} {...props}>
      {loading ? (
        <div className="m-auto" style={{width: 20}}>
        <svg version="1.1" id="L4" x="0px"
             y="0px" viewBox="0 0 100 100" width={40} height={40} enableBackground="new 0 0 0 0">
  <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/>
  </circle>
          <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/>
  </circle>
          <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/>
  </circle>
</svg></div>) : children}
    </button>
  );
};


export default SubmitButton;
