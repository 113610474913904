import React, {useCallback, useEffect, useMemo, useState} from 'react';
import s from './SelectAssetCondition.module.css';
import {useTranslation} from "react-i18next";
import Select from "../Inputs/Select";
import Checkbox from "../Inputs/Checkbox";
import {useFormContext} from "react-hook-form";
import Button from "../Inputs/Button";

const SelectAssetCondition = ({defaultValue, filter = false, addIgnore = false}) => {
  const {t} = useTranslation();

  const [list, setList] = useState([0]);

  const {watch, register, setValue, getValues, unregister} = useFormContext();
  //let type = watch('type');
  let asset_condition = watch('asset_condition', []);


  let ignore = {value: 0, label: 'Ignorar'};
  if(addIgnore)ignore.value = '0';
  const type_options = useMemo(() => {
    let r = [
      {value: 'Suelo', label: 'Suelo'},
      {value: 'Edificio', label: 'Edificio'},
      {value: 'Nave', label: 'Nave'},
      {value: 'Piso', label: 'Piso'},
      {value: 'Local', label: 'Local'},
      {value: 'RK', label: 'RK'},
      {value: 'Chalet', label: 'Chalet'}
    ];
    if(filter || addIgnore)r.push(ignore);
    return r;
  }, []);

  const usage_options = useMemo(() => {
    let r = {
      Suelo: [
        {value: 'Industrial', label: 'Industrial'},
        {value: 'Residencial', label: 'Residencial'},
        {value: 'Comercial', label: 'Comercial'},
        {value: 'Terciario', label: 'Terciario'},
        {value: 'Mixto', label: 'Mixto'},
        {value: 'Dotacional', label: 'Dotacional'}
      ],
      Edificio: [
        {value: 'Residencial', label: 'Residencial'},
        {value: 'Industrial', label: 'Industrial'},
        {value: 'Oficina', label: 'Oficina'},
        {value: 'Dotacional', label: 'Dotacional'},
        {value: 'Hotelero', label: 'Hotelero'},
        {value: 'Comercial', label: 'Comercial'},
        {value: 'Terciario', label: 'Terciario'}
      ],
      Nave: [
        {value: 'Industrial', label: 'Industrial'}
      ],
      Piso: [
        {value: 'Residencial', label: 'Residencial'},
        {value: 'Terciario', label: 'Terciario'}
      ],
      Local: [
        {value: 'Comercial', label: 'Comercial'},
        {value: 'Cambio de uso', label: 'Cambio de uso'}
      ],
      RK: [
        {value: 'Aparcamiento', label: 'Aparcamiento'}
      ],
      Chalet: [
        {value: 'Residencial', label: 'Residencial'},
        {value: 'Industrial', label: 'Industrial'},
        {value: 'Oficina', label: 'Oficina'},
        {value: 'Dotacional', label: 'Dotacional'},
        {value: 'Hotelero', label: 'Hotelero'},
        {value: 'Comercial', label: 'Comercial'},
        {value: 'Terciario', label: 'Terciario'}
      ]
    };
    if(filter || addIgnore){
      let all_pos = [];
      for(let key in r){
        r[key] = [...r[key], ignore];
        r[key].forEach(el => {
          if(!all_pos.find(a => a.value === el.value))all_pos.push(el);
        });
      }
      r[0] = all_pos;
    }




    return r;
  }, []);

  const condition_options = useMemo(() => {

    let def = [
      {value: 'Rentabilizado', label: 'Rentabilizado'},
      {value: 'Obra Nueva', label: 'Obra Nueva'},
      {value: 'A Rehabilitar', label: 'A Rehabilitar'}
    ];

    let r = {
      Suelo: [
        {value: 'Finalista', label: 'Finalista'},
        {value: 'No Finalista', label: 'No Finalista'}
      ],
      Edificio: [
        {value: 'Rentabilizado', label: 'Rentabilizado'},
        {value: 'Obra Nueva', label: 'Obra Nueva'},
        {value: 'A Rehabilitar', label: 'A Rehabilitar'},
        {value: 'Rehabilitado', label: 'Rehabilitado'}
      ],
      Nave: def,
      Piso: def,
      Local: def,
      RK: def,
      Chalet: def
    };
    if(filter || addIgnore){
      let all_pos = [];
      for(let key in r){
        r[key] = [...r[key], ignore];
        r[key].forEach(el => {
          if(!all_pos.find(a => a.value === el.value))all_pos.push(el);
        });
      }
      r[0] = all_pos;
    }


    return r;
  }, []);

  const add = e => {
    e?.preventDefault();
    setList(list => [...list, (list[list.length-1] + 1) || 0]);
  }

  const remove = useCallback((e, index) => {
    e?.preventDefault();
    setList(prev => prev.filter(i => i !== index));
    unregister(`asset_condition.${index}`);
  }, [getValues, setValue, list]);

  const setToCondition = (i,data) => {
    if(!data)return;
    setValue(`asset_condition.${i}.type`, data.type);
    setValue(`asset_condition.${i}.usage`, data.usage);
    setValue(`asset_condition.${i}.condition`, data.condition);
    setValue(`asset_condition.${i}.old_rent`, data.old_rent);
  }


  useEffect(() => {
    if(typeof defaultValue !== 'object')return;
    setList([]);

    defaultValue.asset_condition.forEach(() => add());


    setTimeout(() => {
      defaultValue.asset_condition.forEach((option, i) => {
        setToCondition(i, option);
      });
    }, []);
  }, [defaultValue]);


  let checkboxProps = {
  }
  if(filter)checkboxProps.defaultValue = 'indeterminate';

  return (
    <div>
      {list.map(i =>
        <div key={i}>
          <div className="my-10 flex w-full flex-wrap md:flex-nowrap">
            <Select
              name={`asset_condition.${i}.type`}
              label={t('type')}
              options={[...type_options]}
              defaultValue={filter ? ignore.value : 'Suelo'}
              containerClassName="md:mr-10 mb-5 md:mb-0"
              onChange={v => {
                setValue(`asset_condition.${i}.usage`, usage_options[v] ? usage_options[v][0].value : ignore.value);
                setValue(`asset_condition.${i}.condition`, condition_options[v] ? condition_options[v][0].value : ignore.value);
              }}
            />
            <Select
              name={`asset_condition.${i}.usage`}
              defaultValue={filter ? ignore.value : usage_options['Suelo'][0].value}
              label={t('usage')}
              options={[...usage_options[asset_condition[i]?.type ?? 'Suelo']] || []}
              containerClassName="md:mr-10 mb-5 md:mb-0"
              onChange={v => setValue(`asset_condition.${i}.condition`, condition_options[asset_condition[i]?.type] ? condition_options[asset_condition[i]?.type][0].value : ignore.value)}
            />
            <Select
              name={`asset_condition.${i}.condition`}
              label={t('condition')}
              defaultValue={filter ? ignore.value : condition_options[asset_condition[i]?.type]?.value}
              options={[...condition_options[asset_condition[i]?.type ?? 'Suelo']] || []}
            />
            {list.length > 1 &&
            <div className={s.remove_button}>
              <Button buttonStyle={3} onClick={(e) => remove(e, i)}>{t('remove')}</Button>
            </div>
            }
          </div>
          <Checkbox onClick={e => {
            setValue(`asset_condition.${i}.old_rent`, filter ? e.target.value : e.target.checked);
          }}  defaultValue={defaultValue.asset_condition ? defaultValue.asset_condition[i]?.old_rent : undefined} {...checkboxProps} indeterminate={filter} small={true}  {...register(`asset_condition.${i}.old_rent`)} label="Renta antigua"/>
          <hr className="md:hidden"/>
        </div>
      )}

      <div className={s.add_button}>
        <Button buttonStyle={3} onClick={(e) => add(e)}>Agregar</Button>
      </div>


    </div>
  );
};


export default SelectAssetCondition;
