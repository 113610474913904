import React, {useMemo, useState} from 'react';
import s from './AssetShow.module.css';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import useAsset from "../../../controllers/useAsset";
import {useTranslation} from "react-i18next";
import bases from "../../../routes/bases";
import NavBarShow from "../../NavBarShow";
import {useError} from "../../../hooks/useError/useError";
import Loader from "../../Loader";
import TableV2 from "../../TableV2";
import {useTableColumns} from "../../../hooks/useTableColumns";
import ShowAddress from "../../ShowAddress";
import Accordion from "../../Accordion";
import Cadastre from "../../Cadastre";
import AssetShowCadastreTable from "../../AssetShowCadastreTable";
import MiddleText from "../../TextComponents/MiddleText";
import ShowTableV2 from "../../ShowTableV2";
import {Column, Row} from "../../ShowTableV2/ShowTableV2";
import {transform} from "../../../helpers/util";
import useAdmin from "../../../controllers/useAdmin";
import PossibleAssetShowContacts from "../PossibleAssetShowContacts";
import {ReactComponent as PDFIcon} from '../../../resources/svg/pdf.svg';
import FileExplorer from "../../FileExplorer";

import {ReactComponent as WhiteBell} from "../../../resources/svg/bell.svg";
import OperationData from "../../Operation/OperationData";
import ShowTaskConnectionFilter from "../../ShowTaskConnectionFilter";
import {SimplifiedData} from "../../TableV2/renders";
import useOperation from "../../../controllers/useOperation";
import {investmentProfileOptions} from "../../Inputs/SelectInvestmentProfile/SelectInvestmentProfile";
import {sellTypeOptions} from "../../Inputs/SellType/SellType";
import {BiNotepad} from "react-icons/all";


const AssetShow = props => {
	const {t} = useTranslation();
	const {error, ShowError, manageError} = useError();
	const {demandColumnData} = useTableColumns();
	const {getAdmins} = useAdmin();

	const {id: asset_id} = useParams();
	const {getAsset, deleteAsset, getAssetDemands} = useAsset();
	const {checkOperationsExists} = useOperation();
	const navigate = useNavigate();

	const [asset, setAsset] = useState(false);
	const [loading, setLoading] = useState(true);
	const [demands, setDemands] = useState([]);
	const [admins, setAdmins] = useState([]);


	const loadAsset = async () => {
		let {error, asset} = await getAsset(asset_id);
		setLoading(false);
		if (error) return manageError(error);
		if (!asset) return manageError('not found');

		if (asset.cadastres) {
			asset.cadastres = asset.cadastres.map((c, i) => {
				return {index: i, content: JSON.parse(c.object)};
			});
		}

		setAsset(asset);
	}
	const loadDemands = async () => {
		let {error, demands} = await getAssetDemands(asset_id);
		if (error) return manageError(error);

		let data = demands.map(a => ({
			enterprise_id: a.enterprise_id,
			asset_id: asset_id,
			client_id: a.client_id,
			partner_id: a.partner_id,
		}));
		const {error: error2, operations} = await checkOperationsExists({data});
		if (error2) return manageError(error2);

		if (Array.isArray(operations)) {
			demands = demands.map(d => {
				if (operations.find(a =>
					a.enterprise_id === d.enterprise_id &&
					a.client_id === d.client_id &&
					a.partner_id === d.partner_id
				)) {
					return ({
						...d,
						exists: true
					})
				}
				return d;
			});
		}
		setDemands(demands);
	}

	const onDeleteClick = async () => {
		if (!window.confirm('Esta seguro de eliminar el activo?')) return;
		const {error, success} = await deleteAsset(asset_id);
		if (error || !success) return manageError(error || 'unknown');
		alert(t('deleted'));
		navigate(`/${bases.asset}`);
	}

	const loadAdmins = async () => {
		const {error, admins} = await getAdmins();
		if (error) return manageError(error);
		setAdmins(admins);
	}


	useState(() => {
		loadAsset();
		loadDemands();
		loadAdmins();
	}, [asset_id]);

	let center = [asset.lng * 1, asset.lat * 1];

	/*
		let asset_owners = useMemo(() => {
			if (!asset) return [];
			let r = [];
			asset.asset_owners.filter(owner => !(!owner.name && !owner.contact_data)).forEach(owner => {
				r.push({name: owner.name, content: owner.contact_data});
			});
			return r;
		}, [asset]);

		let asset_file = useMemo(() => {
			if (!asset) return [];
			return [
				{name: 'Título', content: asset.asset_file.title},
				{name: 'Resumen ejecutivo', content: asset.asset_file.summary}
			];
		}, [asset]);
	*/

	const createOperation = demand => {
		let p = {
			enterprise_id: demand.enterprise_id,
			enterprise_name: demand.enterprise?.name,
			client_id: demand.client_id,
			client_name: demand.client?.name,
			partner_name: demand.partner?.name,
			partner_id: demand.partner_id,
			asset_id: asset.asset_id
		};
		let params = '';
		for (let key in p) {
			if (p[key]) params += key + '=' + p[key] + '&'
		}

		navigate('/' + bases.operation + '/new?' + params.substr(0, params.length - 1));
	}


  const showInvestmentProfile = () => {
    return asset.investment_profile?.map(a => investmentProfileOptions.find(b => b.value === a)?.label)?.join(',');
  }


  if (loading || !asset) return (<Loader/>);
	if (error) return <ShowError/>

	let sections = [
		{
			title: 'Catastros', visible: false, content:
				asset?.cadastres && asset.cadastres.map(cadastre =>
					<Cadastre key={cadastre.index} cadastre={cadastre.content}/>)
		},
		{
			title: 'Demandas', visible: false, content: <TableV2
				columns={demandColumnData}
				data={demands}
				id={"demand_id"}
				options={{
					editable: false
				}}
				renders={{
					type: a => SimplifiedData(a, 'type'),
					usage: a => SimplifiedData(a, 'usage'),
					condition: a => SimplifiedData(a, 'condition')
				}}
				onView={demand_id => navigate(`/${bases.demand}/show/${demand_id}`)}
				onDeal={demand => createOperation(demand)}
			/>
		},
		{
			title: 'Hoja de Seguimiento',
			loadOnShow: true,
			visible: false,
			content: <OperationData fullPage={false} filter={{asset_id}}/>
		},
		{
			title: 'Tabla de la ficha', visible: false, content:
				<AssetShowCadastreTable table={asset?.asset_file?.table}/>
		},
		{
			title: 'Tareas', loadOnShow: true, visible: false, content: <ShowTaskConnectionFilter type="asset" id={asset_id}/>
		},
		/*    {
					title: 'Archivos', content: <FileUploader name="asset_file_upload" resource="asset" folder={asset_id}/>,visible: false
				},*/
		{
			title: 'Archivos', content: <FileExplorer resource="asset" folder={asset_id}/>, visible: false, loadOnShow: true
		}
	];

	if (!asset.accepted) {
		sections.unshift({
			title: 'Contactos', vivisble: true, content: <PossibleAssetShowContacts asset={asset}/>
		});
	}

	const old_rent_print = (old_rent) => old_rent ? old_rent.split(';').map(a => (a === '') ? 'No' : 'Si').join(';') : 'No';


	return (
		<div className="showContainer">
			<div className="menuHeader">
				<NavBarShow name={'asset'} id={asset_id} onDeleteClick={onDeleteClick}/>
				<div className="inline-flex ml-5 items-center" onClick={() => navigate('/' + bases.asset + '/pdf/' + asset_id)}>
					<PDFIcon width={15} height={15}/>
					<a href="#">Generar PDF</a>
				</div>
        <div className="inline-flex ml-5 items-center" onClick={() => navigate(`/${bases.asset}/nota_simple/${asset_id}`)}>
          <BiNotepad size={17}/>
          <a href="#">Nota Simple</a>
        </div>
				<div className="inline-flex ml-5 items-center"
						 onClick={() => navigate(`/${bases.task}/new?type=asset&id=${asset_id}`)}>
					<WhiteBell width={15} height={15}/>
					<a href="#">Tarea</a>
				</div>
			</div>
			<div className="showDataContainer">
				<div className="flex flex-wrap">
					<ShowTableV2>
						<Row>
							<Column title={true}>ID Activo</Column>
							<Column>{asset.asset_id}</Column>
						</Row>
						<Row>
							<Column title={true}>Titulo</Column>
							<Column>{asset?.asset_file?.title}</Column>
						</Row>
						<Row>
							<Column title={true}>Tipo</Column>
							<Column>{asset?.type}</Column>
						</Row>
						<Row>
							<Column title={true}>Uso</Column>
							<Column>{asset?.usage}</Column>
						</Row>
						<Row>
							<Column title={true}>Estado</Column>
							<Column>{asset?.condition}</Column>
						</Row>
						<Row>
							<Column title={true}>Renta Antigua</Column>
							<Column>{old_rent_print(asset?.old_rent)}</Column>
						</Row>
						{!!asset.zone_prime &&
						<Row className="mb-0">
							<Column className={"w-full text-center font-bold"}>Zona Prime</Column>
						</Row>}

						{/*            {!!asset.old_rent &&
            <Row className="mb-0">
              <Column className={"w-full text-center font-bold"}>Renta antigua</Column>
            </Row>}*/}
						<Row>
							<Column title={true}>Dirección</Column>
							<Column>{asset?.address}</Column>
						</Row>
						<Row>
							<Column title={true}>Precio</Column>
							<Column>{transform(asset, 'price')}</Column>
						</Row>
						<Row>
							<Column title={true}>Precio alquiler</Column>
							<Column>{transform({price: asset.rent_price}, 'price')}</Column>
						</Row>
						<Row>
							<Column title={true}>Superficie construida</Column>
							<Column>{transform(asset?.surface, 'm2')}</Column>
						</Row>
						<Row>
							<Column title={true}>Superficie gráfica</Column>
							<Column>{transform(asset?.graphic_surface, 'm2')}</Column>
						</Row>
						<Row>
							<Column title={true}>NºViviendas</Column>
							<Column>{asset?.n_houses || 0}</Column>
						</Row>


						<Row>
							<Column title={true}>Dormitorios</Column>
							<Column>{asset?.bedrooms || 0}</Column>
						</Row>
						<Row>
							<Column title={true}>Baños</Column>
							<Column>{asset?.toilets || 0}</Column>
						</Row>

						<Row>
							<Column title={true}>Piso</Column>
							<Column>{asset?.floor || 0}</Column>
						</Row>

						<Row>
							<Column title={true}>Planta</Column>
							<Column>{asset?.plant || 0}</Column>
						</Row>

						{!!asset?.terrace && <Row>
							<Column title={true}>Terraza</Column>
							<Column>Si</Column>
						</Row>}


						{asset?.fee_buyer &&
						<Row>
							<Column title={true}>{t('fee_buyer')}</Column>
							<Column>{transform(asset?.fee_buyer, 'percent')}</Column>
						</Row>}

						{asset?.fee_owner && <Row>
							<Column title={true}>{t('fee_owner')}</Column>
							<Column>{transform(asset?.fee_owner, 'percent')}</Column>
						</Row>}


						{asset?.asset_owners &&
						<Row className="mb-0">
							<Column className={"w-full text-center font-bold"}>Propietarios</Column>
						</Row>
						}
						{asset?.asset_owners && asset?.asset_owners.map((owner, i) => {
							if (owner.type === 'partner' && owner.enterprise_id) {
								return (
									<Row key={i}>
										<Column title={true}>
											<NavLink className="hover:underline link_color"
															 to={`/${bases.enterprise}/show/${owner?.enterprise_id}`}>{owner?.enterprise?.name}</NavLink>
										</Column>
										<Column>
											{owner.partner_id && <NavLink className="hover:underline link_color"
																										to={`/${bases.partner}/show/${owner?.partner_id}`}>{owner?.partner?.name}</NavLink>}
											{owner.client_id && <NavLink className="hover:underline link_color"
																									 to={`/${bases.client}/show/${owner?.client_id}`}>{owner?.client?.name}</NavLink>}
										</Column>
									</Row>
								)
							}

							return (
								<Row>
									<Column title={true}>
										{owner.name}
									</Column>
									<Column>
										<a href={`tel:${owner?.phone}`}>{owner?.phone}</a>
									</Column>
								</Row>
							)
						})}

            <Row>
              <Column title={true}>Perfil de inversión</Column>
              <Column>{showInvestmentProfile()}</Column>
            </Row>

            <Row>
              <Column title={true}>Tipo de venta</Column>
              <Column>{sellTypeOptions.find(v => v.value === asset?.asset_type)?.label}</Column>
            </Row>


            <Row>
							<Column title={true}>{t('updatedAt')}</Column>
							<Column>{transform(asset.updatedAt, 'date')}</Column>
						</Row>
						<Row>
							<Column title={true}>{t('createdAt')}</Column>
							<Column>{transform(asset.createdAt, 'date')}</Column>
						</Row>

					</ShowTableV2>
					<div className="m-auto">
						<ShowAddress title="Posición del activo" center={center}/>
					</div>
				</div>


				{asset?.asset_file?.summary &&
				<div className="show_separated">
					<MiddleText>Resumen ejecutivo</MiddleText>
					<div className="p-5 mt-5 bg-gray-100">
						<pre>{asset.asset_file.summary}</pre>
					</div>
				</div>}

				{asset?.notes &&
				<div className="show_separated">
					<MiddleText>Notas</MiddleText>
					<div className="p-5 mt-5 bg-gray-100">{asset.notes}</div>
				</div>}


				{demands &&
				<Accordion sections={sections}/>
				}
			</div>
		</div>)
};


export default AssetShow;
