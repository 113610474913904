import {query} from "../../helpers/query";

export const useLocation = () => {
  const autocompleteAddress = async address => {
    console.log(address);
    const {error, data} = await query('/api/locate/autocomplete', {address});
    return {error, result: data?.result};
  }

  return {autocompleteAddress};
};

export default useLocation;
