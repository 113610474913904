import React, {useEffect, useState} from 'react';
import s from './FileUploader.module.css';
import useDropbox from "../../controllers/useDropbox";
import loading_gif from '../../resources/images/loading.gif';
import Input from "../Inputs/Input";

import {ReactComponent as FileIcon} from "../../resources/svg/file.svg";
import {ReactComponent as PDFIcon} from "../../resources/svg/pdf.svg";
import {ReactComponent as DOCIcon} from "../../resources/svg/doc.svg";

import {ReactComponent as SVGIcon} from "../../resources/svg/svg.svg";
import {ReactComponent as PNGIcon} from "../../resources/svg/png.svg";
import {ReactComponent as JPGIcon} from "../../resources/svg/jpg.svg";
import {ReactComponent as DeleteSVG} from "../../resources/svg/FileUploader/delete.svg";
import {ReactComponent as UploadSVG} from "../../resources/svg/FileUploader/upload.svg";
import {ReactComponent as DownloadSVG} from "../../resources/svg/FileUploader/download.svg";
import {ReactComponent as EyeSVG} from "../../resources/svg/FileUploader/see_file.svg";

require('../../resources/svg/file.svg');




const size = 50;

const UploadIcon = ({size = 20, ...props}) => <UploadSVG {...props} width={size} height={size}/>
const DownloadIcon = ({size = 30, ...props}) => <DownloadSVG {...props} width={size} height={size}/>
const DeleteIcon = ({size = 30, ...props}) => <DeleteSVG {...props} width={size} height={size}/>
const SeeFileIcon = ({size = 30, ...props}) => <EyeSVG {...props} width={size} height={size} />


const FileUploader = ({name, resource, folder}) => {
  const {listFiles, uploadFile, deleteFile, downloadFile} = useDropbox();
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = async () => {
    const {error, files} = await listFiles(resource, folder);
    setFiles(files);
    setLoading(false);
  }

  const onSelect = async (e) => {
    let files = e.target.files;
    if (files.length === 0) return;

    setLoading(true);
    const {error, success} = await uploadFile(resource, folder, files);

    if (success) {
      await loadFiles();
    }
    setLoading(false);
  }

  const fileExt = file => {
    let ext = file.name.split('.');
    return ext.length > 0 ? ext[ext.length - 1] : 'file';
  }

  const loadFileIcon = file => {
    let ext = fileExt(file);
    let icon;
    switch(ext.toLowerCase()){
      case 'doc':
      case 'docx':
        icon = DOCIcon;
        break;
      case 'pdf':
        icon = PDFIcon;
        break;
      case 'png':
      case 'gif':
        icon = PNGIcon;
        break;
      case 'jpg':
      case 'jpeg':
        icon = JPGIcon;
        break;
      case 'svg':
        icon = SVGIcon;
        break;
      default: icon = FileIcon;
    }
    return icon;
  }

  const filterFiles = file => file.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;


  const onFileDelete = async file => {
    setLoading(true);
    const {error, success} = await deleteFile(resource, folder, file);
    if(!success){
      alert('Se ha producido un error');
    }else{
      await loadFiles();
      alert(`Archivo "${file.name}" eliminado`);
    }
    setLoading(false);
  }

  const onFileDownload = async file => {
    setLoading(true);
    await downloadFile(resource, folder, file);
    setLoading(false);
  }
  const viewPDF = async file => {
    setLoading(true);
    await downloadFile(resource, folder, file, true);
    setLoading(false);
  }

  return (
    <div className={s.container}>
      <div className="mb-5 inline-block">
        <label htmlFor={name} className={s.input_label}>
          <UploadIcon className="mr-5"/>
          Subir archivos
        </label>
        <input onChange={onSelect} type="file" name={name} id={name} multiple/>
      </div>
      {loading && <div className={s.loading_container}>
        <img alt="Loading" width={30} height={30} src={loading_gif}/>
      </div>}

      <div style={{width: '400px'}} className="ml-1">
        <Input
          label="Buscar"
          containerClassName="mb-5"
          onChange={e => setSearchText(e.target.value)}
        />
      </div>

      {files && files.length > 0 && <div className={s.r_container}>
        {files.filter(filterFiles).map((file, i) => {
          let className = `${s.resource} `;
          let Icon = loadFileIcon(file);
          let ext = fileExt(file);

          return (
            <div className={className} key={i}>
              <div className="flex align-center">
                <div><Icon width={50} height={50}/></div>
                <div className={s.r_name}>{file.name}</div>
              </div>
              <div className={s.resource_icons}>

                {ext === 'pdf' && <SeeFileIcon onClick={() => viewPDF(file)}/>}
                <DownloadIcon onClick={() => onFileDownload(file)}/>
                <DeleteIcon onClick={() => onFileDelete(file)}/>
              </div>
            </div>
          )
        })}

      </div>
      }
    </div>
  );
}


export default FileUploader;
