import React, {useEffect, useState} from 'react';
import s from './ShowAssetsMap.module.css';
import Loader from "../Loader";
import useAsset from "../../controllers/useAsset";
import AssetsMap from "../AssetsMap";
import {useWindowSize} from "../../hooks/useWindowSize/useWindowSize";
import AnimatedModal from "../AnimatedModal";
import FilterPage from "../FilterPage";
import {
  adaptFilterKey,
  assetConditionFilter,
  AssetConditionRenderer,
  assetPartnerClientFilter,
  AssetPartnerClientRenderer,
  includesFilter,
  numberFilterRenderer,
  numberLessFilter,
  numberMoreFilter,
  PossibleAssetStateFilter, PossibleAssetStateRenderer
} from "../FilterPage/FilterPage";
import {useTranslation} from "react-i18next";


import {ReactComponent as FilterSVG} from '../../resources/svg/filter.svg';
import {ReactComponent as EyeSVG} from '../../resources/svg/eye.svg';
import {ReactComponent as AddSVG} from '../../resources/svg/add.svg';
import {useNavigate} from "react-router-dom";
import bases from "../../routes/bases";
import FilterButton from "../FilterButton";
import {useLocalStorage} from "../../hooks/useLocalStorage/useLocalStorage";
import {useFavorites} from "../../controllers/useFavorites/useFavorites";
import Select from "../Inputs/Select";
import {useForm, FormProvider} from "react-hook-form";
import {isMobile, isTablet} from "react-device-detect";
import ExportTableButton from "../ExportTableButton";
import {transform} from "../../helpers/util";


const ShowAssetsMap = props => {
  const {t} = useTranslation();
  const {getFavorites} = useFavorites();
  const navigate = useNavigate();

  const [assets, setAssets] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    filter: false,
    visible: false,
    data: []
  });
  const [onlyFavorites, setOnlyFavorites] = useLocalStorage('map_fav', false);
  const [reFilter, setRefilter] = useState(false);

  const form = useForm();

  const {watch} = form;

  const chosenAssets = watch('chosen_assets');

  const windowSize = useWindowSize();

  const {getAssets, getNotAcceptedAssets} = useAsset();

  const filterAvailable = asset => {

  }

  const loadAssets = async () => {
    setLoading(true);
    const {error, favorites} = await getFavorites('asset');
    let r1 = await getAssets();
    let r2 = await getNotAcceptedAssets();
    if (error || r1.error || r2.error) {
      setLoading(false);
      return alert('Se ha producido un error desconocido.');
    }

    let assets = [...r1.assets, ...r2.assets].filter(a => !a.sold).map(a => ({...a, title: a?.asset_file?.title}));
    setFavorites(favorites.map(a => a.id));

    setAssets(assets);
    //setFilteredAssets(r2.assets);
    setLoading(false);
  }

  useEffect(() => {
    if (!Array.isArray(assets) || !Array.isArray(chosenAssets)) return;

    const accepted = chosenAssets.find(a => a.value === 0);
    const not_second_address = chosenAssets.find(a => a.value === 1);
    const second_address = chosenAssets.find(a => a.value === 2);

    setSelectedAssets(assets.filter(asset => {
      if (onlyFavorites && !favorites.find(id => id === asset.asset_id)) return false;
      let r = false;
      if (accepted && asset.accepted) r = true;
      if (not_second_address && !asset.accepted /*&& !asset.second_address*/ && asset.not_accepted_condition !== 'Ir a Mapa') r = true;
      if (second_address && !asset.accepted /*&& asset.second_address*/ && asset.not_accepted_condition === 'Ir a Mapa') r = true;
      return r;
    }));
  }, [assets, chosenAssets, onlyFavorites]);

  useEffect(() => {
    setFilteredAssets(filteredAssets);
    setRefilter(prev => !prev);
  }, [selectedAssets]);


  useEffect(() => {
    loadAssets();
  }, []);
  const toggleFilter = v => setFilter(prev => ({...prev, visible: v ?? !prev.visible}));


  const selectAssetsOptions = React.useMemo(() => {
    let list = ['Activos', 'Sin Ir a Mapa', 'Con Ir a mapa'];
    return list.map((t, i) => ({value: i, label: t}));
  }, []);


  if (loading) return <Loader/>

  return (
    <div className="w-full">
      <div className="p-5 flex items-center z-20 relative">
        <FormProvider {...form}>
          <a href="/" className="link_color md:text-3xl mr-10 hover:underline hidden md:block">Volver</a>
          <div className={s.chosen_assets}>
            <Select
              name="chosen_assets"
              options={selectAssetsOptions}
              usePortal={true}
              isMulti={true}
              styles={{
                control: styles => ({
                  ...styles,
                  fontSize: '15px',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  boxShadow: '0 0 2px #ccc',
                }),
                valueContainer: styles => ({
                  ...styles,
                  flexWrap: 'nowrap',
                  flexDirection: 'row'
                })
              }}
            />
          </div>

          <div className="flex link_color md:text-3xl hover:underline items-center" onClick={toggleFilter}>
            <FilterSVG width={20} height={20}/>
            <div className="ml-5">Filtrar</div>
          </div>

          <div className="flex link_color md:text-3xl hover:underline items-center mb-4 ml-2">
            {(!isMobile || isTablet) && <ExportTableButton title={"Activos"} getData={async () => {

              let data = filteredAssets;

              if(onlyFavorites){
                let {error, favorites} = await getFavorites("asset");
                favorites = favorites.map(f => f.id);
                if(!error){
                  data = data.filter(d => favorites.includes(d.asset_id));
                }
              }

              return ({
                headers: [
                  {Header: 'Número de activo', accessor: "asset_id"},
                  {Header: 'Nombre propiedad', accessor: "title"},
                  {Header: 'Propietarios', accessor: "owners"},
                  {Header: 'Dirección inmueble', accessor: "address"},
                  {Header: 'Domicilio', accessor: "second_address"}
                ],
                columns: data.map(a => ({
                  asset_id: a.asset_id,
                  title: a.title,
                  address: a.address,
                  second_address: a?.second_address?.address || '',
                  owners: transform(a?.asset_owners, 'asset_owners')
                }))
              })
            }}

            />}
          </div>

          <div className="flex link_color md:text-3xl hover:underline ml-5 items-center"
               onClick={() => navigate(`/${bases.demand}/new`)}>
            <AddSVG className="text-black" width={20} height={20}/>
            <div className="ml-2">Crear demanda</div>
          </div>
          <div className="relative top-2">
            <FilterButton icon="star" text=" " className={`${onlyFavorites ? 'starOn' : 'starOff'}`} size={22}
                          onClick={() => setOnlyFavorites(prev => !prev)}/>
          </div>
        </FormProvider>
      </div>

      <AssetsMap assets={filteredAssets} width={windowSize.width} height={windowSize.height - 70}/>


      <AnimatedModal
        visible={filter.visible}
        onClose={toggleFilter}
        className='filter'
      >
        <div className={s.filterContainer}>
          <FilterPage
            reFilter={reFilter}
            data={selectedAssets}
            filters={[
              {
                name: 'client_partner',
                label: 'Procedencia',
                render: AssetPartnerClientRenderer,
                filter: assetPartnerClientFilter
              },
              {name: 'title', label: t('title')},
              {name: 'type', filter: assetConditionFilter, render: AssetConditionRenderer},
              {name: 'address', label: t('address'), filter: includesFilter},
              {name: 'not_accepted_condition', filter: PossibleAssetStateFilter, render: PossibleAssetStateRenderer},
              {name: 'price', label: "Precio minimo", render: numberFilterRenderer, filter: numberLessFilter},
              {
                name: 'price_max', label: "Precio máximo", render: numberFilterRenderer, filter:
                  adaptFilterKey(a => a.price, numberMoreFilter)
              },
            ]}
            onReset={(setValue) => {
              setValue('type', 0);
              setValue('usage', 0);
              setValue('condition', 0);
              setValue('not_accepted_condition', 'ignorar');
              setValue('client_partner', 0);
            }}
            onFilter={data => {
              setFilteredAssets(data);
              toggleFilter(false);
            }}
          />
        </div>
      </AnimatedModal>

    </div>
  );
};


export default ShowAssetsMap;
