import React, {useEffect, useState} from 'react';
import s from './PartnerEdit.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import usePartner from "../../../controllers/usePartner";
import bases from "../../../routes/bases";
import MiddleText from "../../TextComponents/MiddleText";
import Input from "../../Inputs/Input";
import SelectEnterprise from "../../Inputs/SelectEnterprise";
import EmailInput from "../../Inputs/EmailInput";
import PhoneInput from "../../Inputs/PhoneInput";
import Textarea from "../../Inputs/Textarea";
import SubmitButton from "../../Inputs/SubmitButton";
import {useError} from "../../../hooks/useError/useError";
import SelectType from "../../Inputs/SelectType";
import SelectAdmins from "../../Inputs/SelectAdmins";

const PartnerEdit = props => {
  const {t} = useTranslation();
  const {ShowErrors} = useError();
  let {id: partner_id} = useParams();

  const form = useForm();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError: _setError,
    clearErrors
  } = form;

  const {
    errors,
  } = formState;

  const {createPartner, getPartner, editPartner, checkExists} = usePartner();

  const [state, setState] = useState({
    partner: false
  });

  const navigate = useNavigate();

  const setError = message => _setError('other', {message});
  const isNewPartner = () => !partner_id;

  const texts = {
    title: isNewPartner() ? t('create new partner') : `${t('edit partner')} (ID:${partner_id})`,
    submit: isNewPartner() ? t('create') : t('change')
  }

  const onSubmit = async partner => {
    let id = partner_id;
    if(typeof partner.enterprise_id === 'string')partner.enterprise_id = undefined;

    if(!partner.enterprise_id)return alert('Seleccione una empresa');
    console.log(partner);

    partner.name = partner.name.trim();
    partner.surname = partner.surname.trim();

    if (!partner_id) {
      const {exists} = await checkExists(partner.phone);
      if(exists)return alert('Ya exists un partner con este teléfono!');

      const {error, partner_id} = await createPartner(partner);
      if (error) return setError(error);
      id = partner_id;
    } else {
      const {error, success} = await editPartner({partner_id, ...partner});
      if (error) return setError(error);
    }
    navigate(`/${bases.partner}`);
  }

  const loadPartner = async () => {
    const {error, partner} = await getPartner(partner_id);
    if (error) return setError(error);
    console.log(partner);
    setState(prev => ({...prev, partner}));

    for (let key in partner) {
      if(key === 'captured_by')continue;
      setValue(key, partner[key]);
    }
  }

  useEffect(() => {
    if (!partner_id) return;
    loadPartner();
  }, [partner_id]);

  const selectEnterpriseType = enterprise => {
    setValue('type', enterprise.type);
  }


  return (
    <div className="editContainer">
      <div className="menuHeader">
        <NavLink to={`/${bases.partner}`}>{t("see all partners")}</NavLink>
      </div>

      <MiddleText>{texts.title}</MiddleText>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
          <div className="flex flex-wrap md:flex-nowrap w-full">
            <Input
              containerClassName="md:mr-10 mb-10"
              {...register('name', {
                required: 'El nombre es requerido'
              })}
              label={t("name")}/>
            <Input
              containerClassName="md:mr-10 mb-10"
              {...register('surname')}
              label={t("surname")}/>
            <Input
              containerClassName="md:mr-10 mb-10"
              {...register('alias')}
              label={t("alias")}/>
            <Input containerClassName="md:mr-10 mb-10" {...register('dni')} label={t('dni')}/>
          </div>
          <div className="flex flex-wrap md:flex-nowrap w-full">
            <SelectAdmins
              name="captured_by"
              label={t('captured by')}
              containerClassName="mb-10 mr-10"
              defaultValue={state?.partner?.captured_by}
            />
          </div>
          <SelectEnterprise
            containerClassName="mb-10"
            label={t('enterprise')}
            defaultEnterprise={state?.partner?.enterprise}
            onSelect={selectEnterpriseType}
          />

          <Input containerClassName="mb-10" {...register('job')} label={t('job position')}/>
          <div className="flex w-full flex-wrap md:flex-nowrap">
            <EmailInput
              containerClassName="md:mr-10 mb-10"
              {...register('email')}
              label={t('email')}/>
            <PhoneInput containerClassName="mb-10" {...register('phone')} label={t('phone')}/>
          </div>
          <Input
            containerClassName="mb-10"
            {...register('web_page')}
            label={t('web page')}/>
          {/*        <Accordion sections={[
          {
            title: 'Ubicación',
            header: true,
            visible: true,
            content: <FullAddress
              defaultValue={state?.partner}
              control={control}
              register={register}
              setValue={setValue}/>
          }
        ]}/>*/}
          <Textarea containerClassName="mb-10" {...register('notes')} label={t('notes')}/>
          {Object.keys(errors).length > 0 && <ShowErrors errors={errors}/>}

          <div style={{width: 200, marginBottom: '30px'}}>
            <SubmitButton onClick={() => clearErrors('other')}
                          loading={formState.isSubmitting}>{texts.submit}</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};


export default PartnerEdit;
