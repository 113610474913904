import React from 'react';
import s from './Operation.module.css';
import {Outlet} from "react-router-dom";

const Operation = props => {
  return (
    <Outlet/>
  );
};


export default Operation;
