import React, {useEffect, useState} from 'react';
import s from './MarkedAreasReadOnly.module.css';
import useMap from "../../providers/MapProvider/useMap";
import useServerData from "../../controllers/useServerData";
import madrid_m30 from "../../resources/geojson/madrid_m30";
import madrid_m40 from "../../resources/geojson/madrid_m40";
import madrid_m50 from "../../resources/geojson/madrid_m50";
import Map from "../Map";
import {useFormContext} from "react-hook-form";

const MarkedAreasReadOnly = ({defaultValue, setDrawnArea}) => {
  const {mapboxgl} = useMap();
  const {getSpainProvinces, getMadridZonePrime} = useServerData();

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([-2.1805, 40.1035]);
  const [provinces, setProvinces] = useState(null);
  const [loaded, setLoaded] = useState(0);
  const [primeZone, setPrimeZone] = useState(null);

  const [selectedProvinces, setSelectedProvinces] = useState([]);



  const provinceName = a => `${a.properties.NAME_2}(${a.properties.NAME_1})`;
  const loadProvinces = async () => {
    let {error, provinces} = await getSpainProvinces();
    if (error) return console.error(error);

    provinces.features = provinces.features.sort(sortProvinces);
    setProvinces(provinces);
    setLoaded(prev => prev + 1);
  }
  const loadZonePrime = async () => {
    const {error, zone} = await getMadridZonePrime();
    if (error) return console.error(error);
    setPrimeZone(zone);
    setLoaded(prev => prev + 1);
  }

  const sortProvinces = (a, b) => {
    let name1 = provinceName(a);
    let name2 = provinceName(b);
    if (name1 > name2) return 1;
    if (name1 < name2) return -1;
    return 0;
  }

  const onLoad = () => {
    if (loaded === true) return;

    map.addSource('provinces', {
      type: 'geojson',
      data: provinces
    });
    map.addLayer({
      id: 'provinces',
      type: 'fill',
      source: 'provinces',
      paint: {
        'fill-outline-color': 'rgba(0,0,0,1)',
        'fill-color': 'rgba(0,0,0,0.1)'
      }
    }, 'settlement-label');

    map.addLayer({
      id: 'provinces-highlighted',
      type: 'fill',
      source: 'provinces',
      paint: {
        'fill-outline-color': '#484896',
        'fill-color': '#6e599f',
        'fill-opacity': 0.75
      },
      filter: ['in', 'ID_2', ''],
    }, 'settlement-label');

    // prime zone
    map.addSource('zone_prime', {
      type: 'geojson',
      data: primeZone
    });
    map.addLayer({
      id: 'zone_prime',
      type: 'fill',
      source: 'zone_prime',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(156,1,1)',
        'fill-color': 'rgba(248,15,15,0.36)'
      }
    });


    // m30
    map.addSource('m30', {
      type: 'geojson',
      data: madrid_m30
    });
    map.addLayer({
      id: 'm30',
      type: 'fill',
      source: 'm30',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(1,94,156)',
        'fill-color': 'rgba(1,94,156,0.15)'
      }
    });
    // m40
    map.addSource('m40', {
      type: 'geojson',
      data: madrid_m40
    });
    map.addLayer({
      id: 'm40',
      type: 'fill',
      source: 'm40',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(1,94,156)',
        'fill-color': 'rgba(1,94,156,0.15)'
      }
    });
    // m50
    map.addSource('m50', {
      type: 'geojson',
      data: madrid_m50
    });
    map.addLayer({
      id: 'm50',
      type: 'fill',
      source: 'm50',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(1,94,156)',
        'fill-color': 'rgba(1,94,156,0.15)'
      }
    });

  }


  const setDefaultValue = defaultValue => {
    if (defaultValue.provinces instanceof Array) {
      setSelectedProvinces(defaultValue.provinces.map(province => province.id));
    }

    if (defaultValue.drawn_area) {
      setDrawnArea(defaultValue.drawn_area);
    }

    const a = name => onZoneChange({target: {name, checked: true}});

    if(defaultValue.m30)a('m30');
    if(defaultValue.m40)a('m40');
    if(defaultValue.m50)a('m50');
    if(defaultValue.zone_spain)a('zone_spain');
    if(defaultValue.zone_prime)a('zone_prime');
  }

  useEffect(() => {
    if (loaded === true && defaultValue) setDefaultValue(defaultValue);
  }, [defaultValue, loaded]);

  useEffect(() => {
    if (!map) return;
    let filter = ['in', 'ID_2', ...selectedProvinces.filter(a => a)];
    map.setFilter('provinces-highlighted', filter);
    //_setProvinces(translateProvinces(selectedProvinces));
  }, [selectedProvinces]);

  useEffect(() => {
    loadProvinces();
    loadZonePrime();
  }, []);

  useEffect(() => {
    if (loaded === 3) {
      setLoaded(true);
      onLoad();
    }
  }, [loaded]);

  const onZoneDraw = e => {
    setDrawnArea(prev => {
      prev = prev || [];
      if (e.type === 'draw.delete') return prev.filter(feature => feature.id !== e.features[0].id);
      if (e.type === 'draw.create') return [...prev, ...e.features];
      if (e.type === 'draw.update') return prev.map(feature => feature.id === e.features[0].id ? e.features[0] : feature);
    });
  }
  const onZoneChange = e => {
    const {name, checked} = e.target;
    /*

        map.setLayoutProperty('m30', 'visibility', 'none');
        map.setLayoutProperty('m40', 'visibility', 'none');
        map.setLayoutProperty('m50', 'visibility', 'none');
    */


    if (name === 'zone_spain') {
      //provinces.features.forEach(({properties}) => setValue(`provinces.${properties.ID_2}`, checked));
      setSelectedProvinces(checked ? provinces.features.map(({properties}) => properties.ID_2) : []);
    } else if (name === 'zone_prime') {
      map.setLayoutProperty('zone_prime', 'visibility', checked ? 'visible' : 'none');
    } else if (name === 'm30') {
      map.setLayoutProperty('m30', 'visibility', checked ? 'visible' : 'none');
    } else if (name === 'm40') {
      map.setLayoutProperty('m40', 'visibility', checked ? 'visible' : 'none');
    } else if (name === 'm50') {
      map.setLayoutProperty('m50', 'visibility', checked ? 'visible' : 'none');
    }
  }

  return (
    <div>
      <div className={s.mapContainer}>
        <Map
          onDraw={onZoneDraw}
          defaultDrawn={defaultValue?.drawn_area}
          onLoad={map => {
            setMap(map);
            setLoaded(prev => prev + 1)
          }}
          hideDrawControls={true}
          interactive={true}
          center={center}
          zoom={5}
          markers={[]}
        />
      </div>
    </div>
  );
};


export default MarkedAreasReadOnly;
