import useAuth from "../../providers/AuthProvider/useAuth";
import {useTranslation} from "react-i18next";
import ErrorText from "../../components/TextComponents/ErrorText";
import {useEffect, useState} from "react";


export const useError = () => {
  const {logout} = useAuth();
  const {t} = useTranslation();
  const [error, setError] = useState();

  const manageError = (error) => {
    setError(error);
    if(error === 'wrong token'){
      return logout();
    }
  }

  const ShowErrors = ({errors, onlyOne = false}) => {
    const [result, setResult] = useState(null);

    // need useEffect to re-render
    useEffect(() => {
      let r = [];
      const keys = Object.keys(errors);
      if(keys.length){
        for(let i = 0;i < (onlyOne ? 1 : keys.length);i++){
          const error = errors[keys[i]];
          if(error.type === 'required' && !error.message?.length)continue;
          r.push(<ShowError error={error}/>);
        }
      }
      setResult(r);

    }, [errors, onlyOne]);


    return result;
  }

  const ShowError = ({error: _error, ...props}) =>{
    _error = _error || error;
    return <ErrorText {...props}>{t('errors.' + (_error.message || _error))}</ErrorText>
  }



  return {manageError, ShowErrors, ShowError, setError: e => setError(e), error};
}

export default useError;
