import React from 'react';
import s from './PossibleAssetShowContacts.module.css';
import TableV2 from "../../TableV2";
import {useTableColumns} from "../../../hooks/useTableColumns";
import SelectType from "../../Inputs/SelectType";
import {useForm, FormProvider} from "react-hook-form";
import useAsset from "../../../controllers/useAsset";

const PossibleAssetShowContacts = ({asset}) => {
  const {possibleAssetsContactsColumnData} = useTableColumns();
  const {editContactState} = useAsset();
  const form = useForm();
  console.log(asset);

  const changeAssetContact = async (state, contact) => {
    await editContactState({
      asset_id: asset.asset_id,
      contact_list_id: contact.contact_list_id,
      state
    });
  }

  return (
    <FormProvider {...form}>
    <TableV2
      columns={possibleAssetsContactsColumnData}
      data={asset.asset_contacts}
      renders={{
        state: contact =>
          <SelectType
            name={`contact_state_${contact.contact_list_id}`}
            listName="asset_contact_state"
            defaultValue={contact.state}
            onChange={state => changeAssetContact(state, contact)}
            small={true}
          />
      }}
      id="contact_list_id"
      options={{editable: false, checkbox: false}}
    />
    </FormProvider>
  );
};


export default PossibleAssetShowContacts;
