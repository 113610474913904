import React from 'react';
import s from './NavBarShow.module.css';
import {NavLink} from "react-router-dom";
import bases from "../../routes/bases";
import {useTranslation} from "react-i18next";
import useAuth from "../../providers/AuthProvider/useAuth";

const NavBarShow = ({name, onDeleteClick, id}) => {
  const {t} = useTranslation();
  const {getAdmin} = useAuth();

  const full_access = getAdmin().full_access;


  return (<>
  {!!full_access && <><NavLink to={`/${bases[name]}/${id}`}>{t("change")}</NavLink>
    <a href="#" onClick={onDeleteClick}>{t('delete')}</a>
  </>}
    <NavLink to={`/${bases[name]}`}>{t(`see all ${name}s`)}</NavLink>
    <NavLink to={`/${bases[name]}/new`}>{t(`create new ${name}`)}</NavLink>
  </>);
};


export default NavBarShow;
