import React, {useEffect, useState} from 'react';
import s from './Backup.module.css';
import useBackup from "../../controllers/useBackup";
import moment from "moment";
import Button from "../Inputs/Button";
import config from "../../config";

const Backup = props => {
  const {getBackups, deleteBackup, newBackup, restoreBackup, checkStatus} = useBackup();
  const formatDate = date => moment(date).format('LLL');


  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState("Cargando...");

  const loadBackups = async () => {
    const {error, backups} = await getBackups();
    if (error) return alert(error);
    if(Array.isArray(backups))setBackups(backups.map(backup => ({
      ...backup,
      date: moment(backup.date)
    })));
    setLoading(false);
  }

  useEffect(() => {
    loadBackups();
  }, []);


  const onNewBackupClick = async () => {
    setLoading('Creando nueva copia de seguridad...');
    const {error, success} = await newBackup();
    if (error || !success) {
      alert('Se ha producido un error al intentar crear la copia de seguridad');
    }
    await loadBackups();
    setLoading(false);
  }

  const onRestoreBackupClick = async backup => {
    if (!window.confirm('Esta seguro de restablecer la base de datos a la fecha ' + formatDate(backup.date) + '? Esta acción no se puede revertir')) return;
    const errorMsg = 'Se ha producido un error al intentar restablecer la copia de seguridad';

    setLoading('Restableciendo copia de seguridad con fecha ' + formatDate(backup.date) + '...');
    const {error, success} = await restoreBackup(backup.name);
    setLoading(false);

    if (error || !success) {
      alert(errorMsg);
    }

    // start checking
/*    let interval = setInterval(async () => {
      const {error, status} = await checkStatus();
      if (error || status !== 0) {
        clearInterval(interval);
        setLoading(false);
      }
      if (error || status === 2) {
        alert(errorMsg);
      } else if (status === 1) {
        alert('Se ha restablecido la copia de seguridad de forma satisfactoria!');
      }
    }, 2000);*/
  }

  const onDeleteBackupClick = async backup => {
    if (!window.confirm('Esta seguro de eliminar la base de datos con fecha ' + formatDate(backup.date) + '? Esta acción no se puede revertir')) return;

    setLoading('Restableciendo copia de seguridad con fecha ' + formatDate(backup.date) + '...');
    const {error, success} = await deleteBackup(backup.name);
    if (error || !success) {
      alert('Se ha producido un error al intentar eliminar la copia de seguridad');
      setLoading(false);
      return;
    }

    if(success)alert('La copia de seguridad se ha borrado satisfactoriamente!');

    await loadBackups();
    setLoading(false);
  }


  if (loading) return <div>{loading}</div>


  return (
    <div>
      <div className="text-3xl mb-5">Copias de seguridad</div>
      {backups.sort((a,b) => {
        if(a.date.isBefore(b.date))return -1;
        return 1;
      }).map((backup,i) => <div className="flex items-center mb-5">
        <div className="text-2xl font-bold mr-10" style={{width: '200px'}}>
          {formatDate(backup.date)}
        </div>
        <div className="mr-5"><Button type="button" buttonStyle={3} onClick={() => onRestoreBackupClick(backup)}>Restablecer</Button>
        </div>
        <div><Button type="button" buttonStyle={3} onClick={() => onDeleteBackupClick(backup)}>Eliminar</Button></div>
        {i + 1 !== backups.length && <hr/>}
      </div>)}
      {backups.length === 0 && <div className="text-xl">No han sido encontradas copias de seguridad validas</div>}
      <div className="my-10">
        <Button type="button" buttonStyle={3} className={s.newBackupButton} onClick={() => onNewBackupClick()}>Crear nueva copia de
          seguridad</Button>
      </div>
    </div>
  );
};


export default Backup;
