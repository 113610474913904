import React, {useEffect, useRef, useState} from 'react';
import s from './InputWithLabel.module.css';
import {Controller, useFormContext} from "react-hook-form";

const DataElement = ({element, onClick}) => (
  <div className={s.dataElement} onClick={onClick}>
    {element.icon && <div className={s.dataIcon}>{element.icon}</div>}
    <div className="inline-block">
      <div className={s.dataText}>{element.text}</div>
      <div className={s.dataSmallText}>{element.smallText}</div>
    </div>
  </div>
);

const InputWithLabel = React.forwardRef(({
                          id,
                          name,
                          label,
                          type,
                          icon,
                          onFocus,
  defaultValue,
                          onBlur,
                          data,
                          Data,
                          onDataSelect,
                          ...props
                        } = {type: 'text'}, ref) => {

  const [state, setState] = useState({
    isActive: false
  });
  const input = useRef();
  const Element = Data || DataElement;

  const {control} = useFormContext();

  const _onFocus = e => {
    setState({isActive: true});
    e.target.classList.add(s.active);
    onFocus && onFocus();
  }
  const _onBlur = e => {
    setState({isActive: false});
    if (e.target.value.length > 0) {
      e.target.classList.add(s.active);
    } else {
      e.target.classList.remove(s.active);
    }
    onBlur && onBlur(e);
  }

  const dataClassName = `${s.data} ${state.isActive ? s.dataActive : ''}`;

  useEffect(() => {
    const target = input.current;
    target.addEventListener('focus', _onFocus, false);
    target.addEventListener('blur', _onBlur, false);
    return () => {
      target.removeEventListener('focus', _onFocus);
      target.removeEventListener('blur', _onBlur);
    }
  }, []);


  return (
    <Controller
      name={name}
      control={control} render={({field: {onChange: _onChange, value}}) => (
      <div className={s.container}>
        {icon && (<div className={s.icon}>
          {icon}
        </div>)}
        <input ref={input} defaultValue={value} type={type} name={name} id={id} {...props} />
        {label && <label htmlFor={id}>{label}</label>}

        {data && !!data.length && (
          <div className={dataClassName}>
            {data.map((el, i) =>
              (<Element key={el.key || i} element={el} onClick={() => {
                _onChange(el);
                onDataSelect(el, input);
              }}/>))}
          </div>)
        }
      </div>
    )}
    />
  )
});


export default InputWithLabel;
