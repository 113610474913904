import React from 'react';
import s from './AdministratorMe.module.css';

const AdministratorMe = props => {
    return (
        <div/>
    );
};


export default AdministratorMe;