import React, {useMemo} from 'react';
import s from './ShowTable.module.css';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {NavLink} from "react-router-dom";
import bases from "../../routes/bases";

const ShowTable = ({data: _data, order = [], elements, forbiddenKeys = []}) => {
  const {t} = useTranslation();

  const transform = (obj, {name, value}) => {
    if (name === 'createdAt' || name === 'updatedAt') {
      value = moment(value).utc().format('YYYY-MM-DD hh:mm:ss');
      name = t(name);
    } else if (name === 'enterprise_id') {
      name = t('enterprise');
      value = obj.enterprise ?
        <NavLink className="hover:underline link_color" to={`/${bases.enterprise}/show/${value}`}>{obj.enterprise.name}</NavLink>
        : '';
    } else if (name.indexOf('_id') !== -1) {
      name = 'ID ' + t(name.substring(0, name.indexOf('_id')));
    } else if (name === 'min_size') {
      name = t('min size');
      value += ' m²';
    } else if (name === 'max_size') {
      name = t('max size');
      value += ' m²';
    } else if (name === 'min_price') {
      name = t('min price');
      value += '€';
    } else if (name === 'max_price') {
      name = t('max price');
      value += '€';
    } else if (name === 'fee_buyer') {
      name = t('fee_buyer');
      value = Number(value).toFixed(2) + '%';
    } else if (name === 'fee_owner') {
      name = t('fee_owner');
      value = Number(value).toFixed(2) + '%';
    } else if (name === 'price') {
      name = t('price');
      value = Number(value).toFixed(2) + '€';
    } else if (name === 'old_rent') {
      name = t('old_rent');
      value = value === 1 ? 'SI' : 'NO'
    } else if (name === 'name') {
      name = t('name');
      value = value + ' ' + (obj.surname || '') + (obj.alias ? `(${obj.alias})` : '');
    } else if (name === 'phone') {
      name = t(name);
      value = <a href={`tel:${value}`}>{value}</a>
    } else if (name === 'email') {
      name = t(name);
      value = <a href={`mailto:${value}`}>{value}</a>
    } else {
      name = t(name);
    }

    return {name, value};
  }

  let data = useMemo(() => {

    let keys = Object.keys(_data);
    let r = [];
    const allowed = ['string', 'number'];
    const forbidden_keys = [
      'address_id', 'fee_payer', 'creator_id', 'client_id', 'captured_by_id', 'partner_id',
      'country', 'postcode', 'lng', 'lat', 'town', 'province',
      'surname', 'alias', 'notes', 'drawn_area', 'provinces',
      ...forbiddenKeys
    ];
    keys.forEach(key => {
      if (allowed.includes(typeof _data[key]) && !forbidden_keys.includes(key)) {
        r.push(transform(_data, {name: key, value: _data[key]}));
      }
    });
    return r;
  }, [_data]);

  return (
    <div className={s.container}>
      {data && data.map(({name, value}, i) =>
        <div key={i} className={s.tr} style={{
          order: order.indexOf(name) === -1 ? i + 1 : order.indexOf(name) + 1
        }}>
          <div className={s.th}>{name}</div>
          <div className={s.td}>{value}</div>
        </div>
      )}
      {elements && elements.filter(({name, content}) => {
        return !(!name && !content)
      }).map(({name, title, content}, i) => (
        <div key={data.length + i} className={s.tr}>
          <div className={title ? s.title : s.th}>{name}</div>
          {!title && <div className={s.td}>{content}</div>}
        </div>
      ))}
    </div>
  );
};


export default ShowTable;
