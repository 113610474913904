import React, {useCallback, useEffect, useState} from 'react';
import s from './MarkedAreas.module.css';
import Map from "../Map";
import useMap from "../../providers/MapProvider/useMap";
import useServerData from "../../controllers/useServerData";
import Checkbox from "../Inputs/Checkbox";
import Input from "../Inputs/Input";
import {debounce} from "../../helpers/util";
import MapShowInfo from "./MapShowInfo";
import {useFormContext} from "react-hook-form";

import madrid_m30 from '../../resources/geojson/madrid_m30.js';
import madrid_m40 from '../../resources/geojson/madrid_m40.js';
import madrid_m50 from '../../resources/geojson/madrid_m50.js';
import {strFormat} from "../FilterPage/FilterPage";


const MarkedAreas = ({defaultValue, setProvinces: _setProvinces, setDrawnArea}) => {
  const {mapboxgl} = useMap();
  const {getSpainProvinces, getMadridZonePrime} = useServerData();


  const {register, setValue} = useFormContext();

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([-2.1805, 40.1035]);
  const [provinces, setProvinces] = useState(null);
  const [loaded, setLoaded] = useState(0);
  const [primeZone, setPrimeZone] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [selectedProvinces, setSelectedProvinces] = useState([]);


  const [popup, setPopup] = useState(new mapboxgl.Popup({
    closeButton: false
  }));

  const [popupVisible, setPopupVisible] = useState(true);

  const loadProvinces = async () => {
    let {error, provinces} = await getSpainProvinces();
    if (error) return console.error(error);

    provinces.features = provinces.features.sort(sortProvinces);
    setProvinces(provinces);
    setLoaded(prev => prev + 1);
  }
  const loadZonePrime = async () => {
    const {error, zone} = await getMadridZonePrime();
    if (error) return console.error(error);
    setPrimeZone(zone);
    setLoaded(prev => prev + 1);
  }

  const sortProvinces = (a, b) => {
    let name1 = provinceName(a);
    let name2 = provinceName(b);
    if (name1 > name2) return 1;
    if (name1 < name2) return -1;
    return 0;
  }


  useEffect(() => {
    loadProvinces();
    loadZonePrime();
  }, []);

  useEffect(() => {
    if (loaded === 3) {
      setLoaded(true);
      onLoad();
    }
  }, [loaded]);


  const setDefaultValue = defaultValue => {
    if (defaultValue.provinces instanceof Array) {
      defaultValue.provinces.forEach(province => {
        setValue(`provinces.${province.id}`, true);
      });
      setSelectedProvinces(defaultValue.provinces.map(province => province.id));
    }

    if (defaultValue.drawn_area) {
      setDrawnArea(defaultValue.drawn_area);
    }

    const a = name => onZoneChange({target: {name, checked: true}});

    if(defaultValue.m30)a('m30');
    if(defaultValue.m40)a('m40');
    if(defaultValue.m50)a('m50');
    if(defaultValue.zone_spain)a('zone_spain');
    if(defaultValue.zone_prime)a('zone_prime');

  }

  useEffect(() => {
    if (loaded === true && defaultValue) setDefaultValue(defaultValue);
  }, [defaultValue, loaded]);


  const onMapMouseMove = e => {
    var features = map.queryRenderedFeatures(e.point, {
      layers: ['provinces']
    });
    map.getCanvas().style.cursor = features.length ? 'pointer' : '';

    if (!features.length) {
      popup.remove();
      return;
    }

    var feature = features[0];

    /*    if (popupVisible) {
          popup
            .setLngLat(e.lngLat)
            .setText(`${feature.properties.NAME_2}(${feature.properties.NAME_1})`)
            .addTo(map);
        }*/
  }


  const onLoad = () => {
    if (loaded === true) return;

    var start, current, box, touches = 0, zooming = false;

    const mousePos = e => {
      if (!e.clientX && !e.touches[0]) return current;
      let clientX = e.clientX || e.touches[0].clientX;
      let clientY = e.clientY || e.touches[0].clientY;
      var rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        clientX - rect.left - canvas.clientLeft,
        clientY - rect.top - canvas.clientTop
      );
    };

    const onTouchMove = e => current = mousePos(e);

    const mouseDown = e => {
      if (!(e.shiftKey && e.button === 0) && !e.touches) return;

      if(e.touches){
        touches++;
        if(touches >= 2)zooming = true;
      }

      if(!e.touches) {
        map.dragPan.disable();
        map.boxZoom.disable();
      }
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('keydown', onKeyDown);


      document.addEventListener('touchend', onTouchEnd);
      document.addEventListener('touchmove', onTouchMove);


      start = mousePos(e);
    }

    const onMouseMove = e => {
      current = mousePos(e);

      if (!box) {
        box = document.createElement('div');
        box.classList.add('boxdraw');
        canvas.appendChild(box);
      }

      var minX = Math.min(start.x, current.x),
        maxX = Math.max(start.x, current.x),
        minY = Math.min(start.y, current.y),
        maxY = Math.max(start.y, current.y);

      var pos = 'translate(' + minX + 'px,' + minY + 'px)';
      box.style.transform = pos;
      box.style.WebkitTransform = pos;
      box.style.width = maxX - minX + 'px';
      box.style.height = maxY - minY + 'px';
      box.style.border = '2px dotted black';
    }

    const onMouseUp = e => {
      let end = mousePos(e);
      if (start && end) finish([start, end]);
    }
    const onTouchEnd = e => {
      if(!start || zooming) {
        touches--;
        if(touches <= 0){
          touches = 0;
          zooming = false;
        }
        return;
      }
      let end = mousePos(e);

      if(!end)end = new mapboxgl.Point(start.x + 0.1, start.y + 0.1);
      let distance = Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
      if(distance < 20 && touches < 2)finish([start, end]);
      current = false;
      touches--;
    }
    const onKeyDown = e => {
      if (e.keyCode === 27) finish();
    }

    const finish = bbox => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('keydown', onKeyDown);

      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onTouchMove);


      if (box) {
        box.parentNode.removeChild(box);
        box = null;
      }

      if (bbox) {
        var features = map.queryRenderedFeatures(bbox, {
          layers: ['provinces']
        });

        setSelectedProvinces(prev => {
          let n = [...prev];
          let selected_provinces = features.map(f => f.properties.ID_2).filter(pr => {
            let index = prev.indexOf(pr);
            if (index !== -1) {
              n.splice(index, 1);
              return false;
            }
            return true;
          });


          selected_provinces = [...n, ...selected_provinces];


          provinces.features.forEach(({properties}) => {
            setValue(`provinces.${properties.ID_2}`, selected_provinces.includes(properties.ID_2));
          });

          return selected_provinces;
        });
      }
      map.dragPan.enable();
      map.boxZoom.enable();
    }
    map.on('mousemove', onMapMouseMove);


    map.addSource('provinces', {
      type: 'geojson',
      data: provinces
    });
    map.addLayer({
      id: 'provinces',
      type: 'fill',
      source: 'provinces',
      paint: {
        'fill-outline-color': 'rgba(0,0,0,1)',
        'fill-color': 'rgba(0,0,0,0.1)'
      }
    }, 'settlement-label');

    map.addLayer({
      id: 'provinces-highlighted',
      type: 'fill',
      source: 'provinces',
      paint: {
        'fill-outline-color': '#484896',
        'fill-color': '#6e599f',
        'fill-opacity': 0.75
      },
      filter: ['in', 'ID_2', ''],
    }, 'settlement-label');

    // prime zone
    map.addSource('zone_prime', {
      type: 'geojson',
      data: primeZone
    });
    map.addLayer({
      id: 'zone_prime',
      type: 'fill',
      source: 'zone_prime',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(156,1,1)',
        'fill-color': 'rgba(248,15,15,0.36)'
      }
    });


    // m30
    map.addSource('m30', {
      type: 'geojson',
      data: madrid_m30
    });
    map.addLayer({
      id: 'm30',
      type: 'fill',
      source: 'm30',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(1,94,156)',
        'fill-color': 'rgba(1,94,156,0.15)'
      }
    });
    // m40
    map.addSource('m40', {
      type: 'geojson',
      data: madrid_m40
    });
    map.addLayer({
      id: 'm40',
      type: 'fill',
      source: 'm40',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(1,94,156)',
        'fill-color': 'rgba(1,94,156,0.15)'
      }
    });
    // m50
    map.addSource('m50', {
      type: 'geojson',
      data: madrid_m50
    });
    map.addLayer({
      id: 'm50',
      type: 'fill',
      source: 'm50',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-outline-color': 'rgb(1,94,156)',
        'fill-color': 'rgba(1,94,156,0.15)'
      }
    });


    let canvas = map.getCanvasContainer();
    canvas.addEventListener('mousedown', mouseDown, true);
    canvas.addEventListener('touchstart', mouseDown, false);
  }

  const translateProvinces = _provinces => {
    return _provinces.map(id => {
      let p = provinces?.features.find(p => p.properties.ID_2 === id);
      return {name: p?.properties?.NAME_2, id: p?.properties?.ID_2};
    });
  }

  useEffect(() => {
    if (!map) return;
    let filter = ['in', 'ID_2', ...selectedProvinces.filter(a => a)];
    map.setFilter('provinces-highlighted', filter);
    _setProvinces(translateProvinces(selectedProvinces));
  }, [selectedProvinces]);


  const onZoneChange = e => {
    const {name, checked} = e.target;
/*

    map.setLayoutProperty('m30', 'visibility', 'none');
    map.setLayoutProperty('m40', 'visibility', 'none');
    map.setLayoutProperty('m50', 'visibility', 'none');
*/


    if (name === 'zone_spain') {
      provinces.features.forEach(({properties}) => setValue(`provinces.${properties.ID_2}`, checked));
      setSelectedProvinces(checked ? provinces.features.map(({properties}) => properties.ID_2) : []);
    } else if (name === 'zone_prime') {
      map.setLayoutProperty('zone_prime', 'visibility', checked ? 'visible' : 'none');
    } else if (name === 'm30') {
      map.setLayoutProperty('m30', 'visibility', checked ? 'visible' : 'none');
    } else if (name === 'm40') {
      map.setLayoutProperty('m40', 'visibility', checked ? 'visible' : 'none');
    } else if (name === 'm50') {
      map.setLayoutProperty('m50', 'visibility', checked ? 'visible' : 'none');
    }
  }

  const chooseProvince = (province, chosen) => {
    let id = province.properties.ID_2;
    setSelectedProvinces(prev => {
      let selected_provinces = prev;
      selected_provinces = prev.indexOf(id) === -1 ? [...prev, id] : selected_provinces.filter(_name => id !== _name);
      return selected_provinces;
    });
  }

  const filterSearchProvinces = feature => {
    if (!searchText.length) return true;
    let name = provinceName(feature);
    return strFormat(name.toLowerCase()).includes(searchText.toLowerCase());
  }

  const onZoneDraw = e => {
    setDrawnArea(prev => {
      prev = prev || [];
      if (e.type === 'draw.delete') return prev.filter(feature => feature.id !== e.features[0].id);
      if (e.type === 'draw.create') return [...prev, ...e.features];
      if (e.type === 'draw.update') return prev.map(feature => feature.id === e.features[0].id ? e.features[0] : feature);
    });
  }

  useEffect(() => {
    if (!provinces) return;
    setTimeout(() => {
      provinces.features.forEach(({properties}) => {
        setValue(`provinces.${properties.ID_2}`, selectedProvinces.includes(properties.ID_2));
      });
    }, 1000);
  }, [searchText]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchWithDebounce = useCallback(debounce(async text => {
    setSearchText(strFormat(text));
  }, 200), []);

  const provinceName = a => `${a.properties.NAME_2}(${a.properties.NAME_1})`;


  return (
    <div className={s.container}>
      <div className="my-10 flex w-full flex-wrap md:flex-nowrap">
        <Checkbox small={true} id="zone_spain"  {...register("zone_spain")}
                  label="Toda España" onChange={onZoneChange}/>
        <Checkbox small={true} id="zone_prime" {...register("zone_prime")}
                  label="Madrid Zona Prime" onChange={onZoneChange}/>
        <Checkbox small={true} id="m30" {...register("m30")}
                  label="M-30" onChange={onZoneChange}/>
        <Checkbox small={true} id="m40" {...register("m40")}
                  label="M-40" onChange={onZoneChange}/>
        <Checkbox small={true} id="m50" {...register("m50")}
                  label="M-50" onChange={onZoneChange}/>

      </div>
      <div className="flex md:flex-nowrap flex-wrap">
        <div className={s.configContainer}>
        </div>
        <div className={s.mapContainer}>
          <Map
            onDraw={onZoneDraw}
            defaultDrawn={defaultValue?.drawn_area}
            onLoad={map => {
              setMap(map);
              setLoaded(prev => prev + 1)
            }}
            interactive={true}
            center={center}
            zoom={5}
            markers={[]}
          />
          <div className={s.mapShowContainer}>
            <MapShowInfo/>
          </div>
        </div>

        <div className={s.provinces}>
          <table>
            <tbody>
            <tr>
              <td colSpan={2}>
                <Input
                  label='Buscar'
                  onChange={e => setSearchWithDebounce(e.target.value)}
                  containerClassName="pr-5"
                />
              </td>
            </tr>
            {provinces && provinces.features.filter(filterSearchProvinces).map(province => {
              return (
                <tr key={province.properties.ID_2}>
                  <th>{provinceName(province)}</th>
                  <td>
                    <Checkbox
                      value={JSON.stringify({name: province.properties.NAME_2, id: province.properties.ID_2})}
                      {...register(`provinces.${province.properties.ID_2}`)}
                      small={true}
                      onChange={e => chooseProvince(province, e.target.checked)}
                    />
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};


export default MarkedAreas;
