import React, {Suspense} from 'react';
import 'react-dates/initialize';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorCallback from "./components/ErrorCallback";
import routes from "./routes/content";
import {BrowserRouter, useRoutes} from "react-router-dom";
import './helpers/i18n';
import Loader from "./components/Loader";
import AllProviders from "./providers";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'


const RouteWrapper = () => useRoutes(routes);

function App() {

  return (
      <div className="App">
        <Suspense fallback={<Loader/>}>
          <AllProviders>
            <BrowserRouter>
              <ErrorBoundary FallbackComponent={ErrorCallback}>
                <RouteWrapper/>
              </ErrorBoundary>
            </BrowserRouter>
          </AllProviders>
        </Suspense>
      </div>
  );
}

export default App;
