import {useState} from 'react';

const getItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || null;
  } catch (_) {
    return ''
  }
}
const setItem = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
}
export const clearItem = (key) => {
  localStorage.removeItem(key);
}

export const useLocalStorage = (key, def) => {
  let initialState = getItem(key) || def;

  const [storedValue, setStoredValue] = useState(initialState);
  const setValue = value => {
    try{
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setItem(key, valueToStore);
      setStoredValue(valueToStore);
    }catch(err){}
  };

  const clearData = () => {
    clearItem(key);
    setValue(def);
  };

  return [storedValue, setValue, clearData];
};


export default useLocalStorage;
