import {query} from '../../helpers/query';

export const useTask = () => {
	// Task Group
	const createTaskGroup = async task_group => {
		const {error, data} = await query('/api/task_group', task_group, 'POST');
		return {error, task_group_id: data?.task_group_id};
	}
	const getAllTaskGroups = async () => {
		const {error, data} = await query('/api/task_group', false, 'GET');
		return {error, taskGroups: data?.task_groups}
	}
	const editTaskGroup = async (task_group) => {
		const {error, data} = await query(`/api/task_group/${task_group.task_group_id}`, task_group, 'PUT');
		return {error, success: data?.success};
	}
	const deleteTaskGroup = async (task_group_id) => {
		const {error, data} = await query(`/api/task_group/${task_group_id}`, false, 'DELETE');
		return {error, success: data?.success};
	}

	// Task
	const createTask = async task => {
		const {error, data} = await query('/api/task', task, 'POST');
		return {error, task: data};
	}
	const getAllTasks = async ({completed = false, creator_id, type, id} = {}) => {
		if (type) {
			const {error, data} = await query(`/api/task/connected`, {type, id}, 'GET');
			return {error, tasks: data?.tasks};
		} else {
			let query_url = `/api/task?completed=${completed}`;
			if (creator_id) query_url += `&responsible_id=${creator_id}`;
			const {error, data} = await query(query_url, false, 'GET');
			return {error, tasks: data?.tasks}
		}
	}
	const getTask = async ({task_id}) => {
		const {error, data} = await query(`/api/task/${task_id}`, false, 'GET');
		return {error, task: data?.task};
	}
	const editTask = async (task, renew = 1) => {
		const {error, data} = await query(`/api/task/${task.task_id}?renew=${renew}`, task, 'PUT');
		return {error, success: data?.success};
	}

	const deleteTask = async (task) => {
		const {error, data} = await query(`/api/task/${task.task_id}`, false, 'DELETE');
		return {error, success: data?.success};
	}


	return {
		createTaskGroup,
		getAllTaskGroups,
		editTaskGroup,
		deleteTaskGroup,
		createTask,
		getAllTasks,
		editTask,
		deleteTask,
		getTask
	}

}

export default useTask;
