import Select from "../Select";
import React, {useEffect} from "react";
import {useFormContext} from "react-hook-form";


export const investmentProfileOptions = [
  {value: 'opportunistic', label: 'Opportunistic'},
  {value: 'value_add', label: 'Value Add'},
  {value: 'core_plus', label: 'Core +'},
  {value: 'core', label: 'Core'}
]

export function SelectInvestmentProfile(props){
  const { setValue} = useFormContext();

  useEffect(() => {
    if(Array.isArray(props.defaultValue)){
      setValue('investment_profile', props.defaultValue.map(v => investmentProfileOptions.find(o => o.value === v)));
    }
  }, [props.defaultValue]);

  return <Select
    isMulti={true}
    name={`investment_profile`}
    label={"Perfiles de inversión"}
    options={investmentProfileOptions}
    {...props}
  />
}