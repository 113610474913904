import React, {useEffect, useState} from 'react';
import s from './DemandEdit.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import MiddleText from "../../TextComponents/MiddleText";
import {useForm, FormProvider} from "react-hook-form";
import SubmitButton from "../../Inputs/SubmitButton";
import Input from "../../Inputs/Input";
import Textarea from "../../Inputs/Textarea";
import useDemand from "../../../controllers/useDemand";
import bases from "../../../routes/bases";
import {useError} from "../../../hooks/useError/useError";
import Accordion from "../../Accordion";
import MarkedAreas from "../../MarkedAreas";
import SelectAssetCondition from "../../SelectAssetCondition";
import SelectClientAndEnterprise from "../../SelectClientAndEnterprise";
import NumberInput from "../../NumberInput";
import SelectAdmins from "../../Inputs/SelectAdmins";
import {SelectInvestmentProfile} from "../../Inputs/SelectInvestmentProfile/SelectInvestmentProfile";
import {SellType} from "../../Inputs/SellType/SellType";

const DemandEdit = props => {
  const {t} = useTranslation();
  const {ShowErrors, manageError} = useError();
  let {id: demand_id} = useParams();

  const form = useForm({
    defaultValues: {
      zone: 'spain'
    }
  });

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError: _setError,
    clearErrors,
  } = form;

  const {
    errors,
  } = formState;

  const [provinces, setProvinces] = useState([]);
  const [drawnArea, setDrawnArea] = useState(undefined);


  const {createDemand, getDemand, editDemand} = useDemand();

  const [state, setState] = useState({
    demand: false
  });

  const navigate = useNavigate();

  const setError = message => _setError('other',{message});
  const isNewDemand = () => !demand_id;

  const texts = {
    title: isNewDemand() ? t('create new demand') : `${t('edit demand')} (ID:${demand_id})`,
    submit: isNewDemand() ? t('create') : t('change')
  }



  const onSubmit = async demand => {
    let id = demand_id;
    demand.provinces = provinces;//.filter(a => a).map(a => JSON.parse(a));
    demand.drawn_area = drawnArea;

    demand.investment_profile = demand.investment_profile.map(a => a.value).join(';');


    if (!demand_id) {
      const {error, demand_id} = await createDemand(demand);
      if (error) return setError(error);
      id = demand_id;
    } else {
      const {error, success} = await editDemand({demand_id, ...demand});
      if (error) return setError(error);
    }
    navigate(`/${bases.demand}/show/${id}`);
  }

  const loadDemand = async () => {
    const {error, demand} = await getDemand(demand_id);
    if (error) return setError(error);

    if(demand.provinces)demand.provinces = JSON.parse(demand.provinces);
    if(demand.drawn_area)demand.drawn_area = JSON.parse(demand.drawn_area);

    setState(prev => ({...prev, demand}));

    for (let key in demand) {
      const t = typeof demand[key];
      if (t === 'string' || t === 'number') setValue(key, demand[key]);
    }
  }




  useEffect(() => {
    if (!demand_id) return;
    loadDemand();
  }, [demand_id]);

  return (
    <div className="editContainer">
      <div className="menuHeader">
        <NavLink className="hidden md:block" to={`/${bases.demand}`}>{t("see all demands")}</NavLink>
      </div>

      <MiddleText>{texts.title}</MiddleText>
      <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
        <Accordion sections={[
          {title: 'Requisitos de la demanda', header: true, visible: true, content: <>
              <SelectAdmins
                name="captured_by"
                label={t('captured by')}
                containerClassName="mr-10 mb-10"
                defaultValue={state?.demand?.captured_by}
              />
              <SelectClientAndEnterprise
                defaultData={state?.demand}
                manageError={manageError}
              />
              <SelectAssetCondition defaultValue={state?.demand} addIgnore={true} />
              <div className="my-10 flex w-full flex-wrap md:flex-nowrap">
                <NumberInput
                  name="min_price"
                  containerClassName="md:mr-10 mb-5 md:mb-0"
                  label="Precio mínimo"
                  icons={{
                    left: {
                      icon: <span className="inputIconText">€</span>
                    }
                  }}
                />
                <NumberInput
                  name="max_price"
                  label="Precio máximo"
                  icons={{
                    left: {
                      icon: <span className="inputIconText">€</span>
                    }
                  }}
                />
              </div>
              <div className="my-10 flex w-full flex-wrap md:flex-nowrap">
                <NumberInput
                  name="min_rent_price"
                  containerClassName="md:mr-10 mb-5 md:mb-0"
                  label="Precio alquiler mínimo"
                  icons={{
                    left: {
                      icon: <span className="inputIconText">€</span>
                    }
                  }}
                />
                <NumberInput
                  name="max_rent_price"
                  label="Precio alquiler máximo"
                  icons={{
                    left: {
                      icon: <span className="inputIconText">€</span>
                    }
                  }}
                />

              </div>
              <div className="my-10 flex w-full flex-wrap md:flex-nowrap">
                <NumberInput
                  name="min_size"
                  containerClassName="md:mr-10 mb-5 md:mb-0"
                  label="Espacio mínimo"
                  withDecimals={false}
                  icons={{
                    left: {
                      icon: <span className="inputIconText">m²</span>
                    }
                  }}
                />
                <NumberInput
                  name="max_size"
                  label="Espacio máximos"
                  withDecimals={false}
                  icons={{
                    left: {
                      icon: <span className="inputIconText">m²</span>
                    }
                  }}
                />
              </div>
              <div className="my-10 flex w-full flex-wrap md:flex-nowrap">
                <NumberInput
                  name="min_houses"
                  label="Nº Viviendas mínimos"
                  withDecimals={false}
                  containerClassName="md:mr-10 mb-5 md:mb-0"
                />
                <NumberInput
                  name="max_houses"
                  label="Nº Viviendas máximos"
                  withDecimals={false}
                />
              </div>

              <div className="flex flex-wrap md:flex-nowrap w-full mt-10 gap-10">
                <SelectInvestmentProfile defaultValue={state?.demand?.investment_profile}/>
                <SellType/>
              </div>

            </>},
          {title: 'Zonas delimitadas', header: true, visible: true, content:
              <MarkedAreas
                setDrawnArea={setDrawnArea}
                defaultValue={state?.demand}
                setProvinces={setProvinces}
              />
          }
        ]}/>



        <Textarea containerClassName="mb-10" {...register('notes')} label={t('notes')} />
        {Object.keys(errors).length > 0 && <ShowErrors errors={errors}/>}

        <div style={{width: 200, marginBottom: '30px'}}>
          <SubmitButton onClick={() => clearErrors('other')} loading={formState.isSubmitting}>{texts.submit}</SubmitButton>
        </div>
      </form>
      </FormProvider>
    </div>
  );
}


export default DemandEdit;
