import React, {useEffect} from 'react';
import s from './ChooseCadastres.module.css';
import {useFormContext} from "react-hook-form";
import Cadastre from "../Cadastre";
import Checkbox from "../Inputs/Checkbox";

const ChooseCadastres = ({cadastres}) => {
  const {register, setValue} = useFormContext();

  const getRef = (cadastre) => cadastre.length > 1 ? cadastre[0].ref.substring(0, 14) : cadastre[0].ref;
  const getMiniRef = c => c.ref + ' - ' + c.content['DATOS DESCRIPTIVOS DEL INMUEBLE']['Localización'];

  const selectAll = (cadastre, {cadastre_included= false, value = true} = {}) => {
    if (cadastre_included) {
      let ref = getRef(cadastre.content);
      console.log(ref);
      setValue(`want_cadastres.ref_${ref}`, value);
    }

    cadastre.content.forEach(c => setValue(`want_cadastres_box.ref_${getMiniRef(c)}`, value));
  }
  useEffect(() => {
    if (Array.isArray(cadastres)) {
      setTimeout(() => {
        cadastres.forEach(cadastre => selectAll(cadastre,{cadastre_included: true}));
      }, 500);
    }
  }, [cadastres]);

  return (
    <div className="my-10">
      {cadastres.filter(cadastre => cadastre.content).map(cadastre =>
        <Cadastre
          cadastre={cadastre.content}
          chooseBigTitle={() => {
            let ref = getRef(cadastre.content);
            const {onChange, ...rest} = register(`want_cadastres.ref_${ref}`);
            return <div key={ref} className="inline-block relative top-4 mb-3">
              <div className="inline-block">
                <Checkbox
                  small={true}
                  onChange={(e, ...rest) => {
                    e?.stopPropagation();
                    let value = e.target.checked;
                    console.log(value);
                    selectAll(cadastre, {value});
                    onChange(e, ...rest);
                  }}
                  {...rest}
                />
              </div>
              <div className="inline-block relative bottom-4">{ref}</div>
            </div>;
          }}
          chooseTitle={(c) => {
            let ref = getMiniRef(c);
            return <div key={ref} className="inline-block relative top-4">
              <div className="inline-block">
                <Checkbox
                  small={true}
                  {...register(`want_cadastres_box.ref_${ref}`)}
                />
              </div>
              <div className="inline-block relative bottom-4">{ref}</div>
            </div>;
          }}
        />
      )}
    </div>
  );
};


export default ChooseCadastres;
