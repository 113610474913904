import React, {useEffect, useState} from 'react';
import s from './SelectAsset.module.css';
import Select from "../Inputs/Select";
import useAsset from "../../controllers/useAsset";

const SelectAsset = ({name = 'asset_id', ...props}) => {
  const [options, setOptions] = useState([]);
  const {getAssets} = useAsset();


  const loadOptions = async () => {
    const {error, assets} = await getAssets();
    if(error)return alert('Error al cargar los activos');
    setOptions(assets.map(asset => ({value: asset.asset_id, label: asset.asset_file.title})));
  }
  useEffect(() => loadOptions(), []);



  return (
    <Select
      options={options}
      label="Activo"
      name={name}
      isSearchable={true}
      {...props}
    />
  );
};


export default SelectAsset;
