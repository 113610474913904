import React from 'react';
import s from './ShowTableV2.module.css';

const ShowTableV2 = ({children, className, ...props}) => {
  return (
    <div className={`${s.container} ${className}`} {...props}>
      {children}
    </div>
  );
};

export const Row = ({children, className, ...props}) => {
  return <div className={`${s.tr} ${className || ''}`}>
    {children}
  </div>
}
export const Column = ({children, className, title = false, ...props}) => {
  return <div className={`${title ? s.th : s.td} ${className || ''}`}>
    {children}
  </div>
}


export default ShowTableV2;

