import React, {useCallback, useEffect, useState} from 'react';
import s from './TrackingSheetData.module.css';
import {useTranslation} from "react-i18next";
import useOperation from "../../../controllers/useOperation";
import Loader from "../../Loader";
import ErrorText from "../../TextComponents/ErrorText";
import {NavLink, useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";
import TableV2 from "../../TableV2";
import AnimatedModal from "../../AnimatedModal";
import FilterPage from "../../FilterPage";
import FilterButton from "../../FilterButton";
import Button from "../../Inputs/Button";
import Prompt from "../../Prompt";
import {useTableColumns} from "../../../hooks/useTableColumns";
import {findAssetContact, findAssetContactNumber, transform} from "../../../helpers/util";
import {FormProvider, useForm} from "react-hook-form";
import SelectType from "../../Inputs/SelectType";
import Input from "../../Inputs/Input";
import Select from "../../Inputs/Select";
import MiddleText from "../../TextComponents/MiddleText";
import {
  assetConditionFilter,
  AssetConditionRenderer,
  dateRenderer,
  defaultRenderer,
  enterpriseFilterRenderer,
  includesFilter,
  numberLessFilter,
  numberMoreFilter,
  objectNameFilter,
  SelectFilter,
  SelectNumberFilter
} from "../../FilterPage/FilterPage";
import moment from "moment";
import ExportTableButton from "../../ExportTableButton";
import NumberInput from "../../NumberInput";
import {useLocalStorage} from "../../../hooks/useLocalStorage/useLocalStorage";
import {DateSort} from "../../TableV2/sortFunctions";
import {ReactComponent as WhiteBell} from "../../../resources/svg/bell.svg";
import useServerData from "../../../controllers/useServerData";
import useAdmin from "../../../controllers/useAdmin";
import useFavorites from "../../../controllers/useFavorites";

const TrackingSheetData = props => {
  const {t} = useTranslation();
  const {getOperations, editOperation, updateLastDateOperation} = useOperation();
  const {getAdmins} = useAdmin();

  const [onlyFavorites, setOnlyFavorites] = useLocalStorage('ts_fav', false);
  const {loadTextList} = useServerData();

  const {trackingSheetColumnData, trackingSheetTitleData} = useTableColumns();
  const navigate = useNavigate();

  const form = useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [filter, setFilter] = useState({
    filter: false,
    visible: false,
    data: []
  });
  const {getFavorites} = useFavorites();
  const [admins, setAdmins] = useState([]);

  const [phaseModal, setPhaseModal] = useState(false);


  const [typeList, setTypeList] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const toggleFilter = v => setFilter(prev => ({...prev, visible: v ?? !prev.visible}));
  const setFilteredData = data => {
    setFilter(prev => ({...prev, data}));
  }


  const loadTypeList = async () => {
    const {error, list} = await loadTextList('fee_payer');
    if (!error) setTypeList(list);
  }

  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    setLoading(false);
    if (error) return setError(error);

    setAdmins(admins);
  }

  const loadTrackingSheets = async () => {
    let {error, operations} = await getOperations({state: 'muy interesado'});
    setLoading(false);
    if (error) return setError(error);


    operations = operations.map(operation => {
      let r = ({
        ...operation,
        selling_fees: operation.seller_fees || operation?.asset?.fee_seller,
        asset_created_by: admins?.find(admin => operation?.asset?.creator_id * 1 === admin.admin_id * 1)?.name,
        operation_created_by: admins?.find(admin => operation?.creator_id * 1 === admin.admin_id * 1)?.name,
        start_date: transform(operation.start_date, 'date'),
        asset_enterprise: transform(operation.asset, 'enterprise'),
        asset_title: transform(operation, 'asset'),
        asset_contact: findAssetContact(operation.asset),
        asset_contact_number: findAssetContactNumber(operation.asset),
        demand_phone: transform(operation.client || operation.partner, 'phone'),
        price: operation.price
      })
      r.billing = calculateBilling(r);
      return r;
    });

    setData(operations);
    setFilteredData(operations);
  }

  const removeConfirmed = async () => {
    setDeleteVisible(false);
    setLoading(true);
    //await deleteTrackingSheets(selectedRows);
    await loadTrackingSheets();
    setSelectedRows([]);
    setLoading(false);
  }

  const changeFeePayer = async (fee_payer, {operation_id}) => {
    await editOperation({operation_id, fee_payer});
  }

  const changeFees = async (fees, {operation_id}) => {
    await editOperation({operation_id, fees});
    const mapper = operation => {
      if (operation.operation_id !== operation_id) return operation;
      let tmp = operation;
      tmp.fees = fees;
      tmp.billing = calculateBilling(tmp);
      return tmp;
      //operation.operation_id === operation_id ? {...operation, fees, billing: calculateBilling(operation)} : operation;
    }


    setData(prev => {
      let r = prev.map(mapper);
      //setFilteredData(r);
      return r;
    });
    setFilter(prev => ({...prev, data: prev.data.map(mapper)}));
  }

  const changeSellingFees = async (fees, {operation_id}) => {
    await editOperation({operation_id, seller_fees: fees});
    const mapper = operation => {
      if (operation.operation_id !== operation_id) return operation;
      let tmp = operation;
      tmp.seller_fees = fees;
      return tmp;
    }
    setData(prev => prev.map(mapper));
    setFilter(prev => ({...prev, data: prev.data.map(mapper)}));
  }


  const changeOperationPrice = async (price, {operation_id}) => {
    await editOperation({operation_id, price});
    const mapper = operation => {
      if (operation.operation_id !== operation_id) return operation;
      let tmp = operation;
      tmp.price = price;
      tmp.billing = calculateBilling(tmp);
      return tmp;
    }
    setData(prev => prev.map(mapper));
    setFilter(prev => ({...prev, data: prev.data.map(mapper)}));
  }

  const calculateBilling = operation => {
    let price = Math.abs(operation.fees / 100 * operation.price);
    if (!isNaN(price)) return transform({price}, 'price');
    return '';
  }

  const changePhase = async () => {
    let phase = form.getValues('select_phase_modal');
    console.log(selectedRows);
    for (let i = 0; i < selectedRows.length; i++) {
      await editOperation({operation_id: selectedRows[i], phase})
    }
    await loadTrackingSheets();
    setSelectedRows([]);
    setPhaseModal(false);
  }


  const updateLastDate = async () => {
    setLoading(true);
    const {error, success} = await updateLastDateOperation({operations: selectedRows});
    if (error) return alert('Se ha producido un error desconocido');
    await loadTrackingSheets();
    setSelectedRows([]);
    setLoading(false);
    if (success) return alert('Listo');
  }


  const phase_groups = React.useMemo(() => {
    let total = {};
    [1, 2, 3, 4, 5].forEach(b => total[b] = {total: 0, items: 0})
    if (Array.isArray(filter.data)) {
      filter.data.forEach(d => {
        let billing = d.fees / 100 * d.price;
        total[d.phase].total += billing;
        total[d.phase].items++;
      });
    }


    const t = (a, b) => {
      let r = transform({price: (total[a].total) * (b / 100)}, 'price');
      return r.replace(/,\d\d/, '');
    }
    const p = [5, 20, 75, 100];

    const getTotal = () => transform({
      price: Object.keys(total).reduce((r, i) => {
        if (p[i - 1] === undefined) return r;
        return r + (total[i].total * p[i - 1] / 100);
      }, 0)
    }, 'price');

    let r = [
      {name: `Fase 1 - Interés / dossier - 5% (${t(1, p[0])})`, value: 1},
      {name: `Fase 2 - Oferta - 20% (${t(2, p[1])})`, value: 2},
      {name: `Fase 3 - Firmado - 75% (${t(3, p[2])})`, value: 3},
      {name: `Fase 4 - Cobrado - 100% (${t(4, p[3])})`, value: 4},
    ];

    r.push({name: `Total (${getTotal()})`, value: 99, alwaysVisible: true});
    r.push({name: `Descartados`, value: 5, total: total[4]})
    return r;
  }, [filter]);


  const phaseOptions = React.useMemo(() => phase_groups.map(phase => ({
    value: phase.value,
    label: phase.name
  })), [phase_groups]);


  const load = async () => {
    setLoading(true);
    await loadTypeList();
    await loadAdmins();
    setLoading(false);
  }

  useEffect(() => {
    if (admins?.length) loadTrackingSheets();
  }, [admins]);

  useEffect(() => {
    load();
  }, []);

  const setSelectedRowsCallback = useCallback((rows) => {
    setSelectedRows(rows.length > 0 ? rows : []);
  }, [selectedRows]);

  if (loading) return (<Loader/>);
  if (error) return (<ErrorText>{t('errors.' + error)}</ErrorText>);

  return (
    <div className="pt-20">
      <div className="menuHeader">
        <NavLink to={`/${bases.operation}/new`}>{t("create new operation")}</NavLink>
        <FilterButton onClick={toggleFilter}/>
        {selectedRows?.length > 0 &&
          <>
            <FilterButton icon="transfer" text={"Mover de Fase"} onClick={() => setPhaseModal(true)}/>
            <FilterButton icon="update" text={"Actualizar fecha"} onClick={() => updateLastDate()}/>
            <div className="inline-flex ml-5 items-center"
                 onClick={() => navigate(`/${bases.task}/new?type=operation&id=${selectedRows[0]}`)}>
              <WhiteBell width={15} height={15}/>
              <a href="">Tarea</a>
            </div>
          </>}

        <ExportTableButton title={"Hoja de seguimiento"} getData={async () => {
          let cols = phase_groups.map(p => ({
            ...p,
            columns: []
          }));

          let data = filter.data;

          if (onlyFavorites) {
            let {error, favorites} = await getFavorites("operation");
            favorites = favorites.map(f => f.id);
            if (!error) {
              data = data.filter(d => favorites.includes(d.operation_id));
            }
          }


          data.forEach(d => {
            let asset_contact = findAssetContact(d.asset);
            if (asset_contact?.props?.children) asset_contact = asset_contact.props.children;

            let c = {
              asset_title: d?.asset?.asset_file?.title,
              asset_enterprise: d?.asset?.enterprise?.name || '',
              asset_contact,
              asset_created_by: admins?.find(admin => d?.asset?.creator_id * 1 === admin.admin_id * 1)?.name,
              operation_created_by: admins?.find(admin => d?.creator_id * 1 === admin.admin_id * 1)?.name,
              selling_fees: d.seller_fees || d?.asset?.fee_seller || 0,
              fee_payer: d.fee_payer,
              price: d.price,
              billing: calculateBilling(d),
              updatedAt: moment(d.updatedAt).format('LL LT'),
              notes: d.notes
            };

            cols.forEach(g => {
              if (d.phase === g.value) g.columns.push(c);
            });
          });

          return ({
            groups: true,
            headers: trackingSheetColumnData,
            columns: cols
          })
        }}/>
        <FilterButton icon="star" text="Favoritos" className={`${onlyFavorites ? 'starOn' : 'starOff'}`} size={22}
                      onClick={() => setOnlyFavorites(prev => !prev)}/>


      </div>
      <FormProvider {...form}>
        <TableV2
          favoritesType="operation"
          onlyFavorites={onlyFavorites}
          groups={phase_groups}
          groupFilter={group => column => column.phase === group.value}
          // titles={trackingSheetTitleData}
          columns={trackingSheetColumnData}
          data={filter.data}
          renders={{
            seller_fees: operation =>
              <Input
                type="number"
                min="0.01"
                step="0.01"
                defaultValue={operation.selling_fees}
                max="100"
                onChange={e => e.target.value.length > 3 ? e.target.value = e.target.value.substring(0, 4) : 0}
                containerClassName={s.fees_input}
                onBlur={e => changeSellingFees(e.target.value, operation)}
                icons={{
                  left: {
                    icon: <span className="inputIconText">%</span>
                  }
                }}
              />,
            fee_payer: operation =>
              <Input
                type="number"
                min="0.01"
                step="0.01"
                defaultValue={operation.fees}
                max="100"
                onChange={e => e.target.value.length > 3 ? e.target.value = e.target.value.substring(0, 4) : 0}
                containerClassName={s.fees_input}
                onBlur={e => changeFees(e.target.value, operation)}
                icons={{
                  left: {
                    icon: <span className="inputIconText">%</span>
                  }
                }}
              />,
            price: operation =>
              <NumberInput
                name="price"
                maskOptions={{
                  allowNegative: true
                }}
                defaultValue={operation.price}
                onBlur={v => changeOperationPrice(v, operation)}
                icons={{
                  left: {
                    icon: <span className="inputIconText">€</span>
                  }
                }}
                containerClassName={s.changePrice}
              />
          }}
          customSortFunctions={{
            createdAt: DateSort
          }}
          id="operation_id"
          options={{checkbox: true, editable: true}}
          onSelect={setSelectedRowsCallback}
          onEdit={operation_id => window.open(`${window.location.origin}/${bases.operation}/${operation_id}`)}
        />
      </FormProvider>

      {!data.length && <div className="w-96 pl-8">
        <Button onClick={() => navigate(`/${bases.operation}/new`)} buttonStyle={3}>{t('create new')}</Button>
      </div>}


      <Prompt
        title={t("delete confirmation")}
        message={t("not reversible")}
        visible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onAccept={removeConfirmed}
      />

      <AnimatedModal
        visible={filter.visible}
        onClose={toggleFilter}
        className={s.filter}
      >
        <div className={s.container}>
          <FilterPage
            data={data}
            filters={[
              {
                name: 'creator_id',
                label: 'Creador',
                render: SelectFilter({
                  options: admins.map(a => ({value: a.admin_id, label: a.name}))
                }),
                filter: SelectNumberFilter
              },
              {
                name: 'type',
                filter: (_, __, filter, record) => assetConditionFilter(_, __, filter, record.asset || {}),
                render: AssetConditionRenderer
              },
              {
                name: 'start_date_min', filter: (_, raw_date, filterData, record) => {
                  if (raw_date) {
                    let date = moment(raw_date);
                    let record_date = moment(record.start_date, 'LL');
                    return date.isSameOrBefore(record_date);
                  }
                  return true;
                }
              },
              {
                name: 'start_date_max', filter: (_, raw_date, filterData, record) => {
                  if (raw_date) {
                    let date = moment(raw_date);
                    let record_date = moment(record.start_date, 'LL');
                    return date.isSameOrAfter(record_date);
                  }
                  return true;
                }
              },
              {
                name: 'asset_enterprise', filter: (data, filter, filterData, record) => {
                  return objectNameFilter(record?.asset?.enterprise, filter)
                }
              },
              {name: 'asset_contact', filter: includesFilter},
              {name: 'asset_contact_number'},
              {name: 'asset_title', filter: includesFilter},
              {name: 'fee_payer'},
              {name: 'enterprise', filter: objectNameFilter},
              {
                name: 'client', filter: (data, filter, filterData, record) => {
                  return includesFilter(record?.client?.name || record?.partner?.name, filter);
                }
              },
              {name: 'fees_min', filter: (data, filter, filterData, record) => numberLessFilter(record.fees, filter)},
              {name: 'fees_max', filter: (data, filter, filterData, record) => numberMoreFilter(record.fees, filter)},
              {name: 'price_min', filter: (data, filter, filterData, record) => numberLessFilter(record.price, filter)},
              {name: 'price_max', filter: (data, filter, filterData, record) => numberMoreFilter(record.price, filter)},
              {
                name: 'billing_min', filter: (data, filter, filterData, record) =>
                  numberLessFilter(parseFloat(record.billing?.replace(/\./g, '')?.replace(/,/g, '.')), filter)
              },
              {
                name: 'billing_max', filter: (data, filter, filterData, record) =>
                  numberMoreFilter(parseFloat(record.billing.replace(/\./g, '').replace(/,/g, '.')), filter)
              },
            ]}
            onFilter={data => {
              setFilteredData(data);
              toggleFilter(false);
            }}
            body={form => {
              const {register, setValue} = form;
              return <div>
                {AssetConditionRenderer(form)}
                <div className="w-full flex">
                  {dateRenderer({containerClassName: 'mr-5 mb-5'})({
                    name: 'start_date_min',
                    label: 'Fecha inicio Desde'
                  }, register)}
                  {dateRenderer()({name: 'start_date_max', label: 'Fecha inicio Hasta'}, register)}
                </div>
                <div>
                  <MiddleText>OFERTANTE</MiddleText>
                  <hr className="mb-5"/>
                  <div>
                    {enterpriseFilterRenderer({name: 'asset_enterprise', label: 'Empresa'}, register, 0, setValue)}
                    {defaultRenderer({name: 'asset_contact', label: 'Contacto'}, register)}
                    {defaultRenderer({name: 'asset_contact_number', label: t('phone')}, register)}
                  </div>
                </div>
                <div>
                  <MiddleText>OPERACIÓN</MiddleText>
                  <hr className="mb-5"/>
                  <div>
                    <div className="w-full flex">
                      {defaultRenderer({name: 'asset_title', label: 'Título del Activo'}, register)}
                      <SelectType
                        name={'fee_payer'}
                        label="Quién paga honorarios"
                        filter={true}
                        containerClassName="ml-5"
                        listName="fee_payer"
                        usePortal={false}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <MiddleText>DEMANDANTE</MiddleText>
                  <hr className="mb-5"/>
                  <div className="w-full flex">
                    {enterpriseFilterRenderer({name: 'enterprise', label: 'Empresa'}, register, 0, setValue)}
                    <div className="w-full ml-5">
                      {defaultRenderer({name: 'client', label: 'Nombre del Cliente'}, register)}
                    </div>
                  </div>
                </div>
                <div>
                  <MiddleText>OTROS</MiddleText>
                  <hr className="mb-5"/>
                  <div className="w-full flex">
                    <Input
                      type="number"
                      min="0.01"
                      step="0.01"
                      max="100"
                      label="Honorario mímimo"
                      {...register('fees_min')}
                      containerClassName="mr-5"
                      icons={{
                        left: {
                          icon: <span className="inputIconText">%</span>
                        }
                      }}
                    />
                    <Input
                      type="number"
                      min="0.01"
                      step="0.01"
                      max="100"
                      label="Honorario máximo"
                      {...register('fees_max')}
                      icons={{
                        left: {
                          icon: <span className="inputIconText">%</span>
                        }
                      }}
                    />
                  </div>
                  <div className="w-full flex mt-5">
                    <Input
                      type="number"
                      min="1"
                      step="0.01"
                      containerClassName="mr-5"
                      {...register('price_min')}
                      label="Precio mímino"
                      icons={{
                        left: {
                          icon: <span className="inputIconText">€</span>
                        }
                      }}/>
                    <Input
                      type="number"
                      min="1"
                      step="0.01"
                      {...register('price_max')}
                      label="Precio máximo"
                      icons={{
                        left: {
                          icon: <span className="inputIconText">€</span>
                        }
                      }}/>
                  </div>
                  <div className="w-full flex mt-5">
                    <Input
                      type="number"
                      min="1"
                      step="0.01"
                      containerClassName="mr-5"
                      {...register('billing_min')}
                      label="Facturación mímino"
                      icons={{
                        left: {
                          icon: <span className="inputIconText">€</span>
                        }
                      }}/>
                    <Input
                      type="number"
                      min="1"
                      step="0.01"
                      {...register('billing_max')}
                      label="Facturación máximo"
                      icons={{
                        left: {
                          icon: <span className="inputIconText">€</span>
                        }
                      }}/>
                  </div>
                </div>
              </div>
            }}
          />
        </div>
      </AnimatedModal>

      <AnimatedModal
        visible={phaseModal}
        onClose={() => setPhaseModal(false)}
        className='filter'
      >
        <div className={s.container}>
          <FormProvider {...form}>
            <div className="pr-20">
              <MiddleText>Cambiar de fase</MiddleText>
              <Select
                name="select_phase_modal"
                options={phaseOptions}
                containerClassName="my-5"
              />
              <Button
                loadButton={true}
                buttonStyle={3}
                onClick={changePhase}
              >Listo</Button>
            </div>
          </FormProvider>
        </div>
      </AnimatedModal>
    </div>
  );
}


export default TrackingSheetData;
