import bases from './bases';
import Administrator from "../components/Administrator";
import AdministratorData from "../components/Administrator/AdministratorData";
import AdministratorEdit from "../components/Administrator/AdministratorEdit";
import AdministratorMe from "../components/Administrator/AdministratorMe";
import NotFoundPage from "../components/NotFoundPage";
import Client from "../components/Client";
import ClientData from "../components/Client/ClientData";
import ClientEdit from "../components/Client/ClientEdit";
import AdministratorShow from "../components/Administrator/AdministratorShow";
import ClientShow from "../components/Client/ClientShow";
import Enterprise from "../components/Enterprise";
import EnterpriseData from "../components/Enterprise/EnterpriseData";
import EnterpriseEdit from "../components/Enterprise/EnterpriseEdit";
import EnterpriseShow from "../components/Enterprise/EnterpriseShow";
import Partner from "../components/Partner";
import PartnerData from "../components/Partner/PartnerData";
import PartnerEdit from "../components/Partner/PartnerEdit";
import PartnerShow from "../components/Partner/PartnerShow";
import Content from "../components/Content";
import Asset from "../components/Asset";
import AssetData from "../components/Asset/AssetData";
import AssetEdit from "../components/Asset/AssetEdit";
import AssetShow from "../components/Asset/AssetShow";
import Demand from "../components/Demand";
import DemandData from "../components/Demand/DemandData";
import DemandEdit from "../components/Demand/DemandEdit";
import DemandShow from "../components/Demand/DemandShow";
import ConfigPage from "../components/ConfigPage";
import Tasks from "../components/Tasks";
import Operation from "../components/Operation";
import OperationEdit from "../components/Operation/OperationEdit";
import OperationData from "../components/Operation/OperationData";
import TrackingSheet from "../components/TrackingSheet";
import PossibleAsset from "../components/Asset/PossibleAsset";
import TrackingSheetData from "../components/TrackingSheet/TrackingSheetData";
import AssetPDF from "../components/Asset/AssetPDF";
import ShowAssetsMap from "../components/ShowAssetsMap";
import FileExplorer from "../components/FileExplorer";
import TestFileExplorer from "../components/TestFileExplorer";
import PublicDocs from "../components/PublicDocs";
import {Outlet} from "react-router-dom";
import TaskEdit from "../components/Tasks/TaskEdit";
import NotaSimple from "../components/Asset/NotaSimple/NotaSimple";

const routes = [
  {
    path: '/', element: <Content/>, children: [
      {
        path: bases.client, element: <Client/>,
        children: [
          {path: '/', element: <ClientData/>},
          {path: '/new', element: <ClientEdit/>},
          {path: ':id', element: <ClientEdit/>},
          {path: '/show/:id', element: <ClientShow/>}
        ]
      },
      {
        path: bases.enterprise, element: <Enterprise/>,
        children: [
          {path: '/', element: <EnterpriseData/>},
          {path: '/new', element: <EnterpriseEdit/>},
          {path: ':id', element: <EnterpriseEdit/>},
          {path: '/show/:id', element: <EnterpriseShow/>}
        ]
      },
      {
        path: bases.partner, element: <Partner/>,
        children: [
          {path: '/', element: <PartnerData/>},
          {path: '/new', element: <PartnerEdit/>},
          {path: ':id', element: <PartnerEdit/>},
          {path: '/show/:id', element: <PartnerShow/>}
        ]
      },
      {
        path: bases.admin, element: <Administrator/>,
        children: [
          {path: '/', element: <AdministratorData/>},
          {path: '/new', element: <AdministratorEdit/>},
          {path: ':id', element: <AdministratorEdit/>},
          {path: '/me', element: <AdministratorMe/>},
          {path: '/show/:id', element: <AdministratorShow/>}
        ]
      },
      {
        path: bases.asset, element: <Asset/>,
        children: [
          {path: '/', element: <AssetData/>},
          {path: '/new', element: <AssetEdit/>},
          {path: ':id', element: <AssetEdit/>},
          {path: '/show/:id', element: <AssetShow/>},
          {path: '/pdf/:id', element: <AssetPDF/>},
          {path: '/nota_simple/:id', element: <NotaSimple/>},
        ]
      },
      {
        path: bases.demand, element: <Demand/>,
        children: [
          {path: '/', element: <DemandData/>},
          {path: '/new', element: <DemandEdit/>},
          {path: '/:id', element: <DemandEdit/>},
          {path: '/show/:id', element: <DemandShow/>},
        ]
      },
      {
        path: bases.operation, element: <Operation/>,
        children: [
          {path: '/', element: <OperationData/>},
          {path: '/new', element: <OperationEdit/>},
          {path: '/:id', element: <OperationEdit/>}
        ]
      },
      {
        path: bases.tracking_sheet, element: <TrackingSheet/>,
        children: [
          {path: '/', element: <TrackingSheetData/>}
        ]
      },
      {path: bases.possible_asset, element: <PossibleAsset/>},
      {
        path: bases.task, element: <Outlet/>, children: [
          {path: '/', element: <Tasks/>},
          {path: '/new', element: <TaskEdit/>},
          {path: '/:id', element: <TaskEdit/>}
        ]
      },
      {path: bases.public_docs, element: <PublicDocs/>},
      {path: bases.config, element: <ConfigPage/>},
    ]
  },
  {path: bases.assets_map, element: <ShowAssetsMap/>},

  {path: '*', element: <NotFoundPage/>}
];


export default routes;
