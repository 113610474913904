import React, {useEffect, useState} from 'react';
import s from './SelectClient.module.css';
import Input from "../Input";
import useClient from "../../../controllers/useClient";
import {useTranslation} from "react-i18next";
import AnimatedModal from "../../AnimatedModal";
import Text from "../../TextComponents/Text";
import usePartner from "../../../controllers/usePartner";
import {useFormContext} from "react-hook-form";

const SelectClient = React.forwardRef(({client_name = 'client_id', partner_name = 'partner_id', getList = ['clients', 'partners'], clients, containerClassName, onSelect = _ => _, defaultValue, ...props}, ref) => {
  const {t} = useTranslation();
  const {getClientsAndPartners, getClients} = useClient();
  const {getPartners} = usePartner();
  const [state, setState] = useState({
    error: false,
    showModal: false,
    searchText: '',
    selectedClient: false,
    clients: []
  });

  const {setValue, register} = useFormContext();

  useEffect(() => {
    if(defaultValue)return;
    setValue(client_name, 0);
    setValue(partner_name, 0);
  }, []);


  const setError = msg => setState(prev => ({...prev, msg}));

  const loadClients = async () => {
    let r = {};

    if(getList.includes('clients') && getList.includes('partners')) {
      r = await getClientsAndPartners();
    }else if(getList.includes('clients')){
      r = await getClients();
    }else if(getList.includes('partners')){
      const a = await getPartners();
      r.error = a.error;
      r.clients = a.partners;
    }

    if(r.error)return setError(r.error);

    setState(prev => ({...prev, clients: r.clients}));
  }

  const onSearch = e => {
    setState(prev => ({...prev, searchText: e.target.value}));
  }

  const filterClient = client => client.name.toLowerCase().indexOf(state.searchText.toLowerCase()) !== -1

  const selectClient = client => {
    if(!client)return;
    setState(prev => ({...prev, selectedClient: client}));

    if(client.partner_id){
      setValue(client_name, 0);
      setValue(partner_name, client.partner_id*1);
    }else{
      setValue(client_name, client.client_id*1);
      setValue(partner_name, 0);
    }

    onSelect(client);
  };



  useEffect(() => {
    if(clients === undefined)loadClients();
  }, []);

  useEffect(() => {
    if(defaultValue)selectClient(defaultValue.client || defaultValue.partner);
  }, [defaultValue]);

  useEffect(() => {
    console.log(clients);
    if(clients !== undefined)setState(prev => {
      let n = {...prev, clients};
      return defaultValue && clients.length > 0 ? n : {...n, selectedClient: false};
    });
  }, [clients]);

  const setEmpty = () => {
    setValue(client_name, 0);
    setValue(partner_name, 0);
    setState(prev =>({...prev, showModal:false, selectedClient: false}));
    onSelect(false);
  }

  let input_placeholder = getList.includes('partners') && getList.includes('clients') ? "Seleccionar" :
    getList.includes('partners') ? 'Seleccionar partner' : t('select client');

  let search_placeholder = getList.includes('partners') && getList.includes('clients') ? "Nombre" :
    getList.includes('partners') ? 'Nombre del partner' : "Nombre del cliente";



  return (
    <div className={`relative w-full ${containerClassName}`}>
      <input name={client_name} className="hidden" {...register(client_name)} />
      <input name={partner_name} className="hidden" {...register(partner_name)} />

      <Input
        value={state.selectedClient?.name ? (state.selectedClient?.name + ' ' + (state.selectedClient?.surname || '')) : input_placeholder}
        placeholder={input_placeholder}
        onClick={e => setState(prev => ({...prev, showModal: e}))}
        onFocus={e => e.target.blur()}
        readOnly
        {...props}
      />
      <AnimatedModal normalClose={false} className={s.modal} visible={state.showModal} onClose={() => setState(prev => ({...prev, showModal: false}))}>
        <div className={s.container}>
          <div className="link_color text-xl text-right" onClick={() => setEmpty()}>Dejar vacio</div>
          <Input
            label={t("search")}
            placeholder={search_placeholder + '...'}
            onChange={onSearch}
          />

          {!state.clients.length && <Text className="ml-2 mt-3 block">{t("not found")}</Text>}

          <div className={s.clients}>
            {state.clients.filter(filterClient).map((client, i) => (
              <div className={s.client} key={i} onClick={() => {
                selectClient(client);
                setState(prev =>({...prev, showModal:false}));
              }}>
                <Text>{client.name} {client.surname || ''}</Text>
                <br/>
                {/*<SmallText className={s.small_text}>{client.web_page}</SmallText>*/}
              </div>
            ))}
          </div>
        </div>
      </AnimatedModal>
    </div>
  );
});


export default SelectClient;
