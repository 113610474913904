import React, {useEffect, useState} from 'react';
import s from './EnterpriseEdit.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import useEnterprise from "../../../controllers/useEnterprise";
import bases from "../../../routes/bases";
import MiddleText from "../../TextComponents/MiddleText";
import Input from "../../Inputs/Input";
import EmailInput from "../../Inputs/EmailInput";
import Textarea from "../../Inputs/Textarea";
import SubmitButton from "../../Inputs/SubmitButton";
import PhoneInput from "../../Inputs/PhoneInput";
import {useError} from "../../../hooks/useError/useError";
import FullAddress from "../../Inputs/FullAddress";
import Accordion from "../../Accordion";
import SelectType from "../../Inputs/SelectType";
import SelectAdmins from "../../Inputs/SelectAdmins";

const EnterpriseEdit = props => {
  const {t} = useTranslation();
  const {ShowErrors} = useError();
  let {id: enterprise_id} = useParams();
  const form = useForm();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError: _setError,
    clearErrors,
    control,
  } = form;

  const {
    errors,
  } = formState;

  const [state, setState] = useState({
    enterprise: false
  });

  const {createEnterprise, getEnterprise, editEnterprise} = useEnterprise();

  const navigate = useNavigate();

  const setError = message => _setError('other', {message});
  const isNewEnterprise = () => !enterprise_id;


  const texts = {
    title: isNewEnterprise() ? t('create new enterprise') : `${t('edit enterprise')} (ID:${enterprise_id})`,
    submit: isNewEnterprise() ? t('create') : t('change')
  }

  const onSubmit = async enterprise => {
    let id = enterprise_id;
    if (!enterprise_id) {
      const {error, enterprise_id} = await createEnterprise(enterprise);
      if (error) return setError(error);
      id = enterprise_id;
    } else {
      const {error, success} = await editEnterprise({enterprise_id, ...enterprise});
      if (error) return setError(error);
    }
    navigate(`/${bases.enterprise}/show/${id}`);
  }

  const loadEnterprise = async () => {
    const {error, enterprise} = await getEnterprise(enterprise_id);
    if (error) return setError(error);

    setState(prev => ({...prev, enterprise}));

    for (let key in enterprise) {
      setValue(key, enterprise[key]);
    }
  }

  useEffect(() => {
    if (!enterprise_id) return;
    loadEnterprise();
  }, [enterprise_id]);

  return (
    <div className="editContainer">
      <div className="menuHeader">
        <NavLink to={`/${bases.enterprise}`}>{t("see all enterprises")}</NavLink>
      </div>

      <MiddleText>{texts.title}</MiddleText>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
          <div className="flex flex-wrap md:flex-nowrap w-full">
            <Input
              containerClassName="md:mr-10 mb-10"
              {...register('name', {
                required: t('name is required')
              })}
              label={t("name")}/>
            <Input containerClassName="md:mr-10 mb-10" {...register('nif')} label={t('nif')}/>
            <Input
              containerClassName="mb-10"
              {...register('web_page')}
              label={t('web page')}/>
          </div>
          <div className="flex flex-wrap md:flex-nowrap w-full">
            <SelectAdmins
              name="captured_by"
              label={t('captured by')}
              containerClassName="mb-10 mr-10"
              defaultValue={state?.enterprise?.captured_by}
            />
            <SelectType
              name="type"
              label={t('type')}
              containerClassName="mb-10"
              listName="enterprise_types"
            />
          </div>
          <div className="flex w-full flex-wrap md:flex-nowrap">
            <EmailInput
              containerClassName="md:mr-10 mb-10"
              {...register('email')}
              label={t('email')}/>
            <PhoneInput containerClassName="mb-10" {...register('phone')} label={t('phone')}/>
          </div>

          <Accordion sections={[
            {title: 'Ubicación', content: <FullAddress defaultValue={state?.enterprise}/>}
          ]}/>

          <Textarea containerClassName="mb-10" {...register('notes')} label={t('notes')}/>
          {Object.keys(errors).length > 0 && <ShowErrors errors={errors}/>}
          <div style={{width: 200, marginBottom: '30px'}}>
            <SubmitButton onClick={() => clearErrors('other')}
                          loading={formState.isSubmitting}>{texts.submit}</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};


export default EnterpriseEdit;
