import React, {useEffect} from 'react';
import s from './SearchCadastres.module.css';
import Input from "../Inputs/Input";
import {useTranslation} from "react-i18next";
import Button from "../Inputs/Button";
import useCadastre from "../../controllers/useCadastre";
import Cadastre from "../Cadastre";
import {useFormContext} from "react-hook-form";

const SearchCadastres = ({defaultCadastres, setCadastres: _setCadastres, setTable}) => {
  const {t} = useTranslation();
  const [cadastres, setCadastres] = React.useState([{index: 0}]);

  const {register, setValue, getValues} = useFormContext();

  const {getCadastre} = useCadastre();

  useEffect(() => {
    console.log(defaultCadastres);
    if (Array.isArray(defaultCadastres) && defaultCadastres.length > 0) {
      setCadastres(defaultCadastres);
      defaultCadastres.forEach(cadastre => {
        try {
          console.log(cadastre);
          let title = cadastre.content[0].ref.substring(0, 14);
          setTimeout(() => {
            setValue(`cadastres.${cadastre.index}.ref`, title);
          }, 500)
        } catch (_) {
        }
        ;
      });
    }
  }, [defaultCadastres]);


  const addCadastre = (e) => {
    e.preventDefault();
    setCadastres(prev => [...prev, {index: prev[prev.length - 1].index + 1}]);
  }
  const removeCadastre = index => {
    setCadastres(prev => [
      ...prev.filter(item => item.index !== index)
        .map(item => ({...item, index: item.index > index ? item.index - 1 : item.index}))
    ]);
  }


  const convertToTable = table => {
    let r = [];
    let keys = Object.keys(table);
    if (!keys.length) return [];

    for (let i = 0; i < table[keys[0]].length + 1; i++) {
      let m = [];
      keys.forEach(key => {
        if (!i) {
          m.push(key);
        } else {
          m.push(table[key][i - 1]);
        }
      });
      r.push(m);
    }

    return r;
  }

  const searchCadastre = async i => {
    console.log(cadastres);
    const cadastre_raw = getValues(`cadastres[${i}].ref`);
    let {error, cadastres, exists} = await getCadastre(cadastre_raw);

    if (error){
      alert('Se ha producido un error inesperado!');
      return console.error(error);
    }

    if(exists)return alert('Ya existe un activo con el mismo Catastro!');


    console.log(cadastres);

    if (!cadastres || !cadastres[0]) return alert('No encontrado');

    let table = [];

    let surface = 0, graphic_surface = 0, n_houses = 0;

    cadastres.forEach((cadastre_data, j) => {
      let ref = cadastre_data.ref;
      let cadastre = cadastre_data.content;


      if (cadastre['CONSTRUCCIÓN']) {
        let tmp_table = convertToTable(cadastre['CONSTRUCCIÓN']);
        if(Array.isArray(tmp_table)){
          tmp_table.forEach(a => {
            if(a[0] === 'VIVIENDA')n_houses++;
          });
        }
        console.log(tmp_table);
        if (j > 0) tmp_table.shift();
        table.push(...tmp_table);
      }

      let sp = cadastre['DATOS DESCRIPTIVOS DEL INMUEBLE']['Superficie construida ayuda'];
      let sp_gr = cadastre['PARCELA CATASTRAL']['Superficie gráfica'];
      if (sp) {
        sp = sp.substring(0, sp.indexOf(' ') + 1).replace(/[^0-9]/g, '') * 1;
        if (sp > surface) surface = sp;
      }
      if (sp_gr) {
        //sp_gr = sp_gr.replace(/[^0-9]/g, '') * 1;
        sp_gr = sp_gr.substring(0, sp_gr.indexOf(' ') + 1).replace(/[^0-9]/g, '') * 1;

        if (sp_gr > graphic_surface) graphic_surface = sp_gr;
      }
    });



    console.log(surface);

    if (surface) setValue('surface', surface);
    if (graphic_surface) setValue('graphic_surface', graphic_surface);
    if(n_houses)setValue('n_houses', n_houses);

    if (table.length > 0 && window.confirm('Se encontrado una tabla. ¿Quiere agregar los datos a la tabla de la ficha?')) {
      setTable(table);
    }
    setCadastres(prevCadastres => [...prevCadastres.map(item => item.index === i ? {
      ...item,
      content: cadastres
    } : item)]);
  }

  useEffect(() => {
    _setCadastres(cadastres);
  }, [cadastres]);


  return (
    <div className="mb-10">
      {cadastres.map((cadastre) => (
        <div key={cadastre.index}>
          <div className="flex flex-wrap md:flex-nowrap w-full">
            <Input
              containerClassName="md:mr-10 mb-10"
              label="Referencia catastral"
              name={`cadastres.${cadastre.index}.ref`}
              {...register(`cadastres.${cadastre.index}.ref`)}
            />
            <div className={s.buttons}>
              <Button loadButton={true} buttonStyle={3} className="mr-10"
                      onClick={() => searchCadastre(cadastre.index)}>{t('search')}</Button>
              {cadastres.length > 1 &&
              <Button buttonStyle={3} className="mr-10"
                      onClick={() => removeCadastre(cadastre.index)}>{t('remove')}</Button>
              }
            </div>
          </div>
          {cadastre.content && <Cadastre cadastre={cadastre.content}/>}

        </div>
      ))}

      <div className={s.finalButtons}>
        <Button buttonStyle={3} onClick={addCadastre}>Agregar cadastro</Button>
      </div>

    </div>
  );
};


export default SearchCadastres;
