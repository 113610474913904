import React, {useEffect, useState} from 'react';
import s from './AssetShowCadastreTable.module.css';

const AssetShowCadastreTable = ({table: _table}) => {
  const [table, setTable] = useState([]);

  useEffect(() => {
    if(!_table)return;
    try{
      setTable(JSON.parse(_table));
    }catch(err){
      console.error(err);
    }
  }, [_table]);

  return (
    <table className={s.table}>
      <tbody>
      {table.map((tr, i) => <tr key={i} className={i === 0 ? s.title : undefined}>{tr.map((td, j) => <td key={j}>{td}</td>)}</tr>)}
      </tbody>
    </table>
  );
};


export default AssetShowCadastreTable;
