import React, {useEffect, useState} from 'react';
import s from './ClientEdit.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import MiddleText from "../../TextComponents/MiddleText";
import {useForm, FormProvider} from "react-hook-form";
import SubmitButton from "../../Inputs/SubmitButton";
import Input from "../../Inputs/Input";
import Textarea from "../../Inputs/Textarea";
import useClient from "../../../controllers/useClient";
import bases from "../../../routes/bases";
import EmailInput from "../../Inputs/EmailInput";
import SelectEnterprise from "../../Inputs/SelectEnterprise";
import PhoneInput from "../../Inputs/PhoneInput";
import {useError} from "../../../hooks/useError/useError";
import SelectType from "../../Inputs/SelectType";
import SelectAdmins from "../../Inputs/SelectAdmins";

const ClientEdit = props => {
  const {t} = useTranslation();
  const {ShowErrors} = useError();
  let {id: client_id} = useParams();

  const form = useForm();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError: _setError,
    clearErrors,
  } = form;

  const {
    errors,
  } = formState;


  const {createClient, getClient, editClient, checkExists} = useClient();

  const [state, setState] = useState({
    client: false
  });

  const navigate = useNavigate();

  const setError = message => _setError('other',{message});
  const isNewClient = () => !client_id;

  const texts = {
    title: isNewClient() ? t('create new client') : `${t('edit client')} (ID:${client_id})`,
    submit: isNewClient() ? t('create') : t('change')
  }

  const onSubmit = async client => {
    let id = client_id;

    if(typeof client.enterprise_id === 'string')client.enterprise_id = undefined;
    if(!client.enterprise_id)return alert('Seleccione una empresa');

    client.name = client.name.trim();
    client.surname = client.surname.trim();



    if (!client_id) {
      const {exists} = await checkExists(client.phone);
      if(exists)return alert('Ya exists un cliente con este teléfono!');

      const {error, client_id} = await createClient(client);
      if (error) return setError(error);
      id = client_id;
    } else {
      const {error, success} = await editClient({client_id, ...client});
      if (error) return setError(error);
    }
    navigate(`/${bases.client}/show/${id}`);
  }

  const loadClient = async () => {
    const {error, client} = await getClient(client_id);
    if (error) return setError(error);

    setState(prev => ({...prev, client}));

    console.log(client);

    for (let key in client) {
      if(key === 'captured_by')continue;
      setValue(key, client[key]);
    }
  }

  useEffect(() => {
    if (!client_id) return;
    loadClient();
  }, [client_id]);

  const selectEnterpriseType = enterprise => {
    setValue('type', enterprise.type);
  }

  const onCheckExists = async name  => {
    const {exists} = await checkExists(name);
    if(exists)alert('Ya existe un cliente con este nombre!');
  }


  return (
    <div className="editContainer">
      <div className="menuHeader">
        <NavLink to={`/${bases.client}`}>{t("see all clients")}</NavLink>
      </div>

      <MiddleText>{texts.title}</MiddleText>
      <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
        <div className="flex flex-wrap md:flex-nowrap w-full">
          <Input
            containerClassName="md:mr-10 mb-10"
            name="name"
            label={t("name")}
            {...register('name', {
              required: t('name is required')
            })}
            onBlur={e => {
              if(e.target.value.trim().length > 1)onCheckExists(e.target.value.trim());
            }}
          />
          <Input
            containerClassName="md:mr-10 mb-10"
            name="surname"
            label={t("surname")}
            {...register('surname')}
          />
          <Input
            containerClassName="md:mr-10 mb-10"
            name="alias"
            label={t("alias")}
            {...register('alias')}
          />
          <Input
            containerClassName="md:mr-10 mb-10"
            name="dni"
            label={t('dni')}
            {...register('dni')}
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap w-full">
          <SelectAdmins
            name="captured_by"
            label={t('captured by')}
            containerClassName="mb-10 mr-10"
            defaultValue={state?.client?.captured_by}
          />
{/*          <SelectType
            name="type"
            label={t('type')}
            containerClassName="mb-10"
            listName="client_types"
          />*/}
        </div>
        <SelectEnterprise
          containerClassName="mb-10"
          label={t('enterprise')}
          defaultEnterprise={state?.client?.enterprise}
          onSelect={selectEnterpriseType}
        />

        <Input
          containerClassName="mb-10"
          name="job"
          label={t('job position')}
          {...register('job')}
        />
        <div className="flex w-full flex-wrap md:flex-nowrap">
          <EmailInput
            containerClassName="md:mr-10 mb-10"
            name="email"
            label={t('email')}
            {...register('email')}
          />
          <PhoneInput
            containerClassName="mb-10"
            name="phone"
            label={t('phone')}
            {...register('phone')}
          />
        </div>
{/*        <Accordion sections={[
          {title: 'Ubicación', header: true, visible: true, content:
              <FullAddress
                defaultValue={state?.client}
                control={control}
                register={register}
                setValue={setValue}
              />
          }
        ]}/>*/}
        <Textarea
          containerClassName="mb-10"
          name="notes"
          label={t('notes')}
          {...register('notes')}
        />

        {Object.keys(errors).length > 0 && <ShowErrors errors={errors}/>}


        <div style={{width: 200, marginBottom: '30px'}}>
          <SubmitButton onClick={() => clearErrors('other')} loading={formState.isSubmitting}>{texts.submit}</SubmitButton>
        </div>
      </form>
      </FormProvider>
    </div>
  );
};


export default ClientEdit;
