import {useParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import MiddleText from "../../TextComponents/MiddleText";
import React, {useEffect, useState} from "react";
import Input from "../../Inputs/Input";
import Button from "../../Inputs/Button";
import useAsset from "../../../controllers/useAsset";
import {useError} from "../../../hooks/useError";
import Loader from "../../Loader";
import {getAddressParts, validarDatos} from "./util";
import Select from "../../Inputs/Select";


const NotaSimple = (props) => {
  const {id: asset_id} = useParams();
  const {getAsset, solicitarNotaSimple} = useAsset();
  const [loading, setLoading] = useState(true);
  const {error, ShowError, manageError} = useError();
  const [asset, setAsset] = useState(false);
  const [catastres, setCatastres] = useState([]);

  const form = useForm({
    defaultValues: {
      calle: "",
      ncalle: "",
      escalera:"",
      piso: "",
      ciudad: "",
      nfinca:"",
      idufir:"",
      catastro:""
    }
  });

  const {register, setValue} = form;

  const loadAsset = async () => {
    let {error, asset} = await getAsset(asset_id);
    setLoading(false);
    if (error) return manageError(error);
    if (!asset) return manageError('not found');

    if (asset.cadastres) {
      asset.cadastres = asset.cadastres.map((c, i) => {
        return {index: i, content: JSON.parse(c.object)};
      });
    }

    setAsset(asset);
  }

  useEffect(() => {
    if(!asset)return;
    console.log(asset);

    const addressParts = getAddressParts(asset.address);
    setValue('calle', addressParts.calle);
    setValue('ncalle', addressParts.nCalle);
    setValue('ciudad', asset.province);

    if(asset.cadastres?.length > 0){
      setCatastres(asset.cadastres.map(c => ({label: c.content?.[0]?.ref, value: c.content?.[0]?.ref})))
      setValue('catastro', asset.cadastres[0]?.content?.[0]?.ref)
    }

  }, [asset]);

  useEffect(() => {
    loadAsset();
  }, []);



  const onSubmit = async () => {
    return alert ('En proceso de desarrollo');
    let data = form.getValues();
    data.asset_id = asset_id;

    console.log(data);

    let data_error = validarDatos(data);
    if(data_error)return alert(`Por favor, ${data_error}`);



    let {error, success} = await solicitarNotaSimple(asset_id, data);
    if (error) return manageError(error);
    if (success) {
      alert('La nota simple ha sido solicitada! Le llegará un email de confirmación.');
      props.history.push(`/asset/${asset_id}`);
    }
  }

  if(!asset_id)return 'No encontrado';

  if (loading || !asset) return (<Loader/>);

  return <div className="p-10 mb-20">
    <FormProvider {...form}>
      <MiddleText>Solicitar Nota Simple</MiddleText>
      <hr className="mb-10 mt-2"/>
      <div className="flex mb-20">
        <Input
          label="Calle"
          {...register('calle')}
          containerClassName="mr-10"
        />
        <Input
          label="Ciudad"
          {...register('ciudad')}
          containerClassName="mr-10"
        />
      </div>
      <div className="flex mb-20">
        <Input
          label="Número de calle"
          {...register('ncalle')}
          containerClassName="mr-10"
        />
        <Input
          label="Escalera"
          {...register('escalera')}
          containerClassName="mr-10"
        />
        <Input
          label="Piso"
          {...register('piso')}
          containerClassName="mr-10"
        />
      </div>

      <div className="flex mb-20">
        <Input
          label="Número de finca"
          {...register('nfinca')}
          containerClassName="mr-10"
        />
        <Input
          label="IDUFIR (CRU)"
          {...register('idufir')}
          containerClassName="mr-10"
        />
        <Select
          name={`catastro`}
          options={catastres}
          label="Catastro"
          usePortal={false}
        />
      </div>

      <div style={{width: 300}}>
        <Button buttonStyle={4} onClick={onSubmit} className="mr-10">Solicitar Nota Simple</Button>
      </div>

      {error && <ShowError/>}


    </FormProvider>
  </div>
}

export default NotaSimple;