import {query} from "../../helpers/query";
import downloadjs from "downloadjs";

export const useExporter = () => {
  const excel = async ({title, headers, columns, groups}) => {
    console.log(headers);
    console.log(columns);
    let r = await fetch(`/api/export/excel`,{
      method: 'POST',
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({title, headers, columns, groups})
    });
    let blob = await r.blob();
    downloadjs(blob, title);
  }

  const pdf = async ({title, headers, columns, groups}) => {
    let r = await fetch(`/api/export/pdf`,{
      method: 'POST',
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({title, headers, columns, groups})
    });
    let blob = await r.blob();
    downloadjs(blob, title);
  }

  return {excel, pdf};
}

export default useExporter;
