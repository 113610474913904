import React, {useEffect, useState} from 'react';
import s from './AssetEdit.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import MiddleText from "../../TextComponents/MiddleText";
import {useForm, FormProvider} from "react-hook-form";
import SubmitButton from "../../Inputs/SubmitButton";
import Input from "../../Inputs/Input";
import Textarea from "../../Inputs/Textarea";
import useAsset from "../../../controllers/useAsset";
import bases from "../../../routes/bases";
import {useError} from "../../../hooks/useError/useError";
import FullAddress from "../../Inputs/FullAddress";
import SelectOwners from "./SelectOwners";
import Accordion from "../../Accordion";
import SearchCadastres from "../../SearchCadastres";
import AssetFileConfiguration from "../AssetFileConfiguration";
import SelectAssetCondition from "../../SelectAssetCondition";
import SelectAdmins from "../../Inputs/SelectAdmins";
import PossibleAssetsContacts from "../PossibleAssetsContacts";
import NumberInput from "../../NumberInput";
import Checkbox from "../../Inputs/Checkbox";
import {SelectInvestmentProfile} from "../../Inputs/SelectInvestmentProfile/SelectInvestmentProfile";
import {SellType} from "../../Inputs/SellType/SellType";

const AssetEdit = props => {
  const {t} = useTranslation();
  const {ShowErrors} = useError();
  let {id: asset_id} = useParams();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const isNotaccepted = !!params.not_accepted;

  const form = useForm({
    defaultValues: {
      'owners[0].type': 'particular'
    },
    mode: 'onSubmit'
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    setError: _setError,
    clearErrors
  } = form;

  const {
    errors,
  } = formState;

  const no_price = watch('no_price');


  const [defaultOwners, setDefaultOwners] = useState();
  const [defaultCadastres, setDefaultCadastres] = useState();

  const [cadastres, setCadastres] = useState([]);


  const [table, setTable] = useState([
    ['Piso', 'Estado', 'Inicio', 'Renta Mensual'],
    ['Bajo Ext Der', 'Ocupada', 'Octubre 30,2021', '650 €']
  ]);

  const {createAsset, getAsset, editAsset} = useAsset();

  const [state, setState] = useState({
    asset: false
  });

  const navigate = useNavigate();

  const setError = message => _setError('other', {message});
  const isNewAsset = () => !asset_id;

  const texts = {
    title: isNewAsset() ? t('create new asset') : `${t('edit asset')} (ID:${asset_id})`,
    submit: isNewAsset() ? t('create') : t('change')
  }

  const onSubmit = async asset => {
    if(asset.file.title.length === 0)return alert('El título es requerido');
    //if(asset.price.length === 0)return alert('El precio es requerido');

    //if(!asset.enterprise_id)return alert('Seleccine empresa');

    ['enterprise_id', 'client_id', 'price', 'fee_buyer', 'fe_property', 'fee_owner', 'fee_seller'].forEach(key => {
      if (typeof asset[key] === 'string') asset[key] = asset[key] * 1;
      //asset[key] = !asset[key]?.length || isNaN(asset[key]) ? undefined : asset[key] * 1;
    });
    asset.cadastres = cadastres.filter(a => a).map(({content}) => content);
    asset.file.table = table;
    asset.owners = asset.owners ? asset.owners.filter(a => a) : [];
    asset.file.title = asset.file.title.trim();

    for(let i =0;i<asset.owners.length;i++) {

      let owner = asset.owners[i];
      if (owner.type === 'partner') {
        if (!owner.enterprise_id) return alert('Selecciona la empresa del propietario');
        if (!owner.client_id && !owner.partner_id) return alert('Selecciona el partner');
      }else{
        if(!owner.name || !owner.name.length)return alert('Falta el nombre del propietario');
        if(!owner.participation || !owner.participation.length)return alert('Indica la participación del propietario');
      }
    }


    if(asset.contact_list)asset.contact_list = asset.contact_list.filter(contact => !!contact.enterprise_id);

    if(!asset.price || asset.price === 1)asset.accepted = 0;
    if(asset.no_price){
      asset.price = -1;
      asset.accepted = 1;
    }
    asset.asset_condition = asset.asset_condition.filter(a => a);
    asset.investment_profile = asset.investment_profile.map(a => a.value).join(';');



    let id = asset_id;
    if (!asset_id) {
      if(isNotaccepted)asset.accepted = 0;
      const {error, asset_id} = await createAsset(asset);
      if (error) return setError(error);
      if (!asset_id) return setError('internal error')
      id = asset_id;
    } else {
      const {error, success} = await editAsset({asset_id, ...asset});
      if (error) return setError(error);
    }
    navigate(`/${bases.asset}/show/${id}`);
  }

  const setDataAsset = (asset, second = false) => {
    console.log(asset);
    if (asset.asset_file) {
      try {
        let table = JSON.parse(asset.asset_file.table);
        setTable(table);
      } catch (err) {
      }
      setValue('file.title', asset.asset_file.title);
      setValue('file.summary', asset.asset_file.summary);
    }


    if (asset.asset_owners) {
      setDefaultOwners(asset.asset_owners);
    }

    if (asset.cadastres) {
      let cadastres = asset.cadastres.map((c, i) => {
        return {index: i, content: JSON.parse(c.object)};
      });
      setCadastres(cadastres);
      setDefaultCadastres(cadastres);
    }


    for (let key in asset) {
      const t = typeof asset[key];
      if (t === 'string' || t === 'number')
        setValue(key, asset[key]);
    }

    if(asset.price === '-1.00'){
      setValue('no_price', '1');
    }

    /*    if (!second) {
          setTimeout(() => {
            setDataAsset(asset, true);
          }, 1000);
        }*/

  }

  const loadAsset = async () => {
    const {error, asset} = await getAsset(asset_id);
    console.log(asset);
    if (error) return setError(error);

    setState(prev => ({...prev, asset}));
    setDataAsset(asset);

  }


  useEffect(() => {
    if (!asset_id) return;
    loadAsset();
  }, [asset_id]);


  useEffect(() => {
    if(no_price)return setValue('price', '-1.00');
    setValue('price', '1.00');
  }, [no_price]);

  const limitFeeSeller = (e) => {
    const value = e.target.value * 1;
    if (value > 100) setValue('fee_seller', 100);
    else if (value < 0) setValue('fee_seller', 1);
  }



  let accordion_sections = [
    {
      title: 'Datos del activo', header: true, visible: true, content: <>
        <div className="flex flex-wrap md:flex-nowrap w-full">
          <Input
            containerClassName="mr-10"
            label="Título"
            name="file.title"
            {...register('file.title')}
          />
          <SelectAdmins
            name="captured_by"
            label={t('captured by')}
            containerClassName=" mr-10"
            defaultValue={state?.asset?.captured_by}
          />
        </div>
        {/*              <SelectClientAndEnterprise
                defaultData={state?.asset}
                manageError={manageError}
              />*/}
        <SelectAssetCondition defaultValue={state.asset}/>
        <div className="flex flex-wrap md:flex-nowrap w-full">
          <NumberInput
            name="price"
            containerClassName="mr-10"
            label="Precio"
            disabled={no_price}
            maskLayer={no_price ? 'N/A' : false}
            icons={{
              left: {
                icon: <span className="inputIconText">€</span>
              }
            }}
          />

          <NumberInput
            name="surface"
            containerClassName="mr-10"
            label="Superficie construida"
            icons={{
              left: {
                icon: <span className="inputIconText">m²</span>
              }
            }}
          />
          <NumberInput
            name="graphic_surface"
            containerClassName="mr-10"
            label="Superficie gráfica"
            icons={{
              left: {
                icon: <span className="inputIconText">m²</span>
              }
            }}
          />
          <NumberInput
            name="n_houses"
            label="NºViviendas"
            withDecimals={false}
          />
        </div>
        <Checkbox {...register('no_price')} small={true} classNameContainer="mt-2" id="no_price" label="Precio no definido - N/A"/>
        <div className="flex flex-wrap md:flex-nowrap w-full mt-10">
          <NumberInput
            name="rent_price"
            containerClassName="mr-10"
            label="Precio alquiler"
            icons={{
              left: {
                icon: <span className="inputIconText">€</span>
              }
            }}
          />
          <NumberInput
            name="bedrooms"
            label="Dormitorios"
            withDecimals={false}
            containerClassName="mr-10"
          />
          <NumberInput
            name="toilets"
            label="Baños"
            withDecimals={false}
            containerClassName="mr-10"
          />
          <NumberInput
            name="floor"
            label="Piso"
            withDecimals={false}
          />
        </div>
        <Checkbox {...register('terrace')} small={true} classNameContainer="mt-2" id="terrace" label="Terraza"/>

        <div className="flex flex-wrap md:flex-nowrap w-full mt-10">
          <Input
            type="number"
            min="0.00"
            step="0.01"
            max="100"
            containerClassName="mr-10"
            label="Honorario Vendedor"
            {...register(`fee_seller`)}
            onBlur={limitFeeSeller}
            icons={{
              left: {
                icon: <span className="inputIconText">%</span>
              }
            }}
          />
          <NumberInput
            name="plant"
            label="Planta"
            withDecimals={false}
          />
        </div>


        <div className="flex flex-wrap md:flex-nowrap w-full mt-10 gap-10">
          <SelectInvestmentProfile defaultValue={state?.asset?.investment_profile}/>
          <SellType/>
        </div>

      </>
    },
    {
      title: 'Ubicación',
      header: true,
      visible: true,
      content: <FullAddress defaultValue={state?.asset} />
    },
    {
      title: 'Propietarios', header: true, visible: true, content:
        <SelectOwners defaultOwners={defaultOwners}/>
    },
    {
      title: "Catastros",
      header: true,
      visible: true,
      content: <SearchCadastres defaultCadastres={defaultCadastres} setCadastres={(a) => setCadastres(a)}
                                setTable={setTable}/>
    },
    {
      title: 'Ficha',
      header: true,
      visible: true,
      content: <AssetFileConfiguration table={table} setTable={setTable}/>
    }
    ];


  if(state?.asset?.accepted === 0 || isNotaccepted){
    accordion_sections.splice(1,0,{
      title: 'Contactos',
      header: true,
      visible: false,
      content: <PossibleAssetsContacts asset={state.asset}/>
    });
    accordion_sections.splice(3, 0, {
      title: 'Domicilio',
      header: true,
      visible: false,
      content: <FullAddress name="second_address." defaultValue={state?.asset?.second_address}/>
    });
  }



  return (
    <div className="editContainer">
      <div className="menuHeader">
        <NavLink to={`/${bases.asset}`}>{t("see all assets")}</NavLink>
      </div>

      <MiddleText>{texts.title}</MiddleText>
      <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
        <Accordion sections={accordion_sections}/>


        <Textarea containerClassName="mb-10" {...register('notes')} label={t('notes')} />
        {Object.keys(errors).length > 0 && <ShowErrors errors={errors}/>}

        <div style={{width: 200, marginBottom: '30px'}}>
          <SubmitButton onClick={() => clearErrors('other')}
                        loading={formState.isSubmitting}>{texts.submit}</SubmitButton>
        </div>
      </form>
      </FormProvider>
    </div>
  );
};


export default AssetEdit;
