import React, {useEffect, useState} from 'react';
import s from './SelectType.module.css';
import Select from "../Select";
import useServerData from "../../../controllers/useServerData";
import {useFormContext} from "react-hook-form";

const SelectType = ({name, listName, label, manageError, filter= false, defaultValue, cachedList, ...props}) => {
  const [list, setList] = useState(cachedList || []);
  const {loadTextList} = useServerData();

  const {control, setValue} = useFormContext();

  const loadList = async () => {
    let list = cachedList;
    if(!list) {
      const r = await loadTextList(listName);
      if (r.error) {
        if (manageError) manageError(r.error);
        return;
      }
      list = r.list;
    }
    let l = list.map(v => ({value: v, label: v}));
    if(filter){
      let ignore_option = {value: '', label: 'Ignorar'};
      if(!defaultValue)defaultValue = ignore_option;
      l.push(ignore_option);
    }
    setList(l);
    setValue(name, defaultValue || l[0].value);
  }

  useEffect(() => {
    loadList()
  }, []);

  return (
    <Select
      name={name}
      label={label}
      control={control}
      options={list}
      defaultValue={defaultValue}
      menuShouldBlockScroll={true}
      {...props}
    />
  );
};


export default SelectType;
