import React, {useEffect, useState} from 'react';
import s from './SelectAdmins.module.css';
import useAdmin from "../../../controllers/useAdmin";
import Select from "../Select";
import useAuth from "../../../providers/AuthProvider/useAuth";
import {useFormContext} from "react-hook-form";

const SelectAdmins = ({name, name_id='captured_by_id', label, manageError, defaultValue, ...props}) => {
  const [options, setOptions] = useState([]);
  const {getAdmins} = useAdmin();
  const {getAdmin} = useAuth();


  const {setValue, register} = useFormContext();

  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    if(error){
      if(manageError)manageError(error);
      return;
    }

    let list = admins.map(v => ({value: v.admin_id, label: v.name || v.email}));
    setOptions(list);
    console.log(list);
    const id = getAdmin().admin_id;
    setValue(name, defaultValue || list.filter(a => id === a.value));
  }

  const setDefaultValues = () => {
    if(!(defaultValue instanceof Array))return;

    if(options.length === 0)return setTimeout(() => setDefaultValues(), 500);

    setValue(name, options.filter(option => {
      for(let i = 0;i < defaultValue.length; i++){
        if(defaultValue[i] === option.value)return true;
      }
      return false;
    }));
  }

  useEffect(() => {
    setDefaultValues();
  }, [defaultValue]);



  useEffect(() => loadAdmins(), []);

  return (<>
      <input type="hidden" name="captured_by_id" {...register('captured_by_id')}/>
      <Select
      minSelect={1}
      name={name}
      label={label}
      options={options}
      defaultValue={defaultValue}
      isMulti
      {...props}
    />
    </>
  );
};


export default SelectAdmins;
