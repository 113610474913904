import React, {useEffect, useMemo, useState} from 'react';
import s from './Tasks.module.css';
import {useTranslation} from "react-i18next";
import {useError} from "../../hooks/useError/useError";
import useTask from "../../controllers/useTask";
import Button from "../Inputs/Button";
import Checkbox from "../Inputs/Checkbox";
import Select from "../Inputs/Select";
import {useForm, FormProvider} from "react-hook-form";
import Textarea from "../Inputs/Textarea";
import useAdmin from "../../controllers/useAdmin";
import MiddleText from "../TextComponents/MiddleText";
import moment from "moment";
import useAuth from "../../providers/AuthProvider/useAuth";
import Skeleton from "react-loading-skeleton";
import {parseTask} from "../../helpers/util";
import TaskShowDate from "../TaskShowDate";
import bases from "../../routes/bases";
import {useNavigate} from "react-router-dom";
import TaskShowConnection from "../TaskShowConnection";


import {ReactComponent as EditSVG} from '../../resources/svg/edit.svg'

const DeleteSVG =  props => <svg width={18} height={18} {...props} viewBox="-40 0 427 427.00131"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"/><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/></svg>



const Tasks = props => {
  const {t} = useTranslation();
  const {error, ShowError, manageError} = useError();
  const navigate = useNavigate();

  const {getAllTasks, getAllTaskGroups, createTaskGroup, editTask, deleteTaskGroup, deleteTask} = useTask();
  const {getAdmins} = useAdmin();
  const {getAdmin} = useAuth();

  const [loading, setLoading] = useState();
  const [taskGroups, setTaskGroups] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [admins, setAdmins] = useState([]);

  const form = useForm({
    defaultValues: {
      completed_tasks: false,
      my_tasks: true
    }
  });

  const completed_tasks = form.watch('completed_tasks');
  const only_my_tasks = form.watch('my_tasks');


  const loadGroups = async () => {
    const {error, taskGroups} = await getAllTaskGroups();
    if (error) return manageError(error);
    setTaskGroups(taskGroups);
  }
  const loadTasks = async () => {
    setLoading(true);
    let query = {completed: completed_tasks};
    if(only_my_tasks)query.creator_id = getAdmin().admin_id;
    const {error, tasks} = await getAllTasks(query);
    setLoading(false);
    if (error) return manageError(error);
    setTasks(tasks.map(task => parseTask(task)));
  }
  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    if (error) return manageError(error);
    setAdmins(admins.map(admin => ({value: admin.admin_id, label: admin.name})));
  }
  const loadData = async () => {
    setLoading(true);
    await loadGroups();
    await loadTasks();
    await loadAdmins();
    setLoading(false);
  }
  useEffect(() => loadData(), []);

  useEffect(() => {
    loadTasks();
  }, [completed_tasks, only_my_tasks]);


  const onCreateTaskGroup = async () => {
    let name = prompt("Nombre del grupo");
    if(!name)return;

    setLoading(true);
    await createTaskGroup({name});
    await loadGroups();
    setLoading(false);
  }

  const toggleTaskComplete = async (completed, task) => await updateTask(task, 'completed', completed);
  const updateTask = async (task, key, value) => {
    const {success} = await editTask({task_id: task.task_id, [key]: value}, 0);
    if(success)return setTasks(prev => prev.map(t => t.task_id === task.task_id ? {...t, [key]: value}: t));
    alert('Error desconocido');
  }

  const deleteGroup = async group => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('Seguro que quiere eliminar el grupo "' + group.name + '"? También se eliminarán todas sus tareas!')){
      setLoading(true);
      await deleteTaskGroup(group.task_group_id);
      await loadGroups();
      await loadTasks();
      setLoading(false);
    }
  }
  const deleteTaskClicked = async task => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm('Seguro que quiere eliminar la tarea?')){
      setLoading(true);
      const {error, success} = await deleteTask(task);
      if(error || !success)alert('Error desconocido');
      else {
        setTasks(prev => prev.filter(({task_id}) => task_id !== task.task_id));
      }
      setLoading(false);
    }
  }

  const openEditTask = task => navigate(`/${bases.task}/${task.task_id}`);


  const filterTaskGroup = group => {
    let r;
    if(completed_tasks) {
      r = tasks.filter(task => (task.completed && (task.group_id === group.task_group_id)))
    }else{
      r = tasks.filter(task => (!task.completed && (task.group_id === group.task_group_id)))
    }
    if(only_my_tasks)r = r.filter(task => task.responsible_id === getAdmin().admin_id);
    return r;
  }

  const sortTasks = (task1,task2) => {
    let a = moment(task1.date || (new Date().toISOString()));
    let b = moment(task2.date || (new Date().toISOString()));

    if(a < b)return -1;
    if(a > b)return 1;
    return 0;
  }


  if (error) return <ShowError/>

  return (
    <div className="showContainer">
      <div className="p-5 border-b-2 border-gray-100 flex">
                <Button style={{width: '200px', marginRight: '20px'}} className="px-32" onClick={() => navigate(`/${bases.task}/new`)}
                buttonStyle={4}>Crear Tarea</Button>
        <Button style={{width: '200px'}} onClick={() => onCreateTaskGroup()} buttonStyle={4}>Crear Grupo</Button>
      </div>

      <div className="w-full flex my-5">
        <div className="w-5/12 mr-10 pl-5">
          <Checkbox
            label="Tareas completadas"
            small={true}
            defaultChecked={false}
            {...form.register('completed_tasks')}
          />
          <Checkbox
            label="Mis tareas"
            small={true}
            defaultChecked={true}
            {...form.register('my_tasks')}
          />
        </div>
        <div className="w-1/12 mr-10"><MiddleText className={s.table_title}>Vinculada a</MiddleText></div>
        <div className="w-1/12 mr-10"><MiddleText className={s.table_title}>Creador</MiddleText></div>
        <div className="w-3/12 mr-10"><MiddleText className={s.table_title}>Responsables</MiddleText></div>
        <div className="w-2/12 mr-5"><MiddleText className={s.table_title}>Fecha</MiddleText></div>
      </div>


      {loading || admins.length === 0 ? <Skeleton height={250} count={3}/> :
        <FormProvider {...form}>
          <form>
            {taskGroups.filter(filterTaskGroup).map(group => (
              <div key={group.task_group_id}>
                <div className={s.title}>
                  <div className="mr-5">
                    <DeleteSVG onClick={() => deleteGroup(group)}/>
                  </div>
                  <div>
                    {group.name[0].toUpperCase() + group.name.substring(1, group.name.length)}
                  </div>
                </div>
                <div className="relative">
                  {tasks.filter(({group_id, completed}) => {
                    if (completed_tasks) return ((group_id === group.task_group_id) && completed);
                    return group_id === group.task_group_id && !completed;
                  }).sort(sortTasks).map(task => (
                    <div className="flex justify-center items-center w-full mb-5" key={task.task_id}>
                      <div className={`w-1/12 flex justify-center ${s.svg_container}`}>
                        <EditSVG onClick={() => openEditTask(task)} className="relative top-2 mr-3" width={17} height={17}/>
                        <DeleteSVG onClick={() => deleteTaskClicked(task)} className="mt-2" width={17} height={17}/>
                        <Checkbox
                          small={true}
                          defaultChecked={task.completed}
                          className="m-auto"
                          onChange={(e) => toggleTaskComplete(e.target.checked, task)}
                        />
                      </div>
                      <div className="w-4/12 mr-10">
                        <Textarea
                          rows={3}
                          onBlur={(e) => updateTask(task, 'task', e.target.value)}
                          defaultValue={task.task}
                        />
                      </div>
                      <div className={`w-1/12 mr-5 ${s.tableTd}`}>
                        <TaskShowConnection task={task}/>
                      </div>
                      <div className="w-1/12 mr-10">
                        <div className={s.tableTd}>
                          {admins.find(a => a.value === task.creator_id)?.label}
                        </div>
{/*                        <Select
                          name={'creator_' + task.task_id}
                          options={admins}
                          defaultValue={task.creator_id || null}
                          onChange={e => updateTask(task, 'creator_id', e)}
                        />*/}
                      </div>
                      <div className="w-3/12 mr-10">
                        <Select
                          name={'responsible_' + task.task_id}
                          minSelect={1}
                          options={admins}
                          placeholder="Seleccionar responsables"
                          defaultValue={admins.filter(a => task.responsible.includes(a.value + ''))}
                          onChange={e => updateTask(task, 'responsible', e.map(a => a.value).join(','))}
                          isMulti={true}
                        />
                      </div>
                      <div className={`w-2/12 mr-5 ${s.tableTd}`}>
                        <TaskShowDate task={task} updateTask={updateTask}/>
                      </div>

                    </div>
                  ))}
                </div>
              </div>
            ))}
          </form>
        </FormProvider>
      }
    </div>
  );
};


export default Tasks;
