import {useState} from "react";
import notFoundImageSVG from "../../resources/svg/NotFoundImage.svg";

export const Image = ({src: imageSrc, images, onError, node, ...props}) => {
  const [src, setSrc] = useState(imageSrc);
  const [fallbackImages, setFallbackImages] = useState(images || []);

  const onErrorCallback = () => {
    if (fallbackImages && fallbackImages.length > 0) {
      setSrc(fallbackImages[0]);
      setFallbackImages(fallbackImages.slice(1));
    } else {
      setSrc(notFoundImageSVG);
    }

    if (onError) onError();
  }

  if (node) return <>{node(src)}</>;

  return (
    <img src={src} onError={onErrorCallback} {...props}/>
  );
};