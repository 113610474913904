import React, {useEffect, useState} from 'react';
import s from './Accordion.module.css';
import MiddleText from "../TextComponents/MiddleText";


const EmptyContent  = () => <MiddleText className="p-8">0 resultados encontrados</MiddleText>


const Element = ({title, id, content, loadOnShow = false, header = true, visible, toggleVisible,titleStyle = {},i}) =>{
  const [loaded, setLoaded] = useState(!loadOnShow);


  return <div className={s.container}>
  <div onClick={() => {
    setLoaded(true);
    toggleVisible(i);
  }} className={`${s.title} ${header ? s.header : ''}`} style={titleStyle} id={id}>
    <div className={`${s.icon} ${visible[i] ? s.iconVisible : ''}`}>
      <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.631282 0.292893C0.97299 -0.0976315 1.52701 -0.0976315 1.86872 0.292893L6.5 5.58579L11.1313 0.292893C11.473 -0.0976315 12.027 -0.0976315 12.3687 0.292893C12.7104 0.683417 12.7104 1.31658 12.3687 1.70711L7.11872 7.70711C6.77701 8.09763 6.22299 8.09763 5.88128 7.70711L0.631282 1.70711C0.289573 1.31658 0.289573 0.683417 0.631282 0.292893Z" fill="black"/>
      </svg>
    </div>
    <span>{title}</span>
  </div>

  {!header &&
  <div className={s.bar_container}>
    <div className={s.bar}/>
  </div>
  }

  <div className={`${s.content} ${visible[i] ? s.visibleContent : ''}`}>
    {loaded && content}
    {(!content || (content.length === 0)) && <EmptyContent/>}
  </div>
</div>
}


const Accordion = ({sections, direction = 'column'}) => {
  const defaultVisible = () => sections.map(({visible = true}) => visible);

  const [visible, setVisible] = useState(defaultVisible);

  useEffect(() =>
    setVisible(prev =>
      sections.map(({visible = true}, i) => prev[i] === undefined ? visible : prev[i])
    )
  , [sections]);

  const toggleVisible = i => setVisible(prev => {
    let n = [...prev];
    n[i] = !n[i];
    return n;
  });

  return (
    <div className={s.accordion} style={{flexDirection: direction}}>
      {sections.map((section, i) => <Element {...section}
        toggleVisible={toggleVisible}
        visible={visible}
                                             i={i}
        key={section.key|| i}/>)}
    </div>

  );
};


export default Accordion;
