import React, {useEffect, useState} from 'react';
import s from './FullAddress.module.css';
import SelectAddress from "../SelectAddress";
import Input from "../Input";
import {useTranslation} from "react-i18next";
import Accordion from "../../Accordion";
import Map from "../../Map";
import {useFormContext} from "react-hook-form";

const FullAddress = ({name = '', defaultValue = ''}) => {
  const {t} = useTranslation();
  const [center, setCenter] = useState([0, 0]);
  const [marker, setMarker] = useState(null);

  const {register, setValue} = useFormContext();

  useEffect(() => {
    if (!defaultValue) return;
    if (defaultValue.address) setValue(`${name}address`, defaultValue?.address);
    if (defaultValue.lng && defaultValue.lat) {
      let center = [defaultValue.lng * 1, defaultValue.lat * 1];
      setCenter(center);
      setMarker({
        options: {},
        center: center
      });
    }
  }, [defaultValue]);

  const selectAddress = (address, input) => {
    let context = {
      postcode: 'postcode',
      place: 'town',
      region: 'province',
      country: 'country'
    };

    address.context.forEach(c => {
      let type = c.id.substring(0, c.id.indexOf('.'));
      type = context[type];
      if (type) setValue(`${name}${type}`, c.text_es || c.test);
    });

    setCenter(address.center);
    setMarker({
      options: {},
      center: address.center
    });

    setValue(`${name}address`, address.place_name_es);
    setValue(`${name}lng`, address.center[0]);
    setValue(`${name}lat`, address.center[1]);

    if (input.current) input.current.value = address.place_name_es;
  }
  return (
    <>
      <SelectAddress
        defaultValue={defaultValue}
        containerClassName="mb-10"
        name={`${name}address`}
        placeholder="Madrid Calle Abantos"
        onDataSelect={selectAddress}
      />
      <div className="flex flex-wrap md:flex-nowrap w-full">
        <Input
          containerClassName="md:mr-10"
          type="hidden"
          {...register(`${name}postcode`)}
          readOnly
        />
        <Input
          containerClassName="md:mr-10"
          {...register(`${name}town`)}
          readOnly
          type="hidden"
        />
        <Input
          containerClassName="md:mr-10"
          {...register(`${name}province`)}
          readOnly
          type="hidden"
        />
        <Input
          {...register(`${name}country`)}
          readOnly
          type="hidden"
        />
        <input type="hidden"  {...register(`${name}address_id`)} className="hidden"/>
        <input type="hidden" {...register(`${name}lng`)} className="hidden"/>
        <input type="hidden" {...register(`${name}lat`)} className="hidden"/>
      </div>

      {center[0] !== 0 &&
      <Accordion
        sections={[
          {
            title: t('show map'), header: false, visible: false, content:
              <div className={s.mapContainer}>
                <Map center={center} zoom={18} markers={marker ? [marker] : null}/>
              </div>
          }
        ]}
      />
      }
    </>
  );
}


export default FullAddress;
