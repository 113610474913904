import React, {useEffect, useState} from 'react';
import s from './Cadastre.module.css';
import Accordion from "../Accordion";

const Cadastre = ({cadastre: cadastres, chooseBigTitle, chooseTitle}) => {
  const [sections, setSections] = useState([]);


  useEffect(() => {
    setSections(cadastres.map(c => {
      let cadastre = c.content;
      return {
        title: chooseTitle ? chooseTitle(c) : c.ref + ' - ' + c.content['DATOS DESCRIPTIVOS DEL INMUEBLE']['Localización'],
        header: false, visible: false,
        content: (
          <div key={c.ref} className={s.cadastre}>
            {Object.keys(cadastre).filter(title => title !== 'CONSTRUCCIÓN').map((title, i) => (<div key={i}>
              <div className={s.title}>{title}</div>
              <div className={s.content}>
                {Object.keys(cadastre[title]).map((key, j) => (
                  <div className={s.element} key={j}>
                    <div>{key}</div>
                    <div>{cadastre[title][key]}</div>
                  </div>
                ))}
              </div>
            </div>))}
          </div>)
      }
    }));
  }, [cadastres]);

  return <Accordion sections={[
    {title: chooseBigTitle ? chooseBigTitle() : cadastres.length > 0 ?  cadastres[0].ref.substring(0, 14) : cadastres[0].ref, visible:false, content:
      <Accordion sections={sections}/>
    }
  ]}/>
};


export default Cadastre;
