import {query} from "../../helpers/query";

export const useAdmin = () => {
  const getAdmins = async () => {
    const {error, data} = await query('/api/admin', false, 'GET');
    return {error, admins: data?.admins};
  }
  const createAdmin = async admin => {
    const {error, data} = await query('/api/admin', admin);
    return {error, admin_id: data?.admin_id};
  }

  const editAdmin = async admin => {
    const {error, data} = await query(`/api/admin/${admin.admin_id}`, admin, 'PUT');
    return {error, success: data?.success};
  }

  const deleteAdmin = async admin_id => {
    const {error, data} = await query(`/api/admin/${admin_id}`, false, 'DELETE');
    return {error, success: data?.success};
  }

  const getAdmin = async admin_id => {
    const {error, data} = await query(`/api/admin/${admin_id}`, false, 'GET');
    return {error, admin: data?.admin};
  }

  const deleteAdmins = async ids => {
    for(let i = 0;i<ids.length;i++){
      let id = ids[i];
      const {error} = await deleteAdmin(id);
      if(error)return {error};
    }
    return {success: true};
  }

  return {getAdmins, createAdmin, editAdmin, deleteAdmin, getAdmin, deleteAdmins};
};

export default useAdmin;
