import bases from "./bases";

let routes = [
  {
    path: bases.enterprise,
    text: 'enterprises',
    secured: true
  },
  {
    path: bases.client,
    text: 'clients',
    secured: true
  },
  {
    path: bases.partner,
    text: 'partners',
    secured: true
  },
  {
    path: bases.asset,
    text: 'assets'
  },
  {
    path: bases.demand,
    text: 'demands'
  },
  {
    path: bases.tracking_sheet,
    text: 'tracking_sheets'
  },
  {
    path: bases.operation,
    text: 'operations'
  },
  {
    path: bases.possible_asset,
    text: 'possible_assets'
  },
  {
    path: bases.public_docs,
    text: 'public_docs'
  },
  {
    path: bases.task,
    text: 'tasks'
  },
  {
    path: bases.assets_map,
    text: 'map'
  },
  {
    path: bases.admin,
    text: 'administrators',
    secured: true
  },
  {
    path: bases.config,
    text: 'config',
    secured: true
  }
];


export default routes;
