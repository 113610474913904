import React, {useCallback, useEffect} from 'react';
import {ThemeContext} from './ThemeContext';

import {useLocalStorage} from '../../hooks/useLocalStorage';

const defaultTheme = 'default';
const themes = ['default', 'dark'];

const ThemeProvider = (props) => {
  const [theme, setTheme] = useLocalStorage("theme", defaultTheme);

  const updateTheme = useCallback(name => {
    name = themes.includes(name) ? name : 'default';
    setTheme(name);
    themes.forEach(theme => document.body.classList.remove(theme + '-theme'));
    if(name !== 'default')document.body.classList.add(name + '-theme');
  }, [setTheme]);
  const setCSSVar = (variable, value) => {
    document.documentElement.style.setProperty(`--${variable}`, value);
  }
  const listThemes = () => themes;

  useEffect(() => {
    updateTheme(theme);
  }, [theme, updateTheme]);


  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: updateTheme,
        setCSSVar,
        listThemes
      }}>
      {props.children}
    </ThemeContext.Provider>
  );

};

export default ThemeProvider;
