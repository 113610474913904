import React, {useEffect, useState} from 'react';
import s from './AssetData.module.css';
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useAsset from "../../../controllers/useAsset";
import Loader from "../../Loader";
import bases from "../../../routes/bases";
import TableV2 from "../../TableV2";
import AnimatedModal from "../../AnimatedModal";
import FilterPage from "../../FilterPage";
import FilterButton from "../../FilterButton";
import Button from "../../Inputs/Button";
import Prompt from "../../Prompt";
import {useError} from "../../../hooks/useError";
import {
	adaptFilterKey,
	assetConditionFilter,
	AssetConditionRenderer,
	assetPartnerClientFilter,
	AssetPartnerClientRenderer,
	includesFilter,
	includesRequiredFilter,
	numberFilterRenderer,
	numberLessFilter,
	numberMoreFilter,
	SelectFilter,
	SelectNumberFilter,
	textareaRenderer
} from "../../FilterPage/FilterPage";
import {useTableColumns} from "../../../hooks/useTableColumns";
import ExportTableButton from "../../ExportTableButton";
import {transform} from "../../../helpers/util";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {DateSort} from "../../TableV2/sortFunctions";
import useFavorites from "../../../controllers/useFavorites";
import useAdmin from "../../../controllers/useAdmin";

const AssetData = ({fullPage = true, filter: externalFilter}) => {
  const {t} = useTranslation();
  const {error, ShowError, manageError} = useError();
  const [onlyFavorites, setOnlyFavorites] = useLocalStorage('assets_fav', false);

  const {getAssets, deleteAssets, setSold, setTrash, acceptAssets} = useAsset();
  const {assetColumnData} = useTableColumns();


  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    filter: false,
    visible: false,
    data: []
  });

  const {getFavorites} = useFavorites();
  const {getAdmins} = useAdmin();

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const toggleFilter = v => setFilter(prev => ({...prev, visible: v ?? !prev.visible}));
  const setFilteredData = data => {
    setFilter(prev => ({...prev, data}));
  }

  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    setLoading(false);
    if (error) return manageError(error);

    setAdmins(admins);
  }
  const loadAssets = async () => {
    let {error, assets} = await getAssets(externalFilter);
    setLoading(false);
    if (error) return manageError(error);

    assets = assets.map(a => ({...a, title: a.asset_file?.title}));
    setData(assets);
    setFilteredData(assets);
  }

  const removeElements = async () => {
    setDeleteVisible(true);
  }

  const removeConfirmed = async () => {
    setDeleteVisible(false);
    setLoading(true);
    await deleteAssets(selectedRows);
    await loadAssets();
    setSelectedRows([]);
    setLoading(false);
  }

  const toggleSellElements = async () => {
    setLoading(true);
    for (let i = 0; i < selectedRows.length; i++) {
      await setSold(selectedRows[i]);
    }
    await loadAssets();
    alert('Listo!');
    setLoading(false);
  }

  const toggleTrashElements = async () => {
    setLoading(true);
    for (let i = 0; i < selectedRows.length; i++) {
      await setTrash(selectedRows[i]);
    }
    await loadAssets();
    alert('Listo!');
    setLoading(false);
  }

  const moveToPossibleAssets = async () => {
    if (!window.confirm('¿Mover los activos seleccionados a posibles activos?')) return;
    setLoading(true);
    await acceptAssets(selectedRows, false);
    await loadAssets();
    alert("Listo!");
    setLoading(false);
  }
  const phase_groups = React.useMemo(() => [
    {name: '', value: 0},
    {name: 'Vendidos', value: 1},
    {name: 'Basurero', value: 2}
  ], []);


  useEffect(() => {
    loadAssets();
    loadAdmins();
  }, []);


  if (loading) return (<Loader/>);
  if (error) return <ShowError/>

  return (
    <div className={fullPage ? 'pt-20' : ''}>
      {fullPage && <div className="menuHeader">
        <NavLink to={`/${bases.asset}/new`}>{t("create new asset")}</NavLink>
        <FilterButton onClick={toggleFilter}/>
        {selectedRows?.length > 0 && <FilterButton icon="remove" text={t('delete')} onClick={removeElements}/>}

        <ExportTableButton title={"activos"} getData={async () => {
          let cols = [{
            name: '',
            columns: []
          }, {
            name: 'Vendidos',
            columns: []
          }, {
            name: 'Basurero',
            columns: []
          }
          ];
          let data = filter.data;

          if (onlyFavorites) {
            let {error, favorites} = await getFavorites("asset");
            favorites = favorites.map(f => f.id);
            if (!error) {
              data = data.filter(d => favorites.includes(d.asset_id));
            }
          }


          data.forEach(d => {
            let c = {
              asset_id: d.asset_id,
              title: d.title,
              type: d.type,
              condition: d.condition,
              usage: d.usage,
              address: d.address,
              price: transform(d, 'price'),
              updatedAt: transform(d.updatedAt, 'datetime')
            };

            if (d.sold === 1) {
              cols[1].columns.push(c);
            } else if (d.trash === 1) {
              cols[2].columns.push(c);
            } else {
              cols[0].columns.push(c);
            }
          });

          return ({
            groups: true,
            headers: assetColumnData,
            columns: cols
          })
        }}/>
        <FilterButton icon="star" text="Favoritos" className={`${onlyFavorites ? 'starOn' : 'starOff'}`} size={22}
                      onClick={() => setOnlyFavorites(prev => !prev)}/>

        {selectedRows?.length > 0 &&
          <FilterButton text="Otros" icon="more">
            <FilterButton icon="sell" text="Intercambiar vendido" onClick={toggleSellElements}/>
            <FilterButton icon="remove" text="Intercambiar basurero" onClick={toggleTrashElements}/>

            {selectedRows?.length === 1 &&
              <FilterButton icon="pdf" text="Generar PDF" onClick={() => {
                if (selectedRows.length > 1) return alert('Selecciona solo una operación');
                navigate('/' + bases.asset + '/pdf/' + selectedRows[0]);
              }}/>
            }

            <FilterButton icon="transfer" text="Mover a posibles activos" onClick={moveToPossibleAssets}/>


          </FilterButton>
        }

      </div>
      }


      {!fullPage && <div className="menuHeaderFullPage">
        <FilterButton onClick={toggleFilter}/>
      </div>}


      <TableV2
        groups={phase_groups}
        favoritesType="asset"
        onlyFavorites={onlyFavorites}
        groupFilter={group => column => {
          if (group.value === 1) return column.sold === 1 && column.trash === 0;
          if (group.value === 2) return column.trash === 1;
          return column.sold === 0 && column.trash === 0;
        }
        }
        columns={assetColumnData}
        sortFormatFunctions={{
          price: asset => {
            let p = asset.price.replace(/[^\d.]/g, '') * 1;
            if (p === -1) return 'N/A';
            return p;
          }
        }}
        customSortFunctions={{
          updatedAt: DateSort
        }}
        data={filter.data}
        id="asset_id"
        options={{checkbox: true, editable: true}}
        onSelect={rows => {
          setSelectedRows(rows.length > 0 ? rows : []);
        }}
        onEdit={asset_id => window.open(`${window.location.origin}/${bases.asset}/${asset_id}`)}
        onView={asset_id => window.open(`${window.location.origin}/${bases.asset}/show/${asset_id}`)}
      />

      {!data.length && <div className="w-96 pl-8">
        <Button onClick={() => navigate(`/${bases.asset}/new`)} buttonStyle={3}>{t('create new')}</Button>
      </div>}

      <Prompt
        title={t("delete confirmation")}
        message={t("not reversible")}
        visible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onAccept={removeConfirmed}
      />

      <AnimatedModal
        visible={filter.visible}
        onClose={toggleFilter}
        className='filter'
      >
        <div className={s.container}>
          <FilterPage
            data={data}
            filters={[
              {
                name: 'creator_id',
                label: 'Creador',
                render: SelectFilter({
                  options: admins.map(a => ({value: a.admin_id, label: a.name}))
                }),
                filter: SelectNumberFilter
              },
              {
                name: 'client_partner',
                label: 'Procedencia',
                render: AssetPartnerClientRenderer,
                filter: assetPartnerClientFilter
              },
              {name: 'title', label: t('title')},
              {name: 'type', filter: assetConditionFilter, render: AssetConditionRenderer},
              {name: 'address', label: t('address'), filter: includesFilter},
              {name: 'price', label: "Precio minimo", render: numberFilterRenderer, filter: numberLessFilter},
              {
                name: 'price_max', label: "Precio máximo", render: numberFilterRenderer, filter:
                  adaptFilterKey(a => a.price, numberMoreFilter)
              },
              {name: 'notes', label: "Notas", render: textareaRenderer, filter: includesRequiredFilter},

              {name: 'bedrooms', label: "Dormitorios mínimos", render: numberFilterRenderer, filter: numberLessFilter},
              {
                name: 'bedrooms_max',
                label: "Dormitorios máximos",
                render: numberFilterRenderer,
                filter: numberMoreFilter
              },

              {name: 'toilets', label: "Baños mínimos", render: numberFilterRenderer, filter: numberLessFilter},
              {name: 'toilets_max', label: "Baños máximos", render: numberFilterRenderer, filter: numberMoreFilter},

              {name: 'floor', label: "Piso mínimo", render: numberFilterRenderer, filter: numberLessFilter},
              {name: 'floor_max', label: "Piso máximo", render: numberFilterRenderer, filter: numberMoreFilter},

            ]}
            onReset={(setValue) => {
              setValue('type', 0);
              setValue('usage', 0);
              setValue('condition', 0);
              setValue('client_partner', 0);
            }}
            onFilter={data => {
              setFilteredData(data);
              toggleFilter(false);
            }}
          />
        </div>
      </AnimatedModal>
    </div>
  );
}


export default AssetData;
