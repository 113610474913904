import React, {useCallback, useEffect, useState} from 'react';
import s from './SelectAddress.module.css';
import InputWithLabel from "../InputWithLabel";
import {useTranslation} from "react-i18next";
import useLocation from "../../../controllers/useLocation";
import {debounce}  from '../../../helpers/util';
import {useFormContext} from "react-hook-form";

const SelectAddress = React.forwardRef(({id, containerClassName, name,label,...props} = {type: 'text'}, ref) => {
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const {autocompleteAddress} = useLocation();

  const {control} = useFormContext();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findResults = useCallback(
    debounce(async (text) => {
      const {error, result} = await autocompleteAddress(text);
      if(error)setData([]);
      console.log(result);
      if(result instanceof Array) {
        setData(result.map(result => {
          return ({
            ...result,
            text: result.place_name_es
          })
        }));
      }else{
        setData([]);
      }
    }, 500), []);


  const search = async (e) => {
    let text = e.target.value;
    if(text.length > 5 && text.indexOf(' ') !== -1){
      findResults(text);
    }else{
      setData([]);
    }
  }


  return (
    <div className={`${s.container} ${containerClassName}`}>
      <div className={s.label}>{label || t('address')}</div>
      <InputWithLabel
        id={id}
        name={name}
        icon={<svg version="1.1" style={{top: 3,position: 'relative'}} width={18} height={18} x="0px" y="0px" viewBox="0 0 512.032 512.032" > <g> <g> <path d="M496.016,224c-8.832,0-16,7.168-16,16v181.184l-128,51.2V304c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v168.352 l-128-51.2V167.648l74.144,29.664c8.096,3.264,17.504-0.704,20.8-8.928c3.296-8.192-0.704-17.504-8.928-20.8l-95.776-38.336 c0,0,0,0-0.032,0l-0.256-0.096c-3.808-1.536-8.064-1.536-11.872,0l-0.288,0.096c0,0,0,0-0.032,0L10.064,193.152 C4.016,195.584,0.016,201.44,0.016,208v288c0,5.312,2.656,10.272,7.04,13.248c2.688,1.824,5.792,2.752,8.96,2.752 c2.016,0,4.032-0.384,5.952-1.152l154.048-61.6l153.76,61.504c0,0,0,0,0.032,0l0.288,0.128c3.808,1.536,8.064,1.536,11.872,0 l0.288-0.128c0,0,0,0,0.032,0L502,446.88c6.016-2.464,10.016-8.32,10.016-14.88V240C512.016,231.168,504.848,224,496.016,224z M160.016,421.152l-128,51.2V218.816l128-51.2V421.152z"/> </g> </g> <g> <g> <path d="M400.016,64c-26.464,0-48,21.536-48,48s21.536,48,48,48s48-21.536,48-48S426.48,64,400.016,64z M400.016,128 c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16c8.832,0,16,7.168,16,16C416.016,120.832,408.848,128,400.016,128z"/> </g> </g> <g> <g> <path d="M400.016,0c-61.76,0-112,50.24-112,112c0,57.472,89.856,159.264,100.096,170.688c3.04,3.36,7.36,5.312,11.904,5.312 s8.864-1.952,11.904-5.312C422.16,271.264,512.016,169.472,512.016,112C512.016,50.24,461.776,0,400.016,0z M400.016,247.584 c-34.944-41.44-80-105.056-80-135.584c0-44.096,35.904-80,80-80s80,35.904,80,80C480.016,142.496,434.96,206.144,400.016,247.584z "/> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>}
        onChange={search}
        data={data}
        control={control}
        {...props}
      />
    </div>
  );
});


export default SelectAddress;
