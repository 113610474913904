import React from 'react';
import s from './Demand.module.css';
import {Outlet} from "react-router-dom";

const Demand = props => {
    return (
        <Outlet/>
    );
};


export default Demand;
