import React, {useEffect, useState} from 'react';
import s from './SelectClientAndEnterprise.module.css';
import SelectEnterprise from "../Inputs/SelectEnterprise";
import {useTranslation} from "react-i18next";
import SelectClient from "../Inputs/SelectClient";
import useEnterprise from "../../controllers/useEnterprise";

const SelectClientAndEnterprise = ({
                                     enterprise_name,
                                     client_label,
                                     enterprise_params = {},
                                     client_params = {},
                                     client_name,
                                     partner_name,
                                     defaultData,
                                     manageError,
                                     onClientSelect,
  containerClassName,
                                     onEnterpriseSelect
                                   }) => {
  const {t} = useTranslation();
  const [clients, setClients] = useState([]);

  const {getEnterpriseClientsAndPartners} = useEnterprise();

  const _onEnterpriseSelect = async enterprise => {
    if (onEnterpriseSelect) onEnterpriseSelect(enterprise);
    const {error, clients} = await getEnterpriseClientsAndPartners(enterprise.enterprise_id);
    if (error) {
      if (manageError) manageError(error);
      return;
    }

    if (client_params.getList) {
      setClients(clients.filter(client =>
        (client_params.getList.includes('clients') && client.client_id !== undefined) ||
        (client_params.getList.includes('partners') && client.partner_id !== undefined)
      ));
    } else {
      setClients(clients);
    }

  }


  return (
    <div className={`flex w-full flex-wrap md:flex-nowrap ${containerClassName}`}>
      <SelectEnterprise
        name={enterprise_name || "enterprise_id"}
        label={t('enterprise')}
        defaultEnterprise={defaultData?.enterprise}
        onSelect={_onEnterpriseSelect}
        containerClassName="md:mr-10 md:mb-0 mb-5"
        {...enterprise_params}
      />
      <SelectClient
        client_name={client_name || "client_id"}
        partner_name={partner_name || 'partner_id'}
        label={client_label || t('client')}
        clients={clients}
        defaultValue={defaultData}
        onSelect={onClientSelect}
        {...client_params}
      />
    </div>);
};


export default SelectClientAndEnterprise;
