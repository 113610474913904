import React from 'react';
import s from './TaskTypeNotification.module.css';
import TaskPeriodicChoose from "../TaskPeriodicChoose";
import Input from "../../Inputs/Input";
import {useFormContext} from "react-hook-form";

const TaskTypeNotification = props => {
  const {register, watch} = useFormContext();
  const taskType = watch('task.type');

  return (
    <>
      <div className="mt-10 flex">
        <div>
          <div className="label">Tipo de tarea</div>
          <div className="flex flex-wrap md:flex-nowrap w-full my-10">
            <input type="radio" id={`task_type_singular`} {...register(`task.type`)}
                   value="singular" className="mr-5"/>
            <label htmlFor={`task_type_singular`}>Una notificación</label>

            <input type="radio" id={`task_type_periodic`} {...register(`task.type`)} value="periodic"
                   className="mx-5"/>
            <label htmlFor={`task_type_periodic`}>Periodica</label>
          </div>
        </div>
        {taskType === 'periodic' && <TaskPeriodicChoose/>}
      </div>

      {taskType === 'singular' && <div style={{width: '200px'}}>
        <Input type="date" {...register("task.date")} label="Fecha de la notificación"/>
      </div>}
    </>
  );
};


export default TaskTypeNotification;
