import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useTask from "../../controllers/useTask";
import Loader from "../Loader";
import {useError} from "../../hooks/useError/useError";
import TableV2 from "../TableV2";
import {useTableColumns} from "../../hooks/useTableColumns";
import useAdmin from "../../controllers/useAdmin";
import Select from "../Inputs/Select";
import {useForm, FormProvider} from "react-hook-form";
import TaskShowDate from "../TaskShowDate";
import Button from "../Inputs/Button";
import bases from "../../routes/bases";
import {useTranslation} from "react-i18next";


const ShowTaskConnectionFilter = ({type, id}) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(true);
	const {error, ShowError, manageError} = useError();
	const [taskGroups, setTaskGroups] = useState([]);
	const [admins, setAdmins] = useState([]);

	const form = useForm();

	const {getAllTasks, getAllTaskGroups, editTask} = useTask();
	const {getAdmins} = useAdmin();

	const {tasksColumnData} = useTableColumns();

	const loadGroups = async () => {
		const {error, taskGroups} = await getAllTaskGroups();
		if (error) return manageError(error);
		setTaskGroups(taskGroups);
	}
	const loadAdmins = async () => {
		const {error, admins} = await getAdmins();
		if (error) return manageError(error);
		setAdmins(admins.map(admin => ({value: admin.admin_id, label: admin.name})));
	}
	const loadTasks = async () => {
		setLoading(true);
		const {error, tasks} = await getAllTasks({type, id});
		setLoading(false);
		if (error) return manageError(error);

		setTasks(tasks);
	}

	const loadData = async () => {
		setLoading(true);
		await loadGroups();
		await loadAdmins();
		await loadTasks();

		setLoading(false);

	}

	useEffect(() => {
		loadData();
	}, []);

	const updateTask = async (task, key, value) => {
		const {success} = await editTask({task_id: task.task_id, [key]: value}, 0);
		if (success) return setTasks(prev => prev.map(t => t.task_id === task.task_id ? {...t, [key]: value} : t));
		alert('Error desconocido');
	}

	if (loading) return (<Loader/>);
	if (error) return <ShowError/>


	return <div>
		<div style={{width: '200px'}}>
			<Button onClick={() => navigate(`/${bases.task}/new?type=${type}&id=${id}`)} buttonStyle={3}>Crear nueva</Button>
		</div>
		<FormProvider {...form}>
			<TableV2
				columns={tasksColumnData}
				data={tasks}
				id="task_id"
				renders={{
					creator_id: task => admins.find(a => a.value === task.creator_id)?.label,
					group_id: task => taskGroups.find(a => a.task_group_id === task.group_id)?.name,
					responsible: task => <Select
						name={'responsible_' + task.task_id}
						minSelect={1}
						options={admins}
						placeholder="Seleccionar responsables"
						defaultValue={admins.filter(a => task.responsible.includes(a.value + ''))}
						onChange={e => updateTask(task, 'responsible', e.map(a => a.value).join(','))}
						isMulti={true}
					/>,
					date: task => <TaskShowDate task={task} updateTask={updateTask}/>

				}}
			/>
		</FormProvider>
	</div>

}

export default ShowTaskConnectionFilter;