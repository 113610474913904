import React from 'react';
import s from './OperationSelectState.module.css';
import Select from "../../Inputs/Select";

const str = [
  'por presentar',
  'presentado',
  'no contestado',
  'estudiando',
  'muy interesado',
  'rechazado',
  'vendido',
  'Caro-Seguimiento',
  'lo conocen',

  'N.S. Solicitada',
  'N.S. Rechazada',
  'Datos propiedad',
  'Carta 1',
  'Carta 2',
  'Carta 3'
];


const OperationSelectState = ({name = 'state', filter = false, defaultValue, small = false, ...props}) => {
  const options = React.useMemo(() => {
      //if(filter)str.push('ignorar');
      return str.map(t => ({value: t, label: t[0].toUpperCase() + t.substring(1, t.length)}))
    }
    , []);

  const styles = React.useMemo(() => ({
    control: (provided, state) => {
      let bg = '#fff';
      if (state.hasValue) {
        let v = state.getValue()[0].value;
        switch (v) {
          case 'rechazado':
          case 'lo conocen':
            bg = '#de2c2c';
            break;
          case 'estudiando':
            bg = 'yellow';
            break;
          case 'no contestado':
            bg = 'pink';
            break;
          case 'muy interesado':
            bg = 'green';
            break;
          case 'vendido':
            bg = '#5d0404'
            break;
        }
      }
      return {
        ...provided,
        backgroundColor: bg
      };
    },
    singleValue: (provided, state) => {
      let color = '#000';
      if (state.hasValue) {
        let v = state.getValue()[0].value;
        switch (v) {
          case 'rechazado':
          case 'muy interesado':
          case 'vendido':
            color = '#fff';
            break;
        }
      }

      return {
        ...provided,
        color
      };
    }
  }), []);

  var params = {
    options,
    defaultValue: defaultValue ? (filter ? defaultValue : options.find(({value}) => value === defaultValue)?.value) : null,
    name,
    ...props
  };
  if (small) params.styles = styles;
  if (filter) {
    params.usePortal = false;
    params.menuPlacement = "top"
    params.isMulti = true;
  }

  return (
    <Select {...params}/>
  );
};


export default OperationSelectState;
