import React from 'react';
import ThemeProvider from "./ThemeProvider/ThemeProvider";
import AuthProvider from "./AuthProvider/AuthProvider";
import MapProvider from "./MapProvider/MapProvider";
import FileProvider from "./FileProvider/FileProvider";

export const AllProviders = (props) => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <MapProvider>
          <FileProvider>
            {props.children}
          </FileProvider>
        </MapProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default AllProviders;
