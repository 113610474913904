import React, {useEffect} from 'react';
import s from './FilterPage.module.css';
import SubmitButton from "../Inputs/SubmitButton";
import {FormProvider, useForm} from "react-hook-form";
import Input from "../Inputs/Input";
import {useTranslation} from "react-i18next";
import Button from "../Inputs/Button";
import Title from "../TextComponents/Title";
import SelectEnterprise from "../Inputs/SelectEnterprise";
import SelectClient from "../Inputs/SelectClient";
import SelectType from "../Inputs/SelectType";
import SelectAssetCondition from "../SelectAssetCondition";
import EmailInput from "../Inputs/EmailInput";
import PhoneInput from "../Inputs/PhoneInput";
import RangeInput from "../Inputs/RangeInput";
import Checkbox from "../Inputs/Checkbox";
import Select from "../Inputs/Select";
import PossibleAssetSelectState from "../Asset/PossibleAssetSelectState";
import Textarea from "../Inputs/Textarea";


export const strFormat = str => {
  if (typeof str !== 'string') return str;
  return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}


export const defaultFilter = (data = '', filter = '') => {
  if (!filter.length || !data.length) return true;
  return strFormat(data).startsWith(strFormat(filter));
}

export const numberFilter = (data, filter) => {
  if (data === undefined || filter === undefined || data === null || filter == null || filter === '') return true;
  return data * 1 === filter * 1;
}

export const SelectNumberFilter = (data, filter) => {
  if (data === 0) return true;
  return numberFilter(data, filter);
}

export const includesFilter = (data = '', filter = '') => {
  if (!filter.length || !data.length) return true;
  return strFormat(data).includes(strFormat(filter));
}
export const fullNameFilter = (data, filter, filterData, record) => {
  let searchFor;
  let subject = record?.client || record?.partner;
  if (!subject?.name) subject = record;
  if (subject) {
    searchFor = subject?.name;
    if (subject.surname) searchFor += ' ' + subject?.surname;
    if (subject.alias) searchFor += ' ' + subject?.alias;
  } else {
    searchFor = data;
  }
  return includesFilter(searchFor, filter)
}
export const includesRequiredFilter = (data = '', filter = '') => {
  if (!filter.length) return true;
  if (!data.length) return false;
  return strFormat(data).includes(strFormat(filter));
}

export const equalsFilter = (data = '', filter = '') => {
  if (!filter.length || !data.length) return true;
  filter = strFormat(filter);
  data = strFormat(data);

  return data === filter;
}

export const equalsEnterpriseTypeFilter = (data = '', filter = '', filterData, record) => {
  if (!filter.length) return true;
  if (!record?.enterprise?.type?.length) return false;
  return filter === record?.enterprise?.type;
}

export const numberLessFilter = (data = 0, filter = 0) => {
  if (filter === '') return true;
  data = (typeof data) === 'string' ? data?.replace(/[^\d.]/g, '') * 1 : data;
  filter = (typeof filter) === 'string' ? filter?.replace(/[^\d.]/g, '') * 1 : filter;
  return data >= filter;
}

export const numberMoreFilter = (data = 0, filter = 0) => {
  if (filter === '') return true;
  data = (typeof data) === 'string' ? data?.replace(/[^\d.]/g, '') * 1 : data;
  filter = (typeof filter) === 'string' ? filter?.replace(/[^\d.]/g, '') * 1 : filter;
  return data <= filter;
}

export const objectNameFilter = (data, filter) => {
  if (!filter.length) return true;
  if (!data || !data?.name?.length) return false;
  return strFormat(data.name).startsWith(strFormat(filter));
}


export const adaptFilterKey = (adapter, f = defaultFilter) => (data, filter, filterData, record) => {
  return f(adapter(record), filter, filterData, record);
}

export const multiRangeFilter = (data, filter, filterData, record) => {
  data = isNaN(data * 1) ? 0 : data * 1;
  return (filter[0] < data && filter[1] > data);
}

export const checkboxFilter = (data, filter, filterData) => {
  data = data || '0';
  if (filter === 'indeterminate') return true;
  return (data + '' === filter);
}
export const PossibleAssetStateFilter = (data, filter) => {
  if (!Array.isArray(filter) || filter.length === 0) return true;
  data = data || 'atacar';
  return filter.find(({value}) => {
    if (value === 'ignorar') return true;
    return data.toLowerCase() === value.toLowerCase();
  });
}


export const assetConditionFilter = (_, __, filter, record) => {


  let record_data = {
    type: record.type ? record.type.split(';') : [],
    usage: record.usage ? record.usage.split(';') : [],
    condition: record.condition ? record.condition.split(';') : [],
    old_rent: record.old_rent ? record.old_rent.split(';') : false
  };
  if (!record_data.old_rent || !record_data.old_rent.length) record_data.old_rent = record_data.type.map(_ => 0);


  for (let i = 0; i < filter.asset_condition.length; i++) {
    let condition = filter.asset_condition[i];
    if (!condition) continue;

    for (let j = 0; j < record_data.type.length; j++) {
      if (record_data.old_rent[j] === '1') {
      }
      if ((condition.type === 0 || (record_data.type[j] === condition.type)) &&
        (condition.usage === 0 || (record_data.usage[j] === condition.usage)) &&
        (condition.condition === 0 || (record_data.condition[j] === condition.condition)) &&
        ((condition.old_rent === 'indeterminate' || condition.old_rent === undefined) || (record_data.old_rent[j] * 1 === condition.old_rent * 1))
      ) return true;
    }
  }
  return false;


  /*  return (filter.type === 0 || (filter.type === record.type)) &&
      (filter.usage === 0 || (filter.usage === record.usage)) &&
      (filter.condition === 0 || (filter.condition === record.condition));*/
}

export const assetPartnerClientFilter = (data, filter, filterData, record) => {
  filter = filterData['client_partner'];
  if (filter === 0) return true;
  if (filter === 'partner') return !!record.partner_id;

  return !!record.client_id;
}


// RENDERS
export const numberFilterRenderer = ({name, label}, register, i) =>
  <Input
    type="number"
    key={i}
    containerClassName="mb-5"
    name={name}
    label={label}
    {...register(name)}
  />


export const enterpriseFilterRenderer = ({name, label}, register, i, setValue) =>
  <SelectEnterprise
    key={i}
    containerClassName={s.defaultFilter}
    name={name}
    label={label}
    onSelect={enterprise => {
      setValue(name, enterprise.name);
    }
    }
  />

export const clientFilterRenderer = ({name, label}, register, i, setValue) =>
  <SelectClient
    key={i}
    containerClassName={s.defaultFilter}
    name={name}
    {...register(name)}
    label={label}
    setValue={setValue}
    onSelect={enterprise => {
      setValue(name, enterprise.name);
    }
    }
  />


export const AssetPartnerClientRenderer = ({name, label}, register, i) =>
  <Select
    key={i}
    name={name}
    options={[
      {value: 'client', label: 'Cliente'},
      {value: 'partner', label: 'Partner'},
      {value: 0, label: 'Ignorar'},
    ]}
    defaultValue={0}
    containerClassName="mb-5"
    label={label}
  />

export const SelectFilter = ({options}) => ({name, label}, register, i) =>
  <Select
    key={i}
    name={name}
    options={options}
    defaultValue={0}
    containerClassName="mb-5"
    label={label}
  />

export const selectFilterRenderer = (list_name, name = 'type') => ({name, label}, register, i) =>
  <SelectType
    filter={true}
    name={name}
    label={label}
    key={i}
    defaultFilter=""
    containerClassName="mb-10"
    listName={list_name + '_types'}
  />

export const PossibleAssetStateRenderer = ({name, label}, register, i) => <PossibleAssetSelectState
  name={name}
  filter={true}
  key={i}
  defaultValue='ignorar'
  label="Estado"
/>


export const rangeFilterRenderer = props => ({name, label}, register, i) =>
  <RangeInput
    key={i}
    name={name}
    label={label}
    containerClassName="mt-10"
    {...props}
  />

export const dateRenderer = props => ({name, label}, register, i) =>
  <Input
    name={name}
    label={label}
    spellCheck={false}
    {...register(name)}
    type="date"
    key={i}
    {...props}
  />


export const AssetConditionRenderer = ({name, label}, register, i) => {

  const defaultValue = React.useMemo(() => ({
    asset_condition: [
      {type: 0, condition: 0, usage: 0, old_rent: 'indeterminate'}
    ]
  }), [])

  return <SelectAssetCondition
    key={i}
    filter={true}
    defaultValue={defaultValue}
  />
}
export const emailFilterRenderer = ({name, label}, register, i) =>
  <EmailInput
    key={i}
    name={name}
    label={label}
    spellCheck={false}
    {...register(name)}
    containerClassName="mb-4"
  />

export const checkboxRenderer = ({name, label}, register, i) =>
  <Checkbox
    name={'filter_' + name}
    label={label}
    indeterminate={true}
    key={i}
    classNameContainer={s.checkboxFilter}
    small={true}
    {...register(name)}
    defaultValue='indeterminate'
  />


export const phoneFilterRenderer = ({name, label}, register, i) =>
  <PhoneInput
    key={i}
    name={name}
    label={label}
    spellCheck={false}
    {...register(name)}
    containerClassName="mb-4"
  />


export const defaultRenderer = ({name, label}, register, i) => (
  <Input
    name={name}
    label={label}
    spellCheck={false}
    {...register(name)}
    key={i}
    containerClassName={s.defaultFilter}
  />
);

export const textareaRenderer = ({name, label}, register, i) => (
  <Textarea
    name={name}
    label={label}
    spellCheck={false}
    {...register(name)}
    key={i}
    rows={5}
    containerClassName={s.defaultFilter}
  />
);

const FilterPage = ({
                      data,
                      body,
                      filters,
                      onFilter,
                      formClassName,
                      onReset,
                      reFilter = undefined,
                      filterAtStart = false
                    }) => {
  const {t} = useTranslation();

  const updateFilters = (newData) => {
    if (newData) {
      filters.forEach(({name}) => setValue(name, newData[name] || ''));
    } else {
      removeFilters();
    }
    filter(newData, false);
  }

  /*
    const {saveHistoryData} = useHistoryState('filter', null, () => {
      console.log('DATA CHECK');
      console.log(data);
      updateFilters();
    });
  */

  const form = useForm();
  const {getValues, register, handleSubmit, setValue, control, watch} = form;


  const filter = (filterData, saveHistory = true) => {
    let r = data;
    if (filterData) {
      r = data.filter(record => {
        for (let i = 0; i < filters.length; i++) {
          let {name, filter = includesFilter} = filters[i];
          var a = record[name] || '';
          var b = filterData[name] || '';
          a = a?.props?.children ? a.props.children : a;
          if (!filter(a, b, filterData, record)) return false;
        }
        return true;
      });
    }

    if (saveHistory) {
      //saveHistoryData(filterData);
    }
    onFilter(r);
  };

  const removeFilters = () => {
    filters.forEach(({name}) => {
      if (name === 'asset_condition') {
        let values = getValues();
        values['asset_condition'].forEach((_, i) => {
          setValue(`asset_condition.${i}.type`, 0);
          setValue(`asset_condition.${i}.usage`, 0);
          setValue(`asset_condition.${i}.condition`, 0);
          setValue(`asset_condition.${i}.old_rent`, 'indeterminate');
        });
        return;
      }
      setValue(name, '')
    });
    onReset && onReset(setValue);
  }


  useEffect(() => {
    onReset && onReset(setValue);
  }, []);

  useEffect(() => {
    if (filterAtStart) removeFilters();
  }, [filterAtStart]);

  useEffect(() => {
    if (reFilter !== undefined) filter(getValues());
  }, [reFilter]);


  /*  useEffect(() => {
      filter(data);
    }, []);*/

  return (
    <div className={s.container}>
      <Title>{t('filter')}</Title>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(filter)}
              className={`w-full mt-5 lg:w-10/12 m-auto ${formClassName ? formClassName : ''}`}>
          {body ? body(form) : filters.map((filter, i) => {
            let renderer = filter.render || defaultRenderer;
            return renderer(filter, register, i, setValue, control, watch);
          })}
          <div className="flex lg:w-8/12 m-auto mt-10">
            <SubmitButton className="mr-5">{t('filter')}</SubmitButton>
            <Button onClick={removeFilters} buttonStyle={3}>{t("remove filters")}</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};


export default FilterPage;
