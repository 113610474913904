import React, {useState} from 'react';
import s from './ExportTableButton.module.css';
import useExporter from "../../controllers/useExporter";


import {ReactComponent as PDFIcon} from '../../resources/svg/pdf.svg';
import {ReactComponent as ExcelIcon} from '../../resources/svg/excel.svg';
import {ReactComponent as ExportIcon} from '../../resources/svg/export.svg';


const ExportTableButton = ({title, getData}) => {
  const exporter = useExporter();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);


  const Export = async (f, format) => {
    setVisible(false);
    setLoading(true);
    const {headers, columns, groups = false} = await getData();
    await f({title: title + '.' + format, headers, columns, groups});
    setLoading(false);
  }


  if(loading)return <div className={s.container}>Cargando...</div>


  return (
    <div className={s.container}>
      <div onClick={() => setVisible(prev => !prev)} className="flex justify-center">
        <ExportIcon width={24} height={24}/>
        <a href={e => e?.preventDefault()} className="hover:underline">Exportar</a>
      </div>
      {visible &&
      <div className={s.expanded}>
        <div onClick={() => Export(exporter.excel, 'xlsx')} className={s.option}><ExcelIcon/><a href={e => e?.preventDefault()} className="hover:underline">Excel</a></div>
        <div onClick={() => Export(exporter.pdf, 'pdf')} className={s.option}><PDFIcon/><a href={e => e?.preventDefault()} className="hover:underline">PDF</a></div>
      </div>
      }
    </div>
  );
};


export default ExportTableButton;
