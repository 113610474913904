import React, {useEffect, useState} from 'react';
import s from './TableV2.module.css';
import Checkbox from "../Inputs/Checkbox";
import MiddleText from "../TextComponents/MiddleText";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import bases from "../../routes/bases";
import {cutText, transform} from '../../helpers/util';

import {ReactComponent as DealSVG} from "../../resources/svg/deal.svg";
import {ReactComponent as EyeSVG} from "../../resources/svg/eye.svg";
import useFavorites from "../../controllers/useFavorites";
import FavoritesStar from "./FavoritesStar";
import useAuth from "../../providers/AuthProvider/useAuth";


function clearAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}


const sortF = (name, renders, formatFunction, customSort) => ({
  up: (a, b) => {
    let c, d;
    if (formatFunction) {
      c = formatFunction(a);
      d = formatFunction(b);
    } else {
      c = renders[name] ? renders[name](a) : a[name];
      d = renders[name] ? renders[name](b) : b[name];
      if (c?.props?.children) c = c.props.children;
      if (d?.props?.children) d = d.props.children;
    }
    if (typeof c === 'string') c = clearAccents(c.toLowerCase());
    if (typeof d === 'string') d = clearAccents(d.toLowerCase());

    if (customSort) return customSort('up', c, d, {first: a, second: b, name});


    if (c > d) return 1;
    if (c < d) return -1;
    return 0;
  },
  down: (a, b) => {
    let c, d;
    if (formatFunction) {
      c = formatFunction(a);
      d = formatFunction(b);
    } else {
      c = renders[name] ? renders[name](a) : a[name];
      d = renders[name] ? renders[name](b) : b[name];
      if (c?.props?.children) c = c.props.children;
      if (d?.props?.children) d = d.props.children;
    }
    if (typeof c === 'string') c = clearAccents(c.toLowerCase());
    if (typeof d === 'string') d = clearAccents(d.toLowerCase());

    if (customSort) return customSort('down', c, d, {first: a, second: b, name});

    if (c < d) return 1;
    if (c > d) return -1;
    return 0;
  }
});

const sortIcons = {
  default: (
    <svg className={`${s.sortDefaultIcon} mr-2 inline-block transition duration-300 opacity-20`} width="16" height="16"
         viewBox="0 0 16 16">
      <path fill="#666" d="M11 7h-6l3-4z"/>
      <path fill="#666" d="M5 9h6l-3 4z"/>
    </svg>),
  up: (<svg className="inline-block mr-2" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#666" d="M11 7h-6l3-4z"/>
  </svg>),
  down: (<svg className="inline-block mr-2" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#666" d="M5 9h6l-3 4z"/>
  </svg>)
};

const ViewSVG = ({width = 20, height = 20, ...props}) => <EyeSVG width={width} height={height} {...props}/>

const EditableSVG = ({width = 16, height = 16, ...props}) =>
  <svg width={16} height={16} className="inline-block mr-5" x="0px" y="0px" viewBox="0 0 469.336 469.336" {...props}>
    <g>
      <g>
        <path
          d="M456.836,76.168l-64-64.054c-16.125-16.139-44.177-16.17-60.365,0.031L45.763,301.682 c-1.271,1.282-2.188,2.857-2.688,4.587L0.409,455.73c-1.063,3.722-0.021,7.736,2.719,10.478c2.031,2.033,4.75,3.128,7.542,3.128 c0.979,0,1.969-0.136,2.927-0.407l149.333-42.703c1.729-0.5,3.302-1.418,4.583-2.69l289.323-286.983 c8.063-8.069,12.5-18.787,12.5-30.192S464.899,84.237,456.836,76.168z M285.989,89.737l39.264,39.264L120.257,333.998 l-14.712-29.434c-1.813-3.615-5.5-5.896-9.542-5.896H78.921L285.989,89.737z M26.201,443.137L40.095,394.5l34.742,34.742 L26.201,443.137z M149.336,407.96l-51.035,14.579l-51.503-51.503l14.579-51.035h28.031l18.385,36.771 c1.031,2.063,2.708,3.74,4.771,4.771l36.771,18.385V407.96z M170.67,390.417v-17.082c0-4.042-2.281-7.729-5.896-9.542 l-29.434-14.712l204.996-204.996l39.264,39.264L170.67,390.417z M441.784,121.72l-47.033,46.613l-93.747-93.747l46.582-47.001 c8.063-8.063,22.104-8.063,30.167,0l64,64c4.031,4.031,6.25,9.385,6.25,15.083S445.784,117.72,441.784,121.72z"/>
      </g>
    </g>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
    <g/>
  </svg>


const TableV2 = ({
                   columns,
                   title,
                   data,
                   onView,
                   groups,
                   groupFilter,
                   tableWidth = false,
                   renders = {},
                   id,
                   onEdit,
  singleSelect = false,
  popupList = ['notes'],
                   favoritesType = false,
  onlyFavorites = false,
                   onSelect = () => 0,
                   onDeal,
                   titles,
                   options = {},
                   sortFormatFunctions = {},
                   customSortFunctions = {}
                 }) => {
  const {t} = useTranslation();
  const {getFavorites, setFavorite} = useFavorites();
  const {getAdmin} = useAuth();

  const full_access = getAdmin().full_access;

  const [tableParams, setTableParams] = useState({
    columnsSize: 20,
    order: {},
    selected: []
  });
  const [finalData, setFinalData] = useState(data);
  const [popup, setPopup] = useState({
    data: '',
    visible: false,
    persist: false
  });

  const [favorites, setFavorites] = useState([]);


  renders.enterprise = renders.enterprise || (a => <NavLink target="_blank" className="hover:underline link_color"
                                                            to={`/${bases.enterprise}/show/${a?.enterprise_id}`}>{a?.enterprise?.name}</NavLink>);
  renders.client = renders.client || (a => (a ? <NavLink target="_blank" className="hover:underline link_color"
                                                         to={`/${bases.client}/show/${a?.client_id}`}>{(a?.client?.alias || a?.alias) ? (a?.client?.alias || a?.alias) : (a?.client?.name ? (a?.client?.name + ' ' + a?.client?.surname) : (a?.name + ' ' + a?.surname))}</NavLink>
    : <></>));
  renders.partner = renders.partner || (a => a ? <NavLink target="_blank" className="hover:underline link_color"
                                                          to={`/${bases.partner}/show/${a?.partner_id}`}>{(a?.partner?.alias || a?.alias) ? (a?.partner?.alias || a?.alias) : (a?.partner?.name ? (a?.partner?.name + ' ' + a?.partner?.surname) : (a?.name + ' ' + a?.surname))}</NavLink>
    : <></>);
  renders.phone = renders.phone || (a => <a href={`tel:${a?.phone}`}>{a?.phone}</a>);
  renders.email = renders.email || (a => <a href={`mailto:${a?.email}`}>{a?.email}</a>);
  renders.address = renders.address || (({address}) => address || '');
  renders.person = a => a.client_id ? renders.client(a.client) : renders.partner(a.partner);
  renders.price = renders.price || (a => transform(a, 'price'));
  renders.updatedAt = a => transform(a.updatedAt, 'datetime');
  renders.createdAt = a => transform(a.updatedAt, 'datetime');
  renders.enterprise_type = a => a?.enterprise?.type;
  renders.notes = a => <div className={s.notes}>{a.notes}</div>

  useEffect(() => {
    let c = 0;
    let rest = 100;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].size) {
        rest -= columns[i].size;
        continue;
      }
      c++;
    }
    setTableParams({
      columnSize: rest / c,
      selected: [],
      order: {}
    })
  }, [columns]);


  useEffect(() => {
    let r = [];
    if (groups && data && data.length) {
      groups.forEach(group => {
        r.push({
          alwaysVisible: group.alwaysVisible,
          name: group.name,
          columns: data.filter(groupFilter(group))
        });
      });
    } else {
      r.push({columns: data});
    }
    setFinalData(r);
    setTableParams(prev => ({...prev, selected: []}));
    onSelect([]);
  }, [data]);


  const loadFavorites = async () => {
    const {error, favorites} = await getFavorites(favoritesType);
    if(!error)setFavorites(favorites.map(a => a.id));
  }

  useEffect(() => {
    if(favoritesType)loadFavorites();
  }, []);


  const setOrder = name => {
    setTableParams(prev => {
      let n;
      const is_id = name?.includes('id');

      switch (prev.order[name]) {
        case undefined:
          n = is_id ? 'up' : 'down';
          break;
        case 'up':
          n = is_id ? 'down' : undefined;
          break;
        case 'down':
          n = is_id ? undefined : 'up';
            break
      }
      let order = {...prev.order, [name]: n};
      orderData(order);
      return ({...prev, order});
    });
  }

  const orderData = (_order) => {
    setFinalData(groups => {
      return groups.map(group => {
        let columns = group.columns;
        let order = _order || tableParams.order;
        for (let name in order) {
          console.log(name);
          const f = sortF(name, renders, sortFormatFunctions[name], customSortFunctions[name])[order[name]];
          if (f) columns = columns.sort(f);
        }
        return {...group, columns};
      });
    });
  }


  const toggleAllRows = e => {
    if(singleSelect)return alert("Puede seleccionar solo un elemento");
    const selected = [];
    if (!isAllSelected()) finalData.forEach(({columns}) => columns.forEach(column => selected.push(column[id])));
    onSelect(selected);
    setTableParams(prev => ({...prev, selected}));
  };
  const toggleRow = id => {
    let selected = singleSelect ? [] : tableParams.selected;
    const i = tableParams.selected.indexOf(id);
    if (i === -1) {
      selected.push(id);
    } else {
      selected.splice(i, 1);
    }
    onSelect(selected);
    setTableParams(prev => ({...prev, selected}));
  }

  const haveContent = () => {
    if (finalData) {
      for (let i = 0; i < finalData.length; i++) {
        if (finalData[i]?.columns?.length > 0) return true;
      }
    }
    return false;
  }

  const isAllSelected = () => {
    let l = 0;
    for (let i = 0; i < finalData.length; i++) {
      l += finalData[i].columns.length;
    }
    return tableParams.selected.length === l;
  }

  const showPopup = (data, persist) => {
    setPopup(prev => {
      let r = {visible: true, data: data ? data : prev.data, persist: prev.persist};
      if (persist) r.persist = !r.persist;
      return r;
    });
  }
  const hidePopup = (ignore_persist) => {
    setPopup(prev => {
      if (prev.persist && !ignore_persist) return prev;
      return {visible: false, persist: false};
    })
  }

  const setFavoriteState = async id => {
    await setFavorite(id, favoritesType);
    setFavorites(prev => {
      console.log(prev);
      if(prev.includes(id))return prev.filter(f => f !== id);
      return [...prev, id];
    });
  }



  return (
    <div className={s.container} style={{width: tableWidth ? tableWidth : '100%'}}>
      {popup.visible && <div onClick={() => hidePopup(true)} className={s.popup}>{popup.data}</div>}
      {title && <h1 className={s.title}>{title}</h1>}
      {!haveContent() ?
        <MiddleText className={s.dataNotFound}>{t('table.data not found')}</MiddleText> :
        <div className={`${s.table} ${options.editable ? s.table_editable : ''}`}>
          {titles && <div className={s.table_titles_container}>
            <div className={s.title_space}/>
            {(() => {
              let n = 0;
              return titles.map((title, i) => {
                  let size = 0;
                  for (let j = 0; j < title.size_n; j++) {
                    size += (columns[n + j].size || tableParams.columnSize);
                  }

                  n += title.size_n;

                  return (
                    <div key={i} className={s.table_title} style={
                      {
                        flexBasis: (size + '%')
                      }}>
                      <p>{title.text}</p>
                    </div>
                  );
                }
              )
            })()}
          </div>}
          <div className={s.thead}>
            <div className={s.th}>
              <div className={s.actionsContainer}>
                {options.checkbox &&
                <div className={s.checkbox}>
                  <Checkbox checked={isAllSelected()} onChange={toggleAllRows}
                            small={true}/>
                </div>}

                {onView && <div className={s.td_space}/>}
                {(options.editable || onDeal) && <div className={s.td_space}/>}
                {favoritesType && <div className={s.td_space}/>}

              </div>
              <div className={s.tdContainer}>
                {columns.map((column, i) => (
                  <div key={i} onClick={() => setOrder(column.accessor)}
                       style={{flexBasis: (column.size || tableParams.columnSize) + '%'}} className={s.td}>
                    {sortIcons[tableParams.order[column.accessor]] || sortIcons.default}
                    <p>{column.Header}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={s.tbody}>
            {finalData.map((group, m) => {
                return <React.Fragment key={m}>
                  {group.name && (group.columns.length > 0 || group.alwaysVisible) && <div className={s.group_tr}>
                    <div>{group.name}</div>
                  </div>}
                  {group.columns.filter(a => {
                    if(onlyFavorites)return favorites.includes(a[id]);
                    return true;
                  }).map(data => {
                    let checked = tableParams.selected.indexOf(data[id]) !== -1;
                    return (<>
                        <div key={data[id]} className={`${s.tr} ${checked ? s.tr_checked : ''}`}>
                          <div className={s.actionsContainer}>
                            {options.checkbox &&
                            <div className={s.checkbox}>
                              <Checkbox checked={checked} onChange={() => toggleRow(data[id])} small={true}/>
                            </div>}
                            {favoritesType && <div className={s.viewContainer}>
                              <FavoritesStar shiny={favorites.includes(data[id])} onChange={async () => await setFavoriteState(data[id])}/>
{/*
                              <StarSVG width={20} height={20} className={favorites.includes(data[id]) ? s.starOn : s.starOff} onClick={() => setFavoriteState(data[id])}/>
*/}
                            </div>}

                            {onView &&
                            <div className={s.viewContainer}>
                              <ViewSVG onClick={() => onView(data[id])}/>
                            </div>}

                            {options.editable && !!full_access &&
                            <div className={s.editContainer}>
                              <EditableSVG onClick={() => onEdit(data[id])}/>
                            </div>}
                            {onDeal &&
                            <div className={s.editContainer}>
                              <DealSVG style={{fill: data.exists ? 'red' : 'black' }} width={20} height={20} onClick={() => onDeal(data)}/>
                            </div>
                            }



                          </div>

                          <div className={s.tdContainer}>
                            {columns.map((column, j) => (column.popup || popupList.includes(column.accessor)) ? (
                              <div key={j} style={{flexBasis: (column.size || tableParams.columnSize) + '%'}}
                                   className={s.td}
                                   onMouseOut={() => hidePopup()}
                                   onMouseOver={() => showPopup(data[column.accessor])}
                                   onClick={() => showPopup(data[column.accessor], true)}
                              >
                                {renders[column.accessor] ? renders[column.accessor](data) : cutText(data[column.accessor], {maxLength: 100})}
                              </div>
                            ) : (
                              <div key={j} style={{flexBasis: (column.size || tableParams.columnSize) + '%'}}
                                   className={s.td}
                              >
                                {renders[column.accessor] ? renders[column.accessor](data) : data[column.accessor]}
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </React.Fragment>;
              }
              // end finalData map function
            )}
          </div>
        </div>
      }
    </div>
  );
}


export default TableV2;
