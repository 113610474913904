import React from 'react';
import s from './MiddleText.module.css';

const MiddleText = ({children, className = ''}) => {
  const _className = `${s.middle} ${className}`;
  return (
    <div className={_className}>{children}</div>
  );
};


export default MiddleText;
