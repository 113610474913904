import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import MapContext from "./MapContext";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoicGF0aWxhbnoiLCJhIjoiY2pqMWsyb3lkMHIxNDN2bjNldndtaXR6ZyJ9.mHKVrzPJBgi-VJ5GzlGSNg';


const MapProvider = props => {
  return (
    <MapContext.Provider value={{mapboxgl}}>
      {props.children}
    </MapContext.Provider>
  );
}

export default MapProvider;
