import {query} from "../../helpers/query";

export const useCadastre = () => {

  const getCadastre = async cadastre => {
    const {error, data} = await query(`/api/cadastre/${cadastre}`, false);
    return {error: error || data?.error, cadastres: data?.cadastres, exists: data?.exists};
  }


  return {getCadastre};
};

export default useCadastre;
