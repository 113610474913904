import React from 'react';
import AuthContext from "../../providers/AuthProvider/AuthContext";
import LoginPage from "../LoginPage";
import Main from "../Main";

const Content = props => {
  return (
    <AuthContext.Consumer>
      {({isAuthenticated}) => (isAuthenticated() ? <Main/> : <LoginPage/>)}
    </AuthContext.Consumer>
  );
};


export default Content;
