import React, {useEffect, useState} from 'react';
import s from './PDFGenerator.module.css';
import MiddleText from "../TextComponents/MiddleText";
import Input from "../Inputs/Input";
import {FormProvider, useForm} from "react-hook-form";
import Accordion from "../Accordion";
import Textarea from "../Inputs/Textarea";
import Select from "../Inputs/Select";
import EditableTable from "../EditableTable";
import Button from "../Inputs/Button";
import useAsset from "../../controllers/useAsset";
import Checkbox from "../Inputs/Checkbox";
import ChooseCadastres from "../ChooseCadastres";
import useDropbox from "../../controllers/useDropbox";
import isImage from "is-image";
import NumberInput from "../NumberInput";
import OrderList from "../OrderList";
import {ReactComponent as PDFIcon} from '../../resources/svg/pdf.svg';
import ChooseIdealista from "../ChooseIdealista";
import FileExplorerSelectImages from "../FileExplorerSelectImages";


const TextType = ({setData, group}) =>
  <Textarea
    label="Contenido"
    value={group.data.content}
    rows={10}
    onChange={e => setData(prev => ({...prev, content: e.target.value}))}
  />

const TableType = ({setData, group}) =>
  <EditableTable name={false} table={group.data.table} setTable={table => {
    setData({table: typeof table === 'function' ? table(group.data.table) : table});
  }}/>

const ImageType = ({
                     setData,
                     asset,
                     images,
                     group,
                     pdf_local_data,
                     maxImages = 20,
                     label = "Seleccionar imagen/es"
                   }) => {
  let defaultData = false;
  if (pdf_local_data?.groups && group) {
    let local_group = pdf_local_data.groups.find(g => g.id === group.id);
    if (local_group) defaultData = local_group.data.images;
  }

  return <div style={{width: '350px'}} className="mb-10">
    <FileExplorerSelectImages
      label={label}
      resource="asset"
      folder={asset.asset_id + ''}
      maxImages={maxImages}
      onSelectedImages={images => setData(prev => ({...prev, images: images}))}
      defaultData={defaultData}
      containerClassName="mt-10"
    />
  </div>
}

const GalleryType = ({setData, asset, images, group, pdf_local_data}) => {
  let defaultData = false;
  if (pdf_local_data?.groups && group) {
    let local_group = pdf_local_data.groups.find(g => g.id === group.id);
    if (local_group) defaultData = local_group.data.images;
  }
  return <div style={{width: '350px'}}>
    <FileExplorerSelectImages
      label="Seleccionar imágenes"
      resource="asset"
      folder={asset.asset_id + ''}
      maxImages={9}
      onSelectedImages={images => setData(prev => ({...prev, images: images}))}
      defaultData={defaultData}
      containerClassName="mt-10"
    />
  </div>
}
const ImageTextType = (data) =>
  <div>
    <ImageType {...data} maxImages={1} label={"Seleccionar imagen"}/>
    <TextType {...data}/>
  </div>


const PDFGenerator = ({asset_id}) => {
  const form = useForm({
    defaultValues: {
      want_cadastre: false,
      idealista: false
    }
  });

  const {listFiles, getImageURL} = useDropbox();

  const {previewPDF, getAsset, getPDFData, setPDFData} = useAsset();

  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState(false);
  const [images, setImages] = useState(false);
  const [pdf_local_data, setPDF_local_data] = useState(false);

  const {register, setValue, getValues, watch} = form;
  const [mainImage, setMainImage] = useState(false);
  const [groups, setGroups] = useState([]);
  const [orderItems, setOrderItems] = useState([
    {id: 'map', content: 'Ubicación'}
  ]);


  const loadData = async asset_id => {
    const {error, pdf_data} = await getPDFData(asset_id);
    console.log(pdf_data);
    if (!error && pdf_data) {
      setPDF_local_data(JSON.parse(pdf_data));
    }


    const {asset} = await getAsset(asset_id);
    if (asset) {
      if (Array.isArray(asset.cadastres) && asset.cadastres.length > 0) {
        asset.cadastres = asset.cadastres.map((c, i) => {
          return {index: i, content: JSON.parse(c.object)};
        });
        setValue('want_cadastre', true);
      }
      if(asset.address){
        setValue('idealista', true);
      }
      setAsset(asset);
    }
  }

  const loadImages = async () => {
    setImages([]);
    setLoading(false);
    let {error, files} = await listFiles("asset", asset.asset_id);

    if (error) {
      return alert('Error al cargar las imágenes');
    }

    files = files.filter(file => isImage(file.name));
    for (let i = 0; i < files.length; i++) {
      files[i] = {...files[i], src: await getImageURL("asset", asset.asset_id, files[i])};
    }

    setImages(files);
    setLoading(false);
  }

  useEffect(() => {
    loadData(asset_id);
  }, [asset_id]);


  const want_cadastre = watch('want_cadastre');
  const want_idealista = watch('idealista');

  useEffect(() => {
    if (!asset) return;
    setValue('pdf_title', asset.asset_file.title);
    setGroups([
      {id: 0, name: 'Resumen ejecutivo', type: 'text', data: {content: asset.asset_file.summary}}
    ]);
    loadImages();
  }, [asset]);

  useEffect(() => {
    if (!Array.isArray(groups)) return;
    setOrderItems(prev => {
      if (!prev) prev = [];
      let newItems = prev.filter(item => item.id.charAt(0) !== 'g'); // remove all groups
      groups.forEach(group => {
        newItems.push({
          content: group.name,
          id: 'g' + group.id
        });
      });
      return newItems;
    });
  }, [groups]);

  useEffect(() => {
    setOrderItems(prev => {
      if (!prev) prev = [];
      let newItems = prev.filter(item => item.id !== 'idealista');
      if (want_idealista) newItems.push({
        id: 'idealista',
        content: 'Estudio del mercado'
      });
      return newItems;
    });
  }, [want_idealista]);

  useEffect(() => {
    setOrderItems(prev => {
      if (!prev) prev = [];
      let newItems = prev.filter(item => item.id !== 'cadastre');
      if (want_cadastre) newItems.push({
        id: 'cadastre',
        content: 'Catastro'
      });
      return newItems;
    });
  }, [want_cadastre]);


  const groupTypeOptions = React.useMemo(() => [
    {label: 'Texto', value: 'text'},
    {label: 'Tabla', value: 'table'},
    {label: 'Imagen/es', value: 'images'},
    {label: 'Galería', value: 'gallery'},
    {label: 'Imagen y Texto', value: 'text_image'}
  ], []);


  const addGroup = () => {
    setGroups(groups => {
      let id = 0;
      do {
        id++;
      } while (groups.find(group => group.id === id));
      return [...groups, {id, name: 'Nuevo grupo', type: 'text', data: {content: ''}}]
    })
  }

  const removeGroup = group => setGroups(groups.filter(_group => _group.id !== group.id));

  const setName = (group, name) => {
    setGroups(prev => prev.map(_group => _group.id === group.id ? ({...group, name}) : _group));
  }

  const setGroupData = (group, data) => {
    setGroups(prev => prev.map(_group => _group.id === group.id ? ({
      ...group,
      data: typeof data === 'function' ? data(group.data) : data
    }) : _group));
  }

  const setGroupType = (group, type) => {
    setGroups(prev => prev.map(_group => _group.id === group.id ? ({
      ...group,
      type,
      data: getGroupTypeData(type)
    }) : _group));
  }
  const getGroupTypeData = type => {
    switch (type) {
      case 'text':
        return {content: ''}
      case 'table':
        let table = [
            ['Contenido', 'Contenido'],
            ['Contenido', 'Contenido']
          ]
        ;
        if (window.confirm('Quiero cargar la tabla del activo') === true) {
          try {
            table = JSON.parse(asset.asset_file.table);
          } catch (err) {
          }
        }
        return {table};
      case 'images':
      case 'gallery':
        return {images: []};
      case 'text_image':
        return {content: '', image: false}
    }
  }


  const chooseGroupType = group => {
    let Group = props => <div/>;
    switch (group.type) {
      case 'text':
        Group = TextType;
        break;
      case 'table':
        Group = TableType
        break;
      case 'images':
        Group = ImageType;
        break;
      case 'gallery':
        Group = GalleryType
        break;
      case 'text_image':
        Group = ImageTextType;
        break;
    }

    return <Group images={images} pdf_local_data={pdf_local_data} asset={asset} group={group}
                  setData={data => setGroupData(group, data)}/>
  }

  const createPDFData = () => {
    let data = {};
    data.mainImage = mainImage;
    data.title = getValues('pdf_title');
    data.footer = getValues('pdf_footer');
    data.groups = groups;
    data.price = asset.price;
    data.cadastres = asset.cadastres;
    data.want_cadastre = getValues('want_cadastre');
    data.want_cadastres = getValues('want_cadastres');
    data.want_cadastres_box = getValues('want_cadastres_box');
    data.fees = getValues('fees');
    data.idealista = getValues('idealista');
    if(data.idealista) {
      data.idealista_option = getValues('idealista_option');
      data.idealista_address = getValues('idealista_address');
    }
    data.orderedIndex = orderItems;
    return data;
  }

  const onPreviewPDF = async () => {
    let data = createPDFData();
    console.log(data);
    await previewPDF(asset.asset_id, data);
  }


  const savePDFData = async () => {
    let pdf_data = createPDFData();
    const {error, success} = await setPDFData({asset_id, pdf_data: JSON.stringify(pdf_data)});
    if (error) return alert(error);
    if (success) alert('Guardado');
  }

  useEffect(() => {
    console.log(pdf_local_data)
    if (typeof pdf_local_data !== 'object') return;


    setTimeout(() => {

      setGroups(pdf_local_data.groups);

      ['fees', 'pdf_footer', 'want_cadastre', 'want_cadastres', 'want_cadastres_box', 'idealista'].forEach(key => {
        setValue(key, pdf_local_data[key]);
      });

      setValue('pdf_title', pdf_local_data.title);
      setValue('pdf_footer', pdf_local_data.footer);
      setMainImage(pdf_local_data.mainImage);
      if(Array.isArray(pdf_local_data.orderedIndex))setOrderItems(pdf_local_data.orderedIndex);


    }, 1000)

  }, [pdf_local_data]);


  if (!asset || loading) return <div>Cargando...</div>

  return (
    <div>
      <FormProvider {...form}>
        <div className="mb-20">
          <MiddleText>Generar PDF</MiddleText>

          <hr className="mb-10 mt-2"/>

          <Input
            label="Título"
            {...register('pdf_title')}
            containerClassName="mb-10"
          />

          <div className="flex mb-10">
            <NumberInput
              name="fees"
              label="Honorarios"
              withDecimals={false}
              icons={{
                left: {
                  icon: <span className="inputIconText">%</span>
                }
              }}
            />
          </div>


          <Input
            label="Pie de página"
            {...register('pdf_footer')}
          />

          <div style={{width: '350px'}}>
            <FileExplorerSelectImages
              label="Seleccionar imagen principal"
              resource="asset"
              folder={asset.asset_id + ''}
              maxImages={1}
              onSelectedImages={images => setMainImage(images[0])}
              defaultData={[mainImage]}
              containerClassName="mt-10"
            />
          </div>
          {/*<div className="my-10">*/}
          {/*  <div className="flex text-3xl  items-center">*/}
          {/*    <Checkbox {...register('idealista')} id="idealista"/>*/}
          {/*    <div className="ml-5">Idealista</div>*/}
          {/*  </div>*/}
          {/*  <div className="my-10">*/}
          {/*    {want_idealista && <ChooseIdealista asset={asset}/>}*/}
          {/*  </div>*/}
          {/*</div>*/}


          {Array.isArray(asset.cadastres) && asset.cadastres.length > 0 &&
          <><
            Checkbox
            label="Generar catastro"
            {...register('want_cadastre')}
            classNameContainer={s.generate_catastre_checkbox}
          />
            {want_cadastre && <ChooseCadastres cadastres={asset.cadastres}/>}
          </>
          }


          <MiddleText className="mt-10">Grupos</MiddleText>
          <hr className="mb-10 mt-2"/>
          <Accordion sections={groups.map(group => ({
            title: group.name,
            header: false,
            visible: false,
            content:
              <div>
                <Input
                  label="Título"
                  containerClassName="mb-10"
                  value={group.name}
                  onChange={e => setName(group, e.target.value)}
                />
                <Select
                  name={`pdf_group_type_${group.id}`}
                  options={groupTypeOptions}
                  defaultValue={group.type}
                  onChange={(value) => setGroupType(group, value)}
                  label="Tipo de grupo"
                  usePortal={false}
                  containerClassName="mb-10"
                />

                {chooseGroupType(group)}
                <br/>
                {groups.length > 1 && <div className={s.button}>
                  <Button buttonStyle={4} onClick={() => removeGroup(group)}>Eliminar grupo</Button>
                </div>
                }
                <hr className="mb-10 mt-2"/>

              </div>
          }))}/>


          <div className={s.buttons}>
            <Button buttonStyle={4} onClick={addGroup}>Agregar grupo</Button>
          </div>
          <div className="my-20">
            <MiddleText className="mt-10">Índice</MiddleText>
            <hr className="mb-10 mt-2"/>
            {orderItems && <OrderList items={orderItems} setItems={setOrderItems}/>}
          </div>

          <div className={s.buttons}>
            <Button buttonStyle={4} onClick={savePDFData} className="mr-10">Guardar PDF</Button>
            <Button buttonStyle={4} onClick={onPreviewPDF} loadButton={true}><div className="flex">

              <PDFIcon width={24} height={24} className="mr-5"/>
              Generar PDF
            </div></Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};


export default PDFGenerator;
