import s from "../Demand/DemandData/DemandData.module.css";
import React from "react";

export const SimplifiedData = (row, key) => {
  let r = [];
  let types = (row['type'] || '').split(';');
  let usages = (row['usage'] || '').split(';');
  let conditions = (row['condition'] || '').split(';');
  for(let i = 0;i<types.length;i++){
    r.push(<tr>
      <td className="whitespace-nowrap">{types[i] || 'Ignorar'}</td>
      <td className="whitespace-nowrap">{usages[i] === '0' ? 'Ignorar' : usages[i]}</td>
      <td className="whitespace-nowrap">{conditions[i] === '0' ? 'Ignorar' : conditions[i]}</td>
    </tr>);
  }
  let index = row[key].indexOf(';');
  let simplified = row[key].substring(0, index === -1 ? row[key].length : index);
  if(simplified !== row[key])simplified += '...';
  if(simplified === '0')simplified = 'Ignorar';

  return <div className={s.simplified_data_container}>
    <div>{simplified}</div>
    <div className={s.simplified_data}>
      <table border="1">
        <thead>
        <tr>
          <th>Tipo</th>
          <th>Uso</th>
          <th>Condición</th>
        </tr>
        </thead>
        <tbody>{r}</tbody>
      </table>
    </div>
  </div>
}
