import React from 'react';
import s from './EmailInput.module.css';
import Input from "../Input";

const EmailInput = React.forwardRef(({color = '#666', ...props}, ref) => {
  return (
    <Input ref={ref} icons={{
      left: {
        icon: <svg version="1.1" width={18} height={18}  x="0px" y="0px" viewBox="0 0 437.676 437.676"
                   >
<g>
	<polygon fill={color} points="218.841,256.659 19.744,81.824 417.931,81.824 	"/>
  <polygon fill={color} points="139.529,218.781 0,341.311 0,96.252 	"/>
  <polygon fill={color}
           points="157.615,234.665 218.841,288.427 280.055,234.665 418.057,355.852 19.619,355.852 	"/>
  <polygon fill={color} points="298.141,218.787 437.676,96.252 437.676,341.311 	"/>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
</svg>
      }
    }}
           {...props}
    />
  );
});


export default EmailInput;
