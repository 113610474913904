import {query} from "../../helpers/query";

export const useServerData = () => {
  const getSpainProvinces = async () => {
    const {error, data} = await query('/static_res/provinces.geojson', false, 'GET');
    return {error, provinces: data};
  }
  const getMadridZonePrime = async () => {
    const {error, data} = await query('/static_res/madrid_prime.geojson', false, 'GET');
    return {error, zone: data};
  }

  const getConfigData = async () => {
    const {error, data} = await query('/api/config', false, 'GET');
    return {error, config: data?.config};
  }

  const saveConfigData = async config => {
    const {error, data} = await query('/api/config', {config}, 'PUT');
    return {error, success: data?.success};
  }

  const loadTextList = async name => {
    const {error, data} = await query(`/api/text_list/${name}`, false, 'GET');
    return {error, list: data?.list};
  }

  return {getSpainProvinces, getConfigData, saveConfigData, loadTextList, getMadridZonePrime}
}

export default useServerData;
