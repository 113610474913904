import React, {useEffect, useRef, useState} from 'react';
import s from './FileExplorer.module.css';
import useDropbox from "../../controllers/useDropbox";
import isImage from "is-image";
import {closestCenter, DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";

import Loader from "../Loader";


import {ReactComponent as UploadSVG} from "../../resources/svg/FileUploader/upload.svg";
import {ReactComponent as DownloadSVG} from "../../resources/svg/FileUploader/download.svg";
import {ReactComponent as EditSVG} from "../../resources/svg/FileUploader/pencil.svg";
import {ReactComponent as DeleteSVG} from "../../resources/svg/FileUploader/delete.svg";
import {ReactComponent as EyeSVG} from "../../resources/svg/FileUploader/see_file.svg";
import {ReactComponent as GoBackIcon} from "../../resources/svg/go_back.svg";
import {ReactComponent as MoveOutsideFolderSVG} from "../../resources/svg/export.svg";
import {ReactComponent as NewFolderSVG} from "../../resources/svg/FileUploader/new_folder.svg";
import useFileProvider from "../../providers/FileProvider/useFileProvider";
import {File, fileExt, loadFileIcon, MenuButton} from "./components";
import {hashFromString} from "../../helpers/util";

const UploadIcon = ({size = 20, ...props}) => <UploadSVG {...props} width={size} height={size}/>
const DownloadIcon = ({size = 30, ...props}) => <DownloadSVG {...props} width={size} height={size}/>
const DeleteIcon = ({size = 28, ...props}) => <DeleteSVG {...props} width={size} height={size}/>


const FileExplorer = ({
                        resource,
                        folder = "",
                        canSelect = true,
                        maxNumberOfSelection = 9,
                        defaultSelectedData,
                        onSelect: onExternalSelect = _ => _,
                        preserveSelection = false,
                        formatCurrentFolderShow,
                        minHeight,
                        showOnlyImages = false,
                        showFolders = true,
                        cachedThumbnails = [],
                        controllers = true,
                        selectOnlyImages = false,
                        showNFilesSelected = false,
                      }) => {
  const {
    listFiles,
    uploadFiles,
    deleteFile,
    downloadFile,
    moveFile,
    renameFile,
    createFolder
  } = useDropbox();

  const [loading, setLoading] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(folder);
  const [selected, setSelected] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [overDrag, setOverDrag] = useState(false);
  const [highLight, setHighLight] = useState(false);

  const {thumbnailsCache, setThumbnailsCache, addThumbnails} = useFileProvider();


  const containerRef = useRef();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
  );


  useEffect(() => {
    console.log(defaultSelectedData);
    if (Array.isArray(defaultSelectedData)) setSelected(defaultSelectedData.filter(a => a));
  }, [defaultSelectedData]);

  const resetState = () => {
    if (!preserveSelection) {
      setSelected([]);
      onSelect([]);
    }
    setDragging(false);
    setOverDrag(false);
    setHighLight(false);
  }

  const onSelect = r => {
    if (Array.isArray(r)) onExternalSelect(r.filter(a => a).map(a => {
      let copy = {...a};
      delete copy.icon;
      return copy;
    }))
  }

  const removeFileFromState = id => {
    setFiles(prev => prev.filter(file => file.id !== id));
    setSelected(prev => {
      let r = prev.filter(file => file.id !== id)
      onSelect(r);
      return r;
    });
  }
  const formatFileToState = file => {
    let r = {
      ...file,
      isFolder: file['.tag'] === 'folder',
      isImage: isImage(file.name),
      ext: fileExt(file)
    };
    r.icon = loadFileIcon(r);
    return r;
  }
  const loadFiles = async () => {
    //return;
    setLoading(true);
    resetState();

    let {error, files} = await listFiles(resource, currentFolder, showFolders);
    if (error || !Array.isArray(files)) {
      return alert('Erorr al cargar los archivos');
    }

    files = files.map(file => formatFileToState(file))

    /*    const thumbnailsToLoad = [];

        for(let i = 0 ;i<files.length;i++){
          let file = files[i];
          if(file.isImage){
            let thumbnail = thumbnailsCache.get(file.content_hash);
            if(!thumbnail){
              thumbnailsToLoad.push(file.name);
            }else{
              file.image = {src: thumbnail}
            }
          }
        }*/

    /*    if(thumbnailsToLoad.length > 0){
          const {error, images} = await getImagesURL(resource, currentFolder, thumbnailsToLoad);
        }*/


    //let imagesWithNoThumbnails = images.filter(file => );

    /*    if (images.length > 0) {
          await getImagesURL(resource, folder)
        }*/


    if (showOnlyImages) files = files.filter(file => file.isImage || file.isFolder);


    setFiles(files);

    setLoading(false);
  }


  useEffect(() => {
    loadFiles();
  }, [currentFolder]);


  const blockFileId = id => {
    setLoadingFiles(prev => {
      let i = prev.findIndex(i => i === id);
      return i === -1 ? [...(prev || []), id] : prev.filter(i => i !== id);
    });
  }
  const unBlockFileId = id => setLoadingFiles(prev => prev.filter(i => i !== id));

  const handleDragEnd = async e => {
    //if (e.delta.x === 0 && e.delta.y === 0) onFileClick(e.active.data.current);

    console.log(e);

    setDragging(false);
    setOverDrag(false);
    const {active, over} = e;

    // move to folder
    if (active.id !== over.id && over.data.current.isFolder) {
      blockFileId(active.id);

      const {error, success} = await moveFile({
        resource,
        base: currentFolder,
        from: active.data.current.name,
        to: over.data.current.name + '/' + active.data.current.name
      });


      if (error) {
        alert('Se ha producido un error al mover el archivo ' + active.data.current.name);
      }
      if (success) {
        setFiles(prev => prev.filter(file => file.id !== active.id))
      }
      unBlockFileId(active.id);
    }
  }

  const handleDragOver = e => {
    if (!e.active || !e.over) return;
    if (e.active.id !== e.over.id && e.over.data.current.isFolder) {
      setOverDrag(e.over.id);
    }
  }

  const handleDragStart = e => {
    setDragging(e.active.id);
    setOverDrag(false);
  }
  const handleDragCancel = e => {
    setDragging(false);
    setOverDrag(false);
  }
  const handleDragMove = e => {
    if (!e.active || !e.over) return;
    if (e.active.id === e.over.id) {
      setOverDrag(false);
    }
  }

  const onFileClick = file => {
    //setHighLight(prev => prev === file.id ? false : file.id);
    if (canSelect) {
      console.log(file);
      if (selectOnlyImages && !file.isImage) return;
      setSelected(prev => {
        let r;
        let i = prev.findIndex(f => f?.id === file.id);
        if (i === -1) {
          r = [...(prev || []), file];
        } else {
          r = prev.filter(f => f?.id !== file.id);
        }
        if (maxNumberOfSelection && r.length > maxNumberOfSelection) r.splice(0, 1);
        onSelect(r);
        return r;
      });
    }
  }

  const onFilesDownload = async () => {
    if (!Array.isArray(selected)) return;

    selected.forEach(file => blockFileId(file.id))

    for (let i = 0; i < selected.length; i++) {
      let file = selected[i];
      await downloadFile(resource, currentFolder, file);
      unBlockFileId(file.id);
    }
  }

  const onPDFPreview = async file => {
    blockFileId(file.id);
    await downloadFile(resource, currentFolder, file, true);
    unBlockFileId(file.id);
  }


  const onFileRename = async file => {
    let newName = prompt("Renombrar archivo", file.name);
    if (!newName) return;

    blockFileId(file.id);
    const {error, success, newFile} = await renameFile({
      resource, base: currentFolder, from: file.name, to: newName
    });
    unBlockFileId(file.id);

    if (error || !success) return alert("Se ha producido un error al renombrar el archivo " + file.name);

    removeFileFromState(file.id);

    setFiles(prev => [...(prev || []), formatFileToState(newFile)]);
  }

  const onFilesDelete = async () => {
    if (!Array.isArray(selected)) return;

    if (!window.confirm(`Esta seguro de eliminar ${selected.length > 1 ? selected.length + ' archivos' : selected[0].name}`)) return;

    selected.forEach(async file => {
      blockFileId(file.id);
      const {error, success} = await deleteFile(resource, currentFolder, file);
      if (error) alert(`Se ha producido un error al eliminar "${file.name}"`);
      if (success) {
        setFiles(prev => prev.filter(f => f.id !== file.id));
        setSelected(prev => {
          let r = prev.filter(f => f.id !== file.id);
          onSelect(r);
          return r;
        });
      }
      unBlockFileId(file.id);
    });
  }

  const onFilesMoveOutside = async () => {
    if (!Array.isArray(selected)) return;

    for (let i = 0; i < selected.length; i++) {
      let file = selected[i];
      blockFileId(file.id);

      let base = currentFolder.split('/');
      let current = base.pop();
      base = base.join('/');

      const {error, success} = await moveFile({
        resource,
        base,
        from: current + '/' + file.name,
        to: file.name
      });
      if (error) alert(`Se ha producido un error al mover "${file.name}"`);
      if (success) {
        setFiles(prev => prev.filter(f => f.id !== file.id));
        setSelected(prev => {
          let r = prev.filter(f => f.id !== file.id);
          onSelect(r);
          return r;
        });
      }
      unBlockFileId(file.id);
    }
  }


  const onDoubleClick = file => {
    if (!file.isFolder) return;
    setCurrentFolder(prev => prev + '/' + file.name);
    resetState();
  }

  const onNewFolder = async () => {
    let name = window.prompt("Nombre de la carpeta");
    if (!name) return;


    let id = hashFromString(name);
    setLoadingFiles(prev => [...(prev || []), id]);

    setFiles(prev => [...(prev || []), {
      '.tag': 'folder',
      name,
      id
    }]);

    let {error, folder} = await createFolder({
      resource,
      folder: currentFolder,
      name
    });
    folder['.tag'] = 'folder';

    //await loadFiles();

    if (error) {
      alert('Se ha producido un error al crear la carpeta "' + name + '"');
    }


    setLoadingFiles(prev => prev.filter(a => a !== id));
    setFiles(prev => {
      let a = [...(prev || []).filter(f => f.id !== id)];
      if (folder) {
        folder = formatFileToState(folder);
        console.log(folder);
        a.push(folder);
      }
      return a;
    });
  }


  const goBack = () => {
    setCurrentFolder(prev => {
      let tmp = prev.split('/');
      tmp.pop();
      return tmp.join('/');
    });
  }


  const canGoBack = React.useCallback(() => {
    return currentFolder !== folder;
  }, [currentFolder, folder]);

  const showCurrentPath = React.useCallback(() => {
    let folders = currentFolder.split('/');
    if (formatCurrentFolderShow) folders = formatCurrentFolderShow(folders);
    return <div className="flex flex-nowrap">
      {folders.map((folder, i) => {
        const isLast = i === folders.length - 1;

        return <>
          <div onClick={() => {
            if (!isLast) setCurrentFolder(folders.slice(0, i + 1).join('/'))
          }} className={`${!isLast ? 'link_color hover:underline mouse-pointer' : ''}`}>{folder}</div>
          {!isLast && <div className="mx-2">/</div>}
        </>
      })}
    </div>
  }, [currentFolder, resource]);


  const handleDragOverContainer = e => {
    e.preventDefault();
  }
  const handleDropContainer = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (!e?.dataTransfer?.files) return;

    let files = Array.from(e.dataTransfer.files);
    if (!files) return;


    let uploadingFiles = files.map(file => ({
      id: hashFromString(file.name),
      name: file.name,
      uploading: true
    }));
    let uploadingFilesIds = uploadingFiles.map(file => file.id);

    setLoadingFiles(prev => [...(prev || []), ...uploadingFilesIds]);

    setFiles(prev => [
      ...(prev || []),
      ...uploadingFiles
    ]);

    const clean = () => {
      setLoadingFiles(prev => prev.filter(id => !uploadingFilesIds.find(d => id === d)));
      setFiles(prev => prev.filter(file => !uploadingFilesIds.find(d => file.id === d)));
    }
    try {

      const {error, result} = await uploadFiles({
        resource,
        folder: currentFolder,
        files
      });
      console.log(result);

      clean();

      if (error || !Array.isArray(result)) {
        console.log(error);
        return alert("Se ha producido un error desconocido");
      }

      for (let i = 0; i < result.length; i++) {
        let newFile = result[i];
        if (newFile.error) {
          alert('Se ha producido un error al subir el archivo "' + newFile.filename + '"');
          continue;
        }
        setFiles(prev => [...(prev || []), formatFileToState(newFile.file)]);

      }
    } catch (err) {
      clean();
      alert("Se ha producido un error desconocido");
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
    >
      <div ref={containerRef}>
        <div className="p-5 text-2xl flex items-center">
          {canGoBack() && <GoBackIcon width={20} height={20} className={s.iconMenu} onClick={() => goBack()}/>}
          {showCurrentPath()}
        </div>


        {loading ? <Loader/> : <>
          <div className={s.menu}>
            <MenuButton text="" onClick={() => onNewFolder()}>
              <NewFolderSVG/>
            </MenuButton>
            {selected.length === 0 && <div className="text-2xl">Seleccione un archivo</div>}
            {showNFilesSelected && selected.length > 0 &&
              <div className="text-2xl">{selected.length} Seleccionados</div>}
            {controllers &&
              <>
                {
                  selected.length > 0 && !selected.find(a => a.isFolder) &&
                  <MenuButton text="Descargar" onClick={() => onFilesDownload()}>
                    <DownloadSVG/>
                  </MenuButton>
                }
                {selected.length === 1 && selected[0].ext === 'pdf' &&
                  <MenuButton text="Ver" onClick={() => onPDFPreview(selected[0])}>
                    <EyeSVG/>
                  </MenuButton>}
                {selected.length === 1 &&
                  <MenuButton text="Renombrar" onClick={() => onFileRename(selected[0])}><EditSVG/></MenuButton>}

                {selected.length > 0 && <MenuButton text="Eliminar" onClick={() => onFilesDelete()}>
                  <DeleteIcon/>
                </MenuButton>}

                {selected.length > 0 && canGoBack() &&
                  <MenuButton text="Mover fuera" onClick={() => onFilesMoveOutside()}>
                    <MoveOutsideFolderSVG/>
                  </MenuButton>}
              </>
            }

          </div>

          <hr className="mt-3"/>
          <div className={s.container} onDragOver={handleDragOverContainer} onDrop={handleDropContainer}
               style={{minHeight}}>
            {files.map((file, i) => <File
              file={file}
              dragging={dragging === file.id}
              dragAllowed={overDrag === file.id}
              selected={selected.find(f => f?.id === file.id)}
              highLight={highLight === file.id}
              disabled={loadingFiles.find(id => id === file.id)}
              onDoubleClick={onDoubleClick}
              onClick={file => onFileClick(file)}
              i={i}
            />)}
          </div>

        </>
        }
      </div>
    </DndContext>
  );
};


export default FileExplorer;
