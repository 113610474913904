import {useState} from "react";
import {FileContext} from "./FileContext";


const FileProvider = props => {
  const [thumbnailsCache, setThumbnailsCache] = useState(new Map());

  const addThumbnails = (data) => {
    setThumbnailsCache(prev => {
      let n = new Map(prev.size() < 200 ? prev : (new Map())); // cache reset
      for(let i = 0;i<data.length;i++){
        if(!n.has(data[i].hash)){
          n.set(data[i].hash, data[i].content);
        }
      }
      return n;
    })
  }



  return <FileContext.Provider value={{
    thumbnailsCache, setThumbnailsCache, addThumbnails
  }}>
    {props.children}
  </FileContext.Provider>
}

export default FileProvider;
