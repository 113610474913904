import React from 'react';
import s from './PhoneInput.module.css';
import Input from "../Input";



const PhoneInput = React.forwardRef(({color = '#666', ...props}, ref) => {
  return (
    <Input ref={ref} icons={{
      left: {
        icon: <svg  x="0px" y="0px"
                   width={18} height={18} viewBox="0 0 34.546 34.546">
<g>
	<path fill={color} d="M29.33,19.339c-2.326-0.545-5.3-1.969-6.377-3.737c-0.603-0.984-0.666-2.094-0.179-3.042
		c0.027-0.051,0.074-0.128,0.109-0.188c-0.028-0.209,0.021-0.486,0.172-0.847c0.011-0.024,0.012-0.027,0.02-0.044H11.122
		c0.006,0.014,0.006,0.014,0.014,0.032c0,0,0.001,0.001,0.001,0.002c0.268,0.418,0.502,0.792,0.627,1.033
		c0.495,0.961,0.431,2.07-0.17,3.055c-1.08,1.77-4.053,3.193-6.381,3.738L4.57,30.124c-0.07,1.183,0.705,2.149,1.723,2.149h21.958
		c1.018,0,1.792-0.967,1.721-2.149L29.33,19.339z M13.683,26.765h-2.062c-0.38,0-0.688-0.309-0.688-0.688s0.308-0.688,0.688-0.688
		h2.062c0.379,0,0.688,0.309,0.688,0.688S14.062,26.765,13.683,26.765z M13.683,24.143h-2.062c-0.38,0-0.688-0.308-0.688-0.687
		c0-0.382,0.308-0.688,0.688-0.688h2.062c0.379,0,0.688,0.306,0.688,0.688C14.371,23.835,14.062,24.143,13.683,24.143z
		 M13.683,21.521h-2.062c-0.38,0-0.688-0.308-0.688-0.688c0-0.379,0.308-0.688,0.688-0.688h2.062c0.379,0,0.688,0.309,0.688,0.688
		S14.062,21.521,13.683,21.521z M18.304,26.765h-2.061c-0.38,0-0.688-0.309-0.688-0.688s0.309-0.688,0.688-0.688h2.062
		c0.379,0,0.688,0.309,0.688,0.688C18.991,26.456,18.683,26.765,18.304,26.765z M18.304,24.143h-2.061
		c-0.38,0-0.688-0.308-0.688-0.687c0-0.382,0.309-0.688,0.688-0.688h2.062c0.379,0,0.688,0.306,0.688,0.688
		C18.991,23.835,18.683,24.143,18.304,24.143z M18.304,21.521h-2.061c-0.38,0-0.688-0.308-0.688-0.688
		c0-0.379,0.309-0.688,0.688-0.688h2.062c0.379,0,0.688,0.309,0.688,0.688C18.991,21.212,18.683,21.521,18.304,21.521z
		 M22.924,26.765h-2.062c-0.379,0-0.687-0.309-0.687-0.688s0.308-0.688,0.687-0.688h2.062c0.381,0,0.688,0.309,0.688,0.688
		C23.612,26.456,23.306,26.765,22.924,26.765z M22.924,24.143h-2.062c-0.379,0-0.687-0.308-0.687-0.687
		c0-0.382,0.308-0.688,0.687-0.688h2.062c0.381,0,0.688,0.306,0.688,0.688C23.612,23.835,23.306,24.143,22.924,24.143z
		 M22.924,21.521h-2.062c-0.379,0-0.687-0.308-0.687-0.688c0-0.379,0.308-0.688,0.687-0.688h2.062c0.381,0,0.688,0.309,0.688,0.688
		C23.612,21.212,23.306,21.521,22.924,21.521z M34.372,13.114c-0.043,0.216-0.045,0.441-0.13,0.646
		c-0.497,1.202-1.429,2.197-2.115,3.305c-0.885,1.414-8.406-1.634-7.437-3.521c0.365-0.698,1.789-2.626,1.896-3.401
		c0.098-0.692-0.818-1.233-1.664-1.302c-2.232-0.181-5.083-0.017-7.452-0.002v0.002c-0.063,0-0.133-0.001-0.198-0.001
		c-0.064,0-0.134,0.001-0.197,0.001V8.839c-2.369-0.015-5.22-0.178-7.452,0.002c-0.846,0.069-1.762,0.61-1.665,1.302
		c0.108,0.775,1.531,2.703,1.896,3.401c0.971,1.887-6.55,4.935-7.435,3.521c-0.688-1.108-1.618-2.103-2.116-3.305
		c-0.084-0.205-0.086-0.43-0.129-0.646c-1.104-4.93,3.148-9.96,9.551-10.476c2.445-0.198,4.896-0.31,7.35-0.354V2.272
		c0.066,0.001,0.131,0.005,0.197,0.006c0.065-0.001,0.131-0.005,0.198-0.006v0.012c2.452,0.044,4.903,0.156,7.35,0.354
		C31.222,3.153,35.474,8.185,34.372,13.114z"/>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
</svg>
      }
    }}
           {...props}
    />
  );
});


export default PhoneInput;
