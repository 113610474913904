import {query} from "../../helpers/query";

export const useDemand = () => {
  const createDemand = async demand => {
    const {error, data} = await query('/api/demand', demand);
    return {error, demand_id: data?.demand_id};
  }

  const getDemand = async demand_id => {
    const {error, data} = await query(`/api/demand/${demand_id}`, false, 'GET');
    return {error, demand: data?.demand};
  }

  const editDemand = async demand => {
    const {error, data} = await query(`/api/demand/${demand.demand_id}`, demand, 'PUT');
    return {error, success: data?.success};
  }

  const deleteDemand = async demand_id => {
    const {error, data} = await query(`/api/demand/${demand_id}`, false, 'DELETE');
    return {error, success: data?.success};
  }
  const getDemands = async () => {
    const {error, data} = await query('/api/demand', false, 'GET');
    return {error, demands: data?.demands};
  }
  const getDemandsBasicInfo = async () => {
    const {error, data} = await query('/api/demand/basic_info', false, 'GET');
    return {error, demands: data?.demands};
  }
  const deleteDemands = async ids => {
    for(let i = 0;i<ids.length;i++){
      let id = ids[i];
      const {error} = await deleteDemand(id);
      if(error)return {error};
    }
    return {success: true};
  }
  const getDemandAssets = async demand_id => {
    const {error, data} = await query(`/api/demand/${demand_id}/assets`, false, 'GET');
    return {error, assets: data?.assets};
  }

  return {createDemand, getDemand, editDemand, deleteDemand,getDemandsBasicInfo, getDemands, deleteDemands, getDemandAssets};
};

export default useDemand;
