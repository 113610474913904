import {query} from "../../helpers/query";

export const useAsset = () => {
  const createAsset = async asset => {
    const {error, data} = await query('/api/asset', asset);
    return {error, asset_id: data?.asset_id};
  }

  const getAsset = async asset_id => {
    const {error, data} = await query(`/api/asset/${asset_id}`, false, 'GET');
    return {error, asset: data?.asset};
  }

  const editAsset = async asset => {
    const {error, data} = await query(`/api/asset/${asset.asset_id}`, asset, 'PUT');
    return {error, success: data?.success};
  }

  const deleteAsset = async asset_id => {
    const {error, data} = await query(`/api/asset/${asset_id}`, false, 'DELETE');
    return {error, success: data?.success};
  }
  const getAssets = async (filter = false) => {
    const {error, data} = await query('/api/asset', filter, 'GET');
    return {error, assets: data?.assets};
  }
  const getAssetsBasicInfo = async () => {
    const {error, data} = await query('/api/asset/basic_info', false, 'GET');
    return {error, assets: data?.assets};
  }
  const getNotAcceptedAssets = async () => {
    const {error, data} = await query('/api/asset/not_accepted', false, 'GET');
    return {error, assets: data?.assets};
  }

  const deleteAssets = async ids => {
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      const {error} = await deleteAsset(id);
      if (error) return {error};
    }
    return {success: true};
  }
  const getAssetDemands = async asset_id => {
    const {error, data} = await query(`/api/asset/${asset_id}/demands`, false, 'GET');
    return {error, demands: data?.demands};
  }

  const acceptAssets = async (ids, accepted = true) => {
    for(let i = 0;i< ids.length;i++){
      let id = ids[i];
      await editAsset({asset_id: id, only_accepted:true, accepted});
    }
  }

  const editContactState = async ({asset_id, contact_list_id, state}) => {
    const {error, data} = await query(`/api/asset/${asset_id}/contact/${contact_list_id}`,{state}, 'PUT');
    return {error, success: data?.success};
  }

  const previewPDF = async (asset_id, data) => {
    let r = await fetch(`/api/asset/${asset_id}/pdf_preview`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data),
      withCredentials: true
    });



    let blob = await r.blob();
    blob = blob.slice(0, blob.size, 'application/pdf');
    let fileURL = URL.createObjectURL(blob);
    console.log(blob);
    window.open(fileURL);
  }

  const getPDFData = async asset_id => {
    const {error, data} = await query(`/api/asset/${asset_id}/pdf_data`, false, 'GET');
    return {error, pdf_data: data?.pdf_data};
  }
  const setPDFData = async ({asset_id, pdf_data}) => {
    const {error, data} = await query(`/api/asset/${asset_id}/pdf_data`, {pdf_data}, 'POST');
    return {error, success: data?.success};
  }

  const setSold = async asset_id => {
    const {error, data} = await query(`/api/asset/${asset_id}/sold`, false, 'PUT');
    return {error, success: data?.success};
  }

  const setTrash = async asset_id => {
    const {error, data} = await query(`/api/asset/${asset_id}/trash`, false, 'PUT');
    return {error, success: data?.success};
  }

  const setNotAcceptedState = async (asset_id, state) => {
    const {error, data} = await query(`/api/asset/${asset_id}/state`, {state}, 'PUT');
    return {error, success: data?.success};
  }

  const solicitarNotaSimple = async(asset_id, d) => {
    const {error, data} = await query(`/api/asset/${asset_id}/nota_simple`, d, 'POST');
    return {error, success: data?.success};
  }


  return {
    createAsset,
    getAsset,
    editAsset,
    deleteAsset,
    getAssets,
    deleteAssets,
    getAssetDemands,
    getNotAcceptedAssets,
    acceptAssets,
    editContactState,
    previewPDF,
    getPDFData,
    setPDFData,
    setSold,
    setTrash,
    setNotAcceptedState,
    getAssetsBasicInfo,
    solicitarNotaSimple
  };
};

export default useAsset;
