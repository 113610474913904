import React, {useState} from 'react';
import s from './Main.module.css';
import Menu from "./Menu";
import {Outlet} from "react-router-dom";
import Header from "./Header";
import {isTablet} from "react-device-detect";

const Main = props => {
  const [smallMenu, setSmallMenu] = useState(false);

  const toggleMenu = () => {
    setSmallMenu(prev => !prev);
  }

  return (
    <div>
      {isTablet && <div className={s.showMenu} onClick={() => toggleMenu()}>{smallMenu ?  'Ocultar menú' : 'Mostrar menú'} </div>}
      {!smallMenu && <Menu smallMenu={smallMenu}/>}
      <div className={smallMenu ? '' : s.content}>
        <Header/>
        <Outlet/>
      </div>
    </div>
  );
};


export default Main;
