import React from 'react';
import s from './Partner.module.css';
import {Outlet} from "react-router-dom";

const Partner = props => {
  return (
    <Outlet/>
  );
};


export default Partner;
