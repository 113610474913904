import React, {useCallback, useEffect, useState} from "react";
import Input from "../Inputs/Input";
import {useTranslation} from "react-i18next";
import s from "./CreateListInput.module.css";
import Button from "../Inputs/Button";
import {useFormContext} from "react-hook-form";


const sort = (a,b) => {
  if(a > b)return 1;
  if(a < b)return -1;
  return 0;
}

const CreateListInput = ({name, defaultValue, label}) => {
  const {t} = useTranslation();
  const [list, setList] = useState([0]);

  const {register, setValue, getValues, unregister} = useFormContext();


  useEffect(() => {
    if(defaultValue && defaultValue.length > 0){
      setList(defaultValue.map((v,i) => {
        setTimeout(() => {
          setValue(`${name}[${i}]`, v);
        }, 1000);
        return i;
      }));
    }
  }, [defaultValue]);

  const addValue = (e) => {
    e?.preventDefault();
    setList(list => [...list, list[list.length-1] + 1]);
  }

  const removeValue = useCallback((e, index) => {
    console.log(index);
    console.log('remove value');
    e?.preventDefault();

/*    if(index < list.length - 1) {
      for(let i = index;i < list.length;i++) {
        setValue(`${name}[${i}]`, getValues(`${name}[${i + 1}]`));
      }
    }
    setList(prev => {
      return prev.filter(i => i !== prev.length - 1)
    });*/
    setList(prev => prev.filter(i => i !== index));
    unregister(`${name}.${index}`);
  }, [getValues, name, setValue, list]);



  return (
    <div className="mb-10 w-full">
      {list.map((i) => (
        <div key={i} className="flex flex-wrap mb-10 md:flex-nowrap w-full">
          <Input
            containerClassName="md:mr-10"
            name={`${name}[${i}]`}
            {...register(`${name}.${i}`)}
          />
          {list.length > 1 &&
          <div className={s.button}>
            <Button buttonStyle={3}  onClick={(e) => removeValue(e, i)}>{t('remove')}</Button>
          </div>
          }
        </div>
      ))}
      <div className={s.buttons}>
        <Button className="mr-10" buttonStyle={3} onClick={addValue}>{t("add value")}</Button>
        {/*{indexes.length > 1 && <Button onClick={clearValues} buttonStyle={3}>{t("clear values")}</Button>}*/}
      </div>
    </div>
  );

}

export default CreateListInput;


/*
import React, {useEffect, useState} from 'react';
import s from './CreateListInput.module.css';
import Input from "../Inputs/Input";
import Button from "../Inputs/Button";
import {useTranslation} from "react-i18next";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";


const orderList = (a, b) => {
  if (a.order > b.order) return 1;
  if (a.order < b.order) return -1;
  return 0;
}

const DraggableIcon = ({width = 24, height = 24, ...props}) => (
  <div className={s.dragIcon} {...props}>
    <svg  width={width} height={height} viewBox="0 0 48 48"><g >
    <polygon fill="#444444" points="3,7 1,7 1,1 7,1 7,3 3,3 "/>
    <polygon fill="#444444" points="7,39 1,39 1,33 3,33 3,37 7,37 "/>
    <polygon fill="#444444" points="39,7 37,7 37,3 33,3 33,1 39,1 "/>
    <rect x="12" y="1" fill="#444444" width="5" height="2"/>
    <rect x="12" y="37" fill="#444444" width="5" height="2"/>
    <rect x="23" y="1" fill="#444444" width="5" height="2"/>
    <rect x="1" y="12" fill="#444444" width="2" height="5"/>
    <rect x="37" y="12" fill="#444444" width="2" height="5"/>
    <rect x="1" y="23" fill="#444444" width="2" height="5"/>
    <polygon fill="#444444" points="43,25 20,20 25,43 30,34 40,44 44,40 34,30 "/>
    <path fill="#E6E6E6" d="M40,45c-0.256,0-0.512-0.098-0.707-0.293l-9.062-9.062l-4.356,7.841c-0.201,0.363-0.594,0.569-1.021,0.503&#10;&#9;c-0.411-0.061-0.742-0.37-0.831-0.777l-5-23c-0.072-0.333,0.03-0.679,0.271-0.919c0.24-0.24,0.583-0.342,0.92-0.27l23,5&#10;&#9;c0.406,0.088,0.716,0.419,0.776,0.831s-0.14,0.818-0.504,1.02l-7.841,4.356l9.062,9.062c0.391,0.391,0.391,1.023,0,1.414l-4,4&#10;&#9;C40.512,44.902,40.256,45,40,45z M30,33c0.264,0,0.519,0.104,0.707,0.293L40,42.586L42.586,40l-9.293-9.293&#10;&#9;c-0.222-0.222-0.327-0.535-0.283-0.846c0.043-0.311,0.23-0.583,0.505-0.735l6.682-3.712l-18.889-4.106l4.106,18.888l3.712-6.682&#10;&#9;c0.152-0.274,0.425-0.461,0.735-0.504C29.907,33.003,29.954,33,30,33z"/>
  </g></svg>
  </div>
)

const CreateListInput = ({register, label, name, defaultValue = [{order: 0, content: '1'},{order: 1, content: '2'}]}) => {
  const {t} = useTranslation();
  const [list, setList] = useState(defaultValue);

  const removeElement = (e, index) => {
    e?.preventDefault();

    setList(prev => {
      let r = prev.filter(el => el.order !== index);
      if (index < prev.length - 1) {
        r = prev.map(el => el.order > index ? {...el, order: el.order - 1} : el);
      }
      return r;
    });
  }

  const addElement = e => {
    e?.preventDefault();
    setList(prev => [...prev, {order: prev.length, content: ''}]);
  }

  const moveElement = result => {
    let from = result.source.index;
    let to = result.destination.index;

    setList(prev => {
      let items = [...prev];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      return items;
    });
    console.log(result);
/!*    setList(prev => prev.map(el => {
      if(el.order === from)return {...el, order: to};
      if(el.order === to)return {...el, order: from};
      return el;
    } ));*!/
  }
  useEffect(() => console.log(list), [list]);

  return (
    <DragDropContext onDragEnd={moveElement}>
      <Droppable droppableId={name}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((el, i) => (
              <Draggable key={el.order} draggableId={name + el.order} index={el.order}>
                {(provided) => (
                  <div {...provided.draggableProps} ref={provided.innerRef} className="flex w-full">
                    <DraggableIcon {...provided.dragHandleProps}/>
                    <Input
                      name={`${name}[${el.order}]`}
                      ref={register}
                      containerClassName="mr-10"
                    />
                    {list.length > 1 &&
                    <Button buttonStyle={3} onClick={e => removeElement(e, i)}>{t('remove')}</Button>
                    }
                  </div>
                )}
              </Draggable>

            ))}
            {provided.placeholder}
            <Button buttonStyle={3} onClick={addElement}>{t('add')}</Button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};


export default CreateListInput;
*/
