module.exports = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3.8218688964843746,
              40.38342821939494
            ],
            [
              -3.8150024414062496,
              40.383166701111
            ],
            [
              -3.8084792063761472,
              40.37845919838763
            ],
            [
              -3.8009262084960933,
              40.37139732753577
            ],
            [
              -3.789596557617187,
              40.36224194899873
            ],
            [
              -3.780670166015625,
              40.36250354850076
            ],
            [
              -3.7707138061523433,
              40.35988750779825
            ],
            [
              -3.7597274780273438,
              40.358841063092946
            ],
            [
              -3.749771118164062,
              40.360672330668166
            ],
            [
              -3.7353515625,
              40.36171874694918
            ],
            [
              -3.7192153930664062,
              40.36459630797086
            ],
            [
              -3.6979206376147188,
              40.364334716590214
            ],
            [
              -3.6876296997070312,
              40.363549936357174
            ],
            [
              -3.6725234985351562,
              40.36014911643671
            ],
            [
              -3.6570739746093746,
              40.36878163199601
            ],
            [
              -3.651580810546875,
              40.37715150036452
            ],
            [
              -3.6392211914062504,
              40.3805514624311
            ],
            [
              -3.6299514770507817,
              40.39205775317998
            ],
            [
              -3.6217117309570312,
              40.41218903196705
            ],
            [
              -3.5997390747070317,
              40.421860362045194
            ],
            [
              -3.5969924926757812,
              40.43126897044754
            ],
            [
              -3.594245910644531,
              40.44015366986629
            ],
            [
              -3.6035156249999996,
              40.44825340199111
            ],
            [
              -3.6042022705078125,
              40.45321727150385
            ],
            [
              -3.6186218261718746,
              40.456090923107865
            ],
            [
              -3.6265182495117183,
              40.466539529273156
            ],
            [
              -3.6388778686523438,
              40.47750881660339
            ],
            [
              -3.643341064453125,
              40.480642569575636
            ],
            [
              -3.644027709960937,
              40.487692978918865
            ],
            [
              -3.6467742919921875,
              40.495525898498414
            ],
            [
              -3.648490905761719,
              40.50231368920858
            ],
            [
              -3.6526107788085938,
              40.50883976336899
            ],
            [
              -3.6587920637614762,
              40.509883876307235
            ],
            [
              -3.666000366210938,
              40.510405926680356
            ],
            [
              -3.6690902709960938,
              40.516148212555585
            ],
            [
              -3.6814498901367183,
              40.51928016121621
            ],
            [
              -3.690032958984375,
              40.518758179933286
            ],
            [
              -3.7281417846679688,
              40.495525898498414
            ],
            [
              -3.737068176269531,
              40.49056515559304
            ],
            [
              -3.7549209594726562,
              40.476725355504186
            ],
            [
              -3.7628173828124996,
              40.4728079128734
            ],
            [
              -3.7762069702148438,
              40.474897244052464
            ],
            [
              -3.784446716308594,
              40.47306908282607
            ],
            [
              -3.7920637614765625,
              40.462882701979076
            ],
            [
              -3.8098526000976562,
              40.460531779303466
            ],
            [
              -3.816375732421875,
              40.45504597310796
            ],
            [
              -3.8307920637614775,
              40.44276659332215
            ],
            [
              -3.8318252563476562,
              40.43910847204672
            ],
            [
              -3.8376617431640625,
              40.431007637986156
            ],
            [
              -3.8331985473632812,
              40.423167191915596
            ],
            [
              -3.8218688964843746,
              40.414541645611635
            ],
            [
              -3.8211822509765625,
              40.41088198884583
            ],
            [
              -3.829078674316406,
              40.4069607071662
            ],
            [
              -3.833541870117187,
              40.39702577105907
            ],
            [
              -3.829765319824219,
              40.38891986882411
            ],
            [
              -3.8218688964843746,
              40.38342821939494
            ]
          ]
        ]
      }
    }
  ]
}
