import React, {useState} from 'react';
import s from './MapShowInfo.module.css';

const MapShowInfo = props => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={s.container}>
      <div className={s.icon} onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)}>
        <svg fill="#000000" viewBox="0 0 128 128" width="32px" height="32px"><path d="M 64 6 C 32 6 6 32 6 64 C 6 96 32 122 64 122 C 96 122 122 96 122 64 C 122 32 96 6 64 6 z M 64 12 C 92.7 12 116 35.3 116 64 C 116 92.7 92.7 116 64 116 C 35.3 116 12 92.7 12 64 C 12 35.3 35.3 12 64 12 z M 64 30 A 9 9 0 0 0 64 48 A 9 9 0 0 0 64 30 z M 64 59 C 59 59 55 63 55 68 L 55 92 C 55 97 59 101 64 101 C 69 101 73 97 73 92 L 73 68 C 73 63 69 59 64 59 z"/></svg>
      </div>
      {visible &&
      <div className={s.info}>
        Manteniendo SHIFT y arrastrando con el boton derecho puede seleccionar varias provincias
      </div>
      }
    </div>
  );
};


export default MapShowInfo;
