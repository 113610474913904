import React from 'react';
import s from './TaskShowConnection.module.css';
import bases from "../../routes/bases";

const TaskShowConnection = ({task}) => {
  const typesOfConnections = React.useMemo(() => [
    {value: 'enterprise', label: 'Empresa'},
    {value: 'partner', label: 'Partner'},
    {value: 'client', label: 'Cliente'},
    {value: 'asset', label: 'Activo'},
    {value: 'operation', label: 'Operación'},
    {value: 'demand', label: 'Demanda'}
  ], []);

  let name = typesOfConnections.find(a => a.value === task.connection_type);

  if(!name)return <></>;

  const onCLick = () => {
    window.open(`${window.location.origin}/${bases[name.value]}/${task.connection_id}`);
  }

  return (
    <div className="link_color hover:underline" onClick={onCLick}>
      {name.label}
    </div>
  );
};


export default TaskShowConnection;
