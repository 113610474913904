import React, {useState} from 'react';
import s from './PartnerShow.module.css';
import {useNavigate, useParams} from "react-router-dom";
import usePartner from "../../../controllers/usePartner";
import {useTranslation} from "react-i18next";
import bases from "../../../routes/bases";
import NavBarShow from "../../NavBarShow";
import {useError} from "../../../hooks/useError/useError";
import Loader from "../../Loader";
import ShowTable from "../../ShowTable";
import ShowAddress from "../../ShowAddress";
import Accordion from "../../Accordion";
import TableV2 from "../../TableV2";
import {useTableColumns} from "../../../hooks/useTableColumns";
import FileUploader from "../../FileUploader";
import {ReactComponent as WhiteBell} from "../../../resources/svg/bell.svg";
import OperationData from "../../Operation/OperationData";
import ShowTaskConnectionFilter from "../../ShowTaskConnectionFilter";
import useOperation from "../../../controllers/useOperation";
import {SimplifiedData} from "../../TableV2/renders";
import AssetData from "../../Asset/AssetData";

const PartnerShow = props => {
  const {t} = useTranslation();
  const {error, ShowError, manageError} = useError();
  const {demandColumnData} = useTableColumns();

  const {id: partner_id} = useParams();
  const {getPartner, deletePartner, getPartnerDemands} = usePartner();
  const {checkOperationsExists} = useOperation();

  const navigate = useNavigate();

  const [partner, setPartner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [demands, setDemands] = useState([]);

  const loadPartner = async () => {
    const {error, partner} = await getPartner(partner_id);
    setLoading(false);
    if (error) return manageError(error);
    if (!partner) return manageError('not found');
    setPartner(partner);
  }

  const loadDemands = async () => {
    let {error, demands} = await getPartnerDemands(partner_id);
    if (error) return manageError(error);

    let data = demands.map(a => ({
      enterprise_id: a.enterprise_id,
      partner_id: partner_id,
    }));
    const {error: error2, operations} = await checkOperationsExists({data});
    if (error2) return manageError(error2);

    if (Array.isArray(operations)) {
      demands = demands.map(d => {
        if (operations.find(a =>
          a.enterprise_id === d.enterprise_id
        )) {
          return ({
            ...d,
            exists: true
          })
        }
        return d;
      });
    }
    setDemands(demands);
  }

  const onDeleteClick = async () => {
    const {error, success} = await deletePartner(partner_id);
    if (error || !success) return manageError(error || 'unknown');
    alert(t('deleted'));
    navigate(`/${bases.partner}`);
  }
  const createOperation = demand => {
    let p = {
      enterprise_id: demand.enterprise_id,
      enterprise_name: demand.enterprise?.name,
      partner_name: partner.partner?.name,
      partner_id: partner.partner_id
    };
    let params = '';
    for(let key in p){
      if(p[key])params += key + '=' + p[key] + '&'
    }

    navigate('/' + bases.operation + '/new?' + params.substr(0, params.length - 1));
  }

  useState(() => {
    loadPartner();
    loadDemands();
  }, [partner_id]);

  let center = [partner.lng * 1, partner.lat * 1];


  if (loading) return (<Loader/>);
  if (error) return <ShowError/>

  return (
    <div className="showContainer">
      <div className="menuHeader">
        <NavBarShow name={'partner'} id={partner_id} onDeleteClick={onDeleteClick}/>
        <div className="inline-flex ml-5 items-center" onClick={() => navigate(`/${bases.task}/new?type=partner&id=${partner_id}`)}>
          <WhiteBell width={15} height={15}/>
          <a href="">Tarea</a>
        </div>
      </div>
      <div className="showDataContainer">

        <div className="flex flex-wrap">
          <ShowTable data={partner}/>
          <ShowAddress title="Posición del partner" center={center}/>
        </div>

        {demands &&
        <Accordion sections={[
          {
            title: 'Demandas del partner', visible: false, content: <TableV2
              columns={demandColumnData.filter(({accessor}) => accessor !== 'person')}
              data={demands}
              id={"demand_id"}
              options={{
                editable: false
              }}
              renders={{
                type: a => SimplifiedData(a, 'type'),
                usage: a => SimplifiedData(a, 'usage'),
                condition: a => SimplifiedData(a, 'condition')
              }}
              onDeal={demand => createOperation(demand)}
              onView={demand_id => navigate(`/${bases.demand}/show/${demand_id}`)}
            />
          },
          {
            title: 'Hoja de Seguimiento', loadOnShow: true, visible: false, content: <OperationData fullPage={false} filter={{partner_id}} />
          },
          {
            title: 'Activos del partner',
            loadOnShow: true,
            visible: false,
            content: <AssetData fullPage={false} filter={{partner_id}}/>
          },
          {
            title: 'Tareas', loadOnShow:true, visible: false, content: <ShowTaskConnectionFilter type="partner" id={partner_id}/>
          },
          {
            title: 'Archivos', visible:false, content: <FileUploader name="partner_file_upload" resource="partner" folder={partner_id}/>
          }
        ]}/>
        }
      </div>
    </div>)


};


export default PartnerShow;
