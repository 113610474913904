import React from 'react';
import s from './Prompt.module.css';
import AnimatedModal from "../AnimatedModal";
import Title from "../TextComponents/Title";
import Text from "../TextComponents/Text";
import {useTranslation} from "react-i18next";

const Prompt = ({visible, onCancel, onAccept, title, message}) => {
  const {t} = useTranslation();
  return (
    <AnimatedModal
      visible={visible}
      onClose={onCancel}
      className={s.prompt}
    >
      <div>
        {title && <div><h2 className={s.title}>{title}</h2></div>}
        {message && <div><p className={s.message}>{message}</p></div>}

        <div className="text-right">
          <button className={s.button} onClick={onCancel}>{t("cancel")}</button>
          <button className={s.button} onClick={onAccept}>{t("accept")}</button>
        </div>

      </div>
    </AnimatedModal>
  );
};


export default Prompt;
