import React, {useEffect, useState} from 'react';
import s from './PossibleAsset.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import useAsset from "../../../controllers/useAsset";
import Loader from "../../Loader";
import bases from "../../../routes/bases";
import TableV2 from "../../TableV2";
import AnimatedModal from "../../AnimatedModal";
import FilterPage from "../../FilterPage";
import FilterButton from "../../FilterButton";
import Button from "../../Inputs/Button";
import Prompt from "../../Prompt";
import {useError} from "../../../hooks/useError";
import {
	adaptFilterKey,
	assetConditionFilter,
	AssetConditionRenderer,
	includesFilter,
	includesRequiredFilter,
	numberFilterRenderer,
	numberLessFilter,
	numberMoreFilter,
	PossibleAssetStateFilter,
	PossibleAssetStateRenderer,
	SelectFilter,
	SelectNumberFilter,
	textareaRenderer
} from "../../FilterPage/FilterPage";
import {useTableColumns} from "../../../hooks/useTableColumns";
import PossibleAssetSelectState from "../PossibleAssetSelectState";
import {FormProvider, useForm} from "react-hook-form";
import ExportTableButton from "../../ExportTableButton";
import {useLocalStorage} from "../../../hooks/useLocalStorage/useLocalStorage";
import {DateSort} from "../../TableV2/sortFunctions";
import useFavorites from "../../../controllers/useFavorites";
import useAdmin from "../../../controllers/useAdmin";


const PossibleAsset = props => {
  const {t} = useTranslation();
  const {error, ShowError, manageError} = useError();
  const [onlyFavorites, setOnlyFavorites] = useLocalStorage('possible_assets_fav', false);


  const {getNotAcceptedAssets, deleteAssets, acceptAssets, setNotAcceptedState} = useAsset();
  const {possibleAssetColumnData} = useTableColumns();
  const form = useForm();

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    filter: false,
    visible: false,
    data: []
  });
  const {getFavorites} = useFavorites();
  const {getAdmins} = useAdmin();

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [admins, setAdmins] = useState([]);

  const toggleFilter = v => setFilter(prev => ({...prev, visible: v ?? !prev.visible}));
  const setFilteredData = data => {
    setFilter(prev => ({...prev, data}));
  }

  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    setLoading(false);
    if (error) return manageError(error);

    setAdmins(admins);
  }

  const loadAssets = async ({update = true} = {}) => {
    let {error, assets} = await getNotAcceptedAssets();
    setLoading(false);
    if (error) return manageError(error);

    assets = assets.map(a => ({...a, title: a.asset_file?.title}));
    setData(assets);
    if (update) setFilteredData(assets);
  }

  const removeElements = async () => {
    setDeleteVisible(true);
  }

  const removeConfirmed = async () => {
    setDeleteVisible(false);
    setLoading(true);
    await deleteAssets(selectedRows);
    await loadAssets();
    setSelectedRows([]);
    setLoading(false);
  }

  const acceptElements = async () => {
    setLoading(true);
    await acceptAssets(selectedRows);
    await loadAssets();
    setLoading(false);
  }

  const changeNotAcceptedState = async (asset, state) => {
    await setNotAcceptedState(asset.asset_id, state);
    await loadAssets({update: false});
  }


  useEffect(() => {
    loadAssets();
    loadAdmins();
  }, []);


  if (loading) return (<Loader/>);
  if (error) return <ShowError/>

  return (
    <div className="pt-20">
      <div className="menuHeader">
        <NavLink to={`/${bases.asset}/new?not_accepted=1`}>{t("create new asset")}</NavLink>
        <FilterButton onClick={toggleFilter}/>
        {selectedRows?.length > 0 && <FilterButton icon="remove" text={t('delete')} onClick={removeElements}/>}
        {selectedRows?.length > 0 && <FilterButton icon="transfer" text={"Mover a activos"} onClick={acceptElements}/>}

        <ExportTableButton title={"posibles activos"} getData={async () => {

          let data = filter.data;

          if (onlyFavorites) {
            let {error, favorites} = await getFavorites("asset");
            favorites = favorites.map(f => f.id);
            if (!error) {
              data = data.filter(d => favorites.includes(d.asset_id));
            }
          }

          return ({
            headers: possibleAssetColumnData,
            columns: data.map(d => ({
              asset_id: d.asset_id,
              title: d.title,
              type: d.type,
              usage: d.usage,
              address: d.address,
              not_accepted_condition: d.not_accepted_condition,
              notes: d.notes,
              updatedAt: d.updatedAt
            }))
          })
        }}/>
        <FilterButton icon="star" text="Favoritos" className={`${onlyFavorites ? 'starOn' : 'starOff'}`} size={22}
                      onClick={() => setOnlyFavorites(prev => !prev)}/>

      </div>
      <FormProvider {...form}>
        <TableV2
          favoritesType="asset"
          onlyFavorites={onlyFavorites}
          columns={possibleAssetColumnData}
          data={filter.data}
          renders={{
            not_accepted_condition: asset =>
              <PossibleAssetSelectState
                containerClassName="pr-5"
                defaultValue={asset.not_accepted_condition}
                onChange={state => changeNotAcceptedState(asset, state)}
                name={"not_accepted_condition" + asset.asset_id}
                small={true}
              />
          }}
          customSortFunctions={{
            updatedAt: DateSort
          }}
          id="asset_id"
          options={{checkbox: true, editable: true}}
          onSelect={rows => {
            setSelectedRows(rows.length > 0 ? rows : []);
          }}
          onEdit={asset_id => window.open(`${window.location.origin}/${bases.asset}/${asset_id}`)}
          onView={asset_id => window.open(`${window.location.origin}/${bases.asset}/show/${asset_id}`)}
        />
      </FormProvider>

      {!data.length && <div className="w-96 pl-8">
        <Button onClick={() => navigate(`/${bases.asset}/new?not_accepted=1`)}
                buttonStyle={3}>{t('create new')}</Button>
      </div>}

      <Prompt
        title={t("delete confirmation")}
        message={t("not reversible")}
        visible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onAccept={removeConfirmed}
      />

      <AnimatedModal
        visible={filter.visible}
        onClose={toggleFilter}
        className='filter'
      >
        <div className={s.container}>
          <FilterPage
            data={data}
            filters={[
              {
                name: 'creator_id',
                label: 'Creador',
                render: SelectFilter({
                  options: admins.map(a => ({value: a.admin_id, label: a.name}))
                }),
                filter: SelectNumberFilter
              },
              {name: 'title', label: t('title')},
              {name: 'type', filter: assetConditionFilter, render: AssetConditionRenderer},
              {name: 'not_accepted_condition', filter: PossibleAssetStateFilter, render: PossibleAssetStateRenderer},
              {name: 'address', label: t('address'), filter: includesFilter},
              {name: 'price', label: "Precio minimo", render: numberFilterRenderer, filter: numberLessFilter},
              {
                name: 'price_max', label: "Precio máximo", render: numberFilterRenderer, filter:
                  adaptFilterKey(a => a.price, numberMoreFilter)
              },
              {name: 'notes', label: "Notas", render: textareaRenderer, filter: includesRequiredFilter},

              {name: 'bedrooms', label: "Dormitorios mínimos", render: numberFilterRenderer, filter: numberLessFilter},
              {
                name: 'bedrooms_max',
                label: "Dormitorios máximos",
                render: numberFilterRenderer,
                filter: numberMoreFilter
              },

              {name: 'toilets', label: "Baños mínimos", render: numberFilterRenderer, filter: numberLessFilter},
              {name: 'toilets_max', label: "Baños máximos", render: numberFilterRenderer, filter: numberMoreFilter},

              {name: 'floor', label: "Piso mínimo", render: numberFilterRenderer, filter: numberLessFilter},
              {name: 'floor_max', label: "Piso máximo", render: numberFilterRenderer, filter: numberMoreFilter},
            ]}
            onReset={(setValue) => {
              setValue('type', 0);
              setValue('usage', 0);
              setValue('condition', 0);
              setValue('not_accepted_condition', 'ignorar');
            }}
            onFilter={data => {
              setFilteredData(data);
              toggleFilter(false);
            }}
          />
        </div>
      </AnimatedModal>
    </div>
  );
};


export default PossibleAsset;
