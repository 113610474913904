import React from 'react';
import s from './TaskShowDate.module.css';
import Input from "../Inputs/Input";

const TaskShowDate = ({task, updateTask}) => {

  console.log(task);
  let type = task.periodic_type;
  let months = Array.isArray(task.months) ? task.months.join(',') : '';
  let weeks = Array.isArray(task.weeks) ? task.weeks.join(',') : '';

  if(!task.periodic)return <Input
    type="date"
    name={'date_' + task.task_id}
    onBlur={e => updateTask(task, 'date', e.target.value)}
    defaultValue={task.date || new Date().toISOString().substring(0, 10)}
  />

  return (
    <div>
      {type === 'day' && 'Diaria'}
      {type === 'biweek' && 'Bi-Semanal'}
      {type === 'month' && <div title={months}>Mensual</div>}
      {type === 'week' && <div title={weeks}>Semanal</div>}
    </div>
  );
};


export default TaskShowDate;
