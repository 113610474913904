import React, {useEffect, useState} from 'react';
import s from './SelectEnterprise.module.css';
import Input from "../Input";
import useEnterprise from "../../../controllers/useEnterprise";
import {useTranslation} from "react-i18next";
import AnimatedModal from "../../AnimatedModal";
import Text from "../../TextComponents/Text";
import SmallText from "../../TextComponents/SmallText";
import {useFormContext} from "react-hook-form";

const SelectEnterprise = React.forwardRef(({
                                             containerClassName,
                                             enterprises,
                                             name = 'enterprise_id',
                                             defaultEnterprise,
                                             onSelect = _ => _,
                                             ...props
                                           }, ref) => {
  const {t} = useTranslation();
  const {getEnterprisesBasicInfo} = useEnterprise();
  const [state, setState] = useState({
    error: false,
    showModal: false,
    searchText: '',
    selectedEnterprise: false,
    enterprises: []
  });

  const {register, setValue} = useFormContext();


  const setError = msg => setState(prev => ({...prev, msg}));

  const loadEnterprises = async () => {
    const {error, enterprises} = await getEnterprisesBasicInfo();
    if (error) return setError(error);

    setState(prev => ({...prev, enterprises}));
  }

  const onSearch = e => {
    setState(prev => ({...prev, searchText: e.target.value}));
  }

  const filterEnterprise = enterprise => enterprise.name.toLowerCase().indexOf(state.searchText.toLowerCase()) !== -1

  const selectEnterprise = enterprise => {
    if (!enterprise || !enterprise.enterprise_id) return;
    setState(prev => ({...prev, selectedEnterprise: enterprise}));

    setValue(name, enterprise.enterprise_id);
    onSelect(enterprise);
  };


  useEffect(() => {
    if (enterprises === undefined) loadEnterprises();
  }, []);

  useEffect(() => {
    if (enterprises !== undefined) {
      setState(prev => ({...prev, enterprises, selectedEnterprise: null}));
    }
  }, [enterprises]);

  useEffect(() => {
    selectEnterprise(defaultEnterprise);
  }, [defaultEnterprise]);


  return (
    <div className={`relative w-full ${containerClassName}`}>
      <input name={name} className="hidden" {...register(name)} />

      <Input
        value={state.selectedEnterprise?.name || t('select enterprise')}
        placeholder={t('select enterprise')}
        onFocus={e => e.target.blur()}
        onClick={e => setState(prev => ({...prev, showModal: e}))}
        readOnly
        {...props}
      />
      <AnimatedModal normalClose={false} className={s.modal} visible={state.showModal}
                     onClose={() => setState(prev => ({...prev, showModal: false}))}>
        <div className={s.container}>
          <div className="sticky">
            <Input
              label={t("search")}
              placeholder={t("name of the enterprise") + '...'}
              onChange={onSearch}
            />
          </div>

          {!state.enterprises.length && <Text className="ml-2 mt-3 block">{t("not found")}</Text>}

          <div className={s.enterprises}>
            {state.enterprises.filter(filterEnterprise).map((enterprise, i) => (
              <div className={s.enterprise} key={i} onClick={() => {
                selectEnterprise(enterprise);
                setState(prev => ({...prev, showModal: false}));
              }}>
                <Text>{enterprise.name}</Text>
                <br/>
                <SmallText className={s.small_text}>{enterprise.web_page}</SmallText>
              </div>
            ))}
          </div>
        </div>
      </AnimatedModal>
    </div>
  );
});


export default SelectEnterprise;
