import {query} from "../../helpers/query";

export const useFavorites = () => {
  const setFavorite = async (id, type) => {
    const {error, data} = await query(`/api/favorite`, {id, type}, 'POST');
    return {error, success: data?.success};
  }
  const getFavorites = async type => {
    const {error, data} = await query(`/api/favorite/${type}`, null, 'GET');
    return {error, favorites: data?.favorites};
  }

  return {setFavorite, getFavorites};
}
