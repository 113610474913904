import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";

export const useTableColumns = () => {
  const {t} = useTranslation();

  const demandColumnData = useMemo(() => [
    {
      Header: t('enterprise'),
      accessor: 'enterprise',
    },
    {
      Header: t('person'),
      accessor: 'person',
    },
    {
      Header: 'm^2 Máx',
      accessor: 'max_size',
    },
    {
      Header: 'm^2 Min',
      accessor: 'min_size',
    },
    {
      Header: t('type'),
      accessor: 'type',
    },
    {
      Header: t('usage'),
      accessor: 'usage',
    },
    {
      Header: t('condition'),
      accessor: 'condition',
    },
    {
      Header: 'Última actualización',
      accessor: 'updatedAt'
    }
  ], [t]);

  const clientColumnData = useMemo(() => [
    {
      Header: t('client'),
      accessor: 'client',
    },
    {
      Header: t('type'),
      accessor: 'enterprise_type',
    },
    {
      Header: t('enterprise'),
      accessor: 'enterprise',
    },
    {
      Header: t('email'),
      accessor: 'email',
    },
    {
      Header: t('phone'),
      accessor: 'phone',
    }
  ], [t]);

  const assetColumnData = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'asset_id',
      size: 6
    },
    {
      Header: 'Titulo',
      accessor: 'title'
    },
    {
      Header: t('type'),
      accessor: 'type',
    },
    {
      Header: t('usage'),
      accessor: 'usage',
    },
    {
      Header: t('condition'),
      accessor: 'condition'
    },
    {
      Header: t('address'),
      accessor: 'address',
    },
    {
      Header: t('price'),
      accessor: 'price'
    },
    {
      Header: 'Última actualización',
      accessor: 'updatedAt'
    }
  ], [t]);


  const possibleAssetColumnData = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'asset_id',
      size: 6
    },
    {
      Header: 'Titulo',
      accessor: 'title'
    },
    {
      Header: t('type'),
      accessor: 'type',
    },
    {
      Header: t('usage'),
      accessor: 'usage',
    },

    {
      Header: t('address'),
      accessor: 'address',
    },
    {
      Header: 'Estado',
      accessor: 'not_accepted_condition'
    },
    {
      Header: 'Notas',
      accessor: 'notes',
      popup: true
    },
    {
      Header: 'Última actualización',
      accessor: 'updatedAt'
    }
  ], [t]);

  const enterpriseColumnData = React.useMemo(() => [
    {
      Header: t('enterprise'),
      accessor: 'name',
    },
    {
      Header: t('type'),
      accessor: 'type',
    },
    {
      Header: t('email'),
      accessor: 'email',
    },
    {
      Header: t('phone'),
      accessor: 'phone',
    },
    {
      Header: t('address'),
      accessor: 'address',
    }
  ], [t]);

  const partnerColumnData = React.useMemo(() => [
    {
      Header: t('partner'),
      accessor: 'partner',
    },
    {
      Header: t('type'),
      accessor: 'enterprise_type',
    },
    {
      Header: t('enterprise'),
      accessor: 'enterprise',
    },
    {
      Header: t('email'),
      accessor: 'email',
    },
    {
      Header: t('phone'),
      accessor: 'phone',
    }
  ], [t]);

  const operationColumnData = React.useMemo(() => [
    {
      Header: 'ID',
      accessor: 'operation_id',
      size: 6
    },
    {
      Header: t('name'),
      accessor: 'asset_title'
    },
    {
      Header: 'Contacto',
      accessor: 'asset_contact'
    },
    {
      Header: t('enterprise'),
      accessor: 'enterprise',
    },
    {
      Header: t('client'),
      accessor: 'person',
    },
    {
      Header: 'Estado',
      accessor: 'state'
    },
    {
      Header: 'Notas',
      accessor: 'notes'
    },
    {
      Header: 'Última actualización',
      accessor: 'updatedAt'
    }
  ], [t]);

  const operationTitleData = React.useMemo(() => [
    {text: 'Activo', size_n: 3},
    {text: 'Cliente', size_n: 2},
    {text: 'Operación', size_n: 3}
  ], []);

  const trackingSheetColumnData = React.useMemo(() => [
    {
      Header: 'Fecha de inicio',
      accessor: 'createdAt'
    },
    {
      Header: 'Cliente',
      accessor: 'asset_contact'
    },
    {
      Header: 'Activo',
      accessor: 'asset_title',
    },
    {
      Header: 'Captador',
      accessor: 'asset_created_by'
    },
    // {
    //   Header: 'Operación creada por',
    //   accessor: 'operation_created_by'
    // },
/*    {
      Header: 'Reparto honorarios',
      accessor: 'fee_split_partner'
    },*/
    // {
    //   Header: 'Honorarios vendedor',
    //   accessor: 'seller_fees',
    // },
    {
      Header: 'Honorarios',
      accessor: 'fee_payer'
    },
    {
      Header: 'Importe',
      accessor: 'price'
    },
    {
      Header: 'Estimación facturación',
      accessor: 'billing'
    },
    {
      Header: 'Notas',
      accessor: 'notes'
    }
    // {
    //   Header: 'Última actualización',
    //   accessor: 'updatedAt',
    // }
  ], [t]);
  const trackingSheetTitleData = React.useMemo(() => [
    {text: 'Ofertante', size_n: 4},
    {text: 'Operación', size_n: 1},
    {text: '€€', size_n: 5}
  ], []);


  const possibleAssetsContactsColumnData = React.useMemo(() => [
    {
      Header: t('name'),
      accessor: 'name'
    },
    {
      Header: t('enterprise'),
      accessor: 'enterprise'
    },
    {
      Header: 'Estado',
      accessor: 'state'
    },
    {
      Header: t('email'),
      accessor: 'email'
    },
    {
      Header: t('phone'),
      accessor: 'phone'
    },
    {
      Header: 'Última actualización',
      accessor: 'updatedAt'
    }
  ], [t]);

  const tasksColumnData = React.useMemo(() => [
    {
      Header: 'Grupo',
      accessor: 'group_id'
    },
    {
      Header: 'Creador',
      accessor: 'creator_id'
    },
    {
      Header: 'Responsables',
      accessor: 'responsible'
    },
    {
      Header: 'Fecha',
      accessor: 'date'
    }
  ], []);


  return {
    demandColumnData,
    clientColumnData,
    assetColumnData,
    enterpriseColumnData,
    partnerColumnData,
    operationColumnData,
    operationTitleData,
    trackingSheetColumnData,
    trackingSheetTitleData,
    possibleAssetsContactsColumnData,
    possibleAssetColumnData,
    tasksColumnData
  };
}
