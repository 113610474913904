import React, {useEffect, useState} from 'react';
import s from './Checkbox.module.css';

const Checkbox = React.forwardRef(({id, classNameContainer, defaultValue, onClick, indeterminate, name, label,small = false,...props}, ref) => {

  const innerRef = React.useRef(ref);

  const _className = `${s.container} ${small ? s.small :''} ${classNameContainer || ''}`;
  name = name || id;
  const [_indeterminate, setIndeterminate] = useState(defaultValue === 'indeterminate');



  useEffect(() => {
    if(!innerRef.current)return;
    if(defaultValue === 'indeterminate'){
      innerRef.current.value = defaultValue;
      innerRef.current.indeterminate = true;
      innerRef.current.value = 'indeterminate';
    }else {
      innerRef.current.checked = defaultValue === '1';
    }

  }, [defaultValue, innerRef]);

  let add_ref = {};
  if(indeterminate){
    add_ref.ref = innerRef;
  }else{
    add_ref.ref = ref;
  }

  return (
    <div className={_className}>
      <input id={id || name} name={name} type="checkbox" onClick={(e) => {
        if(indeterminate) {
          const checked = !e.target.checked;
          if (checked) {
            e.target.indeterminate = true;
            e.target.checked = false;
            setIndeterminate(true);
            e.target.value = 'indeterminate';
          } else if (_indeterminate) {
            setIndeterminate(false);
            e.target.checked = false;
            e.target.value = '0';
          } else if (!checked) {
            e.target.checked = true;
            e.target.value = '1';
          }
        }
        onClick && onClick(e);
      }} {...add_ref} {...props} />
      {label && <label htmlFor={name}>{label}</label>}
    </div>
  );
});


export default Checkbox;
