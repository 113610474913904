import {useDraggable, useDroppable} from "@dnd-kit/core";
import {CSS} from "@dnd-kit/utilities";
import s from "./FileExplorer.module.css";
import folderSVG from "../../resources/svg/folder.svg";
import notFoundImageSVG from "../../resources/svg/NotFoundImage.svg";
import {ReactComponent as CheckedSVG} from "../../resources/svg/checked.svg";
import {ReactComponent as LoadingSVG} from "../../resources/svg/spinner.svg";
import React from "react";
import {ReactComponent as DOCIcon} from "../../resources/svg/doc.svg";
import {ReactComponent as PDFIcon} from "../../resources/svg/pdf.svg";
import {ReactComponent as PNGIcon} from "../../resources/svg/png.svg";
import {ReactComponent as JPGIcon} from "../../resources/svg/jpg.svg";
import {ReactComponent as SVGIcon} from "../../resources/svg/svg.svg";
import {ReactComponent as PPTXIcon} from "../../resources/svg/ext/pptx.svg";
import {ReactComponent as XLSIcon} from "../../resources/svg/xls.svg";
import {ReactComponent as FileIcon} from "../../resources/svg/file.svg";
import {ReactComponent as FolderIcon} from "../../resources/svg/folder.svg";
import {Image} from '../Image/Image';

export const MenuButton = ({text, children, containerClassName = "", ...props}) => {
  return <div className={`${s.menuButtonContainer} ${containerClassName}`} {...props}>
    <div className="mr-5">{text}</div>
    {children}
  </div>
}

export const Draggable = ({id, data, children, disabled = false,onClick = _ => _, style: _style = {}, ...props}) => {
  const {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable({
    id,
    data,
    disabled
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    ..._style,
    margin: '10px',
    transition: isDragging ? '' : 'transform .5s ease 0s',
    cursor: disabled ? 'no-drop' : 'default'
  };

  return <div ref={setNodeRef} style={style} {...listeners} {...attributes} {...props} onClick={onClick}>
    {children}
  </div>
}

export const Droppable = ({id, data, disabled = false, children, style, ...props}) => {
  const {setNodeRef} = useDroppable({
    id,
    data,
    disabled
  });

  return <div ref={setNodeRef} style={style} {...props}>
    {children}
  </div>
}

export const File = ({i, file, selected, highLight, dragging, dragAllowed, disabled, onZoom, onClick, onDoubleClick}) => {
  let containerClass = `${s.fileContainer} `;
  if (selected) containerClass += `${s.selectedFileContainer} `;
  if (dragAllowed) containerClass += `${s.dragAllowedFileContainer} `;
  if (highLight) containerClass += `${s.highLightFileContainer} `;
  if(dragging)containerClass += `${s.draggingFileContainer} `;

  let Icon = file.icon;

  return <Draggable title={file.name} id={file.id} data={file} disabled={disabled} style={{zIndex: dragging ? 10 : 1}}>
    <Droppable id={file.id} data={file} disabled={disabled}>
      <div className={containerClass}
           style={{animationDelay: (.05 * i) + 's'}}
           onDoubleClick={() => onDoubleClick(file)}
           onClick={() => onClick(file)}
      >
        {(!file.icon) ?
          <Image src={file?.image?.src} images={[file.isFolder ? folderSVG : notFoundImageSVG]} alt={file.name}
                 width={100} height={100}/>
          : <Icon width={100} height={100}/>}

        {/*        {file.isImage &&
        <div className={s.zoomContainer}>
          <ZoomInSVG onClick={() => onZoom(file)} width={20} height={20}/>
        </div>}*/}

        <div className={s.fileName}>{file.name}</div>
        {selected && <div className={s.checked}>
          <CheckedSVG width={20} height={20}/>
        </div>}



        {disabled && <div className={s.disabled}><LoadingSVG/></div>}

      </div>
    </Droppable>
  </Draggable>
}



export const fileExt = file => {
  if (!file) return 'file';
  let ext = file.name.split('.');
  return ext.length > 0 ? ext[ext.length - 1] : 'file';
}

export const loadFileIcon = file => {
  let icon;

  if (file.isFolder) return FolderIcon;

  let ext = fileExt(file);

  switch (ext.toLowerCase()) {
    case 'doc':
    case 'docx':
      icon = DOCIcon;
      break;
    case 'pdf':
      icon = PDFIcon;
      break;
    case 'png':
    case 'gif':
      icon = PNGIcon;
      break;
    case 'jpg':
    case 'jpeg':
      icon = JPGIcon;
      break;
    case 'svg':
      icon = SVGIcon;
      break;
    case 'pptx':
      icon = PPTXIcon;
      break;
    case 'xlx':
    case 'xlsx':
      icon = XLSIcon;
      break;
    default:
      icon = FileIcon;
  }
  return icon;
}
