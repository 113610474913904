import React, {useEffect, useState} from 'react';
import s from './Loader.module.css';
import MiddleText from "../TextComponents/MiddleText";
import Skeleton from "react-loading-skeleton";

const Loader = props => {
  const [visible, setVisible] = useState();

  useEffect(() => {
    const id = setTimeout(() => setVisible(true), 500);
    return () => clearTimeout(id);
  }, []);


  return (<div>
      {visible && <div>
      <Skeleton height={200} />
      <Skeleton height={100} count={4}/>
      </div>}
    </div>

  );
};


export default Loader;
