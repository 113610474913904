import React, {useEffect, useState} from 'react';
import s from './Menu.module.css';
import menu from '../../../routes/menu';
import logo from '../../../resources/images/logo/logo.png';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useAuth from "../../../providers/AuthProvider/useAuth";
import {isMobile, isIPod13, useDeviceData, isTablet} from "react-device-detect";
import bases from "../../../routes/bases";


const match = (path, nav) => path.match(`^${nav.path}.*`);

const Menu = ({toggleMenu, smallMenu}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const {getAdmin} = useAuth();
  const navigate = useNavigate();




  const navClass = (nav) => {
    let path = location.pathname;
    if (path.length > 1) path = path.substring(1);
    let find = `${match(path, nav) === null ? '' : s.selected}`;
    if (nav.path === '/' && !menu.find(n => match(path, n))) find = s.selected;
    return `${s.element} ${find}`;
  }

  useEffect(() => {
    //alert(isTablet);
    if (!isMobile || isTablet) return;
    let path = location.pathname;
    const allowed = ['/demand/new', '/assets_map'];
    if (!allowed.includes(path) && !(/\/demand\/show\/d*/.test(path))) navigate(`/${bases.assets_map}`);
  }, [location.pathname]);

  return (
    <div className={`${s.container} ${smallMenu ? s.smallContainer : ''}`}>
      <div className="text-2xl p-3">
        Atomiun Services | CCRM
      </div>
{/*
      <img className="m-auto" src={logo} width={160} alt="logo"/>
*/}
      <div className={s.menu}>
        {menu.filter(nav => getAdmin().full_access || !nav.secured).map((nav, i) => {
          let linkClassName = `${s.link}`;
          return (<NavLink to={'/' + nav.path} key={i} className={linkClassName}>
            <div className={navClass(nav)}>
              <span>{t('pages.' + nav.text)}</span>
            </div>
          </NavLink>);
        })}
      </div>
    </div>);
};


export default Menu;
