import React, {useState} from 'react';
import s from './DemandShow.module.css';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import useDemand from "../../../controllers/useDemand";
import {useTranslation} from "react-i18next";
import bases from "../../../routes/bases";
import NavBarShow from "../../NavBarShow";
import {useError} from "../../../hooks/useError/useError";
import Loader from "../../Loader";
import TableV2 from "../../TableV2";
import {useTableColumns} from "../../../hooks/useTableColumns";
import ShowAddress from "../../ShowAddress";
import Accordion from "../../Accordion";
import ShowTableV2 from "../../ShowTableV2";
import {Column, Row} from "../../ShowTableV2/ShowTableV2";
import {transform} from "../../../helpers/util";
import MiddleText from "../../TextComponents/MiddleText";
import {isMobile} from "react-device-detect";
import {ReactComponent as WhiteBell} from "../../../resources/svg/bell.svg";
import MarkedAreasReadOnly from "../../MarkedAreasReadOnly";
import {investmentProfileOptions} from "../../Inputs/SelectInvestmentProfile/SelectInvestmentProfile";
import {sellTypeOptions} from "../../Inputs/SellType/SellType";

const DemandShow = props => {
  const {t} = useTranslation();
  const {error, ShowError, manageError} = useError();
  const {assetColumnData} = useTableColumns();

  const {id: demand_id} = useParams();
  const {getDemand, deleteDemand, getDemandAssets} = useDemand();
  const navigate = useNavigate();

  const [demand, setDemand] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const [drawnArea, setDrawnArea] = useState(undefined);

  const loadDemand = async () => {
    const {error, demand} = await getDemand(demand_id);
    setLoading(false);
    if (error) return manageError(error);
    if (!demand) return manageError('not found');

    if(demand.provinces)demand.provinces = JSON.parse(demand.provinces);
    if(demand.drawn_area)demand.drawn_area = JSON.parse(demand.drawn_area);

    setDemand(demand);
  }
  const loadAssets = async () => {
    let {error, assets} = await getDemandAssets(demand_id);
    if (error) return manageError(error);


    assets = assets.map(a => ({...a, title: a.asset_file?.title}));
    setAssets(assets);
  }

  const onDeleteClick = async () => {
    const {error, success} = await deleteDemand(demand_id);
    if (error || !success) return manageError(error || 'unknown');
    alert(t('deleted'));
    navigate(`/${bases.demand}`);
  }
  const createOperation = asset => {
    let p = {
      enterprise_id: demand.enterprise_id,
      enterprise_name: demand.enterprise?.name,
      client_id: demand.client_id,
      client_name: demand.client?.name,
      partner_name: demand.partner?.name,
      partner_id: demand.partner_id,
      asset_id: asset.asset_id
    };
    let params = '';
    for(let key in p){
      if(p[key])params += key + '=' + p[key] + '&'
    }

    navigate('/' + bases.operation + '/new?' + params.substr(0, params.length - 1));
  }


  useState(() => {
    loadDemand();
    loadAssets();
  }, [demand_id]);

  const showInvestmentProfile = () => {
    return demand.investment_profile?.map(a => investmentProfileOptions.find(b => b.value === a)?.label)?.join(',');
  }


  if (loading) return (<Loader/>);
  if (error) return <ShowError/>

  return (
    <div className="showContainer">
      <div className="menuHeader">
        {!isMobile && <NavBarShow name={'demand'} id={demand_id} onDeleteClick={onDeleteClick}/>}
        {isMobile && <NavLink className="link_color text-3xl" to={`/${bases.assets_map}`}>Mapa</NavLink>}
        <div className="inline-flex ml-5 items-center" onClick={() => navigate(`/${bases.task}/new?type=demand&id=${demand_id}`)}>
          <WhiteBell width={15} height={15}/>
          <a href="">Tarea</a>
        </div>
      </div>
      <div className="showDataContainer">

        <div className="flex flex-wrap">
          {/*          <ShowTable data={demand}/>*/}
          <ShowTableV2>
            {demand.enterprise &&
            <Row>
              <Column title={true}>{t('enterprise')}</Column>
              <Column>{transform(demand, 'enterprise')}</Column>
            </Row>}

            {demand.client &&
            <Row>
              <Column title={true}>{t('client')}</Column>
              <Column>{transform(demand, 'client')}</Column>
            </Row>}

            <Row>
              <Column title={true}>{t('type')}</Column>
              <Column>{demand.type}</Column>
            </Row>
            <Row>
              <Column title={true}>{t('usage')}</Column>
              <Column>{demand.usage === '0' ? 'Ignorar' : demand.usage}</Column>
            </Row>
            <Row>
              <Column title={true}>{t('condition')}</Column>
              <Column>{demand.condition === '0' ? 'Ignorar' : demand.condition}</Column>
            </Row>

            {demand.min_price !== null &&
            <Row>
              <Column title={true}>{t('min price')}</Column>
              <Column>{transform({price:demand.min_price}, 'price')}</Column>
            </Row>}
            {demand.max_price !== null &&
            <Row>
              <Column title={true}>{t('max price')}</Column>
              <Column>{transform({price:demand.max_price}, 'price')}</Column>
            </Row>}


            {demand.min_rent_price !== null &&
            <Row>
              <Column title={true}>Precio mínimo de alquiler</Column>
              <Column>{transform({price:demand.min_rent_price}, 'price')}</Column>
            </Row>}
            {demand.max_rent_price !== null &&
            <Row>
              <Column title={true}>Precio máximo de alquiler</Column>
              <Column>{transform({price:demand.max_rent_price}, 'price')}</Column>
            </Row>}

            {demand.min_size !== null &&
            <Row>
              <Column title={true}>{t('min size')}</Column>
              <Column>{transform(demand.min_size, 'size')}</Column>
            </Row>}
            {demand.max_size !== null &&
            <Row>
              <Column title={true}>{t('max size')}</Column>
              <Column>{transform(demand.max_size, 'size')}</Column>
            </Row>}

            {demand.min_houses !== null &&
            <Row>
              <Column title={true}>Viviendas mínimas</Column>
              <Column>{demand.min_houses}</Column>
            </Row>}
            {demand.max_houses !== null &&
            <Row>
              <Column title={true}>Viviendas máximcas</Column>
              <Column>{demand.max_houses}</Column>
            </Row>}



            {!!demand.zone_spain &&
            <Row className="mb-0">
              <Column className={"w-full text-center font-bold"}>Toda España</Column>
            </Row>}
            {!!demand.zone_prime &&
            <Row className="mb-0">
              <Column className={"w-full text-center font-bold"}>Zona Prime</Column>
            </Row>}

            <Row>
              <Column title={true}>Perfil de inversión</Column>
              <Column>{showInvestmentProfile()}</Column>
            </Row>

            <Row>
              <Column title={true}>Tipo de venta</Column>
              <Column>{sellTypeOptions.find(v => v.value === demand?.asset_type)?.label}</Column>
            </Row>

            <Row>
              <Column title={true}>{t('updatedAt')}</Column>
              <Column>{transform(demand.updatedAt, 'date')}</Column>
            </Row>
            <Row>
              <Column title={true}>{t('createdAt')}</Column>
              <Column>{transform(demand.createdAt, 'date')}</Column>
            </Row>
          </ShowTableV2>
          {/*<ShowAddress title="Posición del activo" center={center}/>*/}

          <div className="m-auto">
            <div>
              <MiddleText>Zonas demandadas</MiddleText>
              <MarkedAreasReadOnly defaultValue={demand} setDrawnArea={setDrawnArea}/>
            </div>
          </div>


        </div>

        {demand?.notes &&
        <div className="show_separated">
          <MiddleText>Notas</MiddleText>
          <div className="p-5 mt-5 bg-gray-100">{demand.notes}</div>
        </div>}


        {assets && !isMobile &&
        <Accordion sections={[
          {
            title: 'Activos', content: <TableV2
              columns={assetColumnData}
              data={assets}
              id={"asset_id"}
              options={{
                editable: false
              }}
              onDeal={asset => createOperation(asset)}
              onView={asset_id => navigate(`/${bases.asset}/show/${asset_id}`)}
            />
          }
        ]}/>
        }
      </div>
    </div>)


};


export default DemandShow;
