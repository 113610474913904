import React, {useEffect, useState} from 'react';
import s from './FileExplorerSelectImages.module.css';
import {ReactComponent as CheckedSVG} from "../../resources/svg/checked.svg";
import AnimatedModal from "../AnimatedModal";
import FileExplorer from "../FileExplorer";

const FileExplorerSelectImages = ({
                                    containerClassName,
                                    folder,
  defaultData,
                                    resource = "asset",
                                    onSelectedImages,
                                    maxImages = 9,
                                    label
                                  }) => {
  const [selected, setSelected] = useState([]);
  const [modal, setModal] = useState(false);


  const selectImage = images => {
    setSelected(images);
    onSelectedImages && onSelectedImages(images);
  }

  useEffect(() => {
    console.log(defaultData);
    if(Array.isArray(defaultData)){
      setSelected(defaultData.filter(a => a));
    }
  }, [defaultData]);

  return (
    <div className={containerClassName}>
      <div className={s.selectContainer} onClick={() => setModal(true)}>
        {selected.length > 0 && <CheckedSVG className="inline-block mr-5" width={20} height={20}/>}
        {label}
      </div>
      <AnimatedModal
        visible={modal}
        onClose={() => setModal(false)}
        className={s.modal}
      >
        <FileExplorer
          resource={resource}
          folder={folder + ''}
          selected={selected}
          onSelect={image => selectImage(image)}
          showOnlyImages={true}
          maxNumberOfSelection={maxImages}
          defaultSelectedData={selected}
          canDownload={false}
          controllers={false}
          showNFilesSelected={true}
          selectOnlyImages={true}
          preserveSelection={true}
        />
      </AnimatedModal>
    </div>
  );
};


export default FileExplorerSelectImages;
