import React, {useState} from 'react';
import s from './PasswordInput.module.css';
import Input from "../Input";

const PasswordInput = React.forwardRef((props, ref) => {
  return (
    <Input
      type='password'
      ref={ref}
      icons={{left: {
          icon: <svg width="25" height="20" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.5 3.63078e-07C26.3919 -0.000483083 24.313 0.492849 22.4299 1.44046C20.5467 2.38808 18.9118 3.76363 17.656 5.45687C16.4002 7.15012 15.5585 9.11399 15.1983 11.1911C14.8382 13.2682 14.9696 15.4008 15.582 17.418L0 33V42H9L24.582 26.418C26.4389 26.9816 28.3954 27.1383 30.3183 26.8774C32.2412 26.6165 34.0852 25.9441 35.7247 24.9061C37.3643 23.868 38.7607 22.4887 39.819 20.8622C40.8772 19.2356 41.5724 17.4 41.8571 15.4805C42.1417 13.561 42.0093 11.6026 41.4686 9.73897C40.928 7.87528 39.992 6.15005 38.7243 4.68084C37.4566 3.21163 35.8871 2.03298 34.1227 1.22522C32.3583 0.417449 30.4405 -0.000449661 28.5 3.63078e-07V3.63078e-07ZM28.5 24C27.4673 23.9994 26.4403 23.8468 25.452 23.547L23.7315 23.025L22.461 24.2955L17.6895 29.067L15.621 27L13.5 29.121L15.5685 31.1895L13.1895 33.5685L11.121 31.5L9 33.621L11.0685 35.6895L7.758 39H3V34.242L17.703 19.539L18.975 18.2685L18.453 16.548C17.8119 14.4345 17.8535 12.1726 18.572 10.0841C19.2905 7.99567 20.6493 6.18694 22.4551 4.91524C24.2608 3.64354 26.4216 2.97362 28.63 3.0008C30.8385 3.02797 32.9821 3.75083 34.7561 5.06658C36.53 6.38232 37.8438 8.22393 38.5107 10.3295C39.1777 12.435 39.1636 14.6972 38.4707 16.7943C37.7778 18.8914 36.4412 20.7166 34.6511 22.0102C32.861 23.3039 30.7086 24.0002 28.5 24V24Z" fill="var(--color-grey)"/>
            <path d="M30 15C31.6569 15 33 13.6569 33 12C33 10.3431 31.6569 9 30 9C28.3431 9 27 10.3431 27 12C27 13.6569 28.3431 15 30 15Z" fill="var(--color-grey)"/>
          </svg>
        }}}
      {...props}
    />
  );
});


export default PasswordInput;
