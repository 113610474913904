import React, {useEffect, useState} from 'react';
import s from './OperationData.module.css';
import {useTranslation} from "react-i18next";
import useOperation from "../../../controllers/useOperation";
import Loader from "../../Loader";
import ErrorText from "../../TextComponents/ErrorText";
import {NavLink, useNavigate} from "react-router-dom";
import bases from "../../../routes/bases";
import TableV2 from "../../TableV2";
import AnimatedModal from "../../AnimatedModal";
import FilterPage from "../../FilterPage";
import FilterButton from "../../FilterButton";
import Button from "../../Inputs/Button";
import Prompt from "../../Prompt";
import {useTableColumns} from "../../../hooks/useTableColumns";
import {findAssetContact, transform} from "../../../helpers/util";
import OperationSelectState from "../OperationSelectState";
import {FormProvider, useForm} from "react-hook-form";
import {
  enterpriseFilterRenderer,
  fullNameFilter,
  includesFilter,
  includesRequiredFilter,
  numberFilter,
  numberFilterRenderer,
  objectNameFilter,
  SelectFilter,
  SelectNumberFilter,
  textareaRenderer
} from "../../FilterPage/FilterPage";
import ExportTableButton from "../../ExportTableButton";
import {useLocalStorage} from "../../../hooks/useLocalStorage/useLocalStorage";
import {DateSort} from "../../TableV2/sortFunctions";
import {ReactComponent as WhiteBell} from "../../../resources/svg/bell.svg";
import useFavorites from "../../../controllers/useFavorites";
import useAdmin from "../../../controllers/useAdmin";


const firstAllowedStates = ['por presentar', 'presentado', 'estudiando', 'muy interesado'];

const defaultStateFilter = firstAllowedStates.map(t => ({
  value: t,
  label: t[0].toUpperCase() + t.substring(1, t.length)
}));

const OperationData = ({fullPage = true, tableProps = {}, onSelect, filter: externalFilter}) => {
  const {t} = useTranslation();
  const {getOperations, deleteOperations, setOperationState, updateLastDateOperation} = useOperation();
  const [onlyFavorites, setOnlyFavorites] = useLocalStorage('operation_fav', false);

  const {operationColumnData, operationTitleData} = useTableColumns();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      state: defaultStateFilter
    }
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [filter, setFilter] = useState({
    filter: false,
    visible: false,
    data: []
  });
  const {getAdmins} = useAdmin();

  useEffect(() => {
    console.log(filter);
  }, [filter]);

  const {getFavorites, setFavorite} = useFavorites();
  const [admins, setAdmins] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const toggleFilter = v => setFilter(prev => ({...prev, visible: v ?? !prev.visible}));
  const setFilteredData = data => {
    setFilter(prev => ({...prev, data}));
  }

  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    setLoading(false);
    if (error) return setError(error);

    setAdmins(admins);
  }

  const loadOperations = async ({update = true} = {}) => {
    let {error, operations} = await getOperations(externalFilter);
    setLoading(false);
    if (error) return setError(error);


    operations = operations.map(operation => ({
      ...operation,
      asset_enterprise: transform(operation.asset, 'enterprise'),
      asset_title: transform(operation, 'asset'),
      asset_contact: findAssetContact(operation.asset),
    }));

    console.log(operations);


    setData(operations);
    if (update) setFilteredData(operations.filter(a => firstAllowedStates.includes(a.state)));
  }
  const removeElements = async () => {
    setDeleteVisible(true);
  }

  const removeConfirmed = async () => {
    setDeleteVisible(false);
    setLoading(true);
    await deleteOperations(selectedRows);
    await loadOperations();
    setSelectedRows([]);
    setLoading(false);
  }

  const changeOperationState = async (operation, state) => {
    await setOperationState(operation.operation_id, state);
    await loadOperations({update: false});
  }

  const updateLastDate = async () => {
    setLoading(true);
    const {error, success} = await updateLastDateOperation({operations: selectedRows});
    if (error) return alert('Se ha producido un error desconocido');
    await loadOperations();
    setSelectedRows([]);
    setLoading(false);
    if (success) return alert('Listo');
  }


  useEffect(() => {
    loadOperations();
    loadAdmins();
  }, []);


  if (loading) return (<Loader/>);
  if (error) return (<ErrorText>{t('errors.' + error)}</ErrorText>);

  return (
    <div className={`${fullPage ? 'pt-20' : ''}`}>
      {fullPage &&
        <div className="menuHeader">
          <NavLink to={`/${bases.operation}/new`}>{t("create new operation")}</NavLink>
          <FilterButton onClick={toggleFilter}/>
          {selectedRows?.length > 0 &&
            <>
              <FilterButton icon="remove" text={t('delete')} onClick={removeElements}/>
              <FilterButton icon="update" text={"Actualizar fecha"} onClick={() => updateLastDate()}/>
              <div className="inline-flex ml-5 items-center"
                   onClick={() => navigate(`/${bases.task}/new?type=operation&id=${selectedRows[0]}`)}>
                <WhiteBell width={15} height={15}/>
                <a href="">Tarea</a>
              </div>
            </>}

          <ExportTableButton title={"operaciones"} getData={async () => {
            let data = filter.data;

            if (onlyFavorites) {
              let {error, favorites} = await getFavorites("operation");
              favorites = favorites.map(f => f.id);
              if (!error) {
                data = data.filter(d => favorites.includes(d.operation_id));
              }
            }

            return ({
              headers: operationColumnData,
              columns: data.map(d => ({
                operation_id: d.operation_id,
                asset_title: d?.asset?.asset_file?.title,
                asset_contact: findAssetContact(d.asset)?.props?.children || findAssetContact(d.asset),
                enterprise: d?.enterprise?.name,
                person: transform(d, 'person_name'),
                state: d.state,
                notes: d.notes,
                updatedAt: transform(d.updatedAt, 'datetime')
              }))
            })
          }}/>
          <FilterButton icon="star" text="Favoritos" className={`${onlyFavorites ? 'starOn' : 'starOff'}`} size={22}
                        onClick={() => setOnlyFavorites(prev => !prev)}/>

        </div>
      }
      {!fullPage && <div className="menuHeaderFullPage">
        <FilterButton onClick={toggleFilter}/>
      </div>}
      <FormProvider {...form}>
        <TableV2
          favoritesType="operation"
          onlyFavorites={onlyFavorites}
          titles={operationTitleData}
          columns={operationColumnData}
          renders={{
            state: operation =>
              <OperationSelectState
                containerClassName="pr-5"
                defaultValue={operation.state}
                onChange={state => changeOperationState(operation, state)}
                name={"state_" + operation.operation_id}
                small={true}
              />
          }}
          sortFormatFunctions={{
            state: a => a.state
          }}

          customSortFunctions={{
            state: (pos, a, b) => {
              const orders = ['muy interesado', 'estudiando', 'presentado', 'no contestado', 'rechazado', 'por presentar', 'vendido'];
              const ia = orders.indexOf(a);
              const ib = orders.indexOf(b);
              if (ia < ib) return pos === 'up' ? 1 : -1;
              if (ib < ia) return pos === 'up' ? -1 : 1;
              return 0;
            },
            updatedAt: DateSort

          }}
          data={filter.data}
          id="operation_id"
          options={{checkbox: true, editable: true}}
          onSelect={rows => {
            setSelectedRows(rows.length > 0 ? rows : []);
            onSelect && onSelect(rows);
          }}
          onEdit={operation_id => window.open(`${window.location.origin}/${bases.operation}/${operation_id}`, '_blank')}
          {...tableProps}
        />
      </FormProvider>

      {!data.length && <div className="w-96 pl-8">
        <Button onClick={() => navigate(`/${bases.operation}/new`)} buttonStyle={3}>{t('create new')}</Button>
      </div>}


      <Prompt
        title={t("delete confirmation")}
        message={t("not reversible")}
        visible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onAccept={removeConfirmed}
      />

      <AnimatedModal
        visible={filter.visible}
        onClose={toggleFilter}
        className='filter'
      >
        <div className={s.container}>
          <FilterPage
            filterAtStart={true}
            formClassName="mb-20"
            data={data}
            filters={[
              {
                name: 'creator_id',
                label: 'Creador',
                render: SelectFilter({
                  options: admins.map(a => ({value: a.admin_id, label: a.name}))
                }),
                filter: SelectNumberFilter
              },
              {name: 'operation_id', label: 'ID', render: numberFilterRenderer, filter: numberFilter},
              {name: 'asset_title', label: 'Nombre del activo', filter: includesFilter},
              {name: 'asset_contact', label: 'Contacto', filter: includesFilter},
              {
                name: 'enterprise', label: t('enterprise'),
                filter: objectNameFilter,
                render: enterpriseFilterRenderer
              },
              {name: 'person', label: 'Cliente', filter: fullNameFilter},
              {
                name: 'state', label: 'Estado', render: (_) => {
                  return <OperationSelectState
                    filter={true}
                    label="Estado"
                    name={'state'}
                  />
                },
                filter: (data, filter) => {
                  if (!Array.isArray(filter) || filter.length === 0) return true;
                  return filter.find(({value}) => value === data);
                }
              },
              {name: 'notes', label: "Notas", render: textareaRenderer, filter: includesRequiredFilter}
            ]}
            onReset={(setValue) => {
              console.log('reset!!');
              setValue('state', defaultStateFilter);
            }}
            onFilter={data => {
              console.log(data);
              setFilteredData(data);
              toggleFilter(false);
            }}
          />
        </div>
      </AnimatedModal>
    </div>
  );
}


export default OperationData;
