import React, {useEffect, useState} from 'react';
import s from './AssetsMap.module.css';
import Map from "../Map";
import {transform} from "../../helpers/util";
import ReactDOM from "react-dom";
import bases from "../../routes/bases";

import {ReactComponent as LocationSVG} from "../../resources/svg/location.svg";

const AssetsMap = ({assets, zoom = 6.1, width, height}) => {
  const [center, setCenter] = useState([-3.9191722008377496, 39.992161071583666]);
  const [markers, setMarkers] = useState([]);

  const chooseMarkerColor = asset => {
    let color = 'rgba(59,130,246, .8)';
    if(asset.asset_condition[0].type.toLowerCase() === 'chalet')color = 'rgba(246,59,59,0.8)';
    if(asset.asset_condition[0].type.toLowerCase() === 'piso')color = 'rgb(17,171,39)';
    return color;
  }


  useEffect(() => {
    if (!Array.isArray(assets)) return;

    let center = [0, 0];
    let markers = [];
    assets.forEach(asset => {
      if (asset.lng && asset.lat) {
        center[0] += asset.lng * 1;
        center[1] += asset.lat * 1;
        const component = document.createElement('div');
        ReactDOM.render(<div>
          <h2><b>{asset.asset_file.title}</b></h2>
          <p className="flex flex-nowrap">
            <div>
              <LocationSVG width={18} height={18} className="mr-2"/>
            </div>
            {asset.address}</p>
          {asset?.second_address?.address && <p className="flex flex-nowrap">
            <div>
              <LocationSVG width={18} height={18} className="mr-2"/>
            </div>
            {asset?.second_address?.address}</p>}

          {asset.price && !!asset.accepted && <p><b>{transform(asset, 'price')}</b></p>}
          <a href={`/${bases.asset}/show/${asset.asset_id}`} target="_blank"
             className="hover:text-blue-500 font-bold text-2xl mt-5 hidden md:block">Detalles</a>
        </div>, component);
        markers.push({
          options: {
            color: chooseMarkerColor(asset)
          },
          popup: {
            options: {
              offset: 5
            },
            component
          },
          center: [asset.lng * 1, asset.lat * 1]
        });
      }
    });
    center[0] /= assets.length;
    center[1] /= assets.length;

    //setCenter(center);
    setMarkers(markers);
  }, [assets]);

  return (
    <div className={s.container} style={{width, height}}>
      <Map center={center} zoom={zoom} markers={markers}/>
    </div>
  );
};


export default AssetsMap;
