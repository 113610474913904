import React, {useState} from 'react';
import s from './EnterpriseShow.module.css';
import {useNavigate, useParams} from "react-router-dom";
import useEnterprise from "../../../controllers/useEnterprise";
import {useTranslation} from "react-i18next";
import bases from "../../../routes/bases";
import NavBarShow from "../../NavBarShow";
import {useError} from "../../../hooks/useError/useError";
import Loader from "../../Loader";
import ShowTable from "../../ShowTable";
import TableV2 from "../../TableV2";
import {useTableColumns} from "../../../hooks/useTableColumns";
import ShowAddress from "../../ShowAddress";
import Accordion from "../../Accordion";
import MiddleText from "../../TextComponents/MiddleText";
import FileUploader from "../../FileUploader";

import {ReactComponent as WhiteBell} from "../../../resources/svg/bell.svg";
import OperationData from "../../Operation/OperationData";
import ShowTaskConnectionFilter from "../../ShowTaskConnectionFilter";
import useOperation from "../../../controllers/useOperation";
import AssetData from "../../Asset/AssetData";



const EnterpriseShow = props => {
  const {t} = useTranslation();
  const {error, ShowError, manageError} = useError();
  const {clientColumnData, demandColumnData, partnerColumnData} = useTableColumns();

  const {id: enterprise_id} = useParams();
  const {getEnterprise, deleteEnterprise, getEnterpriseClients, getEnterpriseDemands, getEnterprisePartners} = useEnterprise();
  const {checkOperationsExists} = useOperation();
  const navigate = useNavigate();

  const [enterprise, setEnterprise] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [demands, setDemands] = useState([]);
  const [partners, setPartners] = useState([]);

  const loadEnterprise = async () => {
    const {error, enterprise} = await getEnterprise(enterprise_id);
    setLoading(false);
    if (error) return manageError(error);
    if (!enterprise) return manageError('not found');
    setEnterprise(enterprise);
  }
  const loadClients = async () => {
    const {error, clients} = await getEnterpriseClients(enterprise_id);
    if (error) return manageError(error);
    setClients(clients);
  }
  const loadPartners = async () => {
    const {error, partners} = await getEnterprisePartners(enterprise_id);
    if (error) return manageError(error);
    setPartners(partners);
  }
  const loadDemands = async () => {
    let {error, demands} = await getEnterpriseDemands(enterprise_id);
    if (error) return manageError(error);

    let data = demands.map(a => ({
      enterprise_id: enterprise_id,
      client_id: a.client_id,
      partner_id: a.partner_id,
    }));
    const {error: error2, operations} = await checkOperationsExists({data});
    if (error2) return manageError(error2);


    if (Array.isArray(operations)) {
      demands = demands.map(d => {
        if (operations.find(a =>
          a.client_id === d.client_id &&
          a.partner_id === d.partner_id
        )) {
          return ({
            ...d,
            exists: true
          })
        }
        return d;
      });
    }


    setDemands(demands);
  }

  const onDeleteClick = async () => {
    const {error, success} = await deleteEnterprise(enterprise_id);
    if (error || !success) return manageError(error || 'unknown');
    alert(t('deleted'));
    navigate(`/${bases.enterprise}`);
  }

  const createOperation = demand => {
    let p = {
      enterprise_id: demand.enterprise_id,
      enterprise_name: demand.enterprise?.name,
      client_id: demand.client_id,
      client_name: demand.client?.name,
      partner_name: demand.partner?.name,
      partner_id: demand.partner_id
    };
    let params = '';
    for(let key in p){
      if(p[key])params += key + '=' + p[key] + '&'
    }

    navigate('/' + bases.operation + '/new?' + params.substr(0, params.length - 1));
  }



  useState(() => {
    loadEnterprise();
    loadClients();
    loadDemands();
    loadPartners();
  }, [enterprise_id]);

  let center = [enterprise.lng * 1, enterprise.lat * 1];


  if (loading) return (<Loader/>);
  if (error) return <ShowError/>

  return (
    <div className="showContainer">
      <div className="menuHeader">
        <NavBarShow name={'enterprise'} id={enterprise_id} onDeleteClick={onDeleteClick}/>
        <div className="inline-flex ml-5 items-center" onClick={() => navigate(`/${bases.task}/new?type=enterprise&id=${enterprise_id}`)}>
          <WhiteBell width={15} height={15}/>
          <a href="">Tarea</a>
        </div>
      </div>
      <div className="showDataContainer">

        <div className="flex flex-wrap">
          <ShowTable forbiddenKeys={['enterprise_id', 'postal_code']} data={enterprise}/>
          <ShowAddress title="Posición de la empresa" center={center}/>
        </div>

        {enterprise?.notes &&
        <div className="show_separated">
          <MiddleText>Notas</MiddleText>
          <div className="p-5 mt-5 bg-gray-100">{enterprise.notes}</div>
        </div>}

        {clients && demands && partners &&
        <Accordion sections={[
          {
            title: 'Demandas de la empresa', visible: false, content: <TableV2
              columns={demandColumnData.filter(({accessor}) => accessor !== 'enterprise')}
              data={demands}
              id={"demand_id"}
              options={{
                editable: false
              }}
              onDeal={demand => createOperation(demand)}
              onView={demand_id => navigate(`/${bases.demand}/show/${demand_id}`)}
            />
          },
          {
            title: 'Hoja de Seguimiento', loadOnShow: true, visible: false, content: <OperationData fullPage={false} filter={{enterprise_id}} />
          },
          {
            title: 'Activos de la empresa', loadOnShow: true, visible: false, content: <AssetData fullPage={false} filter={{enterprise_id}}/>
          },
          {
            title: 'Clientes de la empresa', visible: false, content: <TableV2
              columns={clientColumnData.filter(({accessor}) => accessor !== 'enterprise')}
              data={clients}
              id={"client_id"}
              options={{
                editable: false
              }}
              onView={client_id => navigate(`/${bases.client}/show/${client_id}`)}
            />
          },
          {
            title: 'Partners de la empresa', visible: false, content: <TableV2
              columns={partnerColumnData.filter(({accessor}) => accessor !== 'enterprise')}
              data={partners}
              id={"partner_id"}
              options={{
                editable: false
              }}
              onView={partner_id => navigate(`/${bases.partner}/show/${partner_id}`)}
            />
          },
          {
            title: 'Tareas', loadOnShow:true, visible: false, content: <ShowTaskConnectionFilter type="enterprise" id={enterprise_id}/>
          },
          {
            title: 'Archivos', visible: false, content: <FileUploader name="enterprise_file_upload" resource="enterprise" folder={enterprise_id}/>
          }
        ]}/>
        }
      </div>
    </div>)


};


export default EnterpriseShow;
