import React, {useCallback, useState} from 'react';
import s from './LoginPage.module.css';
import {useTranslation} from "react-i18next";
import EmailInput from "../Inputs/EmailInput";
import PasswordInput from "../Inputs/PasswordInput";
import Button from "../Inputs/Button";

import validate from '../../helpers/validate';
import ErrorText from "../TextComponents/ErrorText";
import useAuth from "../../providers/AuthProvider/useAuth";
import Title from "../TextComponents/Title";

const LoginPage = props => {
  const {t} = useTranslation();
  const {login} = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const submit = useCallback(async (e, done) => {
    e.preventDefault();
    const data = {
      email: email.trim(), password
    };
    let errors = [];
    if (!data.email.match(validate.email) || data.password.length < 2) {
      errors.push('wrong_auth');
    }

    if (errors.length) {
      setErrors(errors);
    }

    let error = await login(data.email, data.password);
    if(error)setErrors([error]);
  }, [email, password]);


  return (
    <div className={s.parent}>
      <div className={s.container}>
        <div className="w-full m-auto">
          {/*
          <img src={logo} alt="logo" className={s.logo} width={200} height={200}/>
*/}
        </div>
        <div className="z-10 relative w-full lg:w-10/12 m-auto">
          <div className="w-full text-center">
            <Title className={s.loginTitle}>Login</Title>
          </div>
          <form onSubmit={submit}>
            <EmailInput placeholder={t('email')} value={email} name="email" spellCheck={false} containerClassName={s.input}
                        onChange={(e) =>setEmail(e.target.value) }/>
            <PasswordInput value={password} placeholder={t('password')} spellCheck={false} name="password" containerClassName={s.input}
                           onChange={(e) => setPassword(e.target.value)}/>
            {errors.map((error, i) => <ErrorText className={s.errorText}
                                                       key={i}>{t('errors.' + error)}</ErrorText>)}

            <div className="w-8/12 m-auto">
              <Button className="mt-8" buttonStyle={3} loadButton={true} onClick={submit}>{t("login")}</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default LoginPage;
