import React from 'react';
import {AuthContext} from "./AuthContext";
import {useLocalStorage} from "../../hooks/useLocalStorage";

import logger from '../../helpers/Logger';
import {query} from "../../helpers/query";

const AuthProvider = props => {
  const [data, setData, clearData] = useLocalStorage("authData", {});

  const downloadAdmin = async () => {
    const {error, data} = await query('/api/admin/me', {}, 'GET');
    if(error){
      logger.error('download admin failed');
      setData(prev => ({...prev, admin: false}));
      return false;
    }
    console.log(data);
    setData(prev => ({...prev, admin: data.admin}));
  }

  const login = async (email, password) => {
    const {error,data} = await query('/api/login', {email, password});

    console.log(error);
    if(error)return error;
    if(data.success){
      await downloadAdmin();
      return false;
    } // no errors
  }

  const logout = async () => {
    await query('/api/logout');
    clearData();
  }


  const isAuthenticated = () => !!data.admin;
  const getAdmin = () => data.admin;

  return (
    <AuthContext.Provider value={{isAuthenticated, getAdmin, downloadAdmin, login, logout}}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
