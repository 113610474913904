import React, {useEffect, useState} from 'react';
import s from './AssetFileConfiguration.module.css';
import Textarea from "../../Inputs/Textarea";
import {useFormContext} from "react-hook-form";
import EditableTable from "../../EditableTable";


const AssetFileConfiguration = ({table, setTable = {}}) => {
  const {register} = useFormContext();

  return (
    <div>
      <Textarea
        label="Resumen ejecutivo"
        name="file.summary"
        {...register('file.summary')}
      />
      <EditableTable table={table} setTable={setTable}/>
    </div>
  );
};


export default AssetFileConfiguration;
