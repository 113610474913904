
const routes = {
  main: '/',
  login: 'login',
  admin: 'admin',
  enterprise: 'ent',
  client: '/',
  partner: 'partner',
  asset: 'asset',
  demand: 'demand',
  config: 'config',
  task: 'task',
  operation: 'operation',
  tracking_sheet: 'ts',
  possible_asset: 'p_asset',
  assets_map: 'assets_map',
  public_docs: 'public'
};

export default routes;
