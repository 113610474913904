import React, {useEffect, useState} from 'react';
import s from './TaskEdit.module.css';
import {useTranslation} from "react-i18next";
import {useError} from "../../../hooks/useError/useError";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import bases from "../../../routes/bases";
import MiddleText from "../../TextComponents/MiddleText";
import SubmitButton from "../../Inputs/SubmitButton";
import Select from "../../Inputs/Select";
import useTask from "../../../controllers/useTask";
import useAdmin from "../../../controllers/useAdmin";
import useAuth from "../../../providers/AuthProvider/useAuth";
import Loader from "../../Loader";
import Textarea from "../../Inputs/Textarea";
import SelectElement from "../../SelectElement";
import OperationData from "../../Operation/OperationData";
import Input from "../../Inputs/Input";
import Accordion from "../../Accordion";
import DemandData from "../../Demand/DemandData";
import TaskTypeNotification from "../TaskTypeNotification";
import {parseTask} from "../../../helpers/util";
import moment from "moment";

const TaskEdit = props => {
  const {t} = useTranslation();
  const {ShowErrors, manageError} = useError();
  let {id: task_id} = useParams();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());


  const navigate = useNavigate();


  const form = useForm({
    defaultValues: {
      task: {
        periodic_month_type: 'all',
        type: 'singular',
        connect: {
          type: 'noone'
        },
        xdays: {
          start_date: moment().format('YYYY-MM-DD')
        }
      }
    }
  });
  const {
    register, handleSubmit, setValue, formState, setError: _setError,
    clearErrors, watch
  } = form;
  const {
    errors,
  } = formState;

  let typeOfConnection = watch('task.connect.type');

  const {getAllTaskGroups, editTask, createTask, getTask} = useTask();
  const {getAdmins} = useAdmin();
  const {getAdmin} = useAuth();
  const [taskGroups, setTaskGroups] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [task, setTask] = useState();
  const [loading, setLoading] = useState(true);

  const currentAdmin = getAdmin();


  const staticTypes = ['enterprise', 'partner', 'client', 'asset'];
  const typesOfConnections = React.useMemo(() => [
    {value: 'noone', label: 'Tarea sin viculo'},
    {value: 'enterprise', label: 'Empresa'},
    {value: 'partner', label: 'Partner'},
    {value: 'client', label: 'Cliente'},
    {value: 'asset', label: 'Activo'},
    {value: 'operation', label: 'Operación'},
    {value: 'demand', label: 'Demanda'}
  ], []);


  const setDefaultConnect = ({id, type}) => {
    setValue('task.connect.type', type);
    setValue('task.connect.id', id*1);
  }

  const loadGroups = async () => {
    const {error, taskGroups} = await getAllTaskGroups();
    if (error) return manageError(error);
    setTaskGroups(taskGroups);
  }

  const loadAdmins = async () => {
    const {error, admins} = await getAdmins();
    if (error) return manageError(error);
    setAdmins(admins.map(admin => ({value: admin.admin_id, label: admin.name})));
  }


  const loadTask = async (id) => {
    let {error, task} = await getTask({task_id: id});
    if(error)return alert('Error al cargar la tarea');
    task = parseTask(task);
    setValue('task.description', task.task);
    setValue('task.connect.type', task.connection_type);
    setValue('task.connect.id', task.connection_id);
    setValue('task.type', task.periodic ? 'periodic' : 'singular');
    setValue('task.periodic', task.periodic_type);

    if(task.periodic){
      if(task.periodic_type === 'month') {
        setValue('task.periodic_month_type', task.months.length === 12 ? 'all' : 'select');
        setValue('task.periodic_data.months', task.months.map(a => ({value: a * 1})));
        setValue('task.day_of_month', task.month_day);
      }else if(task.periodic_type === 'week'){
        setValue('task.weeks', task.weeks.map(a => ({value: a * 1})));
      }else if(task.periodic_type === 'xdays'){
        setValue('task.xdays.days', task.date);
        setValue('task.xdays.start_date', task.days);
      }
    }else{
      setValue('task.date', task.date);
    }
    console.log(task);
    setTask(task);
  }

  const loadData = async () => {
    setLoading(true);
    await loadGroups();
    await loadAdmins();
    if(task_id)await loadTask(task_id);
    if(params.id && params.type)setDefaultConnect(params);

    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);


  const setError = message => _setError('other', {message});
  const isNewTask = !task_id;

  let taskGroupsOptions = React.useMemo(() => {
    if (!Array.isArray(taskGroups)) return;
    return taskGroups.map(group => ({value: group.task_group_id, label: group.name}));
  }, [taskGroups]);


  const a = () => new Promise((resolve) => {
    setTimeout(() => resolve(), 5000);
  });

  const onSubmit = async ({task}) => {
    console.log(task);
    // Validar datos
    if(!task.connect.id && task.connect.type !== 'noone'){
      switch(task.connect.type){
        case 'enterprise': alert('Seleccione la empresa');break;
        case 'client': alert('Seleccione el cliente');break;
        case 'partner': alert('Seleccion el partner');break;
        case 'asset': alert('Seleccione el activo'); break;
        case 'demand': alert('Seleccione la demanda'); break;
        case 'operation': alert('Seleccione la operación'); break;
        default: break;
      }
      return;
    }

    if(task.type === 'singular' && (!task.date || !task.date.length))return alert('Seleccione la fecha de notificación');

    if(task.type === 'periodic'){
      if(task.periodic === 'month'){
        if(!task.day_of_month || !task.day_of_month.length){
          return alert('Selecciona el día del mes');
        }
      }
    }

    let data = {
      task: task.description,
      group_id: task.group_id,
      date: new Date(task.date),
      responsible: task.responsible.map(a => a.value),
      connect: task.connect,
      periodic: task.type === 'periodic',
      periodic_type: task.periodic,
      month_day: task.day_of_month,
      months: task.periodic_month_type === 'select' ? task?.periodic_data?.months.map(a => a.value) : Array.from({length: 12}, (_,i) => i + 1),
      weeks: Array.isArray(task.weeks) ? task.weeks.map(week => week.value) : null
    };

    if(task.type === 'singular'){
      if(isNaN(data.date)){
        return alert('Elija la ');
      }
    }


    if(task_id){
      const {error} = await editTask({task_id, ...data});
      if(error)return setError(error);
    }else{
      const {error} = await createTask(data);
      if(error)return setError(error);
    }
    navigate(`/${bases.task}`);
  }


  if (loading || admins.length === 0 || (task_id && !task)) return <Loader/>

  return (
    <div className="editContainer">
      <div className="menuHeader">
        <NavLink to={`/${bases.task}`}>Ver todas las tareas</NavLink>
      </div>

      <MiddleText>{isNewTask ? 'Crear nueva tarea' : 'Editar tarea'}</MiddleText>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-10 w-full pl-5">
          <div className="flex">
            <Select
              minSelect={1}
              placeholder="Seleccionar grupo"
              name="task.group_id"
              label="Grupo"
              options={taskGroupsOptions}
              defaultValue={task ? task.group_id : taskGroupsOptions[0].value}
              containerClassName="mr-10"
            />
            <Select
              minSelect={1}
              placeholder="Seleccionar responsables"
              name="task.responsible"
              label="Responsables"
              defaultValue={
                task ? admins.filter(a => task.responsible.includes(a.value + '')) : [admins.find(a => a.value === currentAdmin.admin_id)]}
              options={admins}
              isMulti={true}
            />
          </div>
          <div className="mt-10">
            <Textarea
              rows={8}
              {...register('task.description')}
              label="Descripción"
            />
          </div>

          <div className="mt-10">
            <div className="label">Vincular a</div>

            <div className="flex">
              <div className={s.staticConnectionContainer}>
                <Select
                  name="task.connect.type"
                  placeholder="Seleccionar el tipo"
                  options={typesOfConnections}
                  defaultValue={task ? typesOfConnections.find(a => a.value === task.connection_type).value : typesOfConnections[0].value}
                  onChange={(e) => {
                    console.log('change');
                    setValue('task.connect.id', '');
                  }}
                />
              </div>
              {typeOfConnection && staticTypes.includes(typeOfConnection) && typeOfConnection !== 'noone' &&

              <SelectElement
                type={typeOfConnection}
                name="task.connect.id"
              />
              }
              {typeOfConnection && !staticTypes.includes(typeOfConnection) && typeOfConnection !== 'noone' &&
              <div style={{width: '100px'}} className="flex items-end">
                <div className="label mr-5">ID</div>
                <Input {...register("task.connect.id")} type="number"/>
              </div>}
            </div>
          </div>


          {typeOfConnection === 'operation' && <Accordion sections={[
            {
              title: 'Mostar Operaciones',
              id: 'show_operations_section',
              visible: false,
              loadOnShow: true,
              content: <OperationData fullPage={false} tableProps={{singleSelect: true}} onSelect={row => {
                setValue('task.connect.id', row.length === 1 ? row[0] : '');
                if(row.length === 1)window.document.querySelector('#show_operations_section').click();
              }}
              />
            }
          ]}/>}
          {typeOfConnection === 'demand' && <Accordion sections={[
            {
              title: 'Mostar Demandas',
              id: 'show_demands_section',
              visible: false,
              loadOnShow: true,
              content: <DemandData fullPage={false} tableProps={{singleSelect: true}} onSelect={row => {
                setValue('task.connect.id', row.length === 1 ? row[0] : '');
                if(row.length === 1)window.document.querySelector('#show_demands_section').click();
              }}
              />
            }
          ]}/>}

          <TaskTypeNotification/>

          {Object.keys(errors).length > 0 && <ShowErrors errors={errors}/>}

          <div style={{width: 200, marginBottom: '30px'}} className="mt-10">
            <SubmitButton onClick={() => clearErrors('other')}
                          loading={formState.isSubmitting}>{isNewTask ? 'Guardar' : 'Editar'}</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};


export default TaskEdit;
