import {NavLink} from "react-router-dom";
import bases from "../routes/bases";
import React from "react";
import moment from "moment";

export const saveParams = (name, data) => {


  //window.history.replaceState({path: window.location.pathname + '?' + result}, '', path);
  //window.history.pushState(JSON.stringify(data), name, window.location.href);
}

export const throttle = (callback, limit) => {
  let waiting = false;
  return async function () {
    if (!waiting) {
      waiting = true;
      await callback.apply(this, arguments);
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  }
};

export const debounce = (callback, delay) => {
  let timeout = null;
  return async function (...args) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      await callback(...args);
      timeout = null;
    }, delay);
  }
}

export const transform = (value, type) => {
  if (type === 'percent') return Number(value).toFixed(2) + ' %';
  if (type === 'price') {
    if (value?.price === '-1.00') return 'N/A';
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR'
    }).format(isNaN(value?.price * 1) ? 0 : value?.price);
  }
  if (type === 'phone') return <a href={`tel:${value?.phone}`}>{value?.phone}</a>;
  if (type === 'person') return value.client_id ? transform(value, 'client') : transform(value, 'partner');


  if (type === 'enterprise')
    return <NavLink target="_blank" className="hover:underline link_color"
                    to={`/${bases.enterprise}/show/${value?.enterprise?.enterprise_id}`}>{value?.enterprise?.name}</NavLink>

  if (type === 'client')
    return <NavLink target="_blank" className="hover:underline link_color"
                    to={`/${bases.client}/show/${value?.client?.client_id}`}>{value?.client?.name}</NavLink>
  if (type === 'partner')
    return <NavLink target="_blank" className="hover:underline link_color"
                    to={`/${bases.partner}/show/${value?.partner?.partner_id}`}>{value?.partner?.name}</NavLink>

  if (type === 'date') return moment(value).format('LL')
  if (type === 'datetime') return moment(value).format('LL LT');

  if (type === 'asset')
    return <NavLink target="_blank" className="hover:underline link_color"
                    to={`/${bases.asset}/show/${value?.asset?.asset_id}`}>{value?.asset?.asset_file?.title}</NavLink>
  if (type === 'm2' || type === 'size') {
    //return value ? ((value+'').replace(/[^\d.]/g, '').replace(/\d(?=(\d{3})+\.)/g, '$&,')) + ' m²' : '';
    if (!value) return '';
    return new Intl.NumberFormat('es-ES').format(value) + ' m²';
  }
  if (type === 'person_name') {
    let p = value.client_id ? value?.client : value?.parnter;
    return p ? (p?.alias || ((p?.name || '') + ' ' + (p?.surname || ""))) : "";
  }
  if(type === 'asset_owners'){
    if(!Array.isArray(value))return '';
    let r = value.reduce((r,owner) => {
      let a = r;
      if(owner.name) a += owner.name;
      if(owner.surname) a += ' ' + owner.surname;
      return a + ',';
    }, '');
    return r.length > 0 ? r.substring(0, r.length - 1) : r;
  }
  return value;
}

export const findAssetOwner = asset => {
  if (asset.client) return asset?.client;
  if (asset.partner) return asset?.partner;


  let max_participation = 0;
  let index = -1;
  for (let i = 0; i < asset.asset_owners.length; i++) {
    let owner = asset.asset_owners[i];
    if (owner.type === 'partner') continue;
    if (owner.participation > max_participation) {
      max_participation = owner.participation;
      index = i;
    }
  }
  if (index !== -1) return asset.asset_owners[index];
  return '';
}

export const findAssetContact = (asset) => {
  if (asset.client || asset.partner) return transform(asset, 'person');
  if (!asset.asset_owners) return '';
  let max_participation = 0;
  let index = -1;

  for (let i = 0; i < asset.asset_owners.length; i++) {
    let owner = asset.asset_owners[i];
    if (owner.type === 'partner') continue;
    if (owner.participation > max_participation) {
      max_participation = owner.participation;
      index = i;
    }
  }
  if (index !== -1) return asset.asset_owners[index].name;
  return '';
}

export const findAssetContactNumber = asset => {
  if (asset.client || asset.partner) return transform(asset.client || asset.partner, 'phone');
  let max_participation = 0;
  let index = -1;

  for (let i = 0; i < asset.asset_owners.length; i++) {
    let owner = asset.asset_owners[i];
    if (owner.type === 'partner') continue;
    if (owner.participation > max_participation) {
      max_participation = owner.participation;
      index = i;
    }
  }
  if (index !== -1) return transform(asset.asset_owners[index], 'phone');
  return '';
}

export const cutText = (text, {maxLength = 200} = {}) => {
  if (typeof text !== 'string') return text;
  if (text.length >= maxLength) return text.substring(0, 197) + '...';
  return text;
}

export const hashFromString = str => {
  let hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}


export const parseTask = task => {
  let r = {...task};

  if(r.responsible)r.responsible = r.responsible.split(',');
  if(r.months)r.months = r.months.split(',');
  if(r.weeks)r.weeks = r.weeks.split(',');

  return r;

}
