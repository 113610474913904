import React from 'react';
import s from './ErrorText.module.css';

const ErrorText = ({children, className = ''}) => {
  const _className = `${s.error} ${className}`;
  return (
    <div className={_className}>{children}</div>
  );
};


export default ErrorText;
