module.exports = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -3.6539840698242188,
              40.306498120891824
            ],
            [
              -3.6532115936279292,
              40.30787264198346
            ],
            [
              -3.6478900909423824,
              40.31592284665154
            ],
            [
              -3.6465167999267583,
              40.31926045495133
            ],
            [
              -3.6415386199951167,
              40.325149949471765
            ],
            [
              -3.6367321014404292,
              40.329599449007816
            ],
            [
              -3.6314964294433594,
              40.33319809435493
            ],
            [
              -3.6250591278076167,
              40.334899205356166
            ],
            [
              -3.5937309265136714,
              40.342684512237525
            ],
            [
              -3.5779206376147844,
              40.35073056591789
            ],
            [
              -3.5657501220703125,
              40.360672330668166
            ],
            [
              -3.5482406616210938,
              40.37920637614748
            ],
            [
              -3.5303878784179683,
              40.39519549132734
            ],
            [
              -3.5255813598632812,
              40.405392130522465
            ],
            [
              -3.5235214233398438,
              40.40931350359072
            ],
            [
              -3.5025787353515625,
              40.41558722527381
            ],
            [
              -3.497772216796875,
              40.422383097039905
            ],
            [
              -3.5022354125976562,
              40.43126897044754
            ],
            [
              -3.5180282592773438,
              40.44929845762177
            ],
            [
              -3.5194015502929688,
              40.45818077435388
            ],
            [
              -3.515625,
              40.464449938054564
            ],
            [
              -3.5010337829589844,
              40.47006556704171
            ],
            [
              -3.4958839416503906,
              40.47698651021974
            ],
            [
              -3.4974288940429688,
              40.48886797514295
            ],
            [
              -3.4945106506347656,
              40.50139998815443
            ],
            [
              -3.494853973388672,
              40.510536438638624
            ],
            [
              -3.4991455078125,
              40.514321174936704
            ],
            [
              -3.528671264648437,
              40.53754694556798
            ],
            [
              -3.5516738891601562,
              40.54380869685629
            ],
            [
              -3.569869995117187,
              40.54172151146236
            ],
            [
              -3.5839462280273438,
              40.5534610833091
            ],
            [
              -3.592529296875,
              40.563633714298774
            ],
            [
              -3.887786865234375,
              40.5216290266943
            ],
            [
              -3.8970565795898438,
              40.51118899461994
            ],
            [
              -3.8991165161132812,
              40.50753459933616
            ],
            [
              -3.8946533203125,
              40.49813666850851
            ],
            [
              -3.8970565795898438,
              40.47803111892374
            ],
            [
              -3.8853836059570312,
              40.46497234195374
            ],
            [
              -3.878173828125,
              40.45321727150385
            ],
            [
              -3.880233764648437,
              40.4466857880754
            ],
            [
              -3.8771438598632812,
              40.43989237193481
            ],
            [
              -3.8901901245117188,
              40.40486926351788
            ],
            [
              -3.8901901245117188,
              40.39937891475059
            ],
            [
              -3.8610076904296875,
              40.35805621890491
            ],
            [
              -3.8620376586914062,
              40.348637376031725
            ],
            [
              -3.8586044311523438,
              40.344974137408165
            ],
            [
              -3.847274780273438,
              40.330842639095756
            ],
            [
              -3.8421249389648438,
              40.32508451346655
            ],
            [
              -3.8204956054687504,
              40.31749350640326
            ],
            [
              -3.792063761472656,
              40.311210648180285
            ],
            [
              -3.771743774414063,
              40.299952398627596
            ],
            [
              -3.7569808959960933,
              40.28476388038702
            ],
            [
              -3.7463378906249996,
              40.2782160525013
            ],
            [
              -3.730201721191406,
              40.27743027053821
            ],
            [
              -3.720245361328125,
              40.27428705136608
            ],
            [
              -3.690719604492187,
              40.27664447944312
            ],
            [
              -3.6828231811523438,
              40.28109717490234
            ],
            [
              -3.6721801757812496,
              40.29445350177531
            ],
            [
              -3.6539840698242188,
              40.306498120891824
            ]
          ]
        ]
      }
    }
  ]
};
