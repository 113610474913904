import {query} from "../../helpers/query";

export const useBackup = () => {
  const getBackups = async () => {
    const {error, data} = await query('/api/backup', false, 'GET');
    return {error, backups: data?.backups};
  }

  const newBackup = async () => {
    const {error, data} = await query('/api/backup', false, 'POST');
    return {error, success: data?.success};
  }

  const deleteBackup = async name => {
    const {error, data} = await query(`/api/backup`, {name}, 'DELETE');
    return {error, success: data?.success};
  }

  const restoreBackup = async name => {
    const {error, data} = await query('/api/backup/restore', {name}, 'POST');
    return {error, success: data?.success};
  }

  const checkStatus = async () => {
    const {error, data} = await query('/api/backup/status', false, 'GET');
    return {error, status: data?.status};
  }

  return {getBackups, newBackup, deleteBackup, restoreBackup, checkStatus};
}

export default useBackup;
